import axios from 'axios'
import history from '~/js/bootstrap/history'
import store from '~/js/bootstrap/store'
import { setLastRequestTime, resetUser } from '~/js/actions'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { getMarkSignAppHost } from './host'

const instance = axios.create({
  withCredentials: true,
  baseURL: `https://${getMarkSignAppHost()}/api/`,
  cancelTokenSource: axios.CancelToken.source,
})

instance.interceptors.request.use(function(axiosConfig) {
  const { user, i18n: { langCode } } = store.getState()

  if (langCode !== 'lt') {
    axiosConfig.baseURL = `https://${getMarkSignAppHost()}/${langCode}/api/`
  }

  if (user) {
    store.dispatch(setLastRequestTime(Date.now()))
  }

  return axiosConfig
}, null)

instance.interceptors.response.use(
  null,
  err => {
    const { response } = err

    if (!response) {
      return Promise.reject(err)
    }

    // Redirect to login page if user is not authenticated
    if (response.status === 401) {
      const { location } = window
      let targetPath

      if (location.pathname.indexOf('/login') === -1) {
        targetPath = `${location.pathname}${location.search}${location.hash}`
      }

      store.dispatch(resetUser())
      history.push(response.data.data?.target || generatePath(ROUTE_NAMES.USER_LOGIN), {
        targetPath,
      })
    }

    const message = response.data.data?.message || response.data.message

    // If response has an error message set it to Error instance
    if (message) {
      err.message = message
    }

    return Promise.reject(err)
  }
)

export default instance
