export const ENABLE_SCROLL = 'ENABLE_SCROLL'
export const DISABLE_SCROLL = 'DISABLE_SCROLL'

export const enableScroll = () => ({
  type: ENABLE_SCROLL,
})

export const disableScroll = () => ({
  type: DISABLE_SCROLL,
})
