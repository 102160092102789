import { DISABLE_SCROLL, ENABLE_SCROLL } from '~/js/actions/page'

export default (
  state = {
    scrollEnabled: true,
  },
  action
) => {
  switch (action.type) {
  case ENABLE_SCROLL:
    return { scrollEnabled: true }
  case DISABLE_SCROLL:
    return { scrollEnabled: false }
  default:
    return state
  }
}