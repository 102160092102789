// @flow

import type { Node } from 'react'
import { useEffect, useRef, useState } from 'react'

type Props = {
  html: string,
  style: {},
  onLoad?: (Event) => void
}

export default function Iframe({ html, style, onLoad }: Props): Node {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [key, setKey] = useState<number>(1)

  function update(): () => void {
    const { current: iframeEl } = iframeRef

    if (!iframeEl) {
      return () => {}
    }
    if (onLoad) {
      iframeEl.addEventListener('load', onLoad)
    }

    writeIframeHtml(iframeEl, html)

    return () => onLoad && iframeEl.removeEventListener('load', onLoad)
  }

  function remount() {
    setKey(key + 1)
  }

  useEffect(update)
  useEffect(remount, [html])

  if (!html) {
    return null
  }

  return <iframe key={key} ref={iframeRef} style={style} />
}

function writeIframeHtml(iframeEl: HTMLIFrameElement, html: string): void {
  const { contentDocument } = iframeEl

  // Iframe is not writable
  if (contentDocument === null) {
    return
  }

  contentDocument.open()
  contentDocument.write(html)
  contentDocument.close()
}