import React from 'react'
import PropTypes from 'prop-types'
import Select from '../Select'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { AUTH_TYPE_SMARTID, AUTH_TYPE_MOBILEID, AUTH_TYPE_SMARTCARD, AUTH_TYPE_LTID } from '~/js/services/user/user'
import withErrorTranslation from '../../hoc/withErrorTranslation'

export default class FormLogin extends React.Component {
  static propTypes = {
    authType: PropTypes.string,
    btnSubmitText: PropTypes.string,
    onSubmit: PropTypes.func,
    t: PropTypes.func,
    loginMode: PropTypes.string,
    registrationMode: PropTypes.string,
    handleOnCountry: PropTypes.func,
    location: PropTypes.object,
    isLoading: PropTypes.bool,
    countryOptions: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)

    this.countryOptions = props.countryOptions
    this.countryDefaultOption = this.countryOptions[0]
  }

  onSubmit(values, props) {
    const { authType, onSubmit, location } = this.props

    let formValues = {
      country: location.state.country,
      personCode: values.personCode,
    }

    if (authType === AUTH_TYPE_MOBILEID) {
      formValues.phoneNumber = '' + values.phoneNumberCode + values.restPhoneNumber
    } else if (authType === AUTH_TYPE_SMARTCARD) {
      formValues = {
        devicePassword: values.smartCardPassword,
      }
    }

    onSubmit(authType, formValues, props)
  }

  render() {
    const { t, location } = this.props
    const btnSubmitText = this.props.btnSubmitText || t('user.login.login')
    const TranslatedErrorMessage = withErrorTranslation('span', 'form-group__error')
    let currentCountry = this.countryDefaultOption
    let initialValues
    let validationSchema

    if (location && location.state) {
      currentCountry = this.countryOptions.find(c => c.value === location.state.country) || currentCountry
    }

    if (this.props.authType === AUTH_TYPE_SMARTID || this.props.authType === AUTH_TYPE_LTID) {
      initialValues = { country: currentCountry.value, personCode: '' }
      validationSchema = Yup.object().shape({
        country: Yup.string()
          .required('validation.field_is_required'),
        personCode: Yup.string()
          .required('validation.field_is_required'),
      })
    } else if (this.props.authType === AUTH_TYPE_MOBILEID) {
      initialValues = { country: currentCountry.value, personCode: '', phoneNumberCode: currentCountry.phone, restPhoneNumber: '' }
      validationSchema = Yup.object().shape({
        country: Yup.string()
          .required('validation.field_is_required'),
        restPhoneNumber: Yup.string()
          .required('validation.field_is_required'),
        personCode: Yup.string()
          .required('validation.field_is_required'),
      })
    } else if (this.props.authType === AUTH_TYPE_SMARTCARD) {
      initialValues = { smartCardPassword: '' }
      validationSchema = Yup.object().shape({
        smartCardPassword: Yup.string()
          .required('validation.field_is_required'),
      })
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        {props => (
          <Form>
            {!this.props.loginMode && !this.props.registrationMode && (
              <div className={'form-group form-group--select' + (props.errors.country && props.touched.country ? ' form-group--error' : '')}>
                <label>{t('user.login.country')}</label>
                <Select
                  name="country"
                  options={this.countryOptions.filter(option => this.props.authType === AUTH_TYPE_MOBILEID ? option.value !== 'LV' : option)}
                  defaultValue={currentCountry}
                  formatOptionLabel={opt => (
                    <div className='country-image'>
                      <img src={opt.flag} alt="country-image" />
                      <span>{t(opt.label)}</span>
                    </div>
                  )}
                  onChange={option => {
                    props.setFieldValue('country', option.value)
                    props.setFieldValue('phoneNumberCode', option.phone)
                    this.props.handleOnCountry(option.value)
                  }}
                  onBlur={props.handleBlur}
                />
                <ErrorMessage name="country" component={TranslatedErrorMessage} />
              </div>
            )}

            {(this.props.loginMode && this.props.authType !== AUTH_TYPE_SMARTCARD || this.props.registrationMode && this.props.authType !== AUTH_TYPE_SMARTCARD) && (
              <div className={'form-group' + (props.errors.personCode && props.touched.personCode ? ' form-group--error' : '')}>
                <label>{t('user.login.person_code')}</label>
                <Field className="form-group__input" type="text" name="personCode" />
                <ErrorMessage name="personCode" component={TranslatedErrorMessage} />
              </div>
            )}

            {(this.props.loginMode && this.props.authType === AUTH_TYPE_SMARTCARD) &&
              <div className={'form-group' + (props.errors.smartCardPassword && props.touched.smartCardPassword ? ' form-group--error' : '')}>
                <label>{t('user.login.device_password')}</label>
                <Field className="form-group__input" type="password" name="smartCardPassword" />
                <ErrorMessage name="smartCardPassword" component={TranslatedErrorMessage} />
              </div>
            }

            {((this.props.loginMode || this.props.registrationMode) && this.props.authType === AUTH_TYPE_MOBILEID) && (
              <div className={'form-group' + (props.errors.restPhoneNumber && props.touched.restPhoneNumber ? ' form-group--error' : '')}>
                <label>{t('user.login.phone_number')}</label>
                <Field className="form-group__input form-number-code" type="text" name="phoneNumberCode" disabled={true} />
                <Field className="form-group__input form-rest-phone-number" type="text" name="restPhoneNumber" />
                <ErrorMessage name="restPhoneNumber" component={TranslatedErrorMessage} />
              </div>
            )}

            {(this.props.loginMode || this.props.registrationMode) && (
              <button
                type="submit"
                className={`btn btn--primary login-button ta-center ${props.isSubmitting || this.props.isLoading ? 'loading' : ''}`}
                disabled={props.isSubmitting || this.props.isLoading}
              >
                {btnSubmitText}
              </button>
            )}
          </Form>
        )}
      </Formik>
    )
  }
}
