import React from 'react'
import { Link } from 'react-router-dom'
import errorLogo from '~/img/icons/error-logo.svg'
import { withTranslation } from 'react-i18next'
import { generatePath } from '~/js/routes/router'
import PropTypes from 'prop-types'

class Error extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t, errorMessage } = this.props

    return (
      <div className="login-error">
        <div className="login-error--icon">
          <img src={errorLogo} alt="Error Logo" />
        </div>
        {errorMessage &&
          <div className="reback-button">
            <span>{t(errorMessage.errorText)}&nbsp;</span>
            {errorMessage.errorLink &&
              <Link
                to={{ pathname: generatePath(errorMessage.errorLink) }}
                target="_blank"
              >
                <span>{t(errorMessage.errorLinkText)}</span>
              </Link>
            }
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(Error)

Error.propTypes = {
  errorMessage: PropTypes.object,
  t: PropTypes.func,
}
