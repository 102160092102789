import { ACTION_ADD_CART_ITEM, ACTION_REMOVE_CART_ITEM, ACTION_CLEAR_CART } from '~/js/actions'

const cartItems = (state = [], action) => {
  switch (action.type) {
  case ACTION_ADD_CART_ITEM:
    return [
      ...state,
      {
        id: action.id,
        product: action.product,
        quantity: action.quantity,
      },
    ]
  case ACTION_REMOVE_CART_ITEM:
    return state.filter(itm => itm.id !== action.id)
  case ACTION_CLEAR_CART:
    return []
  default:
    return state
  }
}

export default cartItems