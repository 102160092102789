import React from 'react'
import { useTranslation } from 'react-i18next'
import Login from '~/js/containers/LoginRoute'
import SmartID from '~/img/smart-id.svg'
import * as userService from '~/js/services/user/user'

function SmartIdRegister(props) {
  const { t } = useTranslation()

  return <Login
    title={t('user.login.register_smartid')}
    imgSrc={SmartID}
    btnSubmitText={t('common.register')}
    registerMode="smartId"
    authType={userService.AUTH_TYPE_SMARTID}
    {...props}
  />
}

export default SmartIdRegister