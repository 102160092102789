import MobileID from '~/img/mobile-id-sm.svg'
import SmartID from '~/img/smart-id.svg'
import ZealID from '~/img/icons/zealid.svg'
import SmartCard from '~/img/icons/smartCard.svg'
import SimplySign from '~/img/icons/simplysign.png'
import LtID from '~/img/icons/ltid-small.png'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import * as userService from '~/js/services/user/user'
import ltFlag from '../../../../img/flags/lithuania-flag.svg'
import lvFlag from '../../../../img/flags/latvia-flag-icon.svg'
import eeFlag from '../../../../img/flags/estonia-flag-icon.svg'
import plFlag from '../../../../img/flags/pl.svg'
import global from '../../../../img/flags/global.png'
import mono from '~/img/images/mono.png'
import prevence from '~/img/images/prevence.svg'
import saules from '~/img/images/saules.svg'
import finomarkLogo from '~/img/logo/finomark-logo.png'
import manoBankasCeo from '~/img/people/mano-bankas-ceo.jpeg'
import finomarkCeo from '~/img/people/finomark-ceo.png'

export function getLoginData(isTemporaryLogin, temporaryLoginRouteParams) {
  let authMethods = [
    {
      id: 'smartId',
      image: SmartID,
      alt: 'Smart ID',
      name: 'user.login.smart_id',
      link: isTemporaryLogin && temporaryLoginRouteParams ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_SMART_ID, temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_SMART_ID),
      reglink: generatePath(ROUTE_NAMES.USER_REGISTER_SMART_ID),
      active: true,
      className: '',
      authType: userService.AUTH_TYPE_SMARTID,
      countryCodes: ['LT', 'LV', 'EE'],
    },
    {
      id: 'mobileId',
      image: MobileID,
      alt: 'Mobile ID',
      name: 'user.login.mobile_id',
      link: isTemporaryLogin && temporaryLoginRouteParams ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_MOBILE_ID, temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_MOBILE_ID),
      reglink: generatePath(ROUTE_NAMES.USER_REGISTER_MOBILE_ID),
      active: false,
      className: '',
      authType: userService.AUTH_TYPE_MOBILEID,
      countryCodes: ['LT', 'EE'],
    },
  ]

  if (!isTemporaryLogin) {
    authMethods = authMethods.concat([
      {
        id: 'ltId',
        image: LtID,
        alt: 'LT ID',
        name: 'user.login.lt_id',
        link: generatePath(ROUTE_NAMES.USER_LOGIN_LT_ID),
        reglink: generatePath(ROUTE_NAMES.USER_REGISTER_LT_ID),
        active: false,
        className: '',
        authType: userService.AUTH_TYPE_LTID,
        countryCodes: ['LT'],
      },
      {
        id: 'simplySign',
        image: SimplySign,
        alt: 'Simple Sign',
        name: 'user.login.simply_sign',
        link: isTemporaryLogin && temporaryLoginRouteParams ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_SIMPLY_SIGN, temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_SIMPLY_SIGN),
        reglink: generatePath(ROUTE_NAMES.USER_REGISTER_SIMPLY_SIGN),
        active: false,
        className: 'simply-sign-login',
        authType: userService.AUTH_TYPE_SIMPLYSIGN,
        countryCodes: ['PL'],
      }, {
        id: 'zealId',
        image: ZealID,
        alt: 'ZEAL ID',
        name: 'user.login.zeal_id',
        link: '',
        active: false,
        zealID: true,
        className: '',
        authType: userService.AUTH_TYPE_ZEALID,
        countryCodes: ['LT', 'LV', 'EE', 'PL', 'other'],
      }, {
        id: 'smartCard',
        image: SmartCard,
        alt: 'Smart Card',
        name: 'user.login.smart_card',
        link: isTemporaryLogin && temporaryLoginRouteParams ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_SMART_CARD, temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_SMART_CARD),
        reglink: isTemporaryLogin && temporaryLoginRouteParams ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_SMART_CARD, temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_SMART_CARD),
        active: false,
        SmartCard: true,
        className: 'smart-card-login',
        authType: userService.AUTH_TYPE_SMARTCARD,
        countryCodes: ['LT', 'LV', 'EE', 'PL'],
      }])
  }

  const countryCodes = authMethods.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.countryCodes.filter(code => !accumulator.some(existingCode => existingCode === code)))
  }, [])

  const allCountryOptions = [
    { value: 'LT', label: 'user.login.lithuania', phone: '+370', flag: ltFlag },
    { value: 'LV', label: 'user.login.latvia', phone: '+371', flag: lvFlag },
    { value: 'EE', label: 'user.login.estonia', phone: '+372', flag: eeFlag },
    { value: 'PL', label: 'user.login.poland', phone: '+48', flag: plFlag },
    { value: 'other', label: 'phone.other', phone: '', flag: global },
  ]

  const filteredCountryOptions = allCountryOptions.filter(countryOption => countryCodes.some(code => code === countryOption.value))

  const quotes = [
    {
      id: 1,
      logo: mono,
      ceoImage: manoBankasCeo,
      alt: 'Mano Bankas CEO',
      author: 'user.login.login_author_one',
      quote: 'user.login.login_quote_one',
      position: 'user.login.login_author_position_one'
    },
    {
      id: 2,
      logo: saules,
      ceoImage: null,
      alt: null,
      author: 'user.login.login_author_two',
      quote: 'user.login.login_quote_two',
      position: 'user.login.login_author_position_two'
    },
    {
      id: 3,
      logo: prevence,
      ceoImage: null,
      alt: null,
      author: 'user.login.login_author_three',
      quote: 'user.login.login_quote_three',
      position: 'user.login.login_author_position_three'
    },
    {
      id: 4,
      logo: finomarkLogo,
      ceoImage: finomarkCeo,
      alt: 'Finomark CEO',
      author: 'user.login.login_author_four',
      quote: 'user.login.login_quote_four',
      position: 'user.login.login_author_position_four'
    }
  ]

  return {
    authMethods,
    countryOptions: filteredCountryOptions,
    quotes
  }
}
