// @flow

import React, { useEffect, useState } from 'react'
import emptySearchImg from '../../../../../img/illustration/empty-search.svg'
import { SM_TYPE_ERROR } from '../../../../components/StatusMessages/StatusMessage'
import StatusMessages from '../../../../containers/StatusMessages'
import apiClient from '../../../../utils/apiClientDocs'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserNamespace } from '../../../../services/user/serviceHelpers'
import markIdLogo from '../../../../../img/marksign-logo-blue.svg'
import User from '../../../../../img/icons/user.svg'
import Company from '../../../../../img/icons/company.svg'

type Props = {
  switchWorkspace: (number) => Object,
  showStatusMessage: (string, string) => void,
  match: Object,
  history: Object,
  user: Object
}

export default function DocumentViewInvitation(props: Props): Node {
  const [error, setError] = useState({ occurred: false, text: null })
  const [workspaces, setWorkspaces] = useState([])
  const { t } = useTranslation()
  const { match, history, user, showStatusMessage, switchWorkspace } = props
  const documentId = match.params.documentId

  const showAsPersonalDocument = () => history.push({
    pathname: `/user/document/${documentId}`,
    search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
  })

  const showAsBusinessDocument = () => history.push({
    pathname: `/workspace/document/${documentId}`,
    search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
  })

  const switchToBusinessAndShowDocument = (workspaceId) => switchWorkspace(workspaceId)
    .then(
      showAsBusinessDocument()
    )

  const onWorkspaceClick = (workspaceId) => workspaceId ? switchToBusinessAndShowDocument(workspaceId) : showAsPersonalDocument()

  useEffect(() => {
    apiClient
      .post(`/${getUserNamespace()}/document/${documentId}/view-invitation`)
      .then(({ data }) => {
        if (!data.personal && !data.business) {
          setError({
            occurred: true,
            text: 'user.document.document_has_been_removed',
          })
        } else if (!data.personal && data.business.length === 1) {
          switchToBusinessAndShowDocument(data.business.pop().id)
        } else if (data.personal && !data.business) {
          showAsPersonalDocument()
        } else {
          const allowedWorkspaces = data.business
          allowedWorkspaces.map(b => b.isBusiness = true)
          if (data.personal) {
            allowedWorkspaces.unshift({
              id: null,
              businessName: user.fullName,
              initials: user.initials,
              hexColor: user.hexColor,
              isBusiness: !!user.workspaceId
            })
          }
          setWorkspaces(allowedWorkspaces)
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          setError({
            occurred: true,
            text: 'user.document.document_has_been_removed',
          })
        } else {
          showStatusMessage(SM_TYPE_ERROR, err.message)
          history.push({
            pathname: '/user/documents'
          })
        }
      })
  }, [])

  return (
    <main>
      {workspaces.length > 1 &&
        <section id="login">
          <div className="grid">
            <img src={markIdLogo} alt="Mark ID" className="login__logo" />
            <div className="login__container">
              <h5 className="ta-center">{t('user.document.choose_workspace_to_view_document')}</h5>
              <div className="login__user">
                <ul>
                  {workspaces.map((workspace) => (
                    <li key={`workspace-${workspace.id}`}>
                      <a
                        className='sidebar__user'
                        href="#"
                        onClick={e => { e.preventDefault(); return onWorkspaceClick(workspace.id) }}
                      >
                        <div className="sidebar__user-logo">
                          <img src={workspace.isBusiness ? Company : User} alt={workspace.isBusiness ? 'Company' : 'User'} />
                        </div>
                        <div className="sidebar__user-info">
                          <span>{workspace.businessName}</span>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      }
      {error.occurred && (
        <section id="empty-list">
          <div className="grid grid--flex">
            <div className="empty__content">
              <img src={emptySearchImg} alt={t(error.text)} />
              <h3 className="empty__title">{t(error.text)}</h3>
            </div>
          </div>
        </section>
      )}
      <StatusMessages />
    </main>
  )
}
