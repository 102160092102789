import React from 'react'
import InputSearch from '../InputSearch'
import ModalCategory from '../ModalCategory'
import ModalRemove from '../ModalRemove/ModalRemove'
import DocumentCategoriesList, {DocumentCategory} from '../DocumentCategoriesList'
import Pagination from '~/js/components/Pagination'
import { getUserWorkspace } from '~/js/routes/router'
import apiClient from '~/js/utils/apiClientDocs'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as settingsService from '~/js/services/user/settings'

const pageSize = 10;

export default class DocumentCategoriesSettings extends React.Component {
  constructor(props) {
    super(props);

    const queryString = this.parseQueryString();
    const pageCategories = queryString.page || 1;

    this.state = {
      modalCategory: {
        active: false,
        documentCategoryIndex: undefined
      },
      categories: [],
      allCategories: [],
      paginationCategories: {
        page: pageCategories,
        offset: this.getOffset(pageCategories),
        limit: pageSize,
        total: undefined,
      },
      modalRemoveDocumentCategory: {
        active: false,
        documentCategoryIndex: undefined
      },
      searchValue: ''
    }

    this.addCategory = this.addCategory.bind(this);
    this.editCategory = this.editCategory.bind(this);
    this.fetchCategories();
  }

  getOffset(page) {
    return (page - 1) * pageSize;
  }

  parseQueryString() {
    const search = this.props.location.search || '';
    const match = search.match(/page=(\d)?/);

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    };
  }

  onPaginationNextClick = () => {
    this.setPaginationPage(this.state.paginationCategories.page + 1);
  }

  onPaginationPreviousClick = () => {
    this.setPaginationPage(this.state.paginationCategories.page - 1);
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      paginationCategories: {
        ...prevState.paginationCategories,
        page: page,
        offset: this.getOffset(page),
      }
    }));
  }

  onAddNewCategoryClick = () => {
    this.setState({
      modalCategory: {
        active: true
      }
    })
  }

  onDocumentCategoryDeleteClick = index => {
    this.setState({
      modalRemoveDocumentCategory: {
        active: true,
        documentCategoryIndex: index,
      },
    });
  }

  onDocumentCategoryEditClick = index => {
    this.setState({
      modalCategory: {
        active: true,
        documentCategoryIndex: index
      }
    })
  }

  onSearchChange = value => {
    this.setState({ searchValue: value, paginationCategories: { page: 1, offset: this.getOffset(1), limit: pageSize } });
    this.setPaginationPage(this.state.paginationCategories.page);
    this.fetchCategories();
  }

  getCategory = () => {
    const { modalCategory, categories } = this.state;

    return categories[modalCategory.documentCategoryIndex]
  }

  onModalRemoveDocumentCategoryClick = () => {
    const { t } = this.props
    const { modalRemoveDocumentCategory, categories } = this.state;
    const category = categories[modalRemoveDocumentCategory.documentCategoryIndex];

    settingsService
      .removeDocumentCategory(category.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_category_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemoveDocumentCategory: { active: false }});
        this.fetchCategories();
      })
  }

  addCategory([categoryName]) {
    const { t } = this.props;

    return apiClient
      .post(`/v1/${getUserWorkspace()}/document/category`, {
        name: categoryName,
      })
      .then(res => {
        return res.data;
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_added')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCategory: { active: false }});
        this.fetchCategories();
      })
  }

  editCategory([categoryName]) {
    const { t } = this.props;

    return apiClient
      .post(`/v1/${getUserWorkspace()}/document/category/` + (this.getCategory()).id, {
        name: categoryName,
      })
      .then(res => {
        return res.data;
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_edited')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCategory: { active: false, documentCategoryIndex: undefined }});
        this.fetchCategories();
      })
  }

  fetchCategories() {
    const { paginationCategories, searchValue } = this.state;

    settingsService
      .getDocumentCategories(paginationCategories.offset, paginationCategories.limit, searchValue)
      .then(data => this.setCategories(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message));
  }

  setCategories(data) {
    const { data: categories, totalCount } = data;

    this.setState(prevState => ({
      categories,
      paginationCategories: {
        ...prevState.paginationCategories,
        total: totalCount,
      },
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this;

    if (state.paginationCategories.page !== prevState.paginationCategories.page) {
      this.fetchCategories();
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="settings__top">
          <div className="settings__top__left">
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.state.searchValue}
                onChange={this.onSearchChange}
                wait={this.props.wait} />
            </div>
          </div>
          <div className="settings__top__right">
            <a className="btn btn--secondary js-open-popup" onClick={this.onAddNewCategoryClick}>
              {t('user.document.plus_add_new_category')}
            </a>
          </div>
        </div>
        <div className="settings__accordion-list accordion">
          <DocumentCategoriesList>
            {this.state.categories.map((category, index) => (
              <DocumentCategory
                key={index}
                t={t}
                categoryName={category.name}
                totalDocuments={category.totalDocuments}
                color={category.color}
                onDocumentCategoryDeleteClick={this.onDocumentCategoryDeleteClick.bind(this, index)}
                onDocumentCategoryEditClick={this.onDocumentCategoryEditClick.bind(this, index)}
              />
            ))}
          </DocumentCategoriesList>
        </div>
        <Pagination
          t={t}
          offset={this.state.paginationCategories.offset}
          limit={this.state.paginationCategories.limit}
          total={this.state.paginationCategories.total}
          onNextClick={this.onPaginationNextClick}
          onPreviousClick={this.onPaginationPreviousClick} />
        <ModalCategory
          active={this.state.modalCategory.active}
          t={t}
          title={this.state.modalCategory.documentCategoryIndex !== undefined ? t('user.document.edit_category') : t('user.document.add_new_category')}
          edit={this.state.modalCategory.documentCategoryIndex !== undefined}
          name={this.state.modalCategory.documentCategoryIndex !== undefined ? (this.getCategory()).name : ''}
          editCategory={this.editCategory}
          addCategory={this.addCategory}
          onCloseClick={() => this.setState({ modalCategory: { active: false, documentCategoryIndex: undefined } })} />
        <ModalRemove
          active={this.state.modalRemoveDocumentCategory.active}
          title={t('user.document.remove_document_category')}
          message={t('user.document.are_you_sure_you_want_to_remove_category')}
          onCloseClick={() => this.setState({ modalRemoveDocumentCategory: { active: false } })}
          onRemoveClick={this.onModalRemoveDocumentCategoryClick} />
      </React.Fragment>
    )
  }
}

DocumentCategoriesSettings.defaultProps = {
  wait: 500,
}