import React from 'react'
import { getBusinessLogoUrl } from '~/js/services/user/user'
import { Link } from 'react-router-dom'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import ChevronDown from '~/img/icons/chevron-down.svg'
import LogOut from '~/img/icons/logout-nav.svg'
import PropTypes from 'prop-types'
import MobileHeader from '~/js/containers/MobileHeader'

export default class NavigationBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      customLogoUrl: '',
      isActiveSideMenu: false
    }

    getBusinessLogoUrl(props.user.workspaceId)
      .then(data => {
        if (data.logo_url === null) {
          this.setState({ customLogoUrl: markIdLogo })
        } else {
          this.setState({ customLogoUrl: data.logo_url })
        }
      })

    this.toggleClass= this.toggleClass.bind(this)
  }

  toggleClass() {
    if (this.state.isActiveSideMenu) {
      document.body.style.overflow = 'initial'
      document.body.style.position = 'initial'
    } else {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    }

    const currentState = this.state.isActiveSideMenu
    this.setState({ isActiveSideMenu: !currentState })
  }

  render() {
    const { t, user, personType } = this.props
    const totalSignatures = user.boughtSignatures + user.subscriptionSignaturesLeft

    let signaturesLeftElm

    if (user.subscriptionPayType === 'prepaid' && user.subscriptionSignaturesLimit) {
      signaturesLeftElm = `${totalSignatures}/${user.subscriptionSignaturesLimit}`
    } else if (user.subscriptionPayType === 'prepaid') {
      signaturesLeftElm = totalSignatures
    } else if (user.subscriptionPayType === 'postpaid') {
      signaturesLeftElm = t('unlimited').toLowerCase()
    }

    return (
      <nav className="navbar">
        <div className="navbar__content-desktop">
          <div className="navbar__left-side">
            <Link
              to={generatePath(ROUTE_NAMES.USER_DASHBOARD)}
            >
              <div className="navbar__logo">
                {this.state.customLogoUrl !== '' && <img src={this.state.customLogoUrl} alt="Logo" />}
              </div>
            </Link>
            <Link to={generatePath(ROUTE_NAMES.USER_DOCUMENTS)}>{t('documents')}</Link>
            <Link to={generatePath(ROUTE_NAMES.USER_PRICING)}>{t('pricing')}</Link>
            <div className="test" />
          </div>
          <div className="navbar__right-side">
            <div className="btn btn--dropdown">
              <div className="dropdown-content">
                <img src={personType === 'juridical' ? Company : User} alt={personType === 'juridical' ? 'Company' : 'User'} />
                <p className="account-title">{user.companyName? user.companyName : user.fullName}</p>
                <img className="arrow-down" src={ChevronDown} alt="Chevron Down" />
              </div>
              <div className="dropdown-menu-animation">
                <div className="dropdown-menu dropdown-menu--animated dropdown-menu-animation">
                  <ul>
                    <li>
                      <div>
                        <label>
                          {t('signatures_left')}
                        </label>
                      </div>
                      <div>
                        <p>{signaturesLeftElm}</p>
                      </div>
                    </li>
                    <li>
                      <div><label>{t('user.settings.current_plan')}:</label></div>
                      <div><p>{t(`user.pricing.${user.subscription}`)}</p></div>
                    </li>
                  </ul>
                  <Link
                    className="btn btn--primary"
                    to={generatePath(ROUTE_NAMES.USER_DOCUMENTS)}
                  >
                    <img src={LogOut} alt="Log Out" />
                    <p className="shrink-hide">{t('documents')}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileHeader
          history={this.props.history}
          canShowWorkspaceSwitch={false}
          path={this.props.path}
        />
      </nav>
    )
  }
}

NavigationBar.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
  personType: PropTypes.string,
  history: PropTypes.object,
  path: PropTypes.string,
}
