import { connect } from 'react-redux'
import { addCartItem, clearCart, foremostSignButtonClick } from '~/js/actions'
import DocumentPreview from '../routes/app/User/DocumentPreview/DocumentPreview'

const mapStateToProps = state => ({
  signatureProducts: state.productStore.signatureProducts,
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
  foremostSignButtonClick: (email, phoneNumber) => dispatch(foremostSignButtonClick(email, phoneNumber)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentPreview)