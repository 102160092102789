import React from 'react'
import PropTypes from 'prop-types'
import Status, { STATUS_WAITING, STATUS_OK, STATUS_DANGER } from '~/js/components/Status'
import {
  DOCUMENT_SIGNER_STATUS_PENDING,
  DOCUMENT_SIGNER_STATUS_SIGNED,
  DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED,
  DOCUMENT_SIGNER_STATUS_REJECTED,
  DOCUMENT_SIGNER_STATUS_CONFIRMED,
  DOCUMENT_SIGNER_STATUS_DENIED,
  SIGNATURE_INTEGRITY_VALID,
  SIGNATURE_INTEGRITY_INVALID,
  SIGNATURE_INTEGRITY_NOT_SIGNED,
  DOCUMENT_SIGNER_STATUS_REVIEWING,
  DOCUMENT_SIGNER_STATUS_PENDING_REVIEW,
  DOCUMENT_SIGNER_STATUS_REVIEWED
} from '~/js/models/Document'

const statusTypeMap = new Map(
  [
    [
      DOCUMENT_SIGNER_STATUS_PENDING,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_SIGNER_STATUS_SIGNED,
      STATUS_OK,
    ],
    [
      DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_SIGNER_STATUS_REJECTED,
      STATUS_DANGER,
    ],
    [
      SIGNATURE_INTEGRITY_VALID,
      STATUS_OK,
    ],
    [
      SIGNATURE_INTEGRITY_INVALID,
      STATUS_DANGER,
    ],
    [
      SIGNATURE_INTEGRITY_NOT_SIGNED,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_SIGNER_STATUS_REVIEWING,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_SIGNER_STATUS_CONFIRMED,
      STATUS_OK
    ],
    [
      DOCUMENT_SIGNER_STATUS_DENIED,
      STATUS_DANGER
    ],
    [
      DOCUMENT_SIGNER_STATUS_PENDING_REVIEW,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_SIGNER_STATUS_REVIEWED,
      STATUS_OK
    ]
  ]
)

export default class StatusSigner extends React.Component {
  render() {
    const { t, status, signatureLevelDesc } = this.props

    return (
      <Status
        level={signatureLevelDesc}
        type={statusTypeMap.get(status)}
        text={t(`user.document.status_${status}`)}
      />
    )
  }
}

StatusSigner.propTypes = {
  t: PropTypes.func,
  status: PropTypes.string,
  signatureLevelDesc: PropTypes.string
}