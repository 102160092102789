import React from 'react'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import uploadDocuments from '~/img/illustration/upload-documents.svg';
import {Link} from 'react-router-dom'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import { withTranslation } from 'react-i18next'

class PurchasePlan extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="page-container--full-height" id="page-container">
        <section id="pricing-success">
          <div className="grid">
            <div className="pricing-success__content">
              <img className="logo" src={markIdLogo} alt="Logo"/>
              <img src={uploadDocuments} alt="Upload documents"/>
              <h3 className="standard-title">{t('user.pricing.document_upload_limit_exceeded_please_purchase_a_subscription_plan')}</h3>
              <Link className="btn btn--primary" to={generatePath(ROUTE_NAMES.USER_PRICING)}>{t('to_pricing_page')}</Link>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation()(PurchasePlan)
