// @flow

import type { Node } from 'react'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'

type Size = 'auto' | 'small' | 'medium' | 'large' | 'full'
type Props = {
  active?: boolean,
  message?: string | number,
  title?: string,
  className?: string,
  improveMessage?: string,
  deleteContinue?: boolean,
  onCloseClick: () => void,
  closable?: boolean,
  size?: ?Size,
  children?: any,
  popupContentClassName?: string,
}

Modal.defaultProps = {
  closable: true,
  onCloseClick: () => {},
}

export default function Modal(props: Props): Node {
  const {
    message,
    deleteContinue,
    title,
    improveMessage,
    className,
    onCloseClick,
    closable,
    size,
    active,
    children,
    popupContentClassName
  } = props

  if (!active) {
    return null
  }

  const contentClassName = popupContentClassName ? `${popupContentClassName} ` : ''
  const sizeModifier = size ? ` popup__content--${size}` : ''

  return (
    <div className={'popup' + (className ? ' ' + className : '' ) + ' js-active'}>
      <div className="popup__overlay" />
      <OutsideClickHandler
        onOutsideClick={closable ? onCloseClick : () => {}}
      >
        <div className={`${contentClassName}popup__content${sizeModifier}`}>
          {closable && (
            <a className="popup__close js-popup__close" onClick={e => {e.preventDefault(); onCloseClick()}} />
          )}
          {(title || message) && !deleteContinue
            ?
            (
              <div>
                {title && <h3 className="standard-title ta-center">{title}</h3>}
                {message && <p className="ta-justify">{message}</p>}
              </div>
            )
            : improveMessage && <h3 className="standard-title ta-center">{improveMessage}</h3>
          }
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  )
}
