import React from 'react'
import mobileIdLogo from '~/img/mobile-id-new.svg'
import smartIdLogo from '~/img/smart-id.svg'
import ltIdLogo from '~/img/lt-id.png'
import Modal from '~/js/components/Modal'
import Timer from '~/js/components/Timer'
import { AUTH_TYPE_SMARTID, AUTH_TYPE_MOBILEID, AUTH_TYPE_LTID } from '~/js/services/user/user'
import PropTypes from 'prop-types'

export default class ModalControlCode extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: 'user.login.control_code',
      time: props.timeLimit,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.setState({ time: this.props.timeLimit })
    }
  }

  render() {
    const { type, t, closable } = this.props

    return (
      <Modal size="small" {...this.props} className="popup__smartid">
        {type === AUTH_TYPE_SMARTID && (
          <img src={smartIdLogo} alt="Smart-ID" />
        )}
        {type === AUTH_TYPE_MOBILEID && (
          <img src={mobileIdLogo} alt="Mobile-ID" />
        )}
        {type === AUTH_TYPE_LTID && (
          <img src={ltIdLogo} alt="LT ID" />
        )}
        <h3 className="standard-title">{t(this.state.message)}</h3>
        <span className="code">{this.props.controlCode}</span>
        <div className="content__message">
          <span className="status status--waiting">
            <Timer time={this.state.time} onTimeChange={time => this.setState({ time })} />
          </span>
          <p>{t('user.login.waiting_confirmation')}</p>
        </div>
        {closable && (
          <a
            className="cancel"
            href="#"
            onClick={e => { e.preventDefault(); return this.props.onCloseClick() }}
          >
            {t('common.cancel')}
          </a>
        )}
      </Modal>
    )
  }
}

ModalControlCode.propTypes = {
  onCloseClick: PropTypes.func,
  controlCode: PropTypes.string,
  timeLimit: PropTypes.number,
  active: PropTypes.bool,
  type: PropTypes.string,
  t: PropTypes.func,
  closable: PropTypes.bool,
}

ModalControlCode.defaultProps = {
  type: AUTH_TYPE_SMARTID,
  controlCode: '????',
  timeLimit: 90,
  closable: true,
}
