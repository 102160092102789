import React from 'react'
import ControlButtonsContact from '../../ControlButtonsContact'
import PropTypes from 'prop-types'

export default class Contact extends React.Component {
  render() {
    const { t } = this.props

    return (
      <li>
        <div className="table__body__col">
          <p><strong>{this.props.name} {this.props.surname}</strong></p>
        </div>
        <div className="table__body__col">
          <p>{this.props.email}</p>
        </div>
        <div className="table__body__col">
          <p>{this.props.category}</p>
        </div>
        <div className="table__body__col">
          <p>{this.props.phoneNumber}</p>
        </div>
        <div className="table__body__col">
          <p>{this.props.companyName}</p>
        </div>
        <div className="table__body__col">
          <p>{this.props.commentary}</p>
        </div>
        <ControlButtonsContact
          t={t}
          className="table__body__col table__body__col--overflow"
          noText={true}
          onEditClick={this.props.onContactEditClick}
          onDeleteClick={this.props.onContactDeleteClick}
        />
      </li>
    )
  }
}

Contact.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  t: PropTypes.func,
  email: PropTypes.string,
  category: PropTypes.string,
  phoneNumber: PropTypes.string,
  companyName: PropTypes.string,
  commentary: PropTypes.string,
  onContactEditClick: PropTypes.func,
  onContactDeleteClick: PropTypes.func,
}