import React from 'react'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as settingsService from '~/js/services/user/settings'
import Select from '~/js/components/Select'
import { Formik, Form } from 'formik'
import InputSearch from '../InputSearch'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'

const purposeOptions = [
  { value: 'e_signature', label: 'user.document.e_signature' },
  { value: 'copy_authentication', label: 'user.document.copy_authentication' },
  { value: 'acknowledgement', label: 'user.document.acknowledgement' },
  { value: 'visa', label: 'user.document.visa' },
  { value: 'review', label: 'user.document.review' },
  { value: 'confirmation', label: 'user.document.confirmation' },
]

const reactSelectCustomStyles = {
  menu: (provided) => ({
    ...provided,
    marginLeft: '1px',
  })
}

export default class FormContactInvite extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allCategories: [],
      contacts: [],
      searchValue: '',
      contactCategory: {
        id: null,
        fetchingByCategory: false
      },
      showBusinessPersonName: false,
      selectedPurpose: [],
      selectAllChecked: false,
      offset: 0,
      categoryOffset: 0,
      limit: 10,
      loadMore: false,
      showLoadMore: false,
      loadMoreUsingCategory: false,
    }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.setContactPurpose = this.setContactPurpose.bind(this)
    this.onSelectAllChange = this.onSelectAllChange.bind(this)
    this.onInviteContactClick = this.onInviteContactClick.bind(this)
    this.onInviteContactsClick = this.onInviteContactsClick.bind(this)
    this.onShowBusinessPersonNameSelect = this.onShowBusinessPersonNameSelect.bind(this)
    this.initializeContactsObject = this.initializeContactsObject.bind(this)
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onLoadMoreClick = this.onLoadMoreClick.bind(this)
    this.fetchContacts()
    this.getAllCategories()
  }

  getAllCategories() {
    settingsService
      .getAllContactCategories()
      .then(data => this.setState({
        allCategories: data.data
      }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchContacts() {
    const { searchValue, offset, limit, loadMore } = this.state

    settingsService
      .getContacts(searchValue, offset, limit)
      .then(data => {
        this.setState({ showLoadMore: !(data.data.length === 0 || data.data.length < limit) })

        if (!loadMore) {
          this.setState({
            contacts: this.initializeContactsObject(data.data)
          })
        } else {
          this.setState(prevState => ({
            contacts: [
              ...prevState.contacts,
              ...this.initializeContactsObject(data.data)
            ],
            loadMore: false,
          }))
        }
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchContactsByCategory(categoryId) {
    const { searchValue, categoryOffset, limit, loadMoreUsingCategory } = this.state

    settingsService
      .getContactsByCategory(categoryId, searchValue, categoryOffset, limit)
      .then(data => {
        this.setState({ showLoadMore: !(data.data.length === 0 || data.data.length < limit) })

        if (!loadMoreUsingCategory) {
          this.setState({
            contacts: this.initializeContactsObject(data.data)
          })
        } else {
          this.setState(prevState => ({
            contacts: [
              ...prevState.contacts,
              ...this.initializeContactsObject(data.data)
            ],
            loadMoreUsingCategory: false,
          }))
        }
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  initializeContactsObject(contacts) {
    return contacts.map(contact => {
      contact.index = contact.index === undefined ? null : contact.index
      contact.selected = contact.selected === undefined ? false : contact.selected
      return contact
    })
  }

  onInviteContactsClick() {
    const { contacts } = this.state
    const { t } = this.props
    const signers = []

    contacts.forEach(contact => {
      if (contact.selected) {
        const purposes = this.state.selectedPurpose
        let contactPurpose = 'e_signature'

        if (typeof purposes[contact.id] !== 'undefined') {
          contactPurpose = purposes[contact.id]
        }

        signers.push({
          name: contact.name,
          surname: contact.surname,
          email: contact.email,
          showBusinessPersonName: this.state.showBusinessPersonName,
          purpose: contactPurpose,
          phone: contact.phoneNumber
        })
      }
    })

    if (signers.length < 1) {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.you_have_not_selected_member'))
      return
    }

    this.props.addSigners(signers)
  }

  onInviteContactClick(contactId) {
    const { contacts } = this.state
    const purposes = this.state.selectedPurpose
    const contact = contacts.find(c => c.id === contactId)
    let contactPurpose = 'e_signature'

    if (typeof purposes[contactId] !== 'undefined') {
      contactPurpose = purposes[contactId]
    }

    this.props.addSigner([
      contact.name,
      contact.surname,
      contact.email,
      this.state.showBusinessPersonName,
      contactPurpose,
      contact.phoneNumber
    ])
  }

  onSearchChange(value) {
    this.setState({ searchValue: value, categoryOffset: 0, offset: 0, contacts: [] })

    if (this.state.contactCategory.fetchingByCategory) {
      this.fetchContactsByCategory(this.state.contactCategory.id)
    } else {
      this.fetchContacts()
    }
  }

  onShowBusinessPersonNameSelect() {
    this.setState({ showBusinessPersonName: !this.state.showBusinessPersonName })
  }

  setContactPurpose(contactId, selection) {
    const selectedPurpose = { ...this.state.selectedPurpose, [contactId]: selection.value }
    this.setState({
      selectedPurpose: selectedPurpose
    })
  }

  onSelectAllChange() {
    const { contacts, selectAllChecked } = this.state

    contacts.forEach(c => c.selected = !selectAllChecked)

    this.setState({ contacts, selectAllChecked: !selectAllChecked })
  }

  onContactSelect(index) {
    const { contacts } = this.state

    contacts[index].selected = !contacts[index].selected
    contacts[index].index = index

    this.setState({
      contacts,
    })
  }

  onCategoryChange(selectedOption) {
    this.setState({ categoryOffset: 0, offset: 0, contacts: [] })

    return selectedOption ? (
      this.setState({ contactCategory: { id: selectedOption.value.id, fetchingByCategory: true } }),
      this.fetchContactsByCategory(selectedOption.value.id)
    ) : (
      this.setState({ contactCategory: { id: null, fetchingByCategory: false } }),
      this.fetchContacts()
    )
  }

  onLoadMoreClick() {
    if (this.state.contactCategory.fetchingByCategory) {
      this.setState(prevState => ({
        categoryOffset: prevState.categoryOffset + prevState.limit,
        loadMoreUsingCategory: true,
      }))
    } else {
      this.setState(prevState => ({
        offset: prevState.offset + prevState.limit,
        loadMore: true,
      }))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.categoryOffset !== prevState.categoryOffset && state.loadMoreUsingCategory) {
      this.fetchContactsByCategory(state.contactCategory.id)
    } else if (state.offset !== prevState.offset && state.loadMore) {
      this.fetchContacts()
    }
  }

  render() {
    const { t } = this.props
    const initialValues = {}
    const defaultOption = purposeOptions.find(option => option.value === 'e_signature')
    const selectOptions = [
      { label: t('common.purpose'), options: purposeOptions },
    ]

    return (
      <React.Fragment>
        <div className="popup__filter">
          <Formik initialValues={initialValues} onSubmit={this.fetchContactsByCategory}>
            {props => (
              <Form>
                <div className="form-group form-group--select">
                  <label>{t('user.settings.contact.category')}</label>
                  <Select
                    name="categories"
                    options={this.state.allCategories.map(category => ({ value: category, label: category.name }))}
                    placeholder={t('common.select')}
                    isClearable
                    onChange={this.onCategoryChange}
                    onBlur={props.handleBlur}
                  />
                </div>
                <div className="form-wrap form-wrap--search">
                  <InputSearch
                    value={this.state.searchValue}
                    onChange={this.onSearchChange}
                    wait={this.props.wait}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {this.props.isBusinessPerson ?
          <div className="form-group__checkbox form-group__checkbox--show-senders-name">
            <input
              className="checkbox__document" type="checkbox" id="show-senders-name-contact"
              checked={this.state.showBusinessPersonName} onChange={this.onShowBusinessPersonNameSelect}
            />
            <label htmlFor="show-senders-name-contact">{t('user.document.show_invitation_senders_name')}</label>
          </div> : null
        }
        <div className="popup__list invite-user" id="share-js">
          <div className="form-group__checkbox">
            <input
              type="checkbox"
              id="document-all"
              onChange={this.onSelectAllChange}
              checked={this.state.selectAllChecked}
            />
            <label htmlFor="document-all">{t('common.select_all')}</label>
          </div>
          <SimpleBar style={{ maxHeight: 345 }}>
            <ul>
              {this.state.contacts.map((contact, index) => (
                <li key={contact.id}>
                  <div className="form-group__checkbox form-group__checkbox--single">
                    <input
                      className="checkbox__contact"
                      type="checkbox"
                      id={contact.id}
                      checked={contact.selected}
                      onChange={this.onContactSelect.bind(this, index)}
                    />
                    <label htmlFor={contact.id} />
                  </div>
                  <div className="invite-user__info-content">
                    <p className="invite-name">{contact.name} {contact.surname}</p>
                    <p className="email-link">
                      <a
                        className="list__email"
                        href={`mailto:${contact.email}`}
                      >{contact.email}
                      </a>
                    </p>
                    <div className="form-group form-group--select">
                      <Select
                        name={`purpose${index}`}
                        styles={reactSelectCustomStyles}
                        options={selectOptions}
                        defaultValue={[defaultOption]}
                        placeholder={t('common.select')}
                        getOptionLabel={opt => t(opt.label)}
                        onChange={(selection) => this.setContactPurpose(contact.id, selection)}
                      />
                    </div>
                  </div>
                  <div className="invite-user__single">
                    <a
                      className="btn btn--icon btn--add"
                      onClick={() => this.onInviteContactClick(contact.id)}
                    >{t('common.invite')}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
            <div className="center-element center-element--invite-user">
              {this.state.showLoadMore && (<button className="btn btn--secondary btn--assign" onClick={() => this.onLoadMoreClick()} disabled={this.state.loadMore}>{t('common.load_more')}</button>
              )}
            </div>
          </SimpleBar>
        </div>

        <div className="center-element center-element--invite-user">
          <a className="btn btn--primary btn--assign" onClick={() => this.onInviteContactsClick()}>
            {t('common.invite_selected')}
          </a>
        </div>
      </React.Fragment>
    )
  }
}

FormContactInvite.defaultProps = {
  wait: 500,
}

FormContactInvite.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  addSigner: PropTypes.func,
  isBusinessPerson: PropTypes.bool,
  wait: PropTypes.number,
  addSigners: PropTypes.func,
}