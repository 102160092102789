import React from 'react'
import * as Yup from 'yup'
import Modal from '../Modal'
import {ErrorMessage, Field, Form, Formik} from 'formik'

export default class ModalCreditCard extends React.Component {
  onCreditCardDetailsSubmit = values => {
    this.props.updateCardDetails([
      values.cardHolderName,
      values.cardNumber,
      values.expMonth,
      values.expYear,
      values.cvc
    ])
  }

  render() {
    const { t } = this.props;

    let initialValues = {
      cardHolderName: this.props.cardHolderName,
      cardNumber: this.props.cardNumber,
      expMonth: this.props.expMonth,
      expYear: this.props.expYear,
      cvc: this.props.cvc
    }
    let validationSchema = Yup.object().shape({
      cardHolderName: Yup.string()
        .required(t('validation.field_is_required')),
      cardNumber: Yup.string()
        .required(t('validation.field_is_required'))
        .length(16, t('validation.card_number_should_be_16_digits_length')),
      expMonth: Yup.number()
        .required(t('validation.field_is_required'))
        .min(1, t('validation.exp_month_should_be_between_1_and_12'))
        .max(12, t('validation.exp_month_should_be_between_1_and_12')),
      expYear: Yup.number()
        .required(t('validation.field_is_required'))
        .min(2021, t('validation.exp_year_should_be_between_this_year_and_2099', { year: new Date().getFullYear() }))
        .max(2099, t('validation.exp_year_should_be_between_this_year_and_2099', { year: new Date().getFullYear() })),
      cvc: Yup.string()
        .required(t('validation.field_is_required'))
        .length(3, t('validation.cvc_should_be_3_digits_length'))
    })

    return (
      <Modal {...this.props}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onCreditCardDetailsSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.cardHolderName && props.touched.cardHolderName ? ' form-group--error' : '')}>
                <label>{t('user.pricing.card_holder_name')}</label>
                <Field className="form-group__input" type="text" name="cardHolderName" />
                <ErrorMessage className="form-group__error" name="cardHolderName" component="span" />
              </div>

              <div className={'form-group' + (props.errors.cardNumber && props.touched.cardNumber ? ' form-group--error' : '')}>
                <label>{t('user.pricing.card_number')}</label>
                <Field className="form-group__input" type="number" name="cardNumber" />
                <ErrorMessage className="form-group__error" name="cardNumber" component="span" />
              </div>

              <div
                className={'form-group' + (props.errors.expMonth && props.touched.expMonth ? ' form-group--error' : '')}>
                <label>{t('user.pricing.exp_month')}</label>
                <Field className="form-group__input" type="number" name="expMonth"/>
                <ErrorMessage className="form-group__error" name="expMonth" component="span"/>
              </div>
              <div
                className={'form-group' + (props.errors.expYear && props.touched.expYear ? ' form-group--error' : '')}>
                <label>{t('user.pricing.exp_year')}</label>
                <Field className="form-group__input" type="number" name="expYear"/>
                <ErrorMessage className="form-group__error" name="expYear" component="span"/>
              </div>

              <div className={'form-group' + (props.errors.cvc && props.touched.cvc ? ' form-group--error' : '')}>
                <label>{t('user.pricing.cvc')}</label>
                <Field className="form-group__input" type="number" name="cvc" />
                <ErrorMessage className="form-group__error" name="cvc" component="span" />
              </div>

              <button
                type="submit"
                className="btn btn--primary ta-center mt-2"
                style={{ margin: '32px auto 0 auto' }}
                disabled={props.isSubmitting}
              >
                {t('user.pricing.submit_card_details')}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}