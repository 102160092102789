// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import IconTooltip from '../IconTooltip'

type Props = {
  dropUpOptions: any
}

export default function DropUpList(props: Props): React.Node {
  const { dropUpOptions } = props
  const { t } = useTranslation()
  const [dropUpHidden, setDropUpHidden] = React.useState<boolean>(true)

  function toggleDropUp() {
    setDropUpHidden(!dropUpHidden)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setDropUpHidden(true)}>
      <div className={`drop-up-list ${dropUpHidden ? '' : 'js-show'}`} onClick={toggleDropUp}>
        <div className="option option--current">
          <div className="option drop-up__menu-item"></div>
        </div>
        <div className="option--list">
          {dropUpOptions.map(dropUpOption => (
            <div className={(!dropUpOption.isFlexibleAccount ? 'flex-option' : '') + ' option'} key={dropUpOption.id}>
              <button
                className="drop-up-btn btn"
                onClick={dropUpOption.onClick}
                disabled={dropUpOption.btnDisabled}
              >
                {t(dropUpOption.name)}
                {!dropUpOption.isFlexibleAccount && <IconTooltip tooltipText={t('user.document.available_from_flex_tooltip')} inline={true} newLine={true} flex={true} extraClass="drop-up-pro-icon" />}
              </button>
            </div>
          )
          )}
        </div>
      </div>
    </OutsideClickHandler>
  )
}
