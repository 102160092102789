import * as userService from '~/js/services/user/user'
import * as workspaceService from '~/js/services/user/workspace'

export const ACTION_SET_LAST_REQUEST_TIME = 'SET_LAST_REQUEST_TIME'
export const ACTION_REQUEST_USER = 'REQUEST_USER'
export const ACTION_RECEIVE_USER = 'RECEIVE_USER'
export const ACTION_REQUEST_SWITCH = 'REQUEST_SWITCH'
export const ACTION_COMPLETE_SWITCH = 'COMPLETE_SWITCH'
export const ACTION_SET_SESSION_ID = 'SET_SESSION_ID'
export const ACTION_SET_SERIAL_NUMBER = 'SET_SERIAL_NUMBER'
export const ACTION_SET_SESSION_STATUS = 'SET_SESSION_STATUS'
export const ACTION_FOREMOST_SIGN_BUTTON_CLICK = 'FOREMOST_SIGN_BUTTON_CLICK'
export const ACTION_CONFIRM_EMAIL_CLICK = 'CONFIRM_EMAIL_CLICK'
export const ACTION_USER_LOGGED_IN = 'USER_LOGGED_IN'
export const ACTION_SET_AUTH_METHOD = 'SET_AUTH_METHOD'
export const ACTION_SET_AUTH_INFOS = 'SET_AUTH_INFOS'

const requestUser = () => ({
  type: ACTION_REQUEST_USER,
})

const receiveUser = (user = null, workspaces = []) => ({
  type: ACTION_RECEIVE_USER,
  user,
  workspaces
})

export const setLastRequestTime = time => ({
  type: ACTION_SET_LAST_REQUEST_TIME,
  time,
})

export const resetUser = () => receiveUser()

export const triggerUserLoggedInEvent = (user, location) => ({
  type: ACTION_USER_LOGGED_IN,
  user,
  location
})

export const fetchUser = user => dispatch => {
  dispatch(requestUser())

  return Promise.all([
    userService.getInfo(user),
    workspaceService.getWorkspaces(user, 0, -1),
  ])
    .then(([ updatedUser, { data: workspaces } ]) => dispatch(receiveUser(updatedUser, workspaces)))
    .catch(err => {
      dispatch(receiveUser())

      return Promise.reject(err)
    })
}

export const fetchUserBySessionId = sessionId => dispatch => {
  dispatch(requestUser())

  return Promise.all([
    userService.getInfoBySessionId(sessionId),
    workspaceService.getWorkspacesBySessionId(sessionId, 0, -1),
  ])
    .then(([ { data: user }, { data: workspaces } ]) => dispatch(receiveUser(user, workspaces)))
    .catch(err => {
      dispatch(receiveUser())

      return Promise.reject(err)
    })
}

const requestSwitch = () => ({
  type: ACTION_REQUEST_SWITCH,
})

const completeSwitch = user => ({
  type: ACTION_COMPLETE_SWITCH,
  user,
})

export const setSessionId = sessionId => ({
  type: ACTION_SET_SESSION_ID,
  sessionId,
})

export const setSerialNumber = serialNumber => ({
  type: ACTION_SET_SERIAL_NUMBER,
  serialNumber,
})

const setSessionStatus = sessionStatus => ({
  type: ACTION_SET_SESSION_STATUS,
  sessionStatus,
})

export const switchWorkspace = workspaceId => (dispatch, getState) => {
  let { user } = getState().user

  dispatch(requestSwitch())

  return userService
    .switchWorkspace(workspaceId)
    .then(() => {
      user = Object.assign({}, user, { workspaceId })

      return fetchUser(user)(dispatch)
    })
    .then(action => dispatch(completeSwitch(action.user)))
    .catch(() => dispatch(completeSwitch()))
}

export const getSessionStatus = () => (dispatch, getState) => {
  const sessionStatus = getState().sessionStatus

  if (sessionStatus) {
    return Promise.resolve(sessionStatus)
  }

  return fetchSessionStatus()(dispatch)
}

export const fetchSessionStatus = () => (dispatch) => {
  return userService
    .getSessionStatus()
    .then(({ data }) => {
      dispatch(setSessionStatus(data))
      return data
    })
}

export const foremostSignButtonClick = (email, phoneNumber) => ({
  type: ACTION_FOREMOST_SIGN_BUTTON_CLICK,
  email,
  phoneNumber
})

export const confirmEmailClick = person => ({
  type: ACTION_CONFIRM_EMAIL_CLICK,
  person
})

export const setAuthMethod = authMethod => ({
  type: ACTION_SET_AUTH_METHOD,
  authMethod
})

export const setAuthInfos = authInfos => ({
  type: ACTION_SET_AUTH_INFOS,
  authInfos
})
