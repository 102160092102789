import React from 'react'
import Modal from '../Modal'

export default class StatusModal extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup--session"
        active={this.props.active}>
        <div className="ta-center">
          <h3 className="standard-title">{this.props.title}</h3>
        </div>
        <br/><br/>
        <div className="center-element">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}>
            {t('common.close')}
          </button>
        </div>
      </Modal>
    )
  }
}