import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import * as userService from '~/js/services/user/user'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import ModalRemoveReason from '../ModalRemoveReason/ModalRemoveReason'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import PropTypes from 'prop-types'
import withEmailValidation from '~/js/hoc/withEmailValidation'
import ModalRemove from '../ModalRemove/ModalRemove'
import * as workspaceService from '~/js/services/user/workspace'
import * as settingsService from '~/js/services/user/settings'

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalRemoveAccount: {
        active: false
      },
      modalRemove: {
        active: false
      },
      totalCompanyUsers: null
    }

    this.canUpdateCompanyDetails = props.user.permissions.isAdmin

    this.onDeleteAccountClick = this.onDeleteAccountClick.bind(this)
    this.onProfileSettingsSubmit = this.onProfileSettingsSubmit.bind(this)
    this.onModalRemoveAccountClick = this.onModalRemoveAccountClick.bind(this)
    this.onModalRemoveWorkspaceClick = this.onModalRemoveWorkspaceClick.bind(this)
    this.onDeleteBusinessAccountClick = this.onDeleteBusinessAccountClick.bind(this)
    this.fetchCompanyPeople = this.fetchCompanyPeople.bind(this)

    this.fetchCompanyPeople()
  }

  fetchCompanyPeople() {
    settingsService
      .getBusinessPeople(null, null, this.props.user.workspaceId, null)
      .then(data => {
        this.setState({
          totalCompanyUsers: data.totalCount
        })
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onProfileSettingsSubmit(values) {
    const { t } = this.props

    const promises = [
      userService.saveUserInfo(values, this.props.user),
    ]

    if (this.props.user.workspaceId && this.canUpdateCompanyDetails) {
      promises.push(userService.saveCompanyInfo(values))
    }

    Promise.all(promises)
      .then( () => {
        this.props.fetchUser()
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.settings_were_successfully_changed'))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onModalRemoveAccountClick(values) {
    let inputValues

    if (values.reasonText !== 'other') {
      delete values.reasonTextarea

      inputValues = values.reasonText
    }

    if (values.reasonText === 'other') {
      inputValues = `${values.reasonText} ${values.reasonTextarea}`
    }

    const { t } = this.props

    userService
      .deleteAccount(this.props.user.id, { reason: inputValues })
      .then(() =>
        this.props.resetUser()
      )
      .then(() =>
        this.props.history.push(generatePath(ROUTE_NAMES.USER_LOGIN))
      )
      .then(() =>
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.workspace.account_was_successfully_removed'))
      )
      .catch(() => {
        this.setState({
          modalRemoveAccount: { active: false }
        })
        this.props.showStatusMessage(SM_TYPE_ERROR, t('user.settings.workspace.account_removal_unsuccessful'))
      })
  }

  onDeleteAccountClick() {
    this.setState({
      modalRemoveAccount: { active: true }
    })
  }

  onModalRemoveWorkspaceClick() {
    const { t } = this.props
    this.props.switchWorkspace(null)
      .then(() => {
        workspaceService
          .removeWorkspace(this.props.user.workspaceId)
          .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.workspace.workspace_was_successfully_removed')))
          .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
          .finally(() => {
            this.props.history.push(generatePath(ROUTE_NAMES.USER_DASHBOARD))
            this.props.fetchUser()
          })
      })
  }

  onDeleteBusinessAccountClick() {
    this.setState({
      modalRemove: { active: true }
    })
  }

  render() {
    const { t, user, isValidEmail } = this.props

    const initialValues = {
      name: user.name,
      surname: user.surname,
      phoneNumber: user.phoneNumber ? user.phoneNumber : '',
      email: user.emailNotConfirmed ? user.emailNotConfirmed : user.email,
      companyName: user.companyName,
      registrationCode: user.companyRegistrationCode,
      address: user.companyAddress,
      companyEmail: user.companyEmailNotConfirmed ? user.companyEmailNotConfirmed : user.companyEmail,
      businessPersonEmail: user.companyUserEmailNotConfirmed ? user.companyUserEmailNotConfirmed : user.businessPersonEmail,
      sendFullySignNotifications: user.companySendSignNotifications,
      acceptsNewsletter: user.acceptsNewsletter,
      docSignNotification: user.docSignNotification,
      docFullySignNotification: user.docFullySignNotification
    }

    let validationSchema = Yup.object().shape({
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail)
    })

    if (user.workspaceId && this.canUpdateCompanyDetails) {
      validationSchema = validationSchema.concat(Yup.object().shape({
        companyName: Yup.string().required(t('validation.field_is_required')),
        registrationCode: Yup.string().required(t('validation.field_is_required')),
        address: Yup.string().required(t('validation.field_is_required')),
        companyEmail:
            Yup.string().required(t('validation.field_is_required'))
              .email(t('validation.value_is_not_a_valid_email'))
              .nullable()
              .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail),
        businessPersonEmail:
            Yup.string().required(t('validation.field_is_required'))
              .email(t('validation.value_is_not_a_valid_email'))
              .nullable()
              .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail),
      }))
    }

    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onProfileSettingsSubmit}
        >
          {props => (
            <Form>
              { user.workspaceId === null ?
                <div className="wrapped-section">
                  <div className="wrapped-section__headline">
                    <h2 className="headline headline--standard">{t('user.settings.personal_details')}</h2>
                  </div>
                  <div className="info-details info-details--standard">
                    <ul>
                      <li>
                        <div className="info-details__label info-details__label--shorter">
                          <label>{t('common.name')}</label>
                        </div>
                        <div className="info-details__info">
                          <div className={'form-group form-group--fixed-size' + (props.errors.name && props.touched.name ? ' form-group--error' : '')}>
                            <Field className="form-group__input" type="text" name="name" disabled={true} />
                            <ErrorMessage className="form-group__error" name="name" component="span" />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="info-details__label info-details__label--shorter">
                          <label>{t('common.surname')}</label>
                        </div>
                        <div className="info-details__info">
                          <div className={'form-group form-group--fixed-size' + (props.errors.surname && props.touched.surname ? ' form-group--error' : '')}>
                            <Field className="form-group__input" type="text" name="surname" disabled={true} />
                            <ErrorMessage className="form-group__error" name="surname" component="span" />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="info-details__label info-details__label--shorter">
                          <label>{t('common.phone')}</label>
                        </div>
                        <div className="info-details__info">
                          <div className={'form-group form-group--fixed-size' + (props.errors.phoneNumber && props.touched.phoneNumber ? ' form-group--error' : '')}>
                            <Field className="form-group__input" type="text" name="phoneNumber" />
                            <ErrorMessage className="form-group__error" name="phoneNumber" component="span" />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="info-details__label info-details__label--shorter">
                          <label>{t('common.email')}</label>
                        </div>
                        <div className="info-details__info">
                          <div className={'form-group form-group--fixed-size' + (props.errors.email && props.touched.email ? ' form-group--error' : '')} >
                            <Field className="form-group__input" type="mail" name="email" />
                            <ErrorMessage className="form-group__error" name="email" component="span" />
                            {user.emailNotConfirmed && user.emailNotConfirmed === props.getFieldProps('email').value && (
                              <span className="form-group__comment">{t('user.settings.waiting_email_confirmation')}</span>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> : null
              }
              {user.workspaceId ?
                <React.Fragment>
                  <div className="wrapped-section">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard">{t('user.settings.company_details')}</h2>
                    </div>
                    <div className="info-details info-details--standard">
                      <ul>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('user.settings.company.name')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.companyName && props.touched.companyName ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="text" name="companyName" disabled={!this.canUpdateCompanyDetails} />
                              <ErrorMessage className="form-group__error" name="companyName" component="span" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('user.settings.workspace.registration_code')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.registrationCode && props.touched.registrationCode ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="text" name="registrationCode" disabled={!this.canUpdateCompanyDetails} />
                              <ErrorMessage className="form-group__error" name="registrationCode" component="span" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('user.settings.workspace.address')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.address && props.touched.address ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="text" name="address" disabled={!this.canUpdateCompanyDetails} />
                              <ErrorMessage className="form-group__error" name="address" component="span" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('common.email')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.companyEmail && props.touched.companyEmail ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="mail" name="companyEmail" disabled={!this.canUpdateCompanyDetails} />
                              <ErrorMessage className="form-group__error" name="companyEmail" component="span" />
                              {user.companyEmailNotConfirmed && user.companyEmailNotConfirmed === props.getFieldProps('companyEmail').value && (
                                <span className="form-group__comment">{t('user.settings.waiting_email_confirmation')}</span>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wrapped-section">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard">{t('user.settings.business_person_details')}</h2>
                    </div>
                    <div className="info-details info-details--standard">
                      <ul>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('common.name')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.name && props.touched.name ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="text" name="name" disabled={true} />
                              <ErrorMessage className="form-group__error" name="name" component="span" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('common.surname')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.surname && props.touched.surname ? ' form-group--error' : '')}>
                              <Field className="form-group__input" type="text" name="surname" disabled={true} />
                              <ErrorMessage className="form-group__error" name="surname" component="span" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label info-details__label--shorter">
                            <label>{t('common.email')}</label>
                          </div>
                          <div className="info-details__info">
                            <div className={'form-group form-group--fixed-size' + (props.errors.businessPersonEmail && props.touched.businessPersonEmail ? ' form-group--error' : '')} >
                              <Field className="form-group__input" type="mail" name="businessPersonEmail" />
                              <ErrorMessage className="form-group__error" name="businessPersonEmail" component="span" />
                              {user.companyUserEmailNotConfirmed && user.companyUserEmailNotConfirmed === props.getFieldProps('businessPersonEmail').value && (
                                <span className="form-group__comment">{t('user.settings.waiting_email_confirmation')}</span>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </React.Fragment> : null
              }
              <div className="wrapped-section">
                <div className="wrapped-section__headline">
                  <h2 className="headline headline--standard">{t('user.settings.email_settings')}</h2>
                </div>
                <div className={'form-group form-group__checkbox' + (props.errors.acceptsNewsletter && props.touched.acceptsNewsletter ? ' form-group--error' : '')}>
                  <Field type="checkbox" name="acceptsNewsletter" id="accept-newsletter" />
                  <label htmlFor="accept-newsletter" id="settings-checkbox">
                    {t('user.settings.subscription_email')}
                  </label>
                  <ErrorMessage className="form-group__error" name="acceptsNewsletter" component="span" />
                </div>
                <div className={'form-group form-group__checkbox' + (props.errors.docSignNotification && props.touched.docSignNotification ? ' form-group--error' : '')}>
                  <Field type="checkbox" name="docSignNotification" id="doc-sign-notification" />
                  <label htmlFor="doc-sign-notification" className="settings-checkbox">
                    {t('user.settings.document_sign_notification')}
                  </label>
                  <ErrorMessage className="form-group__error" name="docSignNotification" component="span" />
                </div>
                <div className='form-group form-group__checkbox'>
                  <Field type="checkbox" name="docFullySignNotification" id="doc-fully-sign-notification" />
                  <label htmlFor="doc-fully-sign-notification" className="settings-checkbox">
                    {t('user.settings.document_fully_sign_notification')}
                  </label>
                </div>
                {(user.permissions.isAdmin && user.subscription === 'flexible') && (
                  <div className={'form-group form-group__checkbox' + (props.errors.sendFullySignNotifications && props.touched.sendFullySignNotifications ? ' form-group--error' : '')} >
                    <Field type="checkbox" name="sendFullySignNotifications" id="send-sign-notifications" />

                    <label htmlFor="send-sign-notifications" id="settings-checkbox">{t('user.settings.document_fully_sign_org_notification')}</label>
                    <ErrorMessage className="form-group__error" name="sendFullySignNotifications" component="span" />
                  </div>
                )}
              </div>
              {!user.workspaceId && (
                <a className="btn btn--medium-grey" onClick={this.onDeleteAccountClick}>
                  {t('user.settings.workspace.remove_account')}
                </a>
              )}
              {user.workspaceId && (
                <a className="btn btn--medium-grey" onClick={() => this.onDeleteBusinessAccountClick()}>
                  {t('user.settings.workspace.remove_account')}
                </a>
              )}
              <div id="bottom-bar">
                <button
                  type="submit"
                  className="btn btn--primary ta-center mt-2"
                >
                  {t('common.save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <ModalRemoveReason
          active={this.state.modalRemoveAccount.active}
          title={t('user.settings.workspace.are_you_sure_you_want_to_remove_your_account')}
          message={(
            <span dangerouslySetInnerHTML={{ __html: t('user.settings.workspace.account_remove_modal_message') }} />
          )}
          onCloseClick={() => this.setState({ modalRemoveAccount: { active: false } })}
          onRemoveClick={this.onModalRemoveAccountClick}
        />
        <ModalRemove
          active={this.state.modalRemove.active}
          title={t('user.settings.workspace.are_you_sure_you_want_to_remove_business_account')}
          message={this.props.user.permissions.isAdmin ? (this.state.totalCompanyUsers > 1 ? t('user.settings.workspace.business_account_with_member_remove_modal_message') : t('user.settings.workspace.account_remove_modal_message')): t('user.settings.workspace.account_remove_modal_message_for_worker')}
          onCloseClick={() => this.setState({ modalRemove: { active: false } })}
          onRemoveClick={this.onModalRemoveWorkspaceClick}
        />
      </React.Fragment>
    )
  }
}

ProfileSettings.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  resetUser: PropTypes.func,
  history: PropTypes.any,
  isValidEmail: PropTypes.func,
  switchWorkspace: PropTypes.func,
}

export default (withEmailValidation(ProfileSettings))