export const formatWorkspaces = (user, workspaces) => {
  const formattedWorkspaces = []

  if (user) {
    formattedWorkspaces.push(
      {
        isBusiness: false,
        id: null,
        name: user.fullName,
        initials: user.initials,
        hexColor: user.hexColor,
        active: typeof user.workspaceId !== 'undefined' && !user.workspaceId,
        canSwitchTo: typeof user.permissions !== 'undefined' ? user.permissions.canSwitchTo : null,
      }
    )
  }

  workspaces.forEach(workspace => {
    formattedWorkspaces.push({
      isBusiness: true,
      id: workspace.id,
      name: workspace.businessName,
      initials: workspace.initials,
      active: workspace.active,
      hexColor: workspace.hexColor,
      canSwitchTo: typeof workspace.permissions !== 'undefined' ? workspace.permissions.canSwitchTo : null,
      registrationCode: workspace.registrationCode,
    })
  })

  return formattedWorkspaces
}
