import { connect } from 'react-redux'
import {
  addCartItem,
  clearCart,
  saveDocumentPaginationHistoryInfo,
  saveDocumentSelectedDocumentsHistoryInfo,
  saveSelectedCanDeleteSelectedDocumentsInfo,
  toggleSidebarShrink
} from '~/js/actions'
import Sidebar from '~/js/components/Sidebar'

const mapStateToProps = state => ({
  products: state.productStore.allProducts,
  signatureProducts: state.productStore.signatureProducts,
  isSidebarShrinked: state.sidebar.isShrinked,
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
  saveDocumentSelectedDocumentsHistoryInfo: (selectedDocumentHistory) => dispatch(saveDocumentSelectedDocumentsHistoryInfo(selectedDocumentHistory)),
  saveSelectedCanDeleteSelectedDocumentsInfo: (selectedCanDeleteDocumentsHistory) => dispatch(saveSelectedCanDeleteSelectedDocumentsInfo(selectedCanDeleteDocumentsHistory)),
  saveDocumentPaginationHistoryInfo: (paginationHistoryPage, paginationHistoryOffset, paginationHistoryPageSize) =>
    dispatch(saveDocumentPaginationHistoryInfo(paginationHistoryPage, paginationHistoryOffset, paginationHistoryPageSize)),
  toggleSidebarShrink: () => dispatch(toggleSidebarShrink())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)