import React from 'react'
import PropTypes from 'prop-types'
import XMLViewer from 'react-xml-viewer'
import Modal from '../Modal'
import PdfViewer from '../DocumentViewer/PdfViewer'
import TabsList, { Tab } from '../TabsList'

export default class ModalDownloadValidationReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: this.getTabs()
    }
    this.getTabs = this.getTabs.bind(this)
    this.pdfViewer = React.createRef()
  }

  getTabs() {
    const { t } = this.props
    return {
      pdf: {
        active: true,
        title: t('validation.report.pdf_format'),
      },
      xml: {
        active: false,
        title: t('validation.report.xml_format'),
      },
    }
  }

  onTabClick(tabIndex) {
    const { tabs } = this.state
    Object.entries(tabs).forEach(([key, tab]) => {
      tab.active = key === tabIndex
    })
    this.setState({ tabs })
  }

  render() {
    const { t } = this.props
    const { tabs } = this.state

    return (
      <Modal
        className="popup--pdf-view validation-report-modal"
        size="full"
        {...this.props}
      >
        <TabsList centered={false}>
          {Object.entries(tabs).map(([key, tab]) => {
            return <Tab
              key={`tab-${key}`}
              active={tab.active}
              title={tab.title}
              onClick={this.onTabClick.bind(this, key)}
            />
          })}
        </TabsList>
        <div className="tabs-content">
          <div className={'tabs-content__tab' + (tabs.pdf.active ? ' active' : '')}>
            <div className="preview__document-content">
              <PdfViewer ref={this.pdfViewer} url={this.props.pdfReportUrl} />
            </div>
            <div className="bottom-buttons">
              <a className="btn btn--primary" href={this.props.pdfReportUrl + '&download=true'}>{t('download')}</a>
            </div>
          </div>
          <div className={'tabs-content__tab' + (tabs.xml.active ? ' active' : '')}>
            {this.props.xmlReport && (
              <div className="preview__document-content xml">
                <XMLViewer
                  xml={this.props.xmlReport}
                  indentSize={4}
                />
              </div>
            )}
            <div className="bottom-buttons">
              <a className="btn btn--primary" href={this.props.xmlReportUrl + '&download=true'}>{t('download')}</a>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ModalDownloadValidationReport.propTypes = {
  t: PropTypes.func,
  pdfReportUrl: PropTypes.string,
  xmlReportUrl: PropTypes.string,
  xmlReport: PropTypes.string,
}