export function compareSemanticVersionNumbers(toCompare, compareWith) {
  const toCompareParts = toCompare.split('.')
  const compareWithParts = compareWith.split('.')

  const maxLength = Math.max(toCompareParts.length, compareWithParts.length)

  for (let i = 0; i < maxLength;) {
    const comparedVar = parseInt(toCompareParts[i] || 0)
    const compareWithVar = parseInt(compareWithParts[i] || 0)

    if (comparedVar > compareWithVar) {
      return 1
    } else if (comparedVar < compareWithVar) {
      return -1
    }
    i++
  }

  return 0
}