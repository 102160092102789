import React from 'react'
import config from '../../../config'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import markIdLogo from '~/img/mark-sign-full-white-logo.svg'
import ChristmasCandy from '~/img/icons/christmas-candy.png'
import arrow from '~/img/icons/arrow-left-long.svg'
import Snowflakes from '~/js/components/Snowflakes'
import quotation from '~/img/icons/quotation.svg'
import Right from '~/img/icons/right.svg'
import verslo from '~/img/logo/verslo-zinios-white.png'
import KrastaAuto from '~/img/logo/krasta-auto-white.png'
import Vku from '~/img/logo/vku-white.png'
import Vvt from '~/img/logo/vvt-white.png'
import girteko from '~/img/logo/girteka-white.png'
import affido from '~/img/logo/affidea-white.png'

class LoginDecorationSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { showChristmasLogin, showChristmasMobile, i18n, t, registrationPage, registerMode, randomQuote } = this.props

    return (showChristmasLogin
      ? (
        <div className="christmas-login">
          <div className={`christmas-login__wrapper ${showChristmasMobile ? 'christmas-mobile-open' : 'christmas-mobile-closed'}`}>
            <div className="christmas-login__logo">
              <a href={`https://${config.get('MARKSIGN_HOST')}`} target="_self">
                <img src={markIdLogo} alt="Logo" className="logo" />
              </a>
            </div>
            <div className="christmas-login__information">
              <img src={ChristmasCandy} alt="Christmas Candy" style={{ margin: '0 8px 16px 0' }} />
              <div className="christmas-login__information--text">
                <h1>
                  <p>{t('user.login.christmas.header_christmas')}</p>
                  <p>{t('user.login.christmas.header_gift')}</p>
                </h1>
                <div className={showChristmasMobile ? '' : 'hide-only-mobile'}>
                  <h2>{t('user.login.christmas.get')}</h2>
                  <div className="discount-text" dangerouslySetInnerHTML={{ __html: t('user.login.christmas.discount') }} />
                  <p>{t('user.login.christmas.valid_period')}</p>
                  <p><strong>2022 12 15 - 2023 01 01</strong></p>
                </div>
              </div>
            </div>
            <div className={`christmas-login__info-button ${showChristmasMobile ? '' : 'hide-only-mobile'}`}>
              <button>
                {i18n.language === 'lt'
                  ? (
                    <a href="https://marksign.lt/naujiena/kaledine-dovana-gauk-25-arba-150-eur-nuolaida/" target="_blank" rel="noreferrer">
                      {t('common.learn_more')}
                      <img src={arrow} alt="Arrow" />
                    </a>
                  ) : (
                    <a href="https://marksign.eu/uncategorized/christmas-gift-get-25-or-150-eur-off/" target="_blank" rel="noreferrer">
                      {t('common.learn_more')}
                      <img src={arrow} alt="Arrow" />
                    </a>
                  )}
              </button>
            </div>
          </div>
          <Snowflakes />
        </div>
      ) : (
        <div className='leftbar-container'>
          <div className='leftbar-container__wrapper'>
            <div className='leftbar-container__wrapper--marksign-logo'>
              <a href={`https://${config.get('MARKSIGN_HOST')}`} target="_self">
                <img src={markIdLogo} alt="Logo" className="logo" />
              </a>
            </div>
            <div className='heading-text'>
              {!registrationPage && !registerMode
                ? (
                  <p className='text-heading'>
                    {t('user.login.login_title_optimise')}<br />
                    <span>{t('user.login.login_title_markid')}</span>
                  </p>
                ) : (
                  <p className='text-heading registration'>
                    {t('user.login.login_registration_title')}
                    <span>{t('user.login.login_registration_with_el')}</span><br />
                    <span>{t('user.login.login_registration_documents')}&nbsp;</span>
                    {t('user.login.login_registration_with_markid')}
                  </p>
                )}
            </div>
            <div className={`paragraph-part ${showChristmasMobile ? '' : 'hide-only-mobile'}`}>
              {!registrationPage && !registerMode
                ? (
                  <div className='heading-middle'>
                    <div className='middle-login'>
                      <img src={quotation} alt="quotation" className="quotation" />
                      <div className='middle-paragraph'>
                        <p className='text-paragraph'>
                          {t(randomQuote.quote)}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='heading-middle'>
                    <div className='quotes'><img src={Right} alt="right-logo" /> <p className="padding--top-none">{t('user.login.login_registration_with_quote_one')}</p></div>
                    <div className='quotes'><img src={Right} alt="right-logo" /> <p>{t('user.login.login_registration_with_quote_two')}</p></div>
                    <div className='quotes'><img src={Right} alt="right-logo" /> <p>{t('user.login.login_registration_with_quote_three')}</p></div>
                  </div>
                )}
              <div>
                {(!registrationPage && !registerMode) && (
                  <div className='person'>
                    {randomQuote.ceoImage && <img src={randomQuote.ceoImage} className="person-img" alt={randomQuote.alt} />}
                    <div className={randomQuote.ceoImage ? 'person-text' : 'person-text margin-left-none'}>
                      <p><strong>{t(randomQuote.author)}</strong></p>
                      <p className='person-occupation'><strong>{t(randomQuote.position)}</strong></p>
                    </div>
                  </div>
                )}
                {!registrationPage && !registerMode
                  ? (
                    <div className='last_part'>
                      <img className='img-mono' src={randomQuote.logo} alt="mono" />
                      <a href={t('helpdesk.login_eSignature')} target='_blank' rel='noreferrer'>
                        {t('user.login.login_eSignature')} <img src={arrow} alt="arrow" />
                      </a>
                    </div>
                  ) : (
                    <div className='bottom-part'>
                      <p>{t('user.login.partner_company')}:</p>
                      <div className='partnership-logo'>
                        <img src={girteko} alt="girteko" />
                        <img src={affido} alt="affido" />
                        <img src={verslo} alt="verslo" />
                        <img src={KrastaAuto} alt="Krasta Auto" />
                        <img src={Vku} alt="VKU" />
                        <img src={Vvt} alt="VVT" />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      ))
  }
}

export default withTranslation()(LoginDecorationSection)

LoginDecorationSection.propTypes = {
  showChristmasLogin: PropTypes.bool,
  showChristmasMobile: PropTypes.bool,
  i18n: PropTypes.object,
  t: PropTypes.func,
  registerMode: PropTypes.string,
  registrationPage: PropTypes.bool,
  randomQuote: PropTypes.object,
}
