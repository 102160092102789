export const NAMESPACE_USER = 'user'
export const NAMESPACE_WORKSPACE = 'workspace'

/* eslint-disable no-multi-spaces */
export const ROUTE_NAMES = Object.freeze({
  THREE_DS_RETURN_URL:             'threeDsReturnUrl',
  TEMPORARY_NOT_FOUND:             'temporaryNotFound',
  TEMPORARY_LOGIN:                 'temporaryDocumentPreviewBulkLogin',
  TEMPORARY_LOGIN_SMART_ID:        'temporaryLoginSmartId',
  TEMPORARY_LOGIN_SMART_CARD:      'temporaryLoginSmartCard',
  TEMPORARY_LOGIN_MOBILE_ID:       'temporaryLoginMobileId',
  TEMPORARY_LOGIN_SIMPLY_SIGN:     'temporaryLoginSimplySign',
  TEMPORARY_DOCUMENT_PREVIEW_BULK: 'temporaryDocumentPreviewBulk',
  HOME_PAGE:                       'homePage',
  CONTACTS:                        'contacts',
  AML_SCREENING:                   'amlScreening',
  PRIVACY_POLICY:                  'privacy',
  ABOUT:                           'about',
  FINTECH:                         'fintech',
  GAMING:                          'gaming',
  TRADING:                         'trading',
  MONEY_INSTITUTIONS:              'moneyInstitutions',
  SERVICE_PROVIDERS:               'serviceProviders',
  PRICING:                         'pricing',
  CAREER_ACHIEVE:                  'careerAchieve',
  CAREER_SINGLE:                   'careerSingle',
  NOT_FOUND:                       'notFound',
  SUPPORT:                         'support',
  ELECTRONIC_SIGNATURE:            'electronicSignature',
  IDENTITY_VERIFICATION:           'identityVerification',
  DOCUMENT_VERIFICATION:           'documentVerification',
  SOFTWARE_DIAGNOSTIC:             'softwareDiagnostic',
  SIMPLY_SIGN_CALLBACK:            'simplySignCallback',
  USER_LOGIN:                      'userLogin',
  USER_LOGIN_SMART_ID:             'userLoginSmartId',
  USER_LOGIN_SMART_CARD:           'userLoginSmartCard',
  USER_LOGIN_MOBILE_ID:            'userLoginMobileId',
  USER_LOGIN_SIMPLY_SIGN:          'userLoginSimplySign',
  USER_LOGIN_LT_ID:                'userLoginLtId',
  USER_REGISTER:                   'userRegister',
  USER_REGISTER_SMART_ID:          'userRegisterSmartId',
  USER_REGISTER_MOBILE_ID:         'userRegisterMobileId',
  USER_REGISTER_SMART_CARD:        'userRegisterSmartCard',
  USER_REGISTER_SIMPLY_SIGN:       'userRegisterSimplySign',
  USER_REGISTER_LT_ID:             'userRegisterLtId',
  USER_WORKSPACES:                 'userWorkspaces',
  USER_CONFIRM_PROFILE:            'userConfirmation',
  USER_CONFIRM_EMAIL:              'userConfirmEmail',
  USER_LOGOUT:                     'userLogout',
  USER_DASHBOARD:                  'userDashboard',
  USER_DOCUMENTS:                  'userDocuments',
  USER_DOCUMENTS_UPLOAD:           'userDocumentsUpload',
  USER_DOCUMENTS_UPLOAD_VALIDATE:  'userDocumentsUploadValidate',
  USER_DOCUMENTS_UPLOAD_PREVIEW:   'userDocumentsUploadPreview',
  USER_DOCUMENTS_VALIDATE_PREVIEW: 'userDocumentsValidatePreview',
  USER_USB_DOCUMENTS:              'userUsbDocuments',
  USER_DOCUMENT_KEY_INVITATION:    'userDocumentKeyInvitation',
  WORKSPACE_ASSIGN:                'workspaceAssign',
  USER_DOCUMENT_SIGNER_INVITATION: 'userDocumentSignerInvitation',
  USER_DISCOUNT_FIRST:             'userDiscountFirst',
  USER_DISCOUNT_SECOND:            'userDiscountSecond',
  USER_DOCUMENT_PREVIEW:           'userDocumentPreview',
  USER_USERS_QUANTITY:             'userUsersQuantity',
  USER_SETTINGS:                   'userSettings',
  USER_PRICING:                    'userPricing',
  USER_GLOBAL_SIGNATURES:          'userGlobalSignatures',
  SUBSCRIPTION_WORKSPACE_ASSIGN:   'subscriptionWorkspaceAssign',
  USER_PRICING_CHECKOUT:           'userPricingCheckout',
  USER_ACCEPT_INVITATION:          'userAcceptInvitation',
  USER_PRICING_SUCCESS:            'userPricingSuccess',
  CONFIRM_EMAIL:                   'confirmEmail',
  USER_PURCHASE_PLAN:              'userPurchasePlan',
  DOCUMENTATION:                   'documentation',
  NEOPAY_USER_CALLBACK:            'neopayUserCallback',
  USER_DOCUMENT_VIEW_INVITATION:   'userDocumentViewInvitation',
})

export const ROUTE_PATHS = new Map([
  [ROUTE_NAMES.THREE_DS_RETURN_URL,             '/3ds/return-url'],
  [ROUTE_NAMES.TEMPORARY_NOT_FOUND,             '/signing-link/not-found'],
  [ROUTE_NAMES.TEMPORARY_LOGIN,                 '/sign/login/bulk/document/:sessionId/:temporarySignLinkId'],
  [ROUTE_NAMES.TEMPORARY_LOGIN_SMART_ID,        '/sign/login/bulk/document/:sessionId/:temporarySignLinkId/smart-id'],
  [ROUTE_NAMES.TEMPORARY_LOGIN_SMART_CARD,      '/sign/login/bulk/document/:sessionId/:temporarySignLinkId/smart-card'],
  [ROUTE_NAMES.TEMPORARY_LOGIN_MOBILE_ID,       '/sign/login/bulk/document/:sessionId/:temporarySignLinkId/mobile-id'],
  [ROUTE_NAMES.TEMPORARY_LOGIN_SIMPLY_SIGN,     '/sign/login/bulk/document/:sessionId/:temporarySignLinkId/simply-sign'],
  [ROUTE_NAMES.TEMPORARY_DOCUMENT_PREVIEW_BULK, '/sign/bulk/document/:sessionId/:temporarySignLinkId/:temporaryAuthSessionId?'],
  [ROUTE_NAMES.HOME_PAGE,                       '/'],
  [ROUTE_NAMES.CONTACTS,                        '/contacts'],
  [ROUTE_NAMES.AML_SCREENING,                   '/aml-screening'],
  [ROUTE_NAMES.PRIVACY_POLICY,                  '/privacy'],
  [ROUTE_NAMES.ABOUT,                           '/about'],
  [ROUTE_NAMES.FINTECH,                         '/fintech'],
  [ROUTE_NAMES.GAMING,                          '/gaming'],
  [ROUTE_NAMES.TRADING,                         '/trading'],
  [ROUTE_NAMES.MONEY_INSTITUTIONS,              '/money-institutions'],
  [ROUTE_NAMES.SERVICE_PROVIDERS,               '/service-providers'],
  [ROUTE_NAMES.PRICING,                         '/pricing'],
  [ROUTE_NAMES.CAREER_ACHIEVE,                  '/career-achieve'],
  [ROUTE_NAMES.CAREER_SINGLE,                   '/career-single'],
  [ROUTE_NAMES.NOT_FOUND,                       '/not-found'],
  [ROUTE_NAMES.SUPPORT,                         '/support'],
  [ROUTE_NAMES.ELECTRONIC_SIGNATURE,            '/electronic-signature'],
  [ROUTE_NAMES.IDENTITY_VERIFICATION,           '/identity-verification'],
  [ROUTE_NAMES.DOCUMENT_VERIFICATION,           '/document-verification'],
  [ROUTE_NAMES.CONFIRM_EMAIL,                   '/confirm-email/:uuid'],
  [ROUTE_NAMES.SOFTWARE_DIAGNOSTIC,             '/software/diagnostic'],
  [ROUTE_NAMES.SIMPLY_SIGN_CALLBACK,            '/simplysign/callback'],
  [ROUTE_NAMES.USER_LOGIN,                      '/user/login'],
  [ROUTE_NAMES.USER_LOGIN_SMART_ID,             '/user/login-smart-id'],
  [ROUTE_NAMES.USER_LOGIN_SMART_CARD,           '/user/login-smart-card'],
  [ROUTE_NAMES.USER_LOGIN_MOBILE_ID,            '/user/login-mobile-id'],
  [ROUTE_NAMES.USER_LOGIN_SIMPLY_SIGN,          '/user/login-simply-sign'],
  [ROUTE_NAMES.USER_LOGIN_LT_ID,                '/user/login-lt-id'],
  [ROUTE_NAMES.USER_REGISTER,                   '/user/register'],
  [ROUTE_NAMES.USER_REGISTER_SMART_ID,          '/user/register-smart-id'],
  [ROUTE_NAMES.USER_REGISTER_SMART_CARD,        '/user/register-smart-card'],
  [ROUTE_NAMES.USER_REGISTER_MOBILE_ID,         '/user/register-mobile-id'],
  [ROUTE_NAMES.USER_REGISTER_SIMPLY_SIGN,       '/user/register-simply-sign'],
  [ROUTE_NAMES.USER_REGISTER_LT_ID,             '/user/register-lt-id'],
  [ROUTE_NAMES.USER_WORKSPACES,                 '/user/workspaces'],
  [ROUTE_NAMES.USER_DOCUMENT_VIEW_INVITATION,   '/:workspace/document/:documentId/view-invitation'],
  [ROUTE_NAMES.USER_CONFIRM_PROFILE,            '/:workspace/confirm-profile'],
  [ROUTE_NAMES.USER_CONFIRM_EMAIL,              '/:workspace/confirm-email'],
  [ROUTE_NAMES.USER_LOGOUT,                     '/:workspace/logout'],
  [ROUTE_NAMES.USER_DASHBOARD,                  '/:workspace/dashboard'],
  [ROUTE_NAMES.USER_DOCUMENTS,                  '/:workspace/documents/:category?'],
  [ROUTE_NAMES.USER_DOCUMENTS_UPLOAD,           '/:workspace/documents/upload'],
  [ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE,  '/:workspace/documents/validate-upload'],
  [ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW,   '/:workspace/documents/upload/preview/:documentId'],
  [ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW, '/:workspace/document/validate/:documentId'],
  [ROUTE_NAMES.USER_DOCUMENT_KEY_INVITATION,    '/:workspace/document/:documentId/key/:key'],
  [ROUTE_NAMES.WORKSPACE_ASSIGN,                '/:workspace/document/:documentId/signer/:signer/workspace-assign'],
  [ROUTE_NAMES.USER_DOCUMENT_SIGNER_INVITATION, '/:workspace/document/:documentId/signer/:signer'],
  [ROUTE_NAMES.USER_USB_DOCUMENTS,              '/:workspace/usb-documents'],
  [ROUTE_NAMES.USER_DISCOUNT_FIRST,             '/:workspace/back25'],
  [ROUTE_NAMES.USER_DISCOUNT_SECOND,            '/:workspace/back40'],
  [ROUTE_NAMES.USER_DOCUMENT_PREVIEW,           '/:workspace/document/:documentId'],
  [ROUTE_NAMES.USER_USERS_QUANTITY,             '/:workspace/users/quantity'],
  [ROUTE_NAMES.USER_SETTINGS,                   '/:workspace/settings/:category?'],
  [ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN,   '/:workspace/pricing/subscription-workspace-assign'],
  [ROUTE_NAMES.USER_PRICING_CHECKOUT,           '/:workspace/pricing/checkout'],
  [ROUTE_NAMES.USER_PRICING,                    '/:workspace/pricing'],
  [ROUTE_NAMES.USER_PRICING_SUCCESS,            '/:workspace/pricing-success'],
  [ROUTE_NAMES.USER_GLOBAL_SIGNATURES,          '/:workspace/global-signatures'],
  [ROUTE_NAMES.USER_ACCEPT_INVITATION,          '/user/workspace/:workspaceId/person/:businessPersonId/accept-invitation'],
  [ROUTE_NAMES.USER_PURCHASE_PLAN,              '/:workspace/purchase-plan'],
  [ROUTE_NAMES.NEOPAY_USER_CALLBACK,            '/neopay/user-callback'],
  [ROUTE_NAMES.DOCUMENTATION,                   '/documentation'],
])
/* eslint-enable no-multi-spaces */
