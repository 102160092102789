// @flow

import type { Action } from './action'
import i18next from 'i18next'
import moment from 'moment/moment'

export type LangCode = 'lt' | 'en' | 'pl'

export type ActionSetLangCode = Action<'SET_LANG_CODE'> & {
  langCode: LangCode,
}

export const setLangCode = (langCode: LangCode): ActionSetLangCode => {
  moment.updateLocale(langCode, {
    week: {
      dow: 1
    }
  })
  i18next.changeLanguage(langCode)

  return {
    type: 'SET_LANG_CODE',
    langCode,
  }
}