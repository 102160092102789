import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import React from 'react'
import {withTranslation} from 'react-i18next'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'

const radioFields = [
  {'name': 'notClearHowToUse', 'translation': 'user.settings.workspace.reason_to_delete.not_clear'},
  {'name': 'otherProvider', 'translation': 'user.settings.workspace.reason_to_delete.other_provider'},
  {'name': 'dontLikeDesign', 'translation': 'user.settings.workspace.reason_to_delete.dont_like_design'},
  {'name': 'functionalityLack', 'translation': 'user.settings.workspace.reason_to_delete.lack_of_functionality'},
  {'name': 'dontNeed', 'translation': 'user.settings.workspace.reason_to_delete.no_longer_need'},
]

class ModalRemoveReason extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteContinue: false,
      textareaSelected: false
    }

    this.onContinueDeleting = this.onContinueDeleting.bind(this)
    this.onModalRemoveClick = this.onModalRemoveClick.bind(this)
    this.onChoseDeleteReason = this.onChoseDeleteReason.bind(this)
  }

  componentDidMount() {
    this.formRef = React.createRef()
  }

  componentWillUnmount() {
    this.formRef = null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({deleteContinue: false, textareaSelected: false})
    }
  }

  onModalRemoveClick() {
    this.formRef.current.handleSubmit()
  }

  onContinueDeleting() {
    this.setState({
      deleteContinue: true
    })
  }

  onChoseDeleteReason() {
    this.setState({textareaSelected: false})
  }

  render() {
    const {t} = this.props
    let validationSchema = Yup.object().shape({
      reasonText: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9-.,\s]+$/, t('validation.general_text')),
    })


    return (
      <Modal
        size='medium'
        className="popup__user modal-remove-reason"
        deleteContinue={this.state.deleteContinue}
        improveMessage={t('user.settings.workspace.account_help_to_improve')}
        {...this.props}>
        <div>
          <Formik
            initialValues={{reasonText: radioFields[0].name}}
            validationSchema={validationSchema}
            onSubmit={this.props.onRemoveClick}
            innerRef={this.formRef}
          >
            {this.state.deleteContinue && (props => (
              <Form>
                <div
                  className={'form-group' + (props.errors.reasonText && props.touched.reasonText ? ' form-group--error' : '')}>
                  {radioFields.map((radioField, index) => (
                    <div key={index} className="form-group__radio">
                      <Field
                        className="popup-radio-value"
                        type="radio"
                        name="reasonText"
                        id={radioField.name}
                        value={radioField.name}
                        onChange={(e) => {
                          props.handleChange(e)
                          this.setState({textareaSelected: false})
                        }}
                      />
                      <label htmlFor={radioField.name}>{t(radioField.translation)}</label>
                    </div>
                  ))}
                  <div className="form-group__radio mb-24">
                    <Field
                      className="popup-radio-value"
                      type="radio"
                      name="reasonText"
                      id="other"
                      value="other"
                      onChange={(e) => {
                        props.handleChange(e)
                        this.setState({textareaSelected: true})
                      }}
                    />
                    <label htmlFor="other">{t('user.settings.workspace.reason_to_delete.other')}</label>
                  </div>
                  <p className="form-group__label" htmlFor="form-group__input--reason-text">{this.props.reasonLabel}</p>
                  {this.state.textareaSelected &&
                  <Field
                    id="form-group__input--reason-text"
                    className="form-group__input form-group__input--reason-text"
                    placeholder={t('user.settings.workspace.account_textarea_placeholder')}
                    component="textarea"
                    name="reasonTextarea"
                    disabled={!this.state.textareaSelected}/>
                  }
                  <ErrorMessage className="form-group__error" name="reasonTextarea" component="span"/>
                </div>
              </Form>
            ))}
          </Formik>
          <div className="center-element center-element--space-between">
            <button
              className="btn btn--grey"
              onClick={this.props.onCloseClick}>
              {t('common.cancel')}
            </button>
            {!this.state.deleteContinue &&
              <button
                className="btn btn--primary"
                type="submit"
                onClick={this.onContinueDeleting}>
                {t('common.continue')}
              </button>
            }
            {this.state.deleteContinue &&
              <button
                className="btn btn--red"
                type="submit"
                onClick={this.onModalRemoveClick}>
                {t('common.delete')}
              </button>
            }
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalRemoveReason)

ModalRemoveReason.propTypes = {
  active: PropTypes.bool,
  t: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  reasonLabel: PropTypes.string,
}