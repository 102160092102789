import React from 'react'
import { withTranslation } from 'react-i18next'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as workspaceService from '~/js/services/user/workspace'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from '../../../../services/user/serviceHelpers'
import PropTypes from 'prop-types'

class WorkspaceAssign extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      workspaces: []
    }

    this.fetchWorkspaces()
  }

  fetchWorkspaces() {
    workspaceService
      .getMatchingEmailWorkspaces(this.props.user, this.props.match.params.signer)
      .then(data => this.setWorkspaces(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setWorkspaces(data) {
    const { data: workspaces } = data
    const { user } = this.props

    workspaces.map(w => w.isBusiness = true)
    workspaces.unshift(
      {
        id: null,
        businessName: user.fullName,
        initials: user.initials,
        hexColor: user.hexColor,
        isBusiness: !!user.workspaceId
      }
    )

    this.setState({ workspaces })
  }

  onWorkspaceClick(workspaceId) {
    const { params } = this.props.match

    return apiClient
      .post(`/${getUserNamespace()}/document/assign-workspace`, {
        signer: params.signer,
        documentId: params.documentId,
        workspaceId: workspaceId
      })
      .then(() => workspaceId ? this.props.switchWorkspace(workspaceId) : Promise.resolve())
      .then(() =>
        this.props.history.push({
          pathname: `/${getUserNamespace()}/document/${params.documentId}`,
          search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
        })
      )
  }

  render() {
    const { t } = this.props
    const { workspaces } = this.state

    return (
      <section id="login">
        <div className="grid">
          <img src={markIdLogo} alt="Mark ID" className="login__logo" />
          <div className="login__container">
            <h5 className="ta-center">{t('user.document.choose_workspace_to_assign_document')}</h5>
            <div className="login__user">
              <ul>
                {workspaces.map(workspace => (
                  <li key={workspace.id}>
                    <a
                      className='sidebar__user'
                      href="#"
                      onClick={e => { e.preventDefault(); return this.onWorkspaceClick(workspace.id) }}
                    >
                      <div className="sidebar__user-logo">
                        <img src={workspace.isBusiness ? Company : User} alt={workspace.isBusiness ? 'Company' : 'User'} />
                      </div>
                      <div className="sidebar__user-info">
                        <span>{workspace.businessName}</span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(WorkspaceAssign)

WorkspaceAssign.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object,
  switchWorkspace: PropTypes.func,
  history: PropTypes.object,
}