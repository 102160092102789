import React from 'react'
import PropTypes from 'prop-types'
import emptySearchImg from '~/img/illustration/empty-search.svg'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'

export default class DocumentKeyInvitation extends React.Component {
  constructor(props) {
    super(props)

    const { params } = props.match

    this.state = {
      error: {
        occurred: false,
        text: '',
      }
    }

    this.requestInvitation(params.documentId, params.key)
  }

  requestInvitation(uuid, key) {
    const { history } = this.props

    apiClient
      .post(`/user/document/${uuid}/key/${key}`)
      .then(data => {
        if (data.data.targetPath && !data.data.workspaceId) {
          history.push({
            pathname: data.data.targetPath,
            search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
          })
        } else if (data.data.targetPath && data.data.workspaceId) {
          this.props.switchWorkspace(data.data.workspaceId)
            .then(
              history.push({
                pathname: `/workspace/document/${uuid}`,
                search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
              })
            )
        } else if (!data.data.targetPath) {
          history.push({
            pathname: `/user/document/${uuid}`,
            search: new URL(location.href).searchParams.get('widget') ? '?widget=true' : '',
          })
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.setState({
            error: {
              occurred: true,
              text: 'user.document.document_has_been_removed',
            },
          })
        }
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  render() {
    const { t } = this.props
    const { error } = this.state

    return (
      <main>
        {error.occurred && (
          <section id="empty-list">
            <div className="grid grid--flex">
              <div className="empty__content">
                <img src={emptySearchImg} alt={t(error.text)} />
                <h3 className="empty__title">{t(error.text)}</h3>
              </div>
            </div>
          </section>
        )}
        <StatusMessages />
      </main>
    )
  }
}

DocumentKeyInvitation.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  switchWorkspace: PropTypes.func,
}