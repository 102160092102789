import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import StatusSigner from '../StatusSigner'
import { ControlButton, ControlButtons } from '~/js/components/ControlButtons'
import {
  DOCUMENT_SIGNER_STATUS_SIGNED,
  DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED,
  DOCUMENT_SIGNER_STATUS_PENDING
} from '~/js/models/Document'
import IconTooltip from '../IconTooltip/IconTooltip'
import { DOCUMENT_SIGNER_STATUS_REVIEWED } from '../../models/Document'

class Member extends React.Component {
  render() {
    const { t, member, index, user } = this.props
    const memberIdentity = `${member.name || ''} ${member.surname || ''} ${member.email && `(${member.email})`}`.trim()

    return (
      <>
        {memberIdentity.length > 0 && (
          <div className="info-details__label status-tooltip--hover">
            <label className="strong-text">
              {(member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED && (this.props.isSigningOrderImportant)) ?
                <span className="info-details__label--index">{index + 1}</span> : null
              }
              {member.isOwner && (
                <span
                  className="info-details__status info-details__status--owner status-tooltip"
                  data-tooltip={t('user.document.owner')}
                />
              )}
              {memberIdentity}
            </label>
          </div>
        )}
        {!this.props.upload
          ? (
            <div className="info-details__info info-details__info--members">
              <StatusSigner
                t={t}
                status={member.signStatus}
                signatureLevelDesc={member.signatureLevel && member.signatureLevel.toLowerCase() === 'qesig' ? member.signatureLevelDesc : null}
              />
              {(member.email && member.email.length > 0) && member.canResendInvitation && this.props.canInviteSigner && member.signStatus === DOCUMENT_SIGNER_STATUS_PENDING && (
                (user.subscription !== 'start' || user.subscription !== 'no_plan') ?
                  <a className="btn btn--icon btn--reminder" onClick={() => this.resendInvitation(member)}>
                    {t('send_reminder')}
                  </a> :
                  <>
                    <div className="btn btn--icon btn--reminder__disabled">
                      {t('send_reminder')}
                    </div>
                    <div className="pro-icon__mobile-adjustment">
                      <IconTooltip tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
                    </div>
                  </>
              )}
              {member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED && member.signStatus !== DOCUMENT_SIGNER_STATUS_REVIEWED && this.props.canInviteSigner && (
                <ControlButtons size="small">
                  <ControlButton
                    type="delete"
                    title={t('common.remove')}
                    showLabel={false}
                    showTooltip={true}
                    onClick={this.props.removeMember.bind(this, index)}
                  />
                </ControlButtons>
              )}
            </div>
          ) : (
            <div className="info-details__info info-details__info--members">
              <StatusSigner t={t} status={member.signStatus || DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED} signatureLevelDesc={member.signatureLevel && member.signatureLevel.toLowerCase() === 'qesig' ? member.signatureLevelDesc : null} />
              {member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED && member.signStatus !== DOCUMENT_SIGNER_STATUS_REVIEWED && (
                <ControlButtons size="small">
                  <ControlButton
                    type="delete"
                    title={t('common.remove')}
                    showLabel={false}
                    showTooltip={true}
                    onClick={this.props.onRemoveClick.bind(this, index)}
                  />
                </ControlButtons>
              )}
            </div>
          )}
      </>
    )
  }
}

Member.propTypes = {
  t: PropTypes.func,
  isSigningOrderImportant: PropTypes.bool,
  upload: PropTypes.bool,
  canInviteSigner: PropTypes.bool,
  removeMember: PropTypes.func,
  onRemoveClick: PropTypes.func,
  member: PropTypes.object,
  user: PropTypes.object,
  index: PropTypes.number,
}

export default withTranslation()(Member)