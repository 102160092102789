import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import config from '../../../config'
import StatusMessages from '../../../containers/StatusMessages'
import Language from '../../../components/Language'
import InformationBar from '../../../components/InformationBar'
import * as msCoreService from '../../../services/msCore'
import Info from '../../../../img/icons/info-slick-white.svg'
import circle from '../../../../img/shape/circle.svg'
import circleOne from '../../../../img/shape/circle_one.png'
import circleTwo from '../../../../img/shape/circle_two.png'
import iconError from '../../../../img/shape/error.svg'
import iconSuccess from '../../../../img/shape/success.svg'
import install from '../../../../img/shape/install.svg'
import markIdLogo from '../../../../img/marksign-logo-blue.svg'
import pillShape from '../../../../img/shape/pill.svg'
import reback from '../../../../img/shape/reback.svg'

const fetchIntervalMs = 5000
let fetchInterval

class SoftwareDiagnostic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      installed: null,
      certificate: '',
      isLoading: false,
      error: null,
      errorText: null,
    }

    this.handleReload = this.handleReload.bind(this)
    this.fetchInstalledTools = this.fetchInstalledTools.bind(this)
    this.softwareOkay = this.softwareOkay.bind(this)
  }

  componentDidMount() {
    this.fetchInstalledTools()
    fetchInterval = setInterval(this.fetchInstalledTools, fetchIntervalMs)
  }

  componentWillUnmount() {
    clearInterval(fetchInterval)
  }

  fetchInstalledTools() {
    msCoreService.checkForUsbCompatability()
      .then(({ installData, certData, osCheckData }) => {
        this.setState({
          installed: installData,
          certificate: certData,
          isWindows: osCheckData.isWindows,
          isLoading: false,
          errorText: null,
        })
        clearInterval(fetchInterval)
      })
      .catch(err => {
        const { installData, certData } = err
        const { error, errorText } = msCoreService.handleUsbCheckErrors(err)
        this.setState({
          error,
          errorText,
          installed: installData,
          certificate: certData,
          isLoading: false,
        })
      })
  }

  handleReload() {
    this.setState({ isLoading: true })

    setTimeout(() => this.fetchInstalledTools(),1000)
  }

  softwareOkay() {
    return this.state.installed && this.state.errorText === null
  }

  render() {
    const { t } = this.props
    const { installed, isWindows, isLoading, error, errorText } = this.state

    return (
      <div className="usb-page-container--full-height" id="usb-page-container">
        <section id="login" className="usb-error login-form-container software_accordion">
          <div className="grid">
            <a href={`https://${config.get('MARKSIGN_HOST')}`} target="_self">
              <img src={markIdLogo} alt="Logo" className="login__logo" />
            </a>
            <div className="usb__container">
              <div>
                <h4 className="ta-center heading-text">{t('usb.install_software')}</h4>
                <h5 className="ta-center">{t('usb.login_sign_mutiple')} <br />
                  {t('usb.with_your')}
                </h5>
              </div>
              <div className="middle-bar">
                <div className="middle-logo-round middle-logo-left">
                  <img src={circleOne} alt="" />
                  <h6 dangerouslySetInnerHTML={{ __html: t('usb.mark_sign_software_top') }}></h6>
                </div>
                <div className="center-round-icon">
                  <img src={pillShape} alt="" />
                  <img src={circle} alt="" />
                  <img src={circle} alt="" />
                </div>
                <div className="middle-logo-round middle-logo-right">
                  <img src={circleTwo} alt="" />
                  <h6>{t('usb.software_based_on_your_usb_token')}</h6>
                </div>
              </div>
              <div className="usb__status">
                <div className={this.softwareOkay() ? 'usb__status_success' : 'usb__status_error'}>
                  <div className="usb__status_icon">
                    <img src={this.softwareOkay() ? iconSuccess : iconError} alt="" />
                  </div>
                  <div>
                    {this.softwareOkay() && <span>{t('usb.the_software_is_ready_to_use')}</span>}
                    {errorText && <span>{t(errorText)}</span>}
                  </div>
                  {isWindows && this.softwareOkay() &&
                    <div className="reback-button">
                      <button onClick={() => this.handleReload()}>
                        <img src={reback} alt="" className={isLoading ? 'spin' : ''} />
                        <span className="install">{t('usb.check_again')}</span>
                      </button>
                    </div>
                  }
                </div>
                {isWindows &&
                  <>
                    <InformationBar
                      icon={Info}
                      iconAlt='Info'
                      informationText={t('user.login.restart_pc')}
                      informationClass='info'
                    />
                    {this.props.i18n.language === 'lt' &&
                      <InformationBar
                        icon={Info}
                        iconAlt='Info'
                        informationText={(
                          <span dangerouslySetInnerHTML={{ __html: t('user.login.activate_identity_card') }} />
                        )}
                        informationClass='info'
                      />
                    }
                  </>
                }
              </div>
              <section className="middle-section">
                <div className="mark-sign-one">
                  <h4>1. {t('usb.mark_sign_software')}</h4>
                  <div className="table  table--usb">
                    <ul className="table__head">
                      <li>
                        <label>{t('usb.software')}</label>
                      </li>
                      <li>
                        <label>{t('usb.status')}</label>
                      </li>
                    </ul>
                    <ul className="table__body">
                      <li className="border-none ">
                        <div className="table__body__col">
                          <p>{t('usb.mark_sign_software')}</p>
                        </div>
                        <div className="table__body__col">
                          <a className={installed && this.state.certificate ? 'success' : 'error'}>
                            {installed && this.state.certificate
                              ? t('usb.installed')
                              : (error === msCoreService.ERR_UPDATE_NEEDED ? t('usb.update_needed') : t('usb.not_installed'))
                            }
                          </a>
                        </div>
                        <div className="table__body__col">
                          <a target="__blank" href="https://software.marksign.lt/MarkSignSetup.msi">
                            <img src={install} alt="" />
                            <span className="install">{t('usb.install')}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <h4 className="text-two">2. {t('usb.software_based_on_your_usb_token')}</h4>
                <div className="tabs">
                  <div className="tab">
                    <input className="checkbox--input" type="checkbox" id="chck1" />
                    <label className="tab-label" htmlFor="chck1">{t('usb.Lithuania')}</label>
                    <div className="tab-content">
                      <div className="table table--usb">
                        <ul className="table__head">
                          <li>
                            <label>{t('usb.software')}</label>
                          </li>
                          <li>
                            <label>{t('usb.status')}</label>
                          </li>
                        </ul>
                        <ul className="table__body">
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.identity_card_issued_one')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.MC === 'true' ? 'success' : 'error'}>{installed.MC === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.nsc.vrm.lt/downloads.htm">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.identity_card_issued_two')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.PWPW === 'true' ? 'success' : 'error'}>{installed.PWPW === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.nsc.vrm.lt/downloads.htm">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.aladdin')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.IDEMIA === 'true' ? 'success' : 'error'}>{installed.IDEMIA === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li className='border-none'>
                            <div className="table__body__col">
                              <p>{t('usb.bit4id_tokenme_evo_2')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.BIT4ID === 'true' ? 'success' : 'error'}>{installed.BIT4ID === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.giesecke')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.GD === 'true' ? 'success' : 'error'}>{installed.GD === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li className='border-none'>
                            <div className="table__body__col">
                              <p>{t('usb.Idemia')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.IDEMIA === 'true' ? 'success' : 'error'}>{installed.IDEMIA === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.safenet')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.IDEMIA === 'true' ? 'success' : 'error'}>{installed.IDEMIA === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="table__body__col">
                              <p>{t('usb.Thales')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.IDPRIME === 'true' ? 'success' : 'error'}>{installed.IDPRIME === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.elektroninis.lt/en/prepare-pc">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="tab">
                    <input className="checkbox--input" type="checkbox" id="chck2" />
                    <label className="tab-label" htmlFor="chck2">{t('usb.Latvia')}</label>
                    <div className="tab-content">
                      <div className="table  table--usb">
                        <ul className="table__head">
                          <li>
                            <label>{t('usb.software')}</label>
                          </li>
                          <li>
                            <label>{t('usb.status')}</label>
                          </li>
                        </ul>
                        <ul className="table__body">
                          <li className="border-none">
                            <div className="table__body__col">
                              <p>{t('usb.identity')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.EPARAKS === 'true' ? 'success' : 'error'}>{installed.EPARAKS === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.eparaksts.lv/en/Downloads">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="tab">
                    <input className="checkbox--input" type="checkbox" id="chck3" />
                    <label className="tab-label" htmlFor="chck3">{t('usb.Estonia')}</label>
                    <div className="tab-content">
                      <div className="table  table--usb">
                        <ul className="table__head">
                          <li>
                            <label>{t('usb.software')}</label>
                          </li>
                          <li>
                            <label>{t('usb.status')}</label>
                          </li>
                        </ul>
                        <ul className="table__body">
                          <li className="border-none">
                            <div className="table__body__col">
                              <p>{t('usb.identity')}</p>
                            </div>
                            <div className="table__body__col">
                              {installed ? <a className={installed.DIGIDOC === 'true' ? 'success' : 'error'}>{installed.DIGIDOC === 'true' ? t('usb.installed') : t('usb.not_installed')}</a> : <a className="unknown">{t('usb.unknown')}</a>}
                            </div>
                            <div className="table__body__col">
                              <a target="__blank" href="https://www.id.ee/en/article/install-id-software/">
                                <img src={install} alt="" />
                                <span className="install">{t('usb.install')}</span>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="usb-lang">
            <Language />
          </div>
        </section>
        <StatusMessages />
      </div>
    )
  }
}

export default withTranslation()(SoftwareDiagnostic)

SoftwareDiagnostic.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
}