import { connect } from 'react-redux'
import {
  addStatusMessageWithTimeout,
  getSessionStatus,
  fetchUserBySessionId,
  fetchUser,
  resetUser,
  triggerUserLoggedInEvent
} from '~/js/actions'
import WorkspacesRoute from '~/js/routes/app/Workspaces'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessageWithTimeout(type, message)),
  getSessionStatus: () => dispatch(getSessionStatus()),
  fetchUserBySessionId: sessionId => dispatch(fetchUserBySessionId(sessionId)),
  fetchUser: user => dispatch(fetchUser(user)),
  resetUser: () => dispatch(resetUser()),
  triggerUserLoggedInEvent: (user, location) => dispatch(triggerUserLoggedInEvent(user, location))
})

const mapStateToProps = state => ({
  sessionId: state.user.sessionId,
  serialNumber: state.user.serialNumber,
  sessionStatus: state.user.sessionStatus,
  user: state.user.user,
  workspaces: state.user.workspaces,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspacesRoute)