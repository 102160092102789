export const signaturePositions = [{
  id: 2,
  translationKey: 'user.document.signature_position_first_top'
}, {
  id: 3,
  translationKey: 'user.document.signature_position_first_bottom'
}, {
  id: 4,
  translationKey: 'user.document.signature_position_last_top'
}, {
  id: 5,
  translationKey: 'user.document.signature_position_last_bottom'
}, {
  id: 1,
  translationKey: 'user.document.signature_position_invisible'
}]
