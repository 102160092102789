import React from 'react'
import Tooltip from '../Tooltip'
import ProIconImage from '~/img/icons/pro-icon.svg'
import PropTypes from 'prop-types'

export default class ProIcon extends React.Component {
  render() {
    const { tooltipText, inline, newLine } = this.props

    return (
      <Tooltip text={tooltipText} inline={inline} newLine={newLine} img={true}>
        <img className="pro-icon" src={ProIconImage} alt="Pro Icon" />
      </Tooltip>
    )
  }
}

ProIcon.propTypes = {
  tooltipText: PropTypes.string,
  inline: PropTypes.bool,
  newLine: PropTypes.bool,
}