import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { getUserWorkspace } from '~/js/routes/router'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'
import PropTypes from 'prop-types'
import Select from '../Select'
import withEmailValidation from '~/js/hoc/withEmailValidation'
import enFlag from '../../../img/flags/en.svg'
import ltFlag from '../../../img/flags/lithuanian.png'
import plFlag from '../../../img/flags/pl.svg'
import Tooltip from '~/js/components/Tooltip'

const purposeOptions = [
  { value: 'e_signature', label: 'user.document.e_signature' },
  { value: 'copy_authentication', label: 'user.document.copy_authentication' },
  { value: 'acknowledgement', label: 'user.document.acknowledgement' },
  { value: 'visa', label: 'user.document.visa' },
  { value: 'review', label: 'user.document.review' },
  { value: 'confirmation', label: 'user.document.confirmation' },
]

const langOptions = [
  { value: 'en', label: 'english', flag: enFlag },
  { value: 'lt', label: 'lithuanian', flag: ltFlag },
  { value: 'pl', label: 'polish', flag: plFlag },
]

class FormSigner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addToContacts: false,
      showBusinessPersonName: false,
      contactPurpose: 'e_signature',
      signerLanguage: 'lt',
    }

    this.onFormSignerSubmit = this.onFormSignerSubmit.bind(this)
    this.onAddToContactsSelect = this.onAddToContactsSelect.bind(this)
    this.onShowBusinessPersonNameSelect = this.onShowBusinessPersonNameSelect.bind(this)
    this.onUpdatePurpose = this.onUpdatePurpose.bind(this)
    this.onUpdateLanguage = this.onUpdateLanguage.bind(this)
  }

  onFormSignerSubmit(values) {
    const { t } = this.props
    const promises = [
      this.props.addSigner([values.name, values.surname, values.email, this.state.showBusinessPersonName, this.state.contactPurpose, values.phone, this.state.signerLanguage])
    ]

    if (this.state.addToContacts) {
      promises.push(this.addContact([values.name, values.surname, values.email, values.phone]))
    }

    Promise.all(promises)
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .then( () => {
        this.props.upload && this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signer_was_added'))
      })
  }

  addContact([name, surname, email, phoneNumber]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/contact`, {
        name: name,
        surname: surname,
        email: email,
        phoneNumber: phoneNumber
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contact_was_successfully_added')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onAddToContactsSelect() {
    this.setState({ addToContacts: !this.state.addToContacts })
  }

  onShowBusinessPersonNameSelect() {
    this.setState({ showBusinessPersonName: !this.state.showBusinessPersonName })
  }

  onUpdatePurpose(selection) {
    this.setState({ 'contactPurpose': selection.value })
  }

  onUpdateLanguage(selection) {
    this.setState({ 'signerLanguage': selection.value })
  }

  render() {
    const { t, isValidEmail } = this.props
    const initialValues = { name: '', surname: '', email: '', purpose: '', phone: '', language: '' }

    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail),
      phone: Yup.string()
        .max(25, t('validation.phone_number_should_contain_not_more_than_25_digits'))
    })
    const defaultOption = purposeOptions.find(option => option.value === 'e_signature')
    const selectOptions = [
      { label: t('common.purpose'), options: purposeOptions },
    ]

    const defaultLang = langOptions.find(option => option.value === 'lt')
    const langSelectOptions = [
      { label: t('common.language'), options: langOptions },
    ]

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onFormSignerSubmit}
      >
        {props => (
          <Form>
            <div className={'form-group' + (props.errors.name && props.touched.name ? ' form-group--error' : '')}>
              <label>{t('common.name')}</label>
              <Field className="form-group__input" type="text" name="name" />
              <ErrorMessage className="form-group__error" name="name" component="span" />
            </div>

            <div className={'form-group' + (props.errors.surname && props.touched.surname ? ' form-group--error' : '')}>
              <label>{t('common.surname')}</label>
              <Field className="form-group__input" type="text" name="surname" />
              <ErrorMessage className="form-group__error" name="surname" component="span" />
            </div>

            <div className={'form-group' + (props.errors.purpose && props.touched.purpose ? ' form-group--error' : '')}>
              <label>{t('common.purpose')}</label>
              <Select
                name="purpose"
                options={selectOptions}
                defaultValue={[defaultOption]}
                placeholder={t('common.select')}
                getOptionLabel={opt => t(opt.label)}
                onChange={this.onUpdatePurpose}
              />
              <ErrorMessage className="form-group__error" name="purpose" component="span" />
            </div>

            <div className={'form-group' + (props.errors.email && props.touched.email ? ' form-group--error' : '')}>
              <label>* {t('common.email')}</label>
              <Field className="form-group__input" type="text" name="email" />
              <ErrorMessage className="form-group__error" name="email" component="span" />
            </div>

            <div className={'form-group' + (props.errors.phone && props.touched.phone ? ' form-group--error' : '')}>
              <label>{t('common.phone')}</label>
              <Field className="form-group__input" type="text" name="phone" />
              <ErrorMessage className="form-group__error" name="phone" component="span" />
            </div>

            <div className={'form-group' + (props.errors.language && props.touched.language ? ' form-group--error' : '')}>
              <label>* {t('common.select_invitation_language')}</label>
              <Select
                name="language"
                options={langSelectOptions}
                defaultValue={[defaultLang]}
                placeholder={t('common.language')}
                formatOptionLabel={opt => (
                  <div className='country-image'>
                    <img className="country-image-pop-up" src={opt.flag} alt="country-image" />
                    <span>{t(opt.label)}</span>
                  </div>
                )}
                onChange={this.onUpdateLanguage}
              />
              <ErrorMessage className="form-group__error" name="language" component="span" />
            </div>

            <br />

            {this.props.isBusinessPerson ?
              <div className="form-group__checkbox form-group--show_inviter">
                <input
                  className="checkbox__document" type="checkbox" id="show-senders-name"
                  checked={this.state.showBusinessPersonName} onChange={this.onShowBusinessPersonNameSelect}
                />
                <label htmlFor="show-senders-name">{t('user.document.show_invitation_senders_name')}</label>
              </div> : null
            }

            <br />

            <div className="form-group__checkbox form-group--add_to_contact">
              <input
                className="checkbox__document" type="checkbox" id="add-to-contacts"
                checked={this.state.addToContacts} onChange={this.onAddToContactsSelect}
              />
              <label htmlFor="add-to-contacts">
                {t('user.settings.contact.save_to_address_book')}
                &nbsp;
                <Tooltip
                  text={t('user.settings.contact.save_to_address_book_explanation')}
                  className='d-inline align-baseline ps-1'
                >
                  <span className="info-icon-grey" />
                </Tooltip>
              </label>
            </div>

            <br />

            <button
              type="submit"
              className="btn btn--primary ta-center mt-2"
              style={{ margin: '32px auto 0 auto' }}
              disabled={props.isSubmitting}
            >
              {t('common.invite')}
            </button>
          </Form>
        )}
      </Formik>
    )
  }
}

FormSigner.propTypes = {
  t: PropTypes.func,
  addSigner: PropTypes.func,
  showStatusMessage: PropTypes.func,
  upload: PropTypes.bool,
  isBusinessPerson: PropTypes.bool,
  isValidEmail: PropTypes.func,
}

export default (withEmailValidation(FormSigner))
