// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import enFlag from '../../../img/flags/en.svg'
import ltFlag from '../../../img/flags/lithuanian.png'
import plFlag from '../../../img/flags/polish.png'
import type { LangCode } from '../../actions'
import { setLangCode } from '../../actions'

type Props = {
  className?: string,
}
enum LangName {
  Lithuanian = 'lithuanian',
  English = 'english',
  Polish = 'polish',
}
const langCodeToNameMap = new Map<LangCode, LangName>([
  ['en', LangName.English],
  ['lt', LangName.Lithuanian],
  ['pl', LangName.Polish],
])

function getLangName(langCode): LangName {
  const langName: LangName | void = langCodeToNameMap.get(langCode)

  return langName ? langName : LangName.English
}

export default function Language({ className }: Props): React.Node {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const langCode: LangCode = useSelector(state => state.i18n.langCode)
  const [languagesHidden, setLanguagesHidden] = React.useState<boolean>(true)
  className = className || ''

  function changeLanguage(newLangCode: LangCode) {
    if (!langCodeToNameMap.has(newLangCode)) { return }

    dispatch(setLangCode(newLangCode))
    setLanguagesHidden(true)
  }

  function toggleLanguagesHidden() {
    setLanguagesHidden(!languagesHidden)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setLanguagesHidden(true)}>
      <div className={`sidebar__language ${languagesHidden ? '' : 'js-show'} ${className}`} onClick={toggleLanguagesHidden}>
        <div className="language language--current">
          <div className="language sidebar__menu-item sidebar__menu-item--lang-switch">
            <p className="shrink-hide">{t(getLangName(langCode))}</p>
          </div>
        </div>
        <div className="language--list">
          <div className="language login--language" onClick={() => changeLanguage('lt')}>
            <img src={ltFlag} alt="Lithuanian Flag" />
            <p className="shrink-hide">{t(LangName.Lithuanian)}</p>
          </div>
          <div className="language login--language" onClick={() => changeLanguage('en')}>
            <img src={enFlag} alt="English Flag" />
            <p className="shrink-hide">{t(LangName.English)}</p>
          </div>
          <div className="language login--language" onClick={() => changeLanguage('pl')}>
            <img src={plFlag} alt="Polish Flag" />
            <p className="shrink-hide">{t(LangName.Polish)}</p>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}