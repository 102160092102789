import { connect } from 'react-redux'
import { addCartItem, clearCart } from '~/js/actions'
import GlobalSignaturePricingTable from '../components/GlobalSignaturePricingTable'

const mapStateToProps = state => ({
  signatureProducts: state.productStore.signatureProducts,
  isSidebarShrinked: state.sidebar.isShrinked,
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSignaturePricingTable)