import { connect } from 'react-redux'
import { addStatusMessage } from '~/js/actions'
import SoftwareDiagnostic from '~/js/routes/app/SoftwareDiagnostic'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessage(type, message)),
})

export default connect(
  null,
  mapDispatchToProps
)(SoftwareDiagnostic)