export const SAVE_DOCUMENT_SEARCH_FIELD_HISTORY_INFO = 'SAVE_USER_PATH_HISTORY_INFO'
export const SAVE_DOCUMENT_TABS_HISTORY_INFO = 'SAVE_DOCUMENT_TABS_HISTORY_INFO'
export const SAVE_DOCUMENT_FILTER_HISTORY_INFO = 'SAVE_DOCUMENT_FILTER_HISTORY_INFO'
export const SAVE_DOCUMENT_SELECT_ALL_HISTORY_INFO = 'SAVE_DOCUMENT_SELECT_ALL_HISTORY_INFO'
export const SAVE_SELECTED_DOCUMENTS_HISTORY_INFO = 'SAVE_SELECTED_DOCUMENTS_HISTORY_INFO'
export const SAVE_DOCUMENT_PAGINATION_HISTORY_INFO = 'SAVE_DOCUMENT_PAGINATION_HISTORY_INFO'
export const SAVE_SELECTED_CAN_DELETE_DOCUMENTS_INFO = 'SAVE_SELECTED_CAN_DELETE_DOCUMENTS_INFO'

export const saveDocumentSearchFieldInfo = (inputFieldValue) => ({
  type: SAVE_DOCUMENT_SEARCH_FIELD_HISTORY_INFO,
  inputFieldValue
})

export const saveDocumentTabsHistoryInfo = (tabListHistory) => ({
  type: SAVE_DOCUMENT_TABS_HISTORY_INFO,
  tabListHistory
})

export const saveDocumentFilterHistoryInfo = (documentFilterHistory) => ({
  type: SAVE_DOCUMENT_FILTER_HISTORY_INFO,
  documentFilterHistory
})

export const saveDocumentSelectAllHistoryInfo = (selectAllHistory) => ({
  type: SAVE_DOCUMENT_SELECT_ALL_HISTORY_INFO,
  selectAllHistory
})

export const saveDocumentSelectedDocumentsHistoryInfo = (selectedDocumentHistory) => ({
  type: SAVE_SELECTED_DOCUMENTS_HISTORY_INFO,
  selectedDocumentHistory
})

export const saveDocumentPaginationHistoryInfo = (paginationHistoryPage, paginationHistoryOffset, paginationHistoryPageSize) => ({
  type: SAVE_DOCUMENT_PAGINATION_HISTORY_INFO,
  paginationHistoryPage,
  paginationHistoryOffset,
  paginationHistoryPageSize
})

export const saveSelectedCanDeleteSelectedDocumentsInfo = (selectedCanDeleteDocumentsHistory) => ({
  type: SAVE_SELECTED_CAN_DELETE_DOCUMENTS_INFO,
  selectedCanDeleteDocumentsHistory
})
