import React from 'react'
import PropTypes from 'prop-types'

export default function InformationBar({ informationText, icon, iconAlt, informationClass, additionalClass }) {
  return (
    //There are three available classes: "error", "warning", "info", "success".
    <div
      className={`information-message information-message__${informationClass} information-message_${additionalClass}`}
    >
      {icon &&
        <div className="massage-icon">
          <img src={icon} alt={iconAlt} />
        </div>
      }
      <div className={`massage-text ${icon ? '' : 'no-icon'}`}>{informationText}</div>
    </div>
  )
}

InformationBar.propTypes = {
  informationText: PropTypes.any,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  informationClass: PropTypes.string,
  additionalClass: PropTypes.string,
}