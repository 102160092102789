import React from 'react'
import Select from '~/js/components/Select'
import PropTypes from 'prop-types'

const pageSizeOptions = [
  { value: 10, label: 10 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
]

export default function Pagination({ t, offset, limit, total, onPreviousClick, onNextClick, onPageSizeChange, showPageSizeSelect }) {
  const firstElement = offset + 1
  const lastElement = offset + limit
  const prevPageExist = offset > 0
  const nextPageExist = lastElement < total

  return (
    <div className="pagination">
      {showPageSizeSelect &&
        <>
          <span className="page-size-label">{t('common.page_size')}</span>
          <Select
            className="page-size-filters"
            menuPlacement="top"
            name="pageSize"
            onChange={onPageSizeChange}
            options={pageSizeOptions}
            placeholder={limit}
          />
        </>
      }
      <div className="pagination__number">
        <span className="pagination__from">
          {firstElement}
          -
          {total < lastElement ? total : lastElement}
        </span>
        {t('common.pagination_of')}
        <span className="pagination__to">
          {total}
        </span>
      </div>
      <div className="pagination__buttons">
        <button className="btn prev" type="button" onClick={onPreviousClick} disabled={!prevPageExist} />
        <button className="btn next" type="button" onClick={onNextClick} disabled={!nextPageExist} />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  t: PropTypes.func,
  offset: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  showPageSizeSelect: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
}