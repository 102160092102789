export const findDiscount = (discountedSubtotal, subtotal) => {
  if (discountedSubtotal) {
    discountedSubtotal = discountedSubtotal.replace(',', '.')
    subtotal = subtotal.replace(',', '.')

    if ((discountedSubtotal).indexOf('€') === 0) {
      return Math.round((parseFloat((subtotal).substr(1)) - parseFloat((discountedSubtotal).substr(1))) * 100) / 100
    } else {
      return Math.round((parseFloat((subtotal).substr(0, (subtotal).indexOf('€'))) - parseFloat((discountedSubtotal).substr(0, (discountedSubtotal).indexOf('€')))) * 100) / 100
    }
  }

  return 0
}

export const convertStrToNum = (data) => {
  data = data.replace(',', '.')
  return (data).indexOf('€') === 0 ? parseFloat(parseFloat((data).substr(1))) : parseFloat((data).substr(0, (data).indexOf('€')))
}

export const findItemVariant = (categories, category) => {
  if (categories.includes('subscription_plan')) {
    return category === 'annual_subscription_plan' ? 'Yearly' : 'Monthly'
  }

  return ''
}

export const findQuantity = (product, signaturesQuantity, finalPrice) => {
  if (product.categories.includes('subscription_plan') && product.name === 'teams') {
    return convertStrToNum(finalPrice) / convertStrToNum(product.price)
  }

  return 1
}

export const findConvertedPrice = (productObj, product) => {
  const itemVariant = findItemVariant(product.categories, productObj.category)
  const productPrice = convertStrToNum(productObj.price)

  if (itemVariant === 'Yearly' && (productObj.name === 'professional' || productObj.name === 'teams')) {
    return (product.gaPercentage * productPrice * 3)
  } else if (itemVariant === 'Monthly' && (productObj.name === 'professional' || productObj.name === 'teams')) {
    return (product.gaPercentage * productPrice * 33)
  }

  return productPrice
}
