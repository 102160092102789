import React from 'react'
import StatusDocument from '~/js/components/StatusDocument'
import ModalAssignDocumentCategory from '../ModalAssignDocumentCategory'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import * as documentService from '~/js/services/user/document'
import Tooltip from '../Tooltip'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import ProIcon from '../ProIcon/ProIcon'
import PropTypes from 'prop-types'
import { formatDateTimeNoSeconds } from '../../utils/date'

export default class SectionDocumentInformation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalAssignDocumentCategory: {
        active: false
      },
      documentCategories: [],
      assignedCategories: [],
      searchValue: '',
      editMode: true,
      documentTitle: props.document.displayTitle,
      saveTitleModeDisplay: 'none',
      deadline: props.deadline ? formatDateTimeNoSeconds(props.deadline) : null
    }

    this.fetchCategories = this.fetchCategories.bind(this)
    this.saveOption = this.saveOption.bind(this)
    this.editOption = this.editOption.bind(this)
    this.displaySaveBlock = this.displaySaveBlock.bind(this)
    this.setTitle = this.setTitle.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onCategorySelect = this.onCategorySelect.bind(this)
    this.onDocumentCategoryUnassign = this.onDocumentCategoryUnassign.bind(this)
    this.onAssignCategoriesToDocument = this.onAssignCategoriesToDocument.bind(this)
    this.onAssignDocumentCategoryClick = this.onAssignDocumentCategoryClick.bind(this)
    this.transformFetchedCategories = this.transformFetchedCategories.bind(this)
    this.fetchAssignedCategories = this.fetchAssignedCategories.bind(this)
    this.setCategories = this.setCategories.bind(this)
    this.setDateTimeValue = this.setDateTimeValue.bind(this)

    this.fetchCategories()
    this.fetchAssignedCategories()
  }

  componentDidUpdate(prevProps) {
    if (this.props.document.categories !== prevProps.document.categories) {
      this.fetchAssignedCategories()
      this.fetchCategories()
    }

    if (this.props.deadline !== prevProps.deadline) {
      this.setState({
        deadline: formatDateTimeNoSeconds(this.props.document.deadline)
      })
    }
  }

  editOption() {
    this.displaySaveBlock(true)
    this.setState({
      editMode: false
    })
  }

  displaySaveBlock(isActive){
    this.setState({ saveTitleModeDisplay: isActive ? 'inline' : 'none' })
  }

  setTitle(value) {
    this.setState({
      documentTitle: value
    })
  }

  saveOption({ documentTitle }) {
    this.displaySaveBlock(false)
    const { t } = this.props
    this.setState({
      editMode: true,
      documentTitle,
    })

    return documentService
      .updateDocumentTitle(this.props.document.uuid, { displayTitle: documentTitle })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_title_was_successfully_updated')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchCategories() {
    const { searchValue, assignedCategories } = this.state

    documentService
      .searchDocumentCategories(searchValue, assignedCategories, this.props.visibleDocumentCategories)
      .then(data => this.setCategories(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setDateTimeValue(deadline) {
    const { t } = this.props

    return documentService
      .updateDocumentDeadline(
        this.props.document.uuid,
        {
          deadline: deadline
        }
      )
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_deadline_was_successfully_updated')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCategories(data) {
    const { data: categories } = data

    this.transformFetchedCategories(categories)
    this.setState({
      documentCategories: categories
    })
  }

  fetchAssignedCategories() {
    const { document } = this.props
    const { assignedCategories } = this.state

    document.categories.forEach(category => assignedCategories.indexOf(category.id) === -1 ? assignedCategories.push(category.id) : null)
  }

  transformFetchedCategories(categories) {
    categories.forEach(category => {
      category.selected = false
    })
  }

  onAssignDocumentCategoryClick() {
    this.setState({
      modalAssignDocumentCategory: {
        active: true
      }
    })
  }

  onAssignCategoriesToDocument(categories) {
    const selectedCategories = []

    categories.forEach(category => {
      if (category.selected) {
        selectedCategories.push(category)
      }
    })

    if (selectedCategories.length > 0) {
      this.setState({ modalAssignDocumentCategory: { active: false }, searchValue: '' })
    }

    this.props.assignCategoriesToDocument(categories)
  }

  onDocumentCategoryUnassign(category) {
    const { assignedCategories } = this.state
    this.props.unassignDocumentCategory(category)

    const index = assignedCategories.indexOf(category.id)
    if (index !== -1) {
      assignedCategories.splice(index, 1)
    }

    this.fetchCategories()
  }

  onCategorySelect(index) {
    const { documentCategories } = this.state

    documentCategories[index].selected = !documentCategories[index].selected

    this.setState({ documentCategories })
  }

  onSearchChange(value) {
    this.setState({ searchValue: value })
    this.fetchCategories()
  }

  onModalClose() {
    this.setState({ modalAssignDocumentCategory: { active: false }, searchValue: '' })
    this.fetchCategories()
  }

  render() {
    const { documentTitle } = this.state
    const { t, document, user, canEdit } = this.props
    const initialValues = { documentTitle }
    const validationSchema = Yup.object().shape({
      documentTitle: Yup.string()
        .required(t('validation.field_is_required'))
    })

    return (
      <React.Fragment>
        <div className="wrapped-section">
          <div className="wrapped-section__headline">
            <h2 className="headline headline--document-information">{t('common.information')}</h2>
          </div>
          <div className="info-details">
            <ul>
              <li>
                <div className="info-details__label">
                  <label>{t('common.title')}</label>
                </div>
                <div className="info-details__info">
                  {
                    !this.state.editMode ?
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={this.saveOption}
                      >
                        {props => (
                          <Form className="edit-document-title">
                            <div
                              className={'form-group' + (props.errors.documentTitle && props.touched.documentTitle ? ' form-group--error' : '')}
                            >
                              <Field
                                name="documentTitle"
                                className="form-group__input"
                                type="text"
                              />
                              <ErrorMessage className="form-group__error" name="documentTitle" component="span" />
                            </div>

                            <button
                              className="save-title"
                              type="submit"
                              style={{ display: this.state.saveTitleModeDisplay }}
                              onChange={e => this.setTitle(e.target.value)}
                            />
                          </Form>
                        )}
                      </Formik>
                      :
                      <p>{this.props.displayTitle ? this.props.displayTitle : documentTitle}
                        { canEdit && (
                          <a className="edit-title" onClick={this.editOption} />
                        )}
                      </p>
                  }
                </div>
              </li>
              {this.props.signaturesInfo && (
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.document_inspection_time')}</label>
                  </div>
                  <div className="info-details__info">
                    <p>{this.props.signaturesInfo.validationTime}</p>
                  </div>
                </li>
              )}
              {this.props.signaturesInfo && (
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.valid_total_signatures')}</label>
                  </div>
                  <div className="info-details__info">
                    <p>{this.props.signaturesInfo.validSignaturesCount}/{this.props.signaturesInfo.signaturesCount}</p>
                  </div>
                </li>
              )}
              {this.props.signaturesInfo && (
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.valid_until')}</label>
                  </div>
                  <div className="info-details__info">
                    <p>{this.props.signaturesInfo.documentValidUntil}</p>
                  </div>
                </li>
              )}
              <li>
                <div className="info-details__label">
                  <label>{t('common.document_uploader')}</label>
                </div>
                <div className="info-details__info">
                  <p>{document && document.documentUploader}</p>
                </div>
              </li>
              <li>
                <div className="info-details__label">
                  <label>{t('common.type')}</label>
                </div>
                <div className="info-details__info">
                  <p>{document.format ? document.format.toUpperCase() : document.format}</p>
                </div>
              </li>
              {this.props.showCategories && document.permissions.canAssignCategory && (
                <li>
                  <div className="info-details__label">
                    <label>
                      {t('common.categories')}&nbsp;
                      <Tooltip text={t('user.document.document_access_by_category')} inline={true} newLine={true}>
                        <span className="info-icon-grey" />
                      </Tooltip>
                    </label>
                  </div>
                  <div className={'info-details__info info-details__info--categories' + ((user.subscription === 'start' || user.subscription === 'no_plan') ? ' disabled-checkbox' : '')}>
                    {document.categories.map((category) => (
                      <p key={category.id} style={{ color: '#' + category.color }}>
                        {category.name}
                        <a className="remove-category" onClick={() => this.onDocumentCategoryUnassign(category)} />
                      </p>
                    )
                    )}
                    {(user.subscription === 'start' || user.subscription === 'no_plan')
                      ? <span className="btn btn--icon btn--add">{t('user.document.assign_category')}</span>
                      :
                      <a
                        className="btn btn--icon btn--add" onClick={() => this.onAssignDocumentCategoryClick()}
                        id="assign-category"
                      >{t('user.document.assign_category')}
                      </a>
                    }
                    <div>
                      {(user.subscription === 'start' || user.subscription === 'no_plan') &&
                        <ProIcon tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
                      }
                    </div>
                  </div>
                </li>
              )}
              {this.props.showStatus && (
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.status')}</label>
                  </div>
                  <div className="info-details__info">
                    <StatusDocument t={t} status={document.status} />
                  </div>
                </li>
              )}
              {this.props.signatureIntegrity && (
                <li>
                  <div className="info-details__label">
                    <label>
                      {t('user.document.status')}&nbsp;
                      <Tooltip text={t('user.document.signature_validity_auto_update')} inline={true} newLine={true}>
                        <span className="info-icon-grey" />
                      </Tooltip>
                    </label>
                  </div>
                  <div className="info-details__info">
                    <StatusDocument t={t} status={this.props.signatureIntegrity} />
                  </div>
                </li>
              )}
              {this.props.showAccess && (
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.access')}</label>
                  </div>
                  <div className="info-details__info">
                    <p>{t(document.access)}</p>
                  </div>
                </li>
              )}
              {this.props.showDeadline && this.state.deadline && (
                <li>
                  <div className="info-details__label">
                    <label>{t('common.deadline')}</label>
                  </div>
                  <div className="info-details__info">
                    <p>{this.state.deadline}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <ModalAssignDocumentCategory
          document={document}
          active={this.state.modalAssignDocumentCategory.active}
          title={t('user.document.assign_category')}
          t={t}
          assignCategory={this.onAssignCategoriesToDocument}
          onCloseClick={() => this.onModalClose()}
          showStatusMessage={this.props.showStatusMessage}
          documentCategories={this.state.documentCategories}
          onCategorySelect={this.onCategorySelect}
          onSearchChange={this.onSearchChange}
          searchValue={this.state.searchValue}
          fetchCategories={this.fetchCategories}
          canCreateCategory={user.workspaceId ? user.subscription === 'flexible' || user.subscription === 'teams' || user.subscription === 'limited_flexible' : true}
        />
      </React.Fragment>
    )
  }
}

SectionDocumentInformation.defaultProps = {
  showStatus: true,
}

SectionDocumentInformation.propTypes = {
  document: PropTypes.object,
  t: PropTypes.func,
  visibleDocumentCategories: PropTypes.array,
  showStatusMessage: PropTypes.func,
  assignCategoriesToDocument: PropTypes.func,
  unassignDocumentCategory: PropTypes.func,
  user: PropTypes.object,
  canEdit: PropTypes.bool,
  signaturesInfo: PropTypes.object,
  showCategories: PropTypes.bool,
  showStatus: PropTypes.bool,
  signatureIntegrity: PropTypes.string,
  showAccess: PropTypes.bool,
  displayTitle: PropTypes.string,
  showDeadline: PropTypes.bool,
  deadline: PropTypes.string,
}
