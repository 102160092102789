import store from '~/js/bootstrap/store'
import { generatePath as baseGeneratePath } from 'react-router-dom'
import { ROUTE_PATHS, NAMESPACE_USER, NAMESPACE_WORKSPACE } from './config'

export function getUserWorkspace(user) {
  user = user || store.getState().user.user
  const { pathname } = window.location
  let namespace

  if (user && (user === NAMESPACE_WORKSPACE || user.workspaceId)) {
    namespace = NAMESPACE_WORKSPACE
  } else if (user) {
    namespace = NAMESPACE_USER
  } else {
    const matches = pathname.match(/^(\/en|\/lt|\/pl)?\/(.+?)\//)
    namespace = matches ? matches[2] : null
  }

  return namespace
}

export function generatePath(routeName, params = {}) {
  if (typeof params.workspace === 'undefined') {
    params.workspace = getUserWorkspace()
  }

  return baseGeneratePath(ROUTE_PATHS.get(routeName), params)
}