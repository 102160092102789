import {
  ACTION_SET_LAST_REQUEST_TIME,
  ACTION_REQUEST_USER,
  ACTION_RECEIVE_USER,
  ACTION_REQUEST_SWITCH,
  ACTION_COMPLETE_SWITCH,
  ACTION_SET_SESSION_ID,
  ACTION_SET_SERIAL_NUMBER,
  ACTION_SET_SESSION_STATUS,
  ACTION_SET_AUTH_METHOD,
  ACTION_SET_AUTH_INFOS,
} from '../actions'
import { ACTION_UPDATE_VALIDATION_COUNT } from '../actions/validateDocument'
import * as Sentry from '@sentry/react'

export default (
  state = {
    lastRequestTime: null,
    isFetching: false,
    isSwitching: false,
    user: null,
    sessionId: null,
    serialNumber: null,
    sessionStatus: null,
    workspaces: [],
    authMethod: null,
    authInfos: {}
  },
  action
) => {
  switch (action.type) {
  case ACTION_SET_LAST_REQUEST_TIME:
    return Object.assign({}, state, {
      lastRequestTime: action.time,
    })
  case ACTION_SET_SESSION_ID:
    return Object.assign({}, state, {
      sessionId: action.sessionId,
    })
  case ACTION_SET_SERIAL_NUMBER:
    return Object.assign({}, state, {
      serialNumber: action.serialNumber,
    })
  case ACTION_SET_SESSION_STATUS:
    return Object.assign({}, state, {
      sessionStatus: action.sessionStatus,
    })
  case ACTION_REQUEST_USER:
    return Object.assign({}, state, {
      isFetching: true,
    })
  case ACTION_RECEIVE_USER:
    action.workspaces.forEach(workspace => {
      workspace.active = workspace.id === action.user.workspaceId
    })

    Sentry.setContext('User context', {
      identityId: action.user?.identityId,
    })

    return Object.assign({}, state, {
      isFetching: false,
      user: action.user,
      workspaces: action.workspaces,
    })
  case ACTION_REQUEST_SWITCH:
    return Object.assign({}, state, {
      isSwitching: true,
    })
  case ACTION_COMPLETE_SWITCH:
    return Object.assign({}, state, {
      isSwitching: false,
    })
  case ACTION_UPDATE_VALIDATION_COUNT:
    return Object.assign({}, state, {
      user: {
        ...state.user,
        validation: {
          left: action.validationInfo.data.left,
          allowed: action.validationInfo.data.allowed,
          validated: action.validationInfo.data.validated,
          canValidate: action.validationInfo.data.canValidate
        }
      }
    })
  case ACTION_SET_AUTH_METHOD:
    return Object.assign({}, state, {
      authMethod: action.authMethod
    })
  case ACTION_SET_AUTH_INFOS:
    return Object.assign({}, state, {
      authInfos: action.authInfos
    })
  default:
    return state
  }
}
