// @flow

import type { Node } from 'react'

type Props = {
  loadingText: string,
}

export default function Loader(props: Props): Node {
  const { loadingText } = props

  return (
    <div
      className="loader dropzone-uploads__inner dropzone-preview"
      id="dropzone-previews"
    >
      <div className="upload-item" id="dropzone-uploadItemTemplate">
        <div className="progressbar" data-pct="100" role="progressbar">
          <div className="loader_background-border">
            <div id="loader" />
          </div>
        </div>
        <p className="loader__text ta-center">{loadingText}</p>
      </div>
    </div>
  )
}