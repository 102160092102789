import { connect } from 'react-redux'
import { removeCartItem, clearCart } from '~/js/actions'
import SubscriptionWorkspace from '~/js/routes/app/User/SubscriptionWorkspace'

const mapStateToProps = state => ({
  cart: state.cart
})

const mapDispatchToProps = dispatch => ({
  removeCartItem: (id) => dispatch(removeCartItem(id)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionWorkspace)