import { combineReducers } from 'redux'
import cart from './cart'
import statusMessages from './statusMessages'
import user from './user'
import dataLayer from './dataLayer'
import page from './page'
import productStore from './productStore'
import pathHistory from './pathHistory'
import temporaryDocument from './temporaryDocument'
import sidebar from './sidebar'
import i18n from './i18n'

export default combineReducers({
  cart,
  statusMessages,
  user,
  dataLayer,
  page,
  productStore,
  pathHistory,
  temporaryDocument,
  sidebar,
  i18n
})
