import React from 'react'

export default class ControlButtons extends React.Component {
  render() {

    const { className, size } = this.props
    let ctrlClassName = 'controls-buttons'

    !this.props.active ? ctrlClassName += ' show' : ctrlClassName += ' active'

    if (className) {
      ctrlClassName += ` ${className}`
    }

    if (size) {
      ctrlClassName += ` controls-buttons--${size}`
    }

    if (this.props.inline) {
      ctrlClassName += ' controls-buttons--inline'
    }

    return (
      <div className={ctrlClassName}>
        {this.props.children}
      </div>
    );
  }
}

ControlButtons.defaultProps = {
  active: true,
}