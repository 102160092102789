import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from '../i18n'
import { withTranslation } from 'react-i18next'

const withErrorTranslation = (DomComponent, className) => {
  class FieldError extends Component {
    constructor(props) {
      super(props)
      this.state = {
        msg: props.t(props.children)
      }

      this.languageChangedListener = this.languageChangedListener.bind(this)
    }

    languageChangedListener() {
      const { t, children } = this.props
      this.setState({ msg: t(children) })
    }

    componentDidMount() {
      i18n.on('languageChanged', this.languageChangedListener)
    }

    componentWillUnmount() {
      i18n.off('languageChanged', this.languageChangedListener)
    }

    render() {
      return <DomComponent className={className}>{this.state.msg}</DomComponent>
    }
  }

  FieldError.propTypes = {
    children: PropTypes.string,
    t: PropTypes.func,
  }

  return withTranslation()(FieldError)
}

export default withErrorTranslation