import axios from 'axios'
import { isWindows } from 'react-device-detect'
import config, { ENV_PROD } from '../config'
import * as utils from '../utils/version'
import { ROUTE_NAMES } from '../routes/config'

const LATEST_SOFTWARE_VERSION = '1.1.1'
const apiClient = axios.create({
  baseURL: 'http://localhost:5000'
})

export const ERR_NOT_WINDOWS = 'not_windows'
export const ERR_NETWORK = 'network_error'
export const ERR_NOT_INSTALLED = 'not_installed'
export const ERR_UPDATE_NEEDED = 'update_needed'
export const ERR_PIN_LOCKED = 'pin_locked'
export const ERR_CERT_MISSING = 'cert_missing'

const errorToTextMap = new Map([
  [ERR_NOT_WINDOWS, 'usb.non_windows_os_error'],
  [ERR_NETWORK, 'user.login.software_missing'],
  [ERR_NOT_INSTALLED, 'user.login.software_missing'],
  [ERR_UPDATE_NEEDED, 'user.login.software_update_needed'],
  [ERR_PIN_LOCKED, 'usb.blocked_card'],
  [ERR_CERT_MISSING, 'user.login.no_device_connected'],
])
const errorToLinkTextMap = new Map([
  [ERR_NOT_WINDOWS, 'user.login.more_information'],
  [ERR_NETWORK, 'user.login.install_here'],
  [ERR_NOT_INSTALLED, 'user.login.install_here'],
  [ERR_UPDATE_NEEDED, 'user.login.more_information'],
  [ERR_PIN_LOCKED, 'user.login.more_information'],
  [ERR_CERT_MISSING, 'user.login.more_information'],
])

export function checkForUsbCompatability() {
  const result = {
    installData: null,
    certData: null,
    osCheckData: usbCheckOs(),
    errors: [],
  }

  if (!result.osCheckData.isWindows) {
    result.errors.push(ERR_NOT_WINDOWS)
  }

  return usbCheckTheInstalledTools()
    .then(({ data, errors }) => {
      result.installData = data

      return errors.length ? Promise.reject(errors) : usbCheckCertificationData()
    })
    .then(({ data, errors }) => {
      result.certData = data

      if (errors.length) {
        return Promise.reject(errors)
      }

      return result
    })
    .catch(errs => {
      result.errors.push(...errs)

      return Promise.reject(result)
    })
}

function usbCheckOs() {
  return { isWindows: isWindows || config.get('ENVIRONMENT') !== ENV_PROD }
}

function usbCheckTheInstalledTools() {
  return apiClient
    .get('/installed')
    .then(({ data }) => {
      const errors = []

      if (data.success !== 'true') {
        errors.push(ERR_NOT_INSTALLED)
      } else if (isSoftwareUpdateNeeded(data.version)) {
        errors.push(ERR_UPDATE_NEEDED)
      }

      return { data, errors }
    })
    .catch(() => {
      return Promise.reject([ERR_NETWORK])
    })
}

function isSoftwareUpdateNeeded(installedVersion) {
  return utils.compareSemanticVersionNumbers(installedVersion, LATEST_SOFTWARE_VERSION) < 0
}

function usbCheckCertificationData() {
  return apiClient.get('/certs')
    .then(({ data }) => {
      const errors = []

      if (data.length < 1) {
        errors.push(ERR_CERT_MISSING)
      } else if (data.find(c => c.userPinLocked === true)) {
        errors.push(ERR_PIN_LOCKED)
      } else if (!data.find(c => c.type === 'auth')) {
        errors.push(ERR_CERT_MISSING)
      }

      return { data, errors }
    })
    .catch(() => {
      return Promise.reject([ERR_NETWORK])
    })
}

function getErrorDetails(error) {
  return {
    error,
    errorText: errorToTextMap.has(error) ? errorToTextMap.get(error) : 'user.login.unknown_error',
    errorLink: ROUTE_NAMES.SOFTWARE_DIAGNOSTIC,
    errorLinkText: errorToLinkTextMap.has(error) ? errorToLinkTextMap.get(error) : 'user.login.more_information',
  }
}

export function handleUsbCheckErrors({ errors }) {
  if (!errors || !errors.length) {
    return getErrorDetails('')
  }

  return getErrorDetails(errors[0])
}