import React from 'react'
import { withTranslation } from 'react-i18next'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'

class ModalRemove extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}
      >
        <div className="center-element center-element--space-between">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn--red"
            onClick={this.props.onRemoveClick}
          >
            {t('common.remove')}
          </button>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalRemove)

ModalRemove.propTypes = {
  onCloseClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  t: PropTypes.func,
}