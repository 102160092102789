// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { generatePath } from '../../routes/router'
import { ROUTE_NAMES } from '../../routes/config'
import uploadDocuments from '../../../img/illustration/upload-documents.svg'
import { AuthMethod } from '../../models/User'
import type { User } from '../../models/User'

type Props = {
  active: boolean,
  openModalAdditionalSignatures: () => void,
  onCloseClick: () => void,
  user: User,
}

export default function ModalSignaturesCta(props: Props): React.Node {
  const { active, openModalAdditionalSignatures, onCloseClick, user } = props
  const { t } = useTranslation()

  return (
    <div className="document-preview">
      <Modal
        className="document-preview__modal"
        size="large"
        active={active}
        onCloseClick={onCloseClick}
      >
        <div className="pricing-table__modal">
          <div className="pricing-table__modal-content">
            <div className="pricing-table__modal-img">
              <img src={uploadDocuments} alt="Upload Documents" />
            </div>
            <div className="pricing-table__row pricing-table__row--signatures">
              <div className="pricing-table__row-heading">
                {user.authMethod === AuthMethod.ZealId
                  ? t('user.signatures.out_of_global_signatures')
                  : t('user.signatures.out_of_signatures')
                }
              </div>
              <div className="pricing-table__sub-rows">
                {user.authMethod === AuthMethod.ZealId
                  ? t('user.signatures.info_about_signatures_global')
                  : t('user.signatures.info_about_signatures')
                }
              </div>
            </div>
          </div>
          <div className="document-preview__modal--buttons">
            <button
              className="btn btn--secondary"
              onClick={openModalAdditionalSignatures}
            >
              {t('user.pricing.buy_more_signatures')}
            </button>
            <a
              className="btn btn--green"
              href={generatePath(ROUTE_NAMES.USER_PRICING)}
            >
              {t('user.settings.upgrade')}
            </a>
          </div>
        </div>
      </Modal>
    </div>
  )
}
