import React from 'react'
import PropTypes from 'prop-types'
import * as dateUtils from '~/js/utils/date'
import InformationBar from '~/js/components/InformationBar'
import Info from '../../../../img/icons/info-slick-white.svg'

export default class UsbDocumentsComments extends React.Component {
  render() {
    const { t, document } = this.props
    return (
      <div className="usb-document-comments">
        <ul>
          {typeof document.documentComments === 'undefined' || document.documentComments.length === 0
            ? <InformationBar
              icon={Info}
              iconAlt="Info"
              informationText={t('user.document.document_has_no_comments')}
              informationClass='info'
            />
            : document.documentComments.map((comment) => (
              <li key={comment.id}>
                <div className="usb-document-comments__date">{dateUtils.formatDateTime(comment.createdAt)}</div>
                <div className="usb-document-comments__commenters-name">{comment.personName}</div>
                <div className="usb-document-comments__comment-text">{comment.commentaryText}</div>
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
}

UsbDocumentsComments.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
}