import React from 'react'
import _debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { Field } from 'formik'

export default class FieldDebounced extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tempValue: props.value || '',
      debouncedOnChange: _debounce(props.onChange, props.wait),
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { props } = this

    if (prevProps.onChange !== props.onChange) {
      this.setState({ debouncedOnChange: _debounce(props.onChange, props.wait) })
    }

    if (prevProps.value !== props.value) {
      this.setState({ tempValue: props.value })
    }
  }

  onChange(e) {
    typeof this.props.onEachKeyPress === 'function' && this.props.onEachKeyPress()
    const tempValue = e.target.value.replace(/\s+/g, ' ')

    if (tempValue !== this.state.tempValue) {
      this.setState({ tempValue })
      this.state.debouncedOnChange(tempValue)
    }
  }

  render() {
    const { props } = this

    return (
      <Field
        className="form-group__input"
        type="text"
        name={props.fieldName}
        onChange={e => {
          this.onChange(e)
          return props.formikProps.handleChange(e)
        }}
      />
    )
  }
}

FieldDebounced.defaultProps = {
  wait: 500,
}

FieldDebounced.propTypes = {
  value: PropTypes.string,
  onEachKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  wait: PropTypes.number,
  fieldName: PropTypes.string,
  formikProps: PropTypes.object,
}