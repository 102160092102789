import { connect } from 'react-redux'
import { addStatusMessage } from '~/js/actions'
import SimplySignCallback from '~/js/routes/app/SimplySignCallback'
import { setSerialNumber, setSessionId } from '../actions'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessage(type, message)),
  setSessionId: sessionId => dispatch(setSessionId(sessionId)),
  setSerialNumber: serialNumber => dispatch(setSerialNumber(serialNumber)),
})

export default connect(
  null,
  mapDispatchToProps
)(SimplySignCallback)
