import React from 'react'
import * as Yup from 'yup'
import Modal from '../Modal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

export default class ModalCategory extends React.Component {
  onCategorySubmit = values => {
    this.props.addCategory([
      values.categoryName
    ])
  }

  onCategoryEditSubmit = values => {
    this.props.editCategory([
      values.categoryName
    ])
  }

  render() {
    const { t, edit } = this.props
    const initialValues = { categoryName: this.props.name }
    const validationSchema = Yup.object().shape({
      categoryName: Yup.string()
        .required(t('validation.field_is_required'))
    })

    return (
      <Modal
        size="small"
        {...this.props}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={edit ? this.onCategoryEditSubmit : this.onCategorySubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.categoryName && props.touched.categoryName ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.categoryName')}</label>
                <Field className="form-group__input" type="text" name="categoryName" maxLength={35} />
                <ErrorMessage className="form-group__error" name="categoryName" component="span" />
              </div>

              <button
                type="submit"
                className="btn btn--primary ta-center mt-2"
                style={{ margin: '32px auto 0 auto' }}
                disabled={props.isSubmitting}
              >
                {edit ? t('user.settings.contact.edit_category') : t('user.settings.contact.add_category')}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

ModalCategory.propTypes = {
  t: PropTypes.func,
  addCategory: PropTypes.func,
  editCategory: PropTypes.func,
  edit: PropTypes.bool,
  name: PropTypes.string,
}