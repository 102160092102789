import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '~/js/components/Pagination'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as settingsService from '~/js/services/user/settings'
import LicensesList, { License } from '~/js/components/LicensesList'
import ModalUnAssignSubscription from '~/js/components/ModalUnAssignSubscription'
import ModalAssignLicense from '~/js/components/ModalAssignLicennse'

const pageSize = 10

export default class Licenses extends React.Component {
  constructor(props) {
    super(props)

    const queryString = this.parseQueryString()
    const page = queryString.page || 1

    this.state = {
      pagination: {
        page: page,
        offset: this.getOffset(page),
        limit: pageSize,
        total: undefined,
      },
      modalAssignLicense: {
        active: false,
        licenseIndex: undefined,
      },
      modalUnAssignSubscription: {
        active: false,
        licenseIndex: undefined,
      },
      licenses: [],
      companyUsers: [],
      selectedLicense: null
    }

    this.onPaginationNextClick = this.onPaginationNextClick.bind(this)
    this.onPaginationPreviousClick = this.onPaginationPreviousClick.bind(this)
    this.onAssignSubscriptionBtnClick = this.onAssignSubscriptionBtnClick.bind(this)
    this.onUnAssignSubscriptionBtnClick = this.onUnAssignSubscriptionBtnClick.bind(this)
    this.onAssignLicenseClick = this.onAssignLicenseClick.bind(this)
    this.onModalUnAssignSubscriptionClick = this.onModalUnAssignSubscriptionClick.bind(this)
    this.fetchCompanyPeople = this.fetchCompanyPeople.bind(this)

    this.fetchLicenses(this.getOffset(page), pageSize)
    this.fetchCompanyPeople()
  }

  getOffset(page) {
    return (page - 1) * pageSize
  }

  fetchCompanyPeople() {
    settingsService
      .getBusinessPeople(null, null, this.props.workspaceId)
      .then(companyUsers => {
        this.setState({ companyUsers: companyUsers.data.filter(user => user.subscription === null) })
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onAssignLicenseClick(businessPersonId) {
    const { t, workspaceId } = this.props

    settingsService
      .assignLicense(workspaceId, businessPersonId, this.state.selectedLicense.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.license_was_successfully_assigned')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalAssignLicense: { active: false } })
        this.fetchLicenses(this.getOffset(this.state.pagination.page), pageSize)
        this.fetchCompanyPeople()
      })
  }

  onModalUnAssignSubscriptionClick() {
    const { t } = this.props
    const { licenses, modalUnAssignSubscription, pagination } = this.state
    const license = licenses[modalUnAssignSubscription.licenseIndex]

    settingsService
      .unAssignSubscription(license.id, license.businessPerson)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.license_was_successfully_assigned')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalUnAssignSubscription: { active: false } })
        this.fetchLicenses(this.getOffset(pagination.page), pageSize)
        this.props.fetchUser()
      })
  }

  fetchLicenses(offset, limit) {
    settingsService
      .getBusinessLicenses(offset, limit)
      .then(data => {
        this.setState(prevState => ({
          licenses: data.data,
          pagination: {
            ...prevState.pagination,
            total: data.totalCount,
          },
        }))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  parseQueryString() {
    const search = this.props.location.search || ''
    const match = search.match(/page=(\d)?/)

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    }
  }

  onPaginationNextClick() {
    this.setPaginationPage(this.state.pagination.page + 1)
  }

  onPaginationPreviousClick() {
    this.setPaginationPage(this.state.pagination.page - 1)
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
        offset: this.getOffset(page),
      }
    }))
  }

  onAssignSubscriptionBtnClick(index) {
    this.setState({
      modalAssignLicense: {
        active: true,
        licenseIndex: index,
      },
      selectedLicense: this.state.licenses[index]
    })
  }

  onUnAssignSubscriptionBtnClick(index) {
    this.setState({
      modalUnAssignSubscription: {
        active: true,
        licenseIndex: index,
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.pagination.page !== prevState.pagination.page) {
      this.fetchLicenses(this.state.pagination.offset, this.state.pagination.limit)
    }
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <div className="users-list users-list--company">
          <div className="table table--settings">
            <ul className="table__head">
              <li>
                <label>{t('user.settings.company_user.license_type')}</label>
              </li>
              <li>
                <label>{t('user.pricing.expiration_date')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.status')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.assigned_to')}</label>
              </li>
            </ul>
            <LicensesList>
              {this.state.licenses.map((l, index) => (
                <License
                  key={l.id}
                  t={t}
                  subscription={l.subscription}
                  expirationDate={l.expirationDate}
                  businessPerson={l.businessPerson}
                  businessPersonName={l.businessPersonName}
                  businessPersonEmail={l.businessPersonEmail}
                  businessOwnerEmail={this.props.user.businessPersonEmail}
                  onAssignSubscriptionClick={this.onAssignSubscriptionBtnClick.bind(this, index)}
                  onUnAssignSubscriptionClick={this.onUnAssignSubscriptionBtnClick.bind(this, index)}
                />
              ))}
            </LicensesList>
          </div>
          <Pagination
            t={t}
            offset={this.state.pagination.offset}
            limit={this.state.pagination.limit}
            total={this.state.pagination.total}
            onNextClick={this.onPaginationNextClick}
            onPreviousClick={this.onPaginationPreviousClick}
          />
        </div>
        <ModalAssignLicense
          active={this.state.modalAssignLicense.active}
          title={t('user.settings.company_user.assign_license')}
          onCloseClick={() => this.setState({ modalAssignLicense: { active: false } })}
          onAssignLicenseClick={this.onAssignLicenseClick}
          companyUsers={this.state.companyUsers}
        />
        <ModalUnAssignSubscription
          active={this.state.modalUnAssignSubscription.active}
          title={t('user.settings.company_user.unassign_license')}
          message={t('user.settings.company_user.are_you_sure_you_want_to_unassign')}
          onCloseClick={() => this.setState({ modalUnAssignSubscription: { active: false } })}
          onUnAssignSubscriptionBtnClick={this.onModalUnAssignSubscriptionClick}
        />
      </React.Fragment>
    )
  }
}

Licenses.propTypes = {
  workspaceId: PropTypes.number,
  user: PropTypes.object,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  t: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
}