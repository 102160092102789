import React from 'react'
import ControlButtonsDocumentCategory from '../../ControlButtonsDocumentCategory'

export default class DocumentCategory extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { t } = this.props;

    return (
      <div className="wrapped-section wrapped-section--shrink">
        <div className="accordion__element">
          <div className="accordion-document-category__title">
            <div className="document-category">
              <h2 className="headline headline--standard" style={{ marginLeft: '40px', color: `#` + this.props.color }}>
                {this.props.categoryName}
                <span className="settings__documents-count">{this.props.totalDocuments}</span>
              </h2>
            </div>
            <ControlButtonsDocumentCategory
              t={t}
              onEditClick={this.props.onDocumentCategoryEditClick}
              onDeleteClick={this.props.onDocumentCategoryDeleteClick}
            />
          </div>
        </div>
      </div>
    )
  }
}