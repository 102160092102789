import React from 'react'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import { SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import * as userService from '~/js/services/user/user'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES, NAMESPACE_USER, NAMESPACE_WORKSPACE } from '~/js/routes/config'
import { formatWorkspaces } from '~/js/utils/user'
import PropTypes from 'prop-types'

class Workspaces extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectTo: null,
      formattedWorkspaces: [],
    }

    this.fetchData()
      .then(this.initialize)
      .then(props.triggerUserLoggedInEvent(props.user, props.location.pathname))
  }

  fetchData = () => {
    const { sessionId, user } = this.props

    return this.props.getSessionStatus()
      .then(sessionStatus => {
        let promise

        if (user) {
          return
        }

        if (sessionStatus.user.status === 'active') {
          promise = this.props.fetchUser(NAMESPACE_USER)
        } else if (sessionStatus.workspace.status === 'active') {
          promise = this.props.fetchUser(NAMESPACE_WORKSPACE)
        } else if (sessionId) {
          promise = this.props.fetchUserBySessionId(sessionId)
        }

        return promise
      })
  }

  initialize = () => {
    const { history, sessionId, serialNumber, sessionStatus, user, workspaces } = this.props

    if (!user && !sessionId && !serialNumber) {
      history.push(generatePath(ROUTE_NAMES.USER_LOGIN))

      return
    }

    const formattedWorkspaces = formatWorkspaces(user, workspaces)

    if (formattedWorkspaces.length === 1) {
      return this.loginWorkspace(formattedWorkspaces[0])
    }

    if (sessionStatus.user.targetPath) {
      return this.loginWorkspace(formattedWorkspaces.find(workspace => !workspace.isBusiness))
    }

    this.setState({ formattedWorkspaces })
  }

  getLocationTargetPath = () => {
    const { location } = this.props

    return location.state && 'targetPath' in location.state ? location.state.targetPath : null
  }

  redirect = data => {
    const { t } = this.props
    const redirectTo = this.getLocationTargetPath() || data.targetPath
    return new Promise(res => {
      this.props.resetUser()
      this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.you_have_successfully_logged_in'))
      if (redirectTo.indexOf('//') === 0) {
        res()
        window.location.href = redirectTo
      } else {
        this.setState({ redirectTo }, res)
      }
    })
  }

  loginWorkspace = workspace => {
    const { sessionStatus, sessionId, serialNumber } = this.props
    const apiWorkspace = workspace.isBusiness ? NAMESPACE_WORKSPACE : NAMESPACE_USER
    let promise

    if (workspace.active) {
      promise = Promise.resolve({ targetPath: generatePath(ROUTE_NAMES.USER_DASHBOARD) })
    } else if (sessionStatus.user.status === 'active' || sessionStatus.workspace.status === 'active') {
      promise = userService.switchWorkspace(workspace.id)
    } else {
      promise = userService.finalizeLogin(sessionId, serialNumber, workspace.id, apiWorkspace)
    }

    return promise.then(this.redirect)
  }

  isWorkspaceDisabled = workspace => {
    return workspace.canSwitchTo === workspace.active // XNOR
  }

  onWorkspaceClick = workspace => {
    if (this.isWorkspaceDisabled(workspace)) {
      return
    }

    this.loginWorkspace(workspace)
  }

  render() {
    const { t } = this.props
    const { formattedWorkspaces, redirectTo } = this.state

    if (redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }

    if (formattedWorkspaces.length === 0) {
      return null
    }

    return (
      <div className="page-container--full-height" id="page-container">
        <section id="login">
          <div className="grid">
            <a
              href={generatePath(ROUTE_NAMES.HOME_PAGE)}
            >
              <img src={markIdLogo} alt="Mark ID" className="login__logo" />
            </a>
            <div className="login__container">
              <h5 className="ta-center">{t('user.login.choose_workspace')}</h5>
              <div className="login__user">
                <ul>
                  {formattedWorkspaces.map((workspace) => (
                    <li key={`workspace-${workspace.id}`}>
                      <a
                        className={'sidebar__user' + (this.isWorkspaceDisabled(workspace) ? ' sidebar__user--disabled' : '')}
                        href="#"
                        onClick={e => { e.preventDefault(); return this.onWorkspaceClick(workspace) }}
                      >
                        <div className="sidebar__user-logo">
                          <img src={workspace.isBusiness ? Company : User} alt={workspace.isBusiness ? 'Company' : 'User'} />
                        </div>
                        <div className="sidebar__user-info">
                          <span>{workspace.name}</span>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation()(Workspaces)

Workspaces.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  sessionId: PropTypes.string,
  getSessionStatus: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchUserBySessionId: PropTypes.func,
  history: PropTypes.object,
  serialNumber: PropTypes.string,
  sessionStatus: PropTypes.object,
  workspaces: PropTypes.array,
  location: PropTypes.object,
  resetUser: PropTypes.func,
  showStatusMessage: PropTypes.func,
  triggerUserLoggedInEvent: PropTypes.func,
}
