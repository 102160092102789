// @flow

import * as React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useEffect, useState } from 'react'

const defaultOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

type Props = {
  url: string,
  version?: string,
  httpHeaders?: any,
}

export default function ReactPdf(props: Props): React.Node {
  const { url, version, httpHeaders } = props
  const [numPages, setNumPages] = useState(1)
  const versionedUrl = version ? url+`?version=${version}` : url

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  })

  return (
    <div className="react-pdf">
      <Document
        className="react-pdf__document"
        file={versionedUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{ ...defaultOptions, httpHeaders }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className="react-pdf__page"
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={true}
          />
        ))}
      </Document>
    </div>
  )
}