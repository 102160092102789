import React from 'react'
import HistoryRecordsList, { HistoryRecord } from '../HistoryRecordsList'
import PropTypes from 'prop-types'

export default class History extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLess: false
    }
  }

  isExpandable = () => {
    return this.props.historyRecords.length > 3
  }

  render() {
    const { t, historyRecords, displayHeadline, displayShowLess } = this.props

    return (
      <div className="wrapped-section">
        { displayHeadline &&
          <div className="wrapped-section__headline">
            <h2 className="headline headline--history">{t('user.document.history')}</h2>
          </div>
        }
        <div className="info-details">
          <HistoryRecordsList showAll={this.state.showLess}>
            {historyRecords.map((record, index) => (
              <HistoryRecord
                key={index}
                t={t}
                date={record.createdAt}
                action={record.action}
                fullname={record.person} />
            ))}
          </HistoryRecordsList>
        </div>
        {(displayShowLess && this.isExpandable()) && (
          <div id="show-more" className={this.state.showLess ? 'js-show-less' : ''}>
            <div className="show-more__overlay" />
            <a className="btn btn--icon btn--arrow-down" onClick={() => this.setState(prevState => ({ showLess: !prevState.showLess }))}>
              <p>{this.state.showLess ? t('user.document.show_less') : t('user.document.show_more')}</p>
            </a>
          </div>
        )}
      </div>
    )
  }
}

History.propTypes = {
  t: PropTypes.func,
  historyRecords: PropTypes.array,
  displayHeadline: PropTypes.bool,
  displayShowLess: PropTypes.bool,
}