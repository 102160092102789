// @flow

export const CATEGORY_SIGNATURE = 'signature'
export const SIGNATURE_TYPE_PLAN = 1
export const SIGNATURE_TYPE_EXTRA = 2
export const SIGNATURE_TYPE_GLOBAL = 3

export default class Product {
  id: number
  name: string
  category: string
  categories: Array<string>
  price: number

  constructor(id: number, name: string, category: string, categories: Array<string>, price: number) {
    this.id = id
    this.name = name
    this.category = category
    this.categories = categories
    this.price = price
  }
}