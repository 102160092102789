export const ACTION_ADD_CART_ITEM = 'ACTION_ADD_CART_ITEM'
export const ACTION_REMOVE_CART_ITEM = 'ACTION_REMOVE_CART_ITEM'
export const ACTION_CLEAR_CART = 'ACTION_CLEAR_CART'
export const ACTION_ADD_PAYMENT_INFO = 'ACTION_ADD_PAYMENT_INFO'
export const ACTION_BEGIN_CHECKOUT = 'ACTION_BEGIN_CHECKOUT'
export const ACTION_PURCHASE_SUCCESS = 'ACTION_PURCHASE_SUCCESS'
export const ACTION_NEO_PAY_PURCHASE_SUCCESS = 'ACTION_NEO_PAY_PURCHASE_SUCCESS'

let nextCartItemId = 0

export const addCartItem = (product, quantity = 1) => ({
  type: ACTION_ADD_CART_ITEM,
  id: nextCartItemId++,
  product,
  quantity,
})

export const removeCartItem = id => ({
  type: ACTION_REMOVE_CART_ITEM,
  id,
})

export const clearCart = () => ({
  type: ACTION_CLEAR_CART,
})

export const pushGTagAddPaymentInfo = (product, subtotal, discountedSubtotal, coupon) => ({
  type: ACTION_ADD_PAYMENT_INFO,
  product,
  subtotal,
  discountedSubtotal,
  coupon
})

export const pushGTagBeginCheckout = (product, subtotal, discountedSubtotal, coupon) => ({
  type: ACTION_BEGIN_CHECKOUT,
  product,
  subtotal,
  discountedSubtotal,
  coupon
})

export const pushGTagPurchaseSuccess = (product, subtotal, discountedSubtotal, coupon, vat, transactionId, user) => ({
  type: ACTION_PURCHASE_SUCCESS,
  product,
  subtotal,
  discountedSubtotal,
  coupon,
  vat,
  transactionId,
  user
})

export const pushGTagNeoPayPurchaseSuccess = (product, transaction, user) => ({
  type: ACTION_NEO_PAY_PURCHASE_SUCCESS,
  product,
  transaction,
  user
})