import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'

class ModalRejectReason extends React.Component {
  render() {
    const { t } = this.props
    const validationSchema = Yup.object().shape({
      reasonText: Yup.string()
        .required(t('validation.field_is_required')),
    })

    return (
      <Modal
        size='medium'
        className="popup__user modal-remove-reason"
        improveMessage={this.props.improveMessage}
        {...this.props}>
        <div>
          <Formik
            initialValues={{ reasonText: '' }}
            validationSchema={validationSchema}
            onSubmit={this.props.onRejectClick}
          >
            {props => (
              <Form>
                <div
                  className={'form-group' + (props.errors.reasonText && props.touched.reasonText ? ' form-group--error' : '')}>
                  <p className="form-group__label" htmlFor="form-group__input--reason-text">{this.props.reasonLabel}</p>
                  <Field
                    id="form-group__input--reason-text"
                    className="form-group__input form-group__input--reason-text"
                    placeholder={t('user.settings.workspace.account_textarea_placeholder')}
                    component="textarea"
                    name="reasonText"/>
                  <ErrorMessage className="form-group__error" name="reasonText" component="span"/>
                </div>
                <div className="center-element center-element--space-between">
                  <button
                    className="btn btn--grey"
                    onClick={this.props.onCloseClick}>
                    {t('common.cancel')}
                  </button>
                  <button
                    className="btn btn--red"
                    type="submit">
                    {t('user.document.reject_signing')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalRejectReason)

ModalRejectReason.propTypes = {
  active: PropTypes.bool,
  t: PropTypes.func,
  onRejectClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  reasonLabel: PropTypes.string,
  improveMessage: PropTypes.string,
}