import React from 'react'
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Trans } from 'react-i18next'
import config from '~/js/config'
import Select from 'react-select'
import PropTypes from 'prop-types'
import withEmailValidation from '~/js/hoc/withEmailValidation'

const countryOptions = [
  { label: 'phone.lt', value: '+370' },
  { label: 'phone.lv', value: '+371' },
  { label: 'phone.ee', value: '+372' },
  { label: 'phone.pl', value: '+48' },
  { label: 'phone.other', value: '' }
]

let phoneNumberPlaceHolder = '6xx xx xxx'
let phoneNumberState = 'default'

FieldEmail.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
}

function FieldEmail({ name, validate }) {
  const { handleChange } = useFormikContext()

  function onChange(e) {
    const { target } = e
    target.value = target.value ? target.value.trim() : target.value
    handleChange(e)
  }

  return <Field
    className="form-group__input"
    type="text"
    name={name}
    validate={validate}
    onChange={onChange}
  />
}

class FormRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countrySelected: props.user.country === 'PL' ? 'pl' : 'default'
    }

    this.onPhoneNumberCodeChange = this.onPhoneNumberCodeChange.bind(this)
  }

  validateEmails = (value1, value2) => {
    const { t } = this.props

    if (!value1 || !value2) {
      return
    }

    if (value1 !== value2) {
      return t('validation.email_fields_do_not_match')
    }
  }

  onPhoneNumberChange = (e, { handleChange }) => {
    const { target: t } = e
    t.value = t.value || ''

    if (phoneNumberState === 'other') {
      t.value = t.value.replace(/\[D\+]/g, '').substring(0, 20)
    } else if (phoneNumberState === 'pl') {
      t.value = t.value.replace(/\[D\+]/g, '').substring(0, 9)
    } else {
      t.value = t.value.replace(/\D/g, '').substring(0, 8)
    }

    handleChange(e)
  }

  onPhoneNumberCodeChange(value) {
    switch (value) {
    case countryOptions[0].value:
      phoneNumberPlaceHolder = '6xx xx xxx'
      phoneNumberState = 'default'
      this.setState({ countrySelected: 'default' })

      break
    case countryOptions[1].value:
      phoneNumberPlaceHolder = '2xx xx xxx'
      phoneNumberState = 'default'
      this.setState({ countrySelected: 'default' })

      break
    case countryOptions[2].value:
      phoneNumberPlaceHolder = '5xx xx xxx'
      phoneNumberState = 'default'
      this.setState({ countrySelected: 'default' })

      break
    case countryOptions[3].value:
      phoneNumberPlaceHolder = 'xxx xxx xxx'
      phoneNumberState = 'pl'
      this.setState({ countrySelected: 'pl' })

      break
    case countryOptions[4].value:
      phoneNumberPlaceHolder = '+xxx xxx xx xxx'
      phoneNumberState = 'other'
      this.setState({ countrySelected: 'other' })

      break
    default:
      phoneNumberPlaceHolder = '6xx xx xxx'
    }
  }

  onSubmit = (values, formik) => {
    const valuesCopy = Object.assign({}, values)
    valuesCopy.phoneNumber = values.phoneNumberCode + values.phoneNumber
    delete valuesCopy.phoneNumberCode
    this.props.onSubmit(valuesCopy, formik)
  }

  render() {
    const style = {
      control: (base) => ({
        ...base,
        border: '0 !important',
        boxShadow: '0 !important',
        '&:hover': {
          border: '0 !important'
        }
      })
    }
    const { t, user, updateContacts, isValidEmail } = this.props
    const { countrySelected } = this.state
    const phoneLength = countrySelected === 'other' ? 20 : (countrySelected === 'pl' ? 9 : 8)
    let phoneNumber = ''
    let phoneNumberCode = countryOptions[0].value
    let countryDefaultOption = countryOptions[0]

    if (user.phoneNumber) {
      phoneNumber = user.phoneNumber
      const found = user.phoneNumber.match(/(\+370|\+371|\+372|\+48)/)
      if (found && typeof found[0] !== 'undefined') {
        countryDefaultOption = countryOptions.find(opt => opt.value === found[0])
        phoneNumber = phoneNumber.replace(found[0], '')
        phoneNumberCode = found[0]
      }
    }

    const initialValues = {
      email: user.email ? user.email : '',
      emailReenter: user.email ? user.email : '',
      phoneNumber,
      phoneNumberCode,
      acceptPrivacyPolicyAndTerms: false,
      acceptNewsletter: false
    }

    const validationShape = {
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.email_must_be_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.email_must_be_a_valid_email'), isValidEmail),
      emailReenter: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.email_must_be_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.email_must_be_a_valid_email'), isValidEmail),
      phoneNumber: Yup.string()
        .required(t('validation.field_is_required'))
        .matches(/^[+\d](?:.*\d)?$/, t('validation.input_number_validation'))
        .test('len', t('validation.number_should_contain_not_more_than_digits', { length: phoneLength }), val => val && val.length <= phoneLength),
      acceptPrivacyPolicyAndTerms: Yup.bool().oneOf([true], t('validation.field_is_required')),
      acceptNewsletter: Yup.bool()
    }

    if (user.authMethod === 'simplysign') {
      initialValues.name = ''
      initialValues.surname = ''
      validationShape.name = Yup.string().required(t('validation.field_is_required'))
      validationShape.surname = Yup.string().required(t('validation.field_is_required'))
    }

    const validationSchema = Yup.object().shape(validationShape)

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        {props => (
          <Form>
            {user.email && user.phoneNumber && !updateContacts ?
              <h5>{t('user.login.accept_privacy_policy_to_proceed')}</h5>
              :
              <React.Fragment>
                <h5>{updateContacts ? t('user.login.update_contacts') : t('user.login.register_account')}</h5>
                {user.authMethod === 'simplysign' && (
                  <div className="form-group-grid">
                    <div className={'form-group' + (props.errors.name && props.touched.name ? ' form-group--error' : '')}>
                      <label>{t('common.name')}</label>
                      <FieldEmail name="name" />
                      <ErrorMessage className="form-group__error" name="name" component="span" />
                    </div>
                    <div className={'form-group' + (props.errors.surname && props.touched.surname ? ' form-group--error' : '')}>
                      <label>{t('common.surname')}</label>
                      <FieldEmail name="surname" />
                      <ErrorMessage className="form-group__error" name="surname" component="span" />
                    </div>
                  </div>
                )}
                <div className="form-group-grid lgi--margin-20px">
                  <div className={'form-group' + (props.errors.email && props.touched.email ? ' form-group--error' : '')}>
                    <label>{t('common.email')}</label>
                    <FieldEmail name="email" validate={this.validateEmails.bind(this, props.getFieldProps('emailReenter').value)} />
                    <ErrorMessage className="form-group__error" name="email" component="span" />
                  </div>
                  <div className={'form-group' + (props.errors.emailReenter && props.touched.emailReenter ? ' form-group--error' : '')}>
                    <label>{t('user.login.reenter_email_address')}</label>
                    <FieldEmail name="emailReenter" validate={this.validateEmails.bind(this, props.getFieldProps('email').value)} />
                    <ErrorMessage className="form-group__error" name="emailReenter" component="span" />
                  </div>
                </div>
                <div className="form-group-grid lgi--margin-20px">
                  <div className={'form-group' + (props.errors.phoneNumber && props.touched.phoneNumber ? ' form-group--error' : '')}>
                    <label>{t('common.phone')}</label>
                    <div className="confirm-phone-number">
                      <Select
                        name="phoneNumberCode"
                        styles={style}
                        className="form-number-code form-group__input"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        options={countryOptions}
                        getOptionLabel={opt => t(opt.label)}
                        defaultValue={countryDefaultOption}
                        onChange={option => { props.setFieldValue('phoneNumberCode', option.value); this.onPhoneNumberCodeChange(option.value) }}
                        onBlur={props.handleBlur}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#a9a9a9',
                          },
                        })}
                      />
                      <Field className="form-group__input form-rest-phone-number" type="text" name="phoneNumber" placeholder={phoneNumberPlaceHolder} onChange={e => this.onPhoneNumberChange(e, props)} />
                    </div>
                    <ErrorMessage className="form-group__error" name="phoneNumber" component="span" />
                  </div>
                </div>
              </React.Fragment>
            }

            <div className={'form-group form-group__checkbox' + (props.errors.acceptPrivacyPolicyAndTerms && props.touched.acceptPrivacyPolicyAndTerms ? ' form-group--error' : '')}>
              <Field type="checkbox" name="acceptPrivacyPolicyAndTerms" id="accept-privacy-policy" />
              <label htmlFor="accept-privacy-policy" id="login-checkbox">
                <Trans i18nKey="user.login.accept_privacy_policy_and_terms">
                  <a href={`https://${config.get('MARKSIGN_HOST')}/privatumo-politika`} target="_blank" rel="noreferrer">Privatumo politika</a>
                  <a href={`https://${config.get('MARKSIGN_HOST')}/naudojimosi-salygos`} target="_blank" rel="noreferrer">Naudojimosi sąlygomis</a>
                </Trans>
              </label>
              <ErrorMessage className="form-group__error" name="acceptPrivacyPolicyAndTerms" component="span" />
            </div>

            <div className={'form-group form-group__checkbox' + (props.errors.acceptNewsletter && props.touched.acceptNewsletter ? ' form-group--error' : '')}>
              <Field type="checkbox" name="acceptNewsletter" id="accept-newsletter" />
              <label htmlFor="accept-newsletter" id="login-checkbox">
                {t('user.login.accept_newsletter')}
              </label>
              <ErrorMessage className="form-group__error" name="acceptNewsletter" component="span" />
            </div>

            <button
              type="submit"
              className="btn btn--primary btn-submit ta-center"
              disabled={props.isSubmitting}
            >
              {user.email || updateContacts ? t('common.continue') : t('common.register')}
            </button>
          </Form>
        )}
      </Formik>
    )
  }
}

FormRegister.propTypes = {
  t: PropTypes.func,
  onSubmit: PropTypes.func,
  user: PropTypes.object,
  updateContacts: PropTypes.string,
  isValidEmail: PropTypes.func,
}

export default (withEmailValidation(FormRegister))
