import React from 'react'
import PropTypes from 'prop-types'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import { Trans, useTranslation } from 'react-i18next'

export default function FaqLinks({ pathname, isTemporaryLogin }) {
  const { t } = useTranslation()
  return (
    <div className="faq-links">
      {(pathname === generatePath(ROUTE_NAMES.USER_LOGIN) || isTemporaryLogin) && (
        <Trans i18nKey="user.login.help_how_to_sign">
          <a href={t('helpdesk.landing')} target='_blank' rel='noreferrer'>DUK</a>
          <a href={t('helpdesk.how_to_sign')} target='_blank' rel='noreferrer'>How to sign a document</a>
        </Trans>
      )}

      {pathname === generatePath(ROUTE_NAMES.USER_REGISTER) && (
        <Trans i18nKey="user.login.help_how_to_create">
          <a href={t('helpdesk.landing')} target='_blank' rel='noreferrer'>DUK</a>
          <a href={t('helpdesk.how_to_create')} target='_blank' rel='noreferrer'>How to create an account</a>
        </Trans>
      )}

      {(pathname === generatePath(ROUTE_NAMES.USER_LOGIN_SMART_ID) || pathname === generatePath(ROUTE_NAMES.USER_REGISTER_SMART_ID)) && (
        <Trans i18nKey="user.login.help_how_to_create_smart_id">
          <a href={t('helpdesk.landing')} target='_blank' rel='noreferrer'>DUK</a>
          <a href={t('helpdesk.how_to_create_smart_id')} target='_blank' rel='noreferrer'>How to create Smart-ID</a>
        </Trans>
      )}

      {(pathname === generatePath(ROUTE_NAMES.USER_LOGIN_MOBILE_ID) || pathname === generatePath(ROUTE_NAMES.USER_REGISTER_MOBILE_ID)) && (
        <Trans i18nKey="user.login.help_how_to_create_mobile_id">
          <a href={t('helpdesk.landing')} target='_blank' rel='noreferrer'>DUK</a>
          <a href={t('helpdesk.how_to_create_mobile_id')} target='_blank' rel='noreferrer'>How to create Mobile-ID</a>
        </Trans>
      )}

      {(pathname === generatePath(ROUTE_NAMES.USER_LOGIN_SMART_CARD) || pathname === generatePath(ROUTE_NAMES.USER_REGISTER_SMART_CARD)) && (
        <Trans i18nKey="user.login.help_how_to_use_smart_card">
          <a href={t('helpdesk.faq_usb')} target='_blank' rel='noreferrer'>DUK</a>
          <a href={t('helpdesk.how_to_use_smart_card')} target='_blank' rel='noreferrer'>How to use SmartCard</a>
        </Trans>
      )}
    </div>
  )
}

FaqLinks.propTypes = {
  pathname: PropTypes.string,
  isTemporaryLogin: PropTypes.bool,
}
