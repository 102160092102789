// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  imgSrc: string,
  imgAlt?: string,
}
export default function AppLogo({ imgSrc, imgAlt }: Props): React.Node {
  const { t } = useTranslation()
  imgAlt = imgAlt || t('common.logo')

  return (
    <div className="logo">
      <img className="logo__img" src={imgSrc} alt={imgAlt} />
    </div>
  )
}