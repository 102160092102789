import React from 'react'
import { Tab, TabsList } from '~/js/components/TabsList'
import PropTypes from 'prop-types'
import Information from '../../../img/icons/information.svg'
import Members from '../../../img/icons/members.svg'
import History from '../../../img/icons/history.svg'
import Comment from '../../../img/icons/comment.svg'
import UsbDocumentMember from '~/js/components/UsbDocumentsTabs/UsbDocumentsMember'
import UsbDocumentInformation from '~/js/components/UsbDocumentsTabs/UsbDocumentsInformation'
import UsbDocumentsHistory from '~/js/components/UsbDocumentsTabs/UsbDocumentsHistory'
import UsbDocumentsComments from '~/js/components/UsbDocumentsTabs/UsbDocumentsComments'

export default class UsbDocumentsTabs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: this.initializeTabs(),
    }
  }

  initializeTabs() {
    return [
      {
        key: 1,
        name: 'information',
        active: true,
        image: Information,
      },
      {
        key: 2,
        name: 'members',
        active: false,
        image: Members,
      },
      {
        key: 3,
        name: 'user.document.history',
        active: false,
        image: History,
      },
      {
        key: 4,
        name: 'user.document.comments',
        active: false,
        image: Comment,
      },
    ]
  }

  onTabClick(tabIndex) {
    const tabs = this.state.tabs

    tabs.forEach(tab => tab.active = false)
    tabs[tabIndex].active = true

    this.setState({ tabs })
  }

  render() {
    const { t, document } = this.props

    return (
      <div className="usb-documents__document-info-tabs">
        <TabsList
          centered={false}
          showOverlay={true}
          wrapperClassName='usb-tab-list'
        >
          {this.state.tabs.map((tab, index) => (
            <Tab
              key={tab.key}
              active={tab.active}
              title={t(tab.name)}
              image={tab.image}
              onClick={this.onTabClick.bind(this, index)}
            />
          ))}
        </TabsList>
        <div className="tabs-content">
          <div className={'tabs-content__tab tabs-content__information' + (this.state.tabs[0].active ? ' active' : '')}>
            <UsbDocumentInformation
              t={t}
              document={document}
            />
          </div>
          <div className={'tabs-content__tab tabs-content__members' + (this.state.tabs[1].active ? ' active' : '')}>
            <UsbDocumentMember
              t={t}
              document={document}
            />
          </div>
          <div className={'tabs-content__tab tabs-content__history' + (this.state.tabs[2].active ? ' active' : '')}>
            <UsbDocumentsHistory
              t={t}
              document={document}
            />
          </div>
          <div className={'tabs-content__tab tabs-content__comments' + (this.state.tabs[3].active ? ' active' : '')}>
            <UsbDocumentsComments
              t={t}
              document={document}
            />
          </div>
        </div>
      </div>
    )
  }
}

UsbDocumentsTabs.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
}