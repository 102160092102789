import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '~/js/components/Modal';

class ModalUserEnableFlexibleSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noUnlimitedSignatures: false,
    }
  }

  onNoUnlimitedSignaturesSelect = () => {
    this.setState({ noUnlimitedSignatures: !this.state.noUnlimitedSignatures });
  }

  render() {
    const { t } = this.props;

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}>
        {this.props.business && (
          <div className="form-group__checkbox">
            <input className="checkbox__document" type="checkbox" id="no-unlimited-signatures"
                   checked={this.state.noUnlimitedSignatures} onChange={this.onNoUnlimitedSignaturesSelect} />
            <label htmlFor="no-unlimited-signatures">{t('user.settings.workspace.no_unlimited_signatures')}</label>
          </div>
        )}
        <div className="center-element center-element--space-between">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn--primary"
            onClick={() => this.props.onEnableClick(this.state.noUnlimitedSignatures)}>
            {t('common.enable')}
          </button>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalUserEnableFlexibleSubscription);