import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '~/js/components/Modal';

class ModalCancelPayments extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}>
        <div className="center-element center-element--space-between">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn--red"
            onClick={this.props.onCancelPaymentsClick}>
            {t('common.cancel_payments')}
          </button>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalCancelPayments);