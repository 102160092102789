import { connect } from 'react-redux'
import {
  addStatusMessage,
  setSessionId,
  setSerialNumber,
  getSessionStatus,
  setLangCode,
  setAuthMethod,
  setAuthInfos,
} from '../actions'
import LoginRoute from '../routes/app/Login'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessage(type, message)),
  getSessionStatus: () => dispatch(getSessionStatus()),
  setSessionId: sessionId => dispatch(setSessionId(sessionId)),
  setSerialNumber: serialNumber => dispatch(setSerialNumber(serialNumber)),
  setLangCode: langCode => dispatch(setLangCode(langCode)),
  setAuthMethod: authMethod => dispatch(setAuthMethod(authMethod)),
  setAuthInfos: authInfos => dispatch(setAuthInfos(authInfos)),
})

export default connect(
  null,
  mapDispatchToProps
)(LoginRoute)
