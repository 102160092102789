import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from './serviceHelpers'

export function getCompanies(offset, limit) {
  const params = { offset, limit }

  return apiClient
    .get(`v1/${getUserNamespace()}/companies`, { params })
    .then(({ data }) => data)
}

export function removeCompanyUser(uuid) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/company/person/${uuid}`)
    .then(({ data }) => data)
}

export function assignSubscription(workspaceId, businessPersonId) {
  return apiClient
    .post(`v1/workspace/${workspaceId}/person/${businessPersonId}/assign-subscription`)
    .then(({ data }) => data)
}

export function unAssignSubscription(licenseId, businessPersonId) {
  return apiClient
    .post(`v1/workspace/${licenseId}/person/${businessPersonId}/unassign-subscription`)
    .then(({ data }) => data)
}

export function getBusinessPeople(offset, limit, workspaceId, query) {
  const params = { offset, limit }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/company/${workspaceId}/persons`, { params })
    .then(({ data }) => data)
}

export function getCategories(offset, limit, query) {
  const params = { offset, limit }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/contacts/categories`, { params })
    .then(({ data }) => data)
}

export function getContacts(query, offset, limit) {
  const params = {}

  if (query) {
    params.query = query
  }

  if (limit) {
    params.offset = offset
    params.limit = limit
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/contacts`, { params })
    .then(({ data }) => data)
}

export function getContactsByCategory(categoryId, query, offset, limit) {
  const params = {}

  if (query) {
    params.query = query
  }

  if (limit) {
    params.offset = offset
    params.limit = limit
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/contacts/${categoryId}`, { params })
    .then(({ data }) => data)
}

export function removeContact(id) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/contact/${id}`)
    .then(({ data }) => data)
}

export function removeContactCategory(id) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/contact/category/${id}`)
    .then(({ data }) => data)
}

export function getAllContactCategories() {
  return apiClient
    .get(`v1/${getUserNamespace()}/contacts/all-categories`)
    .then(({ data }) => data)
}

export function getDocumentCategories(offset, limit, query) {
  const params = { offset, limit }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/documents/categories`, { params })
    .then(({ data }) => data)
}

export function removeDocumentCategory(id) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/document/category/${id}`)
    .then(({ data }) => data)
}

export function getBusinessLicenses(offset, limit) {
  const params = { offset, limit }

  return apiClient
    .get(`v1/${getUserNamespace()}/licenses`, { params })
    .then(({ data }) => data)
}

export function assignLicense(workspaceId, businessPersonId, licenseId) {
  return apiClient
    .post(`v1/workspace/${workspaceId}/person/${businessPersonId}/assign-license/${licenseId}`)
    .then(({ data }) => data)
}

export function getAllBusinessLicenses() {
  return apiClient
    .get(`v1/${getUserNamespace()}/all-licenses`)
    .then(({ data }) => data)
}