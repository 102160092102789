import React from 'react'
import { withTranslation } from 'react-i18next'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'
import InformationBar from '~/js/components/InformationBar'
import Info from '../../../img/icons/info-slick-white.svg'

class ModalAssignLicense extends React.Component {
  render() {
    const { t, companyUsers } = this.props
    return (
      <Modal
        size="small"
        {...this.props}
      >
        {companyUsers.length === 0 ?
          <InformationBar
            icon={Info}
            iconAlt="Info"
            informationText={t('user.settings.company_user.no_unassigned_company_user_found')}
            informationClass='error'
          />
          :
          <table className="table" style={{ marginTop: '20px' }}>
            <tbody>
              {companyUsers.map((user) => (
                <tr key={user.uuid}>
                  <td>
                    {user.name} {user.surname} ({ user.email })
                  </td>
                  <td>
                    <a className="btn btn--icon btn--add" onClick={() => this.props.onAssignLicenseClick(user.uuid)}>{t('user.settings.company_user.assign')}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </Modal>
    )
  }
}

export default withTranslation()(ModalAssignLicense)

ModalAssignLicense.propTypes = {
  t: PropTypes.func,
  onAssignLicenseClick: PropTypes.func,
  companyUsers: PropTypes.array,
}