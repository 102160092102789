import { connect } from 'react-redux'
import { addCartItem, clearCart } from '~/js/actions'
import PricingTable from '~/js/components/PricingTable'

const mapStateToProps = state => ({
  products: state.productStore.allProducts,
  signatureProducts: state.productStore.signatureProducts,
  isSidebarShrinked: state.sidebar.isShrinked
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingTable)