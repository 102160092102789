import React from 'react';
import Status, { STATUS_WAITING, STATUS_OK, STATUS_DANGER } from '~/js/components/Status'
import {
  DOCUMENT_STATUS_UPLOADED,
  DOCUMENT_STATUS_SAVED,
  DOCUMENT_STATUS_SAVED_DRAFT,
  DOCUMENT_STATUS_SIGNED_PARTIALLY,
  DOCUMENT_STATUS_SIGNED,
  SIGNATURE_INTEGRITY_NOT_SIGNED,
  SIGNATURE_INTEGRITY_HAS_INVALID_SIGNATURE,
  SIGNATURE_INTEGRITY_HAS_UNTRUSTED_TIMESTAMP
} from '~/js/models/Document';

const statusTypeMap = new Map(
  [
    [
      DOCUMENT_STATUS_UPLOADED,
      STATUS_WAITING
    ],
    [
      DOCUMENT_STATUS_SAVED,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_STATUS_SAVED_DRAFT,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_STATUS_SIGNED_PARTIALLY,
      STATUS_WAITING,
    ],
    [
      DOCUMENT_STATUS_SIGNED,
      STATUS_OK,
    ],
    [
      SIGNATURE_INTEGRITY_NOT_SIGNED,
      STATUS_WAITING,
    ],
    [
      SIGNATURE_INTEGRITY_HAS_INVALID_SIGNATURE,
      STATUS_DANGER,
    ],
    [
      SIGNATURE_INTEGRITY_HAS_UNTRUSTED_TIMESTAMP,
      STATUS_DANGER,
    ],
  ]
)

export default class StatusDocument extends React.Component {
  render() {
    const { t, status } = this.props

    return (
      <Status
        type={statusTypeMap.get(status)}
        text={t(`user.document.status_${status}`)} />
    )
  }
}