import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Datetime from '~/js/components/DateTime'
import * as documentService from '~/js/services/user/document'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import Select from '../Select'
import { formatDateTimeNoSeconds } from '~/js/utils/date'
import Tooltip from '~/js/components/Tooltip'
import IconTooltip from '~/js/components/IconTooltip/IconTooltip'
import moment from 'moment'

const reminderFrequencyOptions = []
for (let i = 1; i <= 10; i++) {
  reminderFrequencyOptions.push({ 'label': `${i} d.`, 'value': i })
}

class AdditionalPerks extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accordionActive: true,
      deadline: props.deadline ? formatDateTimeNoSeconds(props.deadline) : null,
      isSignForbidden: props.signForbid,
      signReminderFrequency: props.reminderFrequency,
      viaEmail: this.props.viaEmail,
      viaSMS: this.props.viaSMS,
      changedPhone: []
    }

    this.handleDeadlineChange = this.handleDeadlineChange.bind(this)
    this.onSignForbidSelect = this.onSignForbidSelect.bind(this)
    this.onSignReminderFrequencySelect = this.onSignReminderFrequencySelect.bind(this)
    this.saveInputValues = this.saveInputValues.bind(this)
    this.onReminderTypeSelect = this.onReminderTypeSelect.bind(this)
    this.findReminderType = this.findReminderType.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.remindingTypeAsText = this.remindingTypeAsText.bind(this)
    this.updateMemberPhones = this.updateMemberPhones.bind(this)
  }

  onSignForbidSelect(event) {
    this.setState({ isSignForbidden: event.target.checked })
  }

  onAccordionClick = () => {
    this.setState(prevState => ({ accordionActive: !prevState.accordionActive }))
  }

  onSignReminderFrequencySelect(selectedOption) {
    this.setState({ signReminderFrequency: selectedOption?.value || 0 })
  }

  onPhoneChange(event) {
    const { t } = this.props
    const { changedPhone } = this.state
    changedPhone.map((phone, index) => {
      if (phone.id === event.target.id) {
        changedPhone.splice(index, 1)
      }
    })

    if (event.target.value.length > 10 && event.target.value.length < 26) {
      changedPhone.push(
        {
          id: event.target.id,
          email: event.target.getAttribute('data-email'),
          phone: event.target.value
        }
      )
      this.setState({ changedPhone: changedPhone })
    } else if (event.target.value.length > 25) {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('validation.phone_number_should_contain_not_more_than_25_digits'))
    }
  }

  onReminderTypeSelect(event) {
    if (event.target.id === 'via-email') {
      this.setState({ viaEmail: event.target.checked })
    }
    if (event.target.id === 'via-sms') {
      this.setState({ viaSMS: event.target.checked })
    }
  }

  findReminderType(emailSelection, SMSSelection) {
    let reminderType = null
    if (emailSelection !== true && SMSSelection === true) {
      reminderType = 1
    } else if (emailSelection === true && SMSSelection !== true) {
      reminderType = 2
    } else if (emailSelection === true && SMSSelection === true) {
      reminderType = 3
    }
    return reminderType
  }

  handleDeadlineChange(date) {
    this.setState({ deadline: date ? formatDateTimeNoSeconds(date) : null })
  }

  saveInputValues() {
    const { documentId, runDeadlineCheck, user, t } = this.props
    const { deadline, signReminderFrequency, isSignForbidden, viaEmail, viaSMS } = this.state

    if (user.subscription === 'start' || user.subscription === 'no_plan') {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.available_from_pro_tooltip'))
      return
    }

    return documentService
      .updateDocumentDeadline(
        documentId,
        {
          deadline: deadline,
          reminderFrequency: signReminderFrequency,
          signForbid: isSignForbidden,
          reminderType: this.findReminderType(viaEmail, viaSMS)
        }
      )
      .finally(() => {
        this.props.fetchDocumentInfo()
        if (runDeadlineCheck) {
          this.props.onSelectDeadLine(deadline)
          this.props.onSelectReminderFrequency(isSignForbidden)
        }
      })
  }

  updateMemberPhones() {
    const { documentId } = this.props
    const { changedPhone } = this.state

    if (changedPhone.length) {
      this.props.updateDocumentMemberPhone(documentId, changedPhone)
      this.setState({ changedPhone: [] })
    }
  }

  remindingTypeAsText() {
    const { viaEmail, viaSMS } = this.state
    const { t } = this.props
    return (viaEmail && viaSMS && t('user.document.via_email_sms'))
      || (viaEmail && !viaSMS && t('user.document.via_email'))
      || (!viaEmail && viaSMS && t('user.document.via_sms'))
      || t('user.document.via_none')
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.deadline !== prevState.deadline
        || state.viaEmail !== prevState.viaEmail
        || state.viaSMS !== prevState.viaSMS
        || state.signReminderFrequency !== prevState.signReminderFrequency
        || state.isSignForbidden !== prevState.isSignForbidden
    ) {
      this.saveInputValues()
    }
  }

  render() {
    const { t, reminderFrequency, members, user } = this.props

    return (
      <div className="settings__accordion-list accordion additional_member">
        <div className="wrapped-section wrapped-section--shrink">
          <div className={`accordion__element ${this.state.accordionActive ? 'active' : ''}`}>
            <div className="accordion__title__additional" >
              <h2 className="d-flex align-items-center headline headline--members d-flex">
                {t('user.document.additional_perks')}
                {(user.subscription === 'start' || user.subscription === 'no_plan') &&
                <IconTooltip
                  tooltipText={t('user.document.available_from_pro_tooltip')} inline={true}
                  newLine={true}
                />
                }
              </h2>
              <div className="accordion__clickable" onClick={this.onAccordionClick} ></div>
            </div>
            <div className="accordion__content_addition">
              <div className="info-details info-details--wider additional-perks">
                <li>
                  <div className="info-details__label label-perks">
                    <label>{t('common.deadline')}</label>
                  </div>
                  <div className="info-details__info">
                    <Datetime
                      className="info-details__select"
                      initialValue={this.state.deadline}
                      onClose={this.handleDeadlineChange}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm"
                      inputProps={{ className: 'form-group__input', readOnly: true, disabled: (user.subscription === 'start' || user.subscription === 'no_plan') }}
                      closeOnSelect={true}
                      initialViewDate={moment().hour(9).minute(0).second(0)}
                    />
                  </div>
                </li>
                <li>
                  <div className="info-details__label label-perks">
                    <label>{t('user.document.forbid_signature_after_deadline')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className={'form-group__checkbox' + ((user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline) ? ' disabled-checkbox' : '')}>
                      <input
                        className="checkbox__document"
                        type="checkbox"
                        id="is-forbid-signature"
                        onChange={this.onSignForbidSelect}
                        checked={this.state.isSignForbidden}
                        disabled={user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline}
                      />
                      <label htmlFor="is-forbid-signature">&nbsp;</label>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info-details__label">
                    <label>{t('user.document.signature_reminders_frequency')}</label>
                  </div>
                  <div className="info-details__info">
                    <Select
                      className="info-details__select"
                      name="reminder-frequency"
                      options={reminderFrequencyOptions}
                      defaultValue={reminderFrequencyOptions.filter(o => o.value === (reminderFrequency ? reminderFrequency : 0))}
                      onChange={this.onSignReminderFrequencySelect}
                      onBlur={this.props.handleBlur}
                      isClearable={true}
                      isDisabled={user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline}
                    />
                  </div>
                </li>
                <li>
                  <div className="info-details__label label-perks">
                    <label>{t('user.document.reminder_type')}</label>
                  </div>
                  <div className="info-details__info">
                    <div className="reminder-type">
                      <div className="reminder-type__email">
                        <div
                          className={'form-group__checkbox' + ((user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline) ? ' disabled-checkbox' : '')}
                        >
                          <input
                            className="checkbox__document"
                            type="checkbox" id="via-email"
                            checked={this.state.viaEmail}
                            onChange={this.onReminderTypeSelect}
                            disabled={user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline}
                          />
                          <label
                            htmlFor="via-email"
                            className={(user.subscription === 'start' || user.subscription === 'no_plan' || !this.state.deadline) ? 'cursor-initial ' : ''}
                          >{t('user.document.via_email')}
                          </label>
                        </div>
                      </div>
                      <div className="reminder-type__sms">
                        <div className={'form-group__checkbox' + ((user.subscription !== 'flexible' || !this.state.deadline) ? ' disabled-checkbox' : '')}>
                          <input
                            className="checkbox__document"
                            type="checkbox" id="via-sms"
                            checked={this.state.viaSMS}
                            onChange={this.onReminderTypeSelect}
                            disabled={user.subscription !== 'flexible' || !this.state.deadline}
                          />
                          <label htmlFor="via-sms" className={(user.subscription !== 'flexible' || !this.state.deadline) ? 'cursor-initial ' : ''}>{t('user.document.via_sms')}</label>
                        </div>
                        {user.subscription !== 'flexible' &&
                          <IconTooltip tooltipText={t('user.document.available_from_flex_tooltip')} inline={true} newLine={true} flex={true} />
                        }
                      </div>
                    </div>
                  </div>
                </li>
                {members.map((member) => (
                  (this.state.viaSMS && member.signStatus !== 'signed') && (
                    <li key={member.email}>
                      <div className="info-details__label">
                        <label>
                          {!member.name || !member.surname
                            ? member.email
                            : member.name + ' ' + member.surname
                          }&nbsp;
                          <Tooltip text={t('user.document.member_full_phone_number_for_sms')} inline={true} newLine={true}>
                            <span className="info-icon-grey" />
                          </Tooltip>
                        </label>
                      </div>
                      <div className="info-details__info">
                        <input
                          className="form-group__input info-details__select"
                          id={member.id}
                          data-email={member.email}
                          type="text"
                          defaultValue={member.phone ? member.phone : ''}
                          onChange={this.onPhoneChange}
                          placeholder={t('user.document.enter_member_phone_number')}
                          onBlur={this.updateMemberPhones}
                        />
                      </div>
                    </li>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AdditionalPerks.propTypes = {
  deadline: PropTypes.string,
  signForbid: PropTypes.bool,
  runDeadlineCheck: PropTypes.bool,
  reminderFrequency: PropTypes.number,
  documentId: PropTypes.string,
  handleBlur: PropTypes.func,
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  onSelectDeadLine: PropTypes.func,
  onSelectReminderFrequency: PropTypes.func,
  fetchDocumentInfo: PropTypes.func,
  viaSMS: PropTypes.bool,
  viaEmail: PropTypes.bool,
  user: PropTypes.object,
  members: PropTypes.array,
  updateDocumentMemberPhone: PropTypes.func,
}

export default withTranslation()(AdditionalPerks)