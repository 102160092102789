import React from 'react'
import PropTypes from 'prop-types'

export default class Money extends React.Component {
  formatPrice() {
    const { monthlyPrice, price, annual, special, t } = this.props

    if (price.indexOf('€') !== 0) {
      return (
        <React.Fragment>
          {special ? t('user.pricing.from') : null}
          {annual ?
            special ? '49,99 ' : monthlyPrice.slice(0, monthlyPrice.indexOf('€')) :
            (special ? '49,99 ' : price.slice(0, price.indexOf('€')))
          }
          {price.slice(price.indexOf('€'))}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {special ? t('user.pricing.from') : null}
          {annual ?
            special ? '49,99 ' : monthlyPrice.slice(1) :
            (special ? '49,99 ' : price.slice(1))
          }
          {price.charAt(price.indexOf('€'))}
        </React.Fragment>
      )
    }
  }

  render() {
    let className = 'price'

    if (this.props.strikethrough) {
      className += ' price--strikethrough'
    }

    if (this.props.discount) {
      className += ' price--discount'
    }

    return (
      <span className={className}>
        {this.formatPrice()}
      </span>
    )
  }
}

Money.propTypes = {
  t: PropTypes.func,
  monthlyPrice: PropTypes.string,
  price: PropTypes.string,
  annual: PropTypes.bool,
  special: PropTypes.bool,
  strikethrough: PropTypes.bool,
  discount: PropTypes.bool,
}
