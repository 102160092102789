import {
  ACTION_COMPLETE_SWITCH,
  ACTION_ADD_CART_ITEM,
  ACTION_CLEAR_CART,
  ACTION_FOREMOST_SIGN_BUTTON_CLICK,
  ACTION_CONFIRM_EMAIL_CLICK,
  ACTION_USER_LOGGED_IN,
  ACTION_ADD_PAYMENT_INFO,
  ACTION_BEGIN_CHECKOUT,
  ACTION_PURCHASE_SUCCESS, ACTION_NEO_PAY_PURCHASE_SUCCESS
} from '~/js/actions'

import { ProductFieldObject } from '../dataLayer/models'
import { convertStrToNum, findDiscount, findItemVariant, findQuantity, findConvertedPrice } from '~/js/utils/dataLayerMethods'

window.dataLayer = window.dataLayer || []

const EVENT_EEC_ADD_TO_CART = 'eec.addToCart'
const EVENT_EEC_REMOVE_FROM_CART = 'eec.removeFromCart'
const EVENT_FIRST_DOCUMENT_SIGNED = 'first_document_signed'
const EVENT_REGISTRATION = 'registration'
const EVENT_LOGIN = 'login'
const EVENT_ADD_TO_CART = 'add_to_cart'
const EVENT_ADD_PAYMENT_INFO = 'add_payment_info'
const EVENT_BEGIN_CHECKOUT = 'begin_checkout'
const EVENT_PURCHASE = 'purchase'
export const MARK_SIGN_AFFILIATION = 'marksign.lt'
export const CURRENCY_CODE_EUR = 'EUR'

export default (
  state = {
    cartProducts: []
  },
  action
) => {
  const { dataLayer } = window
  let productFieldObj

  switch (action.type) {
  case ACTION_COMPLETE_SWITCH:
    dataLayer.push({ 'client_type': action.user.subscription })

    return state
  case ACTION_ADD_CART_ITEM:
    productFieldObj = ProductFieldObject.fromProduct(action.product)
    productFieldObj.quantity = action.quantity
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: EVENT_EEC_ADD_TO_CART,
      ecommerce: {
        currencyCode: CURRENCY_CODE_EUR,
        add: {
          products: [productFieldObj]
        }
      }
    })

    dataLayer.push({ ecommerce: null })
    dataLayer.push({ gtm: null })
    dataLayer.push(() => { this.reset() })

    dataLayer.push({
      event: EVENT_ADD_TO_CART,
      ecommerce: {
        currency: CURRENCY_CODE_EUR,
        value: (convertStrToNum(productFieldObj.price) - findDiscount(action.product.annualPlanPrice, productFieldObj.price)),
        items: [
          {
            item_id: productFieldObj.id, // eslint-disable-line
            item_name: productFieldObj.name, // eslint-disable-line
            affiliation: MARK_SIGN_AFFILIATION,
            coupon: action.coupon ? action.coupon.code : null,
            currency: CURRENCY_CODE_EUR,
            discount: findDiscount(action.product.annualPlanPrice, productFieldObj.price),
            price: findConvertedPrice(productFieldObj, action.product),
            item_variant: findItemVariant(action.product.categories, productFieldObj.category), // eslint-disable-line
            quantity: findQuantity(action.product, action.product.signaturesQuantity, productFieldObj.price)
          }
        ]
      }
    })

    return Object.assign({}, state, { cartProducts: [...state.cartProducts, productFieldObj] })
  case ACTION_CLEAR_CART:
    if (state.cartProducts.length > 0) {
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: EVENT_EEC_REMOVE_FROM_CART,
        ecommerce: {
          remove: {
            products: state.cartProducts,
          }
        }
      })
    }

    return Object.assign({}, state, { cartProducts: [] })

  case ACTION_FOREMOST_SIGN_BUTTON_CLICK:
    dataLayer.push({ ecommerce: null })
    dataLayer.push(() => { this.reset() })
    dataLayer.push({
      'event': EVENT_FIRST_DOCUMENT_SIGNED,
      'email': action.email,
      'phone_number': action.phoneNumber,
    })
    return state
  case ACTION_CONFIRM_EMAIL_CLICK:
    dataLayer.push(() => { this.reset() })
    dataLayer.push({
      'event': EVENT_REGISTRATION,
      'email': action.person.email,
      'phone_number': action.person.phone,
      'newsletter': action.person.acceptNewsletter
    })
    return state
  case ACTION_USER_LOGGED_IN:
    if (action.location
        &&
        (action.location === '/user/dashboard'
            || action.location ==='/workspace/dashboard'
            || action.location.includes('/accept-invitation')
            || action.location ==='/user/confirm-profile'
        )
        && action.user) {
      dataLayer.push(() => { this.reset() })
      dataLayer.push({
        'event': EVENT_LOGIN,
        'user_id': action.user.id
      })
    }
    return state

  case ACTION_ADD_PAYMENT_INFO:
    productFieldObj = ProductFieldObject.fromProduct(action.product)
    dataLayer.push(() => { this.reset() })
    dataLayer.push({
      event: EVENT_ADD_PAYMENT_INFO,
      ecommerce: {
        'currency': CURRENCY_CODE_EUR,
        'value': convertStrToNum(action.subtotal),
        'coupon': action.coupon ? action.coupon.code : null,
        'payment_type': 'Credit Card',
        items: [
          {
            'item_id': productFieldObj.id,
            'item_name': productFieldObj.name,
            'affiliation': MARK_SIGN_AFFILIATION,
            'currency': CURRENCY_CODE_EUR,
            'discount': findDiscount(action.discountedSubtotal, action.subtotal),
            'price': findConvertedPrice(productFieldObj, action.product),
            'item_variant': findItemVariant(action.product.categories, productFieldObj.category),
            'quantity': findQuantity(action.product, action.product.signaturesQuantity, action.subtotal)
          }
        ]
      }
    })
    return state
  case ACTION_BEGIN_CHECKOUT:
    productFieldObj = ProductFieldObject.fromProduct(action.product)
    dataLayer.push(() => { this.reset() })
    dataLayer.push({ gtm: null })
    dataLayer.push({ ecommerce: null })

    dataLayer.push({
      event: EVENT_BEGIN_CHECKOUT,
      ecommerce: {
        currency: CURRENCY_CODE_EUR,
        value: convertStrToNum(action.subtotal) - findDiscount(action.discountedSubtotal, action.subtotal),
        coupon: action.coupon ? action.coupon.code : null,
        items: [
          {
            'item_id': productFieldObj.id,
            'item_name': productFieldObj.name,
            'affiliation': MARK_SIGN_AFFILIATION,
            'coupon': action.coupon ? action.coupon.code : null,
            'currency': CURRENCY_CODE_EUR,
            'discount': findDiscount(action.discountedSubtotal, action.subtotal),
            'price': findConvertedPrice(productFieldObj, action.product),
            'item_variant': findItemVariant(action.product.categories, productFieldObj.category),
            'quantity': findQuantity(action.product, action.product.signaturesQuantity, action.subtotal)
          }
        ]
      }
    })
    return state
  case ACTION_PURCHASE_SUCCESS:
    productFieldObj = ProductFieldObject.fromProduct(action.product)
    dataLayer.push(() => { this.reset() })
    dataLayer.push({
      event: EVENT_PURCHASE,
      'email': action.user.email,
      'phone_number': action.user.phoneNumber,
      ecommerce: {
        'transaction_id': action.transactionId,
        'affiliation': MARK_SIGN_AFFILIATION,
        'value': convertStrToNum(action.subtotal),
        'tax': convertStrToNum(action.vat),
        'currency': CURRENCY_CODE_EUR,
        'coupon': action.coupon ? action.coupon.code : null,
        items: [
          {
            'item_id': productFieldObj.id,
            'item_name': productFieldObj.name,
            'coupon': action.coupon ? action.coupon.code : null,
            'currency': CURRENCY_CODE_EUR,
            'discount': findDiscount(action.discountedSubtotal, action.subtotal),
            'price': findConvertedPrice(productFieldObj, action.product),
            'item_variant': findItemVariant(action.product.categories, productFieldObj.category),
            'quantity': findQuantity(action.product, action.product.signaturesQuantity, action.subtotal)
          }
        ]
      }
    })
    return state
  case ACTION_NEO_PAY_PURCHASE_SUCCESS:
    productFieldObj = ProductFieldObject.fromProduct(action.product)
    dataLayer.push(() => { this.reset() })
    dataLayer.push({
      event: EVENT_PURCHASE,
      'email': action.user.email,
      'phone_number': action.user.phoneNumber,
      ecommerce: {
        'transaction_id': action.transaction.id,
        'affiliation': MARK_SIGN_AFFILIATION,
        'value': action.transaction.price,
        'tax': action.transaction.tax,
        'currency': CURRENCY_CODE_EUR,
        'coupon': action.transaction.coupon ? action.transaction.coupon.name : null,
        items: [
          {
            'item_id': action.product.id,
            'item_name': action.product.name,
            'coupon': action.transaction.coupon ? action.transaction.coupon.name : null,
            'currency': CURRENCY_CODE_EUR,
            'discount': action.transaction.discount,
            'price': findConvertedPrice(productFieldObj, action.product),
            'item_variant': 'Yearly',
            'quantity': action.transaction.quantity
          }
        ]
      }
    })
    return state
  default:
    return state
  }
}