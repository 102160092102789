import React from 'react'
import { Link } from 'react-router-dom'
import AppHeader, { AppHeaderSearch } from '~/js/components/AppHeader'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import PropTypes from 'prop-types'

export default class AppHeaderDocuments extends React.Component {
  render() {
    const { t } = this.props

    return (
      <AppHeader
        className="header--document"
        title={t('user.documents')}
        left={(
          <AppHeaderSearch
            t={t}
            className="hide-gt-1075px"
            onChange={this.props.onSearchChange}
            value={this.props.searchValue}
          />
        )}
        right={(
          <Link className="btn btn--primary" to={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD)}>{t('plus_upload_document')}</Link>
        )}
        bottom={(
          <AppHeaderSearch
            t={t}
            className="show-lt-1076px"
            onChange={this.props.onSearchChange}
            value={this.props.searchValue}
          />
        )}
      />
    )
  }
}

AppHeaderDocuments.propTypes = {
  t: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
}