import React from 'react'
import PropTypes from 'prop-types'
import ControlButtons, { ControlButton } from '~/js/components/ControlButtons'

export default class ControlButtonsDocument extends React.Component {
  render() {
    const { t, noText, showRemove, showView, showShare, showMigrate, showDownload, canValidate, canPrint, canDownloadDetailedReport, temporaryDocTrans } = this.props

    return (
      <ControlButtons
        active={this.props.active}
        className={this.props.className}
      >
        {canPrint && (
          <ControlButton
            type="print"
            title={t('common.print')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onPrintAction}
          />
        )}
        {canValidate && (
          <ControlButton
            type="validate"
            title={t('validate')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onValidateDocumentClick}
          />
        )}
        {showMigrate && (
          <ControlButton
            type="migrate"
            title={t('common.migrate')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onMigrateClick}
          />
        )}
        {showView && (
          <ControlButton
            type="view"
            title={t('common.view')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onViewClick}
          />
        )}
        {canDownloadDetailedReport &&
          <ControlButton
            type="download"
            title={t('common.download_validation_report')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onDownloadValidationReportClick}
          />
        }
        {showDownload && (
          <ControlButton
            type="download"
            title={t('common.download')}
            mobileTitle={temporaryDocTrans ? t('user.document.view_document') : t('common.view')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onDownloadClick}
          />
        )}
        {showShare && (
          <ControlButton
            type="share"
            title={t('common.invite_btn')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onShareClick}
          />
        )}
        <ControlButton
          type="delete"
          title={t('common.remove')}
          showLabel={!noText}
          showTooltip={noText}
          onClick={this.props.onRemoveClick}
          isDisabled={!showRemove}
        />
      </ControlButtons>
    )
  }
}

ControlButtonsDocument.defaultProps = {
  noText: false
}

ControlButtonsDocument.propTypes = {
  t: PropTypes.func,
  noText: PropTypes.bool,
  showRemove: PropTypes.bool,
  showView: PropTypes.bool,
  showShare: PropTypes.bool,
  showMigrate: PropTypes.bool,
  showDownload: PropTypes.bool,
  canValidate: PropTypes.bool,
  canPrint: PropTypes.bool,
  canDownloadDetailedReport: PropTypes.bool,
  active: PropTypes.bool,
  temporaryDocTrans: PropTypes.bool,
  className: PropTypes.string,
  onPrintAction: PropTypes.func,
  onValidateDocumentClick: PropTypes.func,
  onMigrateClick: PropTypes.func,
  onViewClick: PropTypes.func,
  onDownloadValidationReportClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onShareClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
}