import { connect } from 'react-redux'
import { addStatusMessageWithTimeout, confirmEmailClick, fetchUser } from '~/js/actions'
import ConfirmEmail from '~/js/routes/app/ConfirmEmail'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessageWithTimeout(type, message)),
  fetchUser: user => dispatch(fetchUser(user)),
  confirmEmailClick: person => dispatch(confirmEmailClick(person))
})

export default connect(
  null,
  mapDispatchToProps
)(ConfirmEmail)