import React from 'react'
import PricingTable from '~/js/containers/PricingTable'
import PropTypes from 'prop-types'

export default function Pricing(props) {
  return <PricingTable {...props} />
}

Pricing.propTypes = {
  showStatusMessage: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  fetchUser: PropTypes.func,
  switchWorkspace: PropTypes.func,
}