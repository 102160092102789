// @flow

export enum AuthMethod {
  SmartId = 'smartid',
  MobileId = 'mobileid',
  ZealId = 'zealid',
}

export type User = {
  authMethod: AuthMethod,
  subscription: string,
}