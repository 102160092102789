import React from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'

export default class ModalFutureDiscount extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup--user"
        active={this.props.discount}
        {...this.props}>
        <br />
        <div className="center-element">
          <button
            className="btn btn--green"
            onClick={this.props.onAcceptDiscountClick}>
            {t('common.activate')}
          </button>
        </div>
      </Modal>
    )
  }
}

ModalFutureDiscount.propTypes = {
  t: PropTypes.func,
  discount: PropTypes.bool,
  onAcceptDiscountClick: PropTypes.func,
}