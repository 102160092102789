import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import Login from '~/js/containers/LoginRoute'

function Register(props) {
  const { t } = useTranslation()

  return <Login
    title={t('user.login.verify_identity')}
    subTitle={
      <>
        {t('user.login.already_have_account')}
        <Link rel="canonical" to={generatePath(ROUTE_NAMES.USER_LOGIN)}> {t('user.login.login')}</Link>
      </>
    }
    btnSubmitText={t('common.register')}
    registrationPage={true}
    {...props}
  />
}

export default Register