import { getProducts } from '../services/user/product'

export const ACTION_SET_PLAN_PRODUCTS = 'SET_PLAN_PRODUCTS'

const setProducts = products => ({
  type: ACTION_SET_PLAN_PRODUCTS,
  products
})

export const fetchProducts = () => dispatch => {
  return getProducts()
    .then(({ data }) => dispatch(setProducts(data)))
}