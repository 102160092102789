// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Error = {
  key: string,
  type: string,
  translated: boolean,
  message: string,
}
type Props = {
  errors: Error[]
}

function getListItemContent(t, err: Error) {
  return t(`user.document.validation.${err.type}`) + ' - ' + (!err.translated ? t(err.message) : err.message)
}

export default function SectionErrorInformation({ errors }: Props): React.Node {
  const { t } = useTranslation()

  if (errors.length === 0) {
    return null
  }

  return (
    <div className="wrapped-section">
      <div className="wrapped-section__headline">
        <h2 className="headline headline--document-alert">{t('common.errors')}</h2>
      </div>
      <div className="info-details">
        <ul>
          {errors.map(err => (
            <li key={err.key ? err.key: err.message}>
              {getListItemContent(t, err)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
