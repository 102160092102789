import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import successImg from '~/img/illustration/empty-document-new.svg'
import Language from '~/js/components/Language'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import * as userService from '~/js/services/user/user'
import PropTypes from 'prop-types'

let fetchInterval

export default class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userEmail: props.user.email,
    }

    this.fetchUser = this.fetchUser.bind(this)
  }

  fetchUser() {
    userService.getInfo(this.props.user)
      .then((user) => {
        this.setState({ userEmail: user.email })
      })
  }

  componentDidMount() {
    fetchInterval = setInterval(this.fetchUser, 2000)
  }

  componentWillUnmount() {
    clearInterval(fetchInterval)
  }

  render() {
    const { t } = this.props
    const { userEmail } = this.state

    if (userEmail) {
      return <Redirect to={generatePath(ROUTE_NAMES.USER_DOCUMENTS)} />
    }

    return (
      <section id="confirm-email">
        <div className="grid">
          <div className="pricing-success__content">
            <img className="logo" src={markIdLogo} alt="Mark ID"/>
            <img className="middle-image" src={successImg} alt={t('user.documents')} />
            <h3 className="standard-title">{t('user.login.please_verify_your_email')}</h3>
            <p className="message">{t('user.login.you_will_receive_email_shortly')}</p>
            <Link className="btn btn--medium-grey" to={generatePath(ROUTE_NAMES.USER_CONFIRM_PROFILE)}>{t('common.change_data')}</Link>
          </div>
        </div>
        <Language />
      </section>
    )
  }
}

ConfirmEmail.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
}
