// @flow

import type { LangCode, ActionSetLangCode } from '../actions'
import Cookies from 'js-cookie'

type SliceI18n = {
  langCode: LangCode
}

const initLangCode = getInitialLangCode()

export default (
  state: SliceI18n = {
    langCode: initLangCode,
  },
  action: ActionSetLangCode
): SliceI18n => {
  let { langCode } = state

  switch (action.type) {
  case 'SET_LANG_CODE':
    langCode = action.langCode
    Cookies.set('lng', langCode)

    return { langCode }
  default:
    return state
  }
}

function getInitialLangCode(): LangCode {
  if (Cookies.get('lng') !== undefined) {
    return Cookies.get('lng')
  }

  const tld = window.location.origin.split('.').pop()

  if (tld === 'eu') {
    return 'en'
  } else if (tld === 'pl') {
    return 'pl'
  }

  return 'lt'
}