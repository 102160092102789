import { connect } from 'react-redux'
import {
  saveDocumentFilterHistoryInfo,
  saveDocumentPaginationHistoryInfo,
  saveDocumentSearchFieldInfo,
  saveDocumentSelectAllHistoryInfo,
  saveDocumentSelectedDocumentsHistoryInfo,
  saveDocumentTabsHistoryInfo,
  saveSelectedCanDeleteSelectedDocumentsInfo
} from '../actions'
import Documents from '../routes/app/User/Documents/Documents'

const mapStateToProps = state => ({
  searchFieldHistory: state.pathHistory.searchFieldHistory,
  tabListHistory: state.pathHistory.tabListHistory,
  documentFilterHistory: state.pathHistory.documentFilterHistory,
  selectAllHistory: state.pathHistory.selectAllHistory,
  selectedDocumentHistory: state.pathHistory.selectedDocumentHistory,
  selectedCanDeleteDocumentsHistory: state.pathHistory.selectedCanDeleteDocumentsHistory,
  paginationHistory: state.pathHistory.paginationHistory,
  langCode: state.i18n.langCode,
})

const mapDispatchToProps = dispatch => ({
  saveDocumentSearchFieldInfo: (inputValue) => dispatch(saveDocumentSearchFieldInfo(inputValue)),
  saveDocumentTabsHistoryInfo: (tabSelected) => dispatch(saveDocumentTabsHistoryInfo(tabSelected)),
  saveDocumentFilterHistoryInfo: (documentFilterHistory) => dispatch(saveDocumentFilterHistoryInfo(documentFilterHistory)),
  saveDocumentSelectAllHistoryInfo: (selectAllHistory) => dispatch(saveDocumentSelectAllHistoryInfo(selectAllHistory)),
  saveDocumentSelectedDocumentsHistoryInfo: (selectedDocumentHistory) => dispatch(saveDocumentSelectedDocumentsHistoryInfo(selectedDocumentHistory)),
  saveSelectedCanDeleteSelectedDocumentsInfo: (selectedCanDeleteDocumentsHistory) => dispatch(saveSelectedCanDeleteSelectedDocumentsInfo(selectedCanDeleteDocumentsHistory)),
  saveDocumentPaginationHistoryInfo: (paginationHistoryPage, paginationHistoryOffset, paginationHistoryPageSize) =>
    dispatch(saveDocumentPaginationHistoryInfo(paginationHistoryPage, paginationHistoryOffset, paginationHistoryPageSize)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents)