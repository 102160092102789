import React from 'react'
import PropTypes from 'prop-types'
import DocumentViewer from '~/js/components/DocumentViewer'
import SectionDocumentInformation from '~/js/components/SectionDocumentInformation'
import MembersValidate from '~/js/components/MembersValidate'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import * as documentService from '~/js/services/user/document'
import ControlButtonsDocument from '~/js/components/ControlButtonsDocument'
import ModalDocumentViewer from '~/js/components/ModalDocumentViewer'
import Loader from '../../../../components/Loader'
import { DOCUMENT_ACCESS_PRIVATE } from '~/js/models/Document'
import { validationInfo } from '../../../../services/user/user'
import { DOCUMENT_VALIDATION_PURPOSE_VALIDATION } from '~/js/models/Document'
import { Link } from 'react-router-dom'
import ModalDownloadValidationReport from '~/js/components/ModalDownloadValidationReport'
import SectionErrorInformation from '../../../../components/SectionErrorInformation'

const validationStatusTimeLimit = 90

export class ValidateDocumentPreview extends React.Component {
  constructor(props) {
    super(props)
    const filesArray = JSON.parse(localStorage.getItem('validation_files'))

    this.state = {
      document: undefined,
      signaturesInfo: [],
      signatures: [],
      isWidget: !!new URL(location.href).searchParams.get('widget'),
      loading: true,
      printing: false,
      isSigningOrderImportant: false,
      signaturesPlacingPosition: '2',
      billDocumentOwner: false,
      uploadedFiles: filesArray.reverse(),

      currentPageNumber: this.props.pageNumber,
      totalPages: this.props.count,

      showViewReportModal: false,
      pdfReportUrl: undefined,
      xmlReportUrl: undefined,
      xmlReport: undefined,
      validationErrors: [],
    }

    this.getDocumentUuid = this.getDocumentUuid.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.getDocumentInfo = this.getDocumentInfo.bind(this)
    this.findDocumentValidationInfo = this.findDocumentValidationInfo.bind(this)
    this.onValidateAnotherClick = this.onValidateAnotherClick.bind(this)
    this.onSaveDraft = this.onSaveDraft.bind(this)
    this.onSaveDraftAll = this.onSaveDraftAll.bind(this)
    this.isContentShrink = this.isContentShrink.bind(this)
    this.onDocumentViewClick = this.onDocumentViewClick.bind(this)
    this.onDocumentDownloadClick = this.onDocumentDownloadClick.bind(this)
    this.onPrintAction = this.onPrintAction.bind(this)
    this.onDownloadValidationReportClick = this.onDownloadValidationReportClick.bind(this)
    this.onPageClickAction = this.onPageClickAction.bind(this)
    this.fetchValidationInfo = this.fetchValidationInfo.bind(this)

    this._isMounted = true
    this.cancelSignatureIntegrityRequest = null
    this.cancelValidationInfoRequest = null

    this.fetchValidationInfo()
  }

  componentWillUnmount() {
    this._isMounted = false
    typeof this.cancelSignatureIntegrityRequest === 'function' && this.cancelSignatureIntegrityRequest()
    typeof this.cancelValidationInfoRequest === 'function' && this.cancelValidationInfoRequest()
  }

  getDocumentUuid() {
    return this.props.uuid
  }

  fetchData() {
    const uuid = this.getDocumentUuid()

    return Promise.all([
      this.getDocumentInfo(uuid),
      this.findDocumentValidationInfo(uuid),
    ])
  }

  fetchValidationInfo() {
    /**
     * 3 steps:
     * 1. Check if validated amount exceeds his allowed amount
     * 2. Do fetch document info and signature integrity
     * 3. Fetch remaining validation amount to update the sidebar
     */
    const { user, fetchValidation, history } = this.props
    const { promise, cancel } = validationInfo(user.id)
    this.cancelValidationInfoRequest = cancel
    promise
      .then(data => data.data.canValidate ? data : Promise.reject(data))
      .then(() => this.fetchData())
      .then(() => {
        const validationData = fetchValidation(user.id)
        this.cancelValidationInfoRequest = validationData.cancel
      })
      .then(() => {
        this._isMounted && this.setState({ loading: false })
      })
      .catch(() => {
        this._isMounted && this.setState({ loading: false })
        history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE, {
          workspace: getUserWorkspace()
        }))
      })
  }

  getDocumentInfo(uuid) {
    uuid = uuid || this.getDocumentUuid()

    return documentService
      .getInfo(uuid)
      .then(data => this.setState({ document: data }))
      .catch(err => err.message && this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  findDocumentValidationInfo(uuid) {
    const { showStatusMessage, validationFetchCompleted, pageNumber } = this.props
    uuid = uuid || this.getDocumentUuid()
    const { promise, cancel } = documentService.initializeDocumentValidation(uuid, DOCUMENT_VALIDATION_PURPOSE_VALIDATION)
    this.cancelSignatureIntegrityRequest = cancel

    return promise
      .then((data) => {
        const statusRequest = documentService.pollValidationStatus(data.session_id, uuid, validationStatusTimeLimit)
        return statusRequest.promise
      })
      .then(data => {
        this.setState({ signaturesInfo: data.data, signatures: data.data.signatures, signatureIntegrity: data.data.indication, validationErrors: data.errors })
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.errors) {
          this.setState({ validationErrors: err.response.data.errors })
        }
        err.message && showStatusMessage(SM_TYPE_ERROR, err.message)
      })
      .finally(() => validationFetchCompleted(pageNumber))
  }

  onValidateAnotherClick() {
    return this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE))
  }

  onSaveDraft() {
    const { t } = this.props
    const { document, isSigningOrderImportant, signaturesPlacingPosition, billDocumentOwner } = this.state

    return documentService
      .saveDrafts(
        [document.uuid],
        {
          isSigningOrderImportant,
          signaturesPlacingPosition,
          billDocumentOwner
        }
      )
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_draft_was_successfully_saved')))
      .then(() => {
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW, { documentId: document.uuid }))
      })
      .catch(err => err.message && this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onSaveDraftAll() {
    const { t } = this.props

    return documentService
      .saveDrafts(this.state.uploadedFiles, { access: DOCUMENT_ACCESS_PRIVATE })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_draft_was_successfully_saved')))
      .then(() => {
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DASHBOARD))
      })
      .catch(err => err.message && this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
      document.querySelector('#side-document-preview').classList.toggle('shrink')
      document.querySelector('#bottom-bar').classList.toggle('shrink')
    }
  }

  componentDidMount() {
    this.isContentShrink()
  }

  componentDidUpdate() {
    const { printing } = this.state

    if (printing) {
      setTimeout(() => {
        window.print()
        this.setState({ printing: false })
      }, 500)
    }
  }

  onDocumentViewClick() {
    this.setState({ modalPdfViewActive: true })
  }

  onDocumentDownloadClick() {
    const url = `/api/v1/${getUserWorkspace()}/document/${this.getDocumentUuid()}/download`

    window.open(url, '_blank')
    this.fetchDocumentHistory()
  }

  onPrintAction() {
    this.setState({ printing: true })
  }

  onDownloadValidationReportClick() {
    this.setState({
      showViewReportModal: true,
      pdfReportUrl: `/api${documentService.getDiagnosticReportUrl(this.getDocumentUuid(), 'pdf')}`,
      xmlReportUrl: `/api${documentService.getDiagnosticReportUrl(this.getDocumentUuid(), 'xml')}`,
    })
  }

  onPageClickAction(page) {
    this.props.showPage(page, this.props.pageNumber)
  }

  render() {
    const { t, user, i18n } = this.props
    const { document, signatures, signaturesInfo, isWidget, loading, printing, currentPageNumber, totalPages, validationErrors } = this.state

    return (
      <div id="page-container" className={this.props.active ? 'show' : 'hide'}>
        <main id="preview">
          <section>
            <div className="grid">
              <div className="row">
                <div className="col md-6-12">
                  <div id="header" className="document-validation__document-header document-validation__align_center">
                    <div className="validation_header">
                      {!isWidget ?
                        <Link
                          className="header__page-navigation"
                          to={{
                            pathname: generatePath(ROUTE_NAMES.USER_DOCUMENTS),
                            previousSearchData: this.props.location.searchValue
                          }}
                        >
                          {t('user.document.all_documents')}
                        </Link> : null}
                      <h1 className="page-title">{t('validate_document')}</h1>
                    </div>
                    {this.state.uploadedFiles.length > 1 &&
                      <div className="col md-4-12">
                        <div className="pagination__buttons pagination__buttons--center-items">
                          <button className="btn prev" type="button" onClick={() => this.onPageClickAction(currentPageNumber - 1)} disabled={!(currentPageNumber > 1)} />
                          <span>{currentPageNumber + '/' + totalPages}</span>
                          <button className="btn next" type="button" onClick={() => this.onPageClickAction(currentPageNumber + 1)} disabled={!(currentPageNumber < totalPages)} />
                        </div>
                      </div>
                    }
                    <div className="document-validation__document-header">
                      <div className="preview__controls">
                        {!loading && document && (
                          <ControlButtonsDocument
                            t={t}
                            showRemove={false}
                            showView={true}
                            showShare={false}
                            showMigrate={false}
                            canPrint={true}
                            canDownloadDetailedReport={document.format !== 'adoc'}
                            onPrintAction={this.onPrintAction}
                            onViewClick={this.onDocumentViewClick}
                            onDownloadClick={this.onDocumentDownloadClick}
                            onDownloadValidationReportClick={this.onDownloadValidationReportClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <Loader loadingText={t('user.document.document_check')} />
                  )}
                  {!loading && document && validationErrors?.length > 0 && (
                    <SectionErrorInformation errors={validationErrors} />
                  )}
                  {!loading && document && (
                    <SectionDocumentInformation
                      t={t}
                      user={user}
                      document={document}
                      showCategories={false}
                      signatureIntegrity={this.state.signatureIntegrity}
                      showStatus={false}
                      showAccess={false}
                      showDeadline={true}
                      signaturesInfo={signaturesInfo}
                      displayTitle={document.displayTitle}
                      showStatusMessage={this.props.showStatusMessage}
                    />
                  )}
                  {!loading && signatures.length > 0 && (
                    <MembersValidate
                      i18n={i18n}
                      t={t}
                      members={signatures}
                    />
                  )}
                </div>
                <div className="col md-6-12">
                  <div id="side-document-preview" className="preview__document-box">
                    {!loading && !isWidget ?
                      <div className="preview__controls">
                        <ControlButtonsDocument
                          t={t}
                          showRemove={false}
                          showView={true}
                          showShare={false}
                          showMigrate={false}
                          canPrint={true}
                          canDownloadDetailedReport={document.format !== 'adoc'}
                          onPrintAction={this.onPrintAction}
                          onViewClick={this.onDocumentViewClick}
                          onDownloadClick={this.onDocumentDownloadClick}
                          onDownloadValidationReportClick={this.onDownloadValidationReportClick}
                        />
                      </div> : null}
                    <p>{t('user.document.document_preview')}</p>
                    {document && !printing && !loading && (
                      <DocumentViewer
                        t={t}
                        document={document}
                        showStatusMessage={this.props.showStatusMessage}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <div id="bottom-bar">
          {document && document.status === 'validation_uploaded' && (
            <button className="btn btn--primary  btn--margin-right-validate-btn" onClick={this.onSaveDraft}>{t('user.document.save_a_document')}</button>
          )}
          {totalPages > 1 && (
            <button className="btn btn--primary  btn--margin-right-validate-btn" onClick={this.onSaveDraftAll}>{t('user.document.save_all_document')}</button>
          )}
          <button className="btn btn--secondary" onClick={this.onValidateAnotherClick}>{t('upload_document')}</button>
        </div>

        <StatusMessages />
        <ModalDocumentViewer
          t={t}
          document={document}
          showStatusMessage={this.props.showStatusMessage}
          active={this.state.modalPdfViewActive}
          onCloseClick={() => this.setState({ modalPdfViewActive: false })}
          title={t('user.document.document_preview')}
        />

        <ModalDownloadValidationReport
          t={t}
          active={this.state.showViewReportModal}
          onCloseClick={() => this.setState({ showViewReportModal: false })}
          pdfReportUrl={this.state.pdfReportUrl}
          xmlReportUrl={this.state.xmlReportUrl}
          xmlReport={this.state.xmlReport}
        />
      </div>
    )
  }
}

ValidateDocumentPreview.propTypes = {
  pageNumber: PropTypes.number,
  count: PropTypes.number,
  uuid: PropTypes.string,
  active: PropTypes.bool,
  showPage: PropTypes.func,
  validationFetchCompleted: PropTypes.func,
  showStatusMessage: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object,
  fetchValidation: PropTypes.func,
}