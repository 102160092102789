import React from 'react'
import ControlButtons, { ControlButton } from '../ControlButtons'
import ControlButtonsDocument from '../ControlButtonsDocument'
import PropTypes from 'prop-types'

export default class ControlButtonsCompanyUser extends React.Component {
  render() {
    const { t, noText } = this.props

    return (
      <ControlButtons
        active={this.props.active}
        className={this.props.className}
        inline={true}
      >
        {this.props.canAssignSubscription && (
          <ControlButton
            type="add"
            title={t('user.settings.company_user.assign_license')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onAssignSubscriptionClick}
          />
        )}
        {this.props.canUpdate && (
          <ControlButton
            type="edit"
            title={t('common.edit')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onEditClick}
          />
        )}
        {this.props.canDelete && (
          <ControlButton
            type="delete"
            title={t('common.remove')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onRemoveClick}
          />
        )}
      </ControlButtons>
    )
  }
}

ControlButtonsDocument.defaultProps = {
  noText: false,
}

ControlButtonsCompanyUser.propTypes = {
  t: PropTypes.func,
  noText: PropTypes.bool,
  className: PropTypes.string,
  canAssignSubscription: PropTypes.bool,
  onAssignSubscriptionClick: PropTypes.func,
  canUpdate: PropTypes.bool,
  onEditClick: PropTypes.func,
  canDelete: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  active: PropTypes.bool,
}