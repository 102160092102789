import React from 'react'
import PropTypes from 'prop-types'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import illustrationImage from '~/img/illustration/empty-search.svg'
import Modal from '../Modal'

export default class ModalValidationLimitReached extends React.Component {
  constructor(props) {
    super(props)

    this.onBuyBtnClick = this.onBuyBtnClick.bind(this)
  }

  onBuyBtnClick() {
    this.props.history.push(
      generatePath(ROUTE_NAMES.USER_PRICING, { workspace: getUserWorkspace() })
    )
  }

  render() {
    const { t } = this.props

    if (!this.props.active) {
      return null
    }

    return (
      <div className="document-preview">
        <Modal
          className="document-preview__modal"
          active={this.props.active}
          size="large"
          onCloseClick={this.props.onCloseClick}
        >
          <div className="pricing-table__modal">
            <div className="pricing-table__modal-content">
              <div className="pricing-table__modal-img">
                <img src={illustrationImage} alt="Validate Documents" />
              </div>
              <div className="pricing-table__row pricing-table__row--signatures">
                <div className="pricing-table__row-heading">
                  {t('user.validation.limit_alert_title')}
                </div>
                <div className="pricing-table__sub-rows">
                  {t('user.validation.limit_alert_msg', { maxLimit: this.props.maxValidationslimit })}
                </div>
              </div>
            </div>
            <div className="document-preview__modal--buttons">
              <button
                className="btn btn--secondary"
                onClick={this.props.openModalAdditionalSignatures}
              >{t('user.validation.buy_more_validations')}
              </button>
              <button
                className='btn btn--green'
                onClick={this.onBuyBtnClick}
              >
                {t('user.validation.limit_upgrade')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

ModalValidationLimitReached.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  active: PropTypes.bool,
  onCloseClick: PropTypes.func,
  openModalAdditionalSignatures: PropTypes.func,
  maxValidationslimit: PropTypes.number,
}
