import React from 'react'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import pricingSuccess from '~/img/illustration/pricing-success.svg'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import { SETTINGS_COMPANY_USERS } from '~/js/models/Settings'
import PropTypes from 'prop-types'

export default function PricingSuccess({ t, user, history, fetchUser, location, pushGTagNeoPayPurchaseSuccess }) {
  if (location && location.state && location.state.transactionDetails) {
    pushGTagNeoPayPurchaseSuccess(location.state.product, location.state.transactionDetails, user)
  }

  function onContinueClick() {
    const redirectPath = user.workspaceId
      ? generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_COMPANY_USERS })
      : generatePath(ROUTE_NAMES.USER_DASHBOARD)

    fetchUser()
      .then(() => history.push(redirectPath))
  }

  return (

    <div className="page-container--full-height" id="page-container">
      <section id="pricing-success">
        <div className="grid">
          <div className="pricing-success__content">
            <img className="logo" src={markIdLogo} alt="Logo" />
            <img src={pricingSuccess} alt="Pricing success" />
            <h3 className="standard-title">{t('user.pricing.you_have_successfully_bought_chosen_plan')}</h3>
            <div className="pricing-success__user-info d-none">
              <p id="user-email-pricing-success" className="pricing-success__user-info--email">{user.email}</p>
              <p id="user-phone-number-pricing-success" className="pricing-success__user-info--phone-number">{user.phoneNumber}</p>
            </div>
            <button className="btn btn--green" onClick={onContinueClick}>{t('common.continue')}</button>
          </div>
        </div>
      </section>
    </div>
  )
}

PricingSuccess.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  fetchUser: PropTypes.func,
  location: PropTypes.object,
  pushGTagNeoPayPurchaseSuccess: PropTypes.func,
}
