import { connect } from 'react-redux'
import { switchWorkspace, fetchUser } from '~/js/actions'
import SettingsRoute from '~/js/routes/app/User/Settings'

const mapDispatchToProps = dispatch => ({
  switchWorkspace: workspaceId => dispatch(switchWorkspace(workspaceId)),
  fetchUser: () => dispatch(fetchUser()),
})

const mapStateToProps = state => ({
  userIsSwitching: state.user.isSwitching,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsRoute)