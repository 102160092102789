import React from 'react'
import ControlButtons, { ControlButton } from '~/js/components/ControlButtons'

export default class ControlButtonsDocumentCategory extends React.Component {
  render() {
    const { t, noText } = this.props

    return (
      <ControlButtons
        active={this.props.active}
        className={this.props.className}>
        <ControlButton
          type="edit"
          title={t('common.edit')}
          showLabel={!noText}
          showTooltip={noText}
          onClick={this.props.onEditClick} />
        <ControlButton
          type="delete"
          title={t('common.remove')}
          showLabel={!noText}
          showTooltip={noText}
          onClick={this.props.onDeleteClick} />
      </ControlButtons>
    )
  }
}

ControlButtonsDocumentCategory.defaultProps = {
  noText: false
}