// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_NAMES } from '../../routes/config'
import { generatePath } from '../../routes/router'
import Product from '../../models/Product'
import type { User } from '../../models/User'
import ModalAdditionalSignatures from '../ModalAdditionalSignatures/ModalAdditionalSignatures'
import GlobalSignatureWorld from '../../../img/global-signatures/global-signature-world.png'
import SafetyLock from '../../../img/global-signatures/safety-lock.png'
import Squares from '../../../img/global-signatures/squares.png'
import SrcLogo from '../../../img/global-signatures/src-logo.png'
import TuvitLogo from '../../../img/global-signatures/tuvit-logo.png'
import TrustEidas from '../../../img/global-signatures/trust-logo-eidas.png'
import UploadBlue from '../../../img/global-signatures/upload-blue.svg'
import Pen from '../../../img/global-signatures/pen.svg'
import Globe from '../../../img/global-signatures/globe.svg'
import LithuanianFlag from '../../../img/flags/lithuanian.png'
import LatvianFlag from '../../../img/flags/latvian.png'
import EstonianFlag from '../../../img/flags/estonian.png'
import GooglePlay from '../../../img/global-signatures/google-play.png'
import ApplePlay from '../../../img/global-signatures/apple-store.png'
import LeftDecoration from '../../../img/global-signatures/global-signatures-blue-left.png'
import RightDecoration from '../../../img/global-signatures/global-signatures-blue-right.png'
import TopRightDecoration from '../../../img/global-signatures/global-signatures-blue-top-rigt.png'
import BottomLeftDecoration from '../../../img/global-signatures/global-signatures-blue-bottom-left.png'
import BottomRightDecoration from '../../../img/global-signatures/global-signatures-blue-bottom-rigt.png'

type Props = {
  clearCart: () => void,
  addCartItem: (Product) => void,
  user: User,
  history: any,
  signatureProducts: Product[],
  isSidebarShrinked: boolean,
}

type ModalAdditionalSignaturesState = {
  active: boolean,
}

export default function GlobalSignaturePricingTable(props: Props): Node {
  const { user, isSidebarShrinked } = props
  const { t } = useTranslation()
  const [modalAdditionalSignatures, setModalAdditionalSignatures] = useState<ModalAdditionalSignaturesState>({ active: false })

  const [signatureProducts, setSignatureProducts] = useState<Array<Product>>([])
  useEffect(() => setSignatureProducts(props.signatureProducts.filter(signatureProduct => signatureProduct.categories.includes('global'))), [props.signatureProducts])

  function onBuyAdditionalSignaturesClick() {
    setModalAdditionalSignatures({ active: true })
  }

  function closeAdditionalSignaturesModal() {
    setModalAdditionalSignatures({ active: false })
  }

  function onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const selectedProduct = props.signatureProducts.find(itm => itm.id === selectedProductId)

    if (typeof selectedProduct === 'undefined') {
      throw new Error('Product was not found')
    }

    props.clearCart()
    props.addCartItem(selectedProduct)
    props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  return (
    <main className={'global-signatures' + (isSidebarShrinked ? ' shrink' : '')}>
      <div id="header">
        <div className="grid">
          <h1 className="page-title">{t('user.pricing.global_signatures')}</h1>
        </div>
      </div>
      <section id="tabs-content">
        <div className="grid grid--flex">
          <div className="tabs-content">
            <div className="wrapped-section">
              <div className="global-signatures__content">
                <div className="wrapped-section__headline">
                  <h2 className="headline headline--standard display-tablet">{t('user.global_signatures.sign_documents_globally')}</h2>
                </div>
                <div className="global-signatures__content--info-block">
                  <div className="global-signatures__content--img">
                    <img src={GlobalSignatureWorld} alt="Global Signature World" />
                  </div>
                  <div className="global-signatures__content--paragraph">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard display-desktop">{t('user.global_signatures.sign_documents_globally')}</h2>
                    </div>
                    <p>{t('user.global_signatures.secure_business')}</p>
                    <ul>
                      <li>{t('user.global_signatures.beyond_eu_signature')}</li>
                      <li>{t('user.global_signatures.certificated_signature')}</li>
                      <li>{t('user.global_signatures.option_of_signatures')}</li>
                      <li>{t('user.global_signatures.first_time_signing')}</li>
                      <li>{t('user.global_signatures.multilingual_app')}</li>
                      <li>{t('user.global_signatures.unused_signatures')}</li>
                    </ul>
                    <button
                      className="btn btn--green"
                      disabled={user.subscription === 'flexible'}
                      onClick={onBuyAdditionalSignaturesClick}
                    >
                      {t('user.global_signatures.common.buy_signatures')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-content">
            <div className="wrapped-section">
              <div className="global-signatures__content">
                <div className="wrapped-section__headline">
                  <h2 className="headline headline--standard display-tablet">{t('user.global_signatures.safe_reliable.title')}</h2>
                </div>
                <div className="global-signatures__content--info-block">
                  <div className="global-signatures__content--img">
                    <img src={SafetyLock} alt="Safety Lock" />
                  </div>
                  <div className="global-signatures__content--paragraph safe-reliable">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard display-desktop">{t('user.global_signatures.safe_reliable.title')}</h2>
                    </div>
                    <div className="global-signatures__es-qualification">
                      <p dangerouslySetInnerHTML={{ __html: t('user.global_signatures.safe_reliable.zeal_id_intro') }} />
                      <p>{t('user.global_signatures.safe_reliable.compliance')}</p>
                      <div>
                        <img src={SrcLogo} alt="Src Logo" />
                        <img src={TuvitLogo} alt="Tuvit Logo" />
                        <img src={TrustEidas} alt="Trust Eidas" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-content">
            <div className="wrapped-section">
              <div className="global-signatures__content global-signatures__how-it-works">
                <div className="wrapped-section__headline">
                  <h2 className="headline headline--standard">{t('user.global_signatures.how_it_works.title')}</h2>
                </div>
                <div className="global-signatures__content--info-block ">
                  <div className="row">
                    <div className="col xl-4-12 lg-4-12 md-12-12">
                      <img src={UploadBlue} alt="Upload Blue" />
                      {/* <p><strong>{t('user.global_signatures.upload_document')}</strong></p> */}
                      <p>
                        <p>
                          <strong dangerouslySetInnerHTML={{ __html: t('user.global_signatures.how_it_works.login.intro') }} />
                        </p>
                        <ul>
                          <li>
                            <strong>
                              <img src={LithuanianFlag} alt="Lithuanian Flag" />
                              <img src={EstonianFlag} alt="Estonian Flag" />
                            </strong>
                            <span>{t('user.global_signatures.how_it_works.login.lt_ee')}</span>
                          </li>
                          <li>
                            <strong>
                              <img src={LatvianFlag} alt="Latvian Flag" />
                            </strong>
                            <span>{t('user.global_signatures.how_it_works.login.lv')}</span>
                          </li>
                          <li>{t('user.global_signatures.how_it_works.login.others')}</li>
                        </ul>
                      </p>
                    </div>
                    <div className="col xl-4-12 lg-4-12 md-12-12">
                      <img src={Pen} alt="Pen" />
                      <p>
                        <p>
                          <span>
                            <strong>{t('user.global_signatures.how_it_works.upload.intro')}</strong>
                          </span>
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: t('user.global_signatures.how_it_works.upload.note') }} />
                      </p>
                    </div>
                    <div className="col xl-4-12 lg-4-12 md-12-12">
                      <img src={Globe} alt="Globe" />
                      <p>
                        <span>
                          <strong dangerouslySetInnerHTML={{ __html: t('user.global_signatures.how_it_works.worldwide.intro') }} />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-content">
            <div className="wrapped-section">
              <div className="global-signatures__content first-time-zealid-connect">
                <div className="wrapped-section__headline">
                  <h2 className="headline headline--standard display-tablet">{t('user.global_signatures.zealid_connect.first_time.title')}</h2>
                </div>
                <div className="global-signatures__content--info-block">
                  <div className="global-signatures__content--img">
                    <img src={Squares} alt="Squares" />
                  </div>
                  <div className="global-signatures__content--paragraph">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard display-desktop">{t('user.global_signatures.zealid_connect.first_time.title')}</h2>
                    </div>
                    <div className="global-signatures__first-time-zealid">
                      <p>
                        <p>{t('user.global_signatures.zealid_connect.first_time.intro')}</p>
                        <ul>
                          <li>{t('user.global_signatures.zealid_connect.first_time.req1')}</li>
                          <li>{t('user.global_signatures.zealid_connect.first_time.req2')}</li>
                        </ul>
                      </p>
                      <div>
                        <a href="https://play.google.com/store/apps/details?id=com.zealid.app&hl=en_US&gl=US" target="_blank" rel="noreferrer">
                          <img src={GooglePlay} alt="Google Play" />
                        </a>
                        <a href="https://apps.apple.com/lt/app/zealid/id1531680051" target="_blank" rel="noreferrer">
                          <img src={ApplePlay} alt="Apple Play" />
                        </a>
                      </div>
                    </div>
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard">{t('user.global_signatures.zealid_connect.registered.title')}</h2>
                    </div>
                    <div className="global-signatures__first-time-zealid">
                      <p>{t('user.global_signatures.zealid_connect.registered.intro')}</p>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-content">
            <div className="wrapped-section global-signatures__cta">
              <div className="global-signatures__content">
                <div className="global-signatures__content--info-block">
                  <div className="global-signatures__content--side-images">
                    <img className="display-desktop" src={LeftDecoration} alt="Left Decoration" />
                  </div>
                  <div className="global-signatures__content--side-images global-signatures__content--tablet-img-top">
                    <img src={TopRightDecoration} alt="Top Right Decoration" />
                  </div>
                  <div className="global-signatures__content--buttons">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--standard">{t('user.global_signatures.get_global_signatures.title')}</h2>
                    </div>
                    <div className="buttons-wrapper">
                      <a
                        href={t('helpdesk.landing')}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn--primary"
                      >
                        {t('user.global_signatures.get_global_signatures.faq')}
                      </a>
                      <button
                        className="btn btn--green"
                        disabled={user.subscription === 'flexible'}
                        onClick={onBuyAdditionalSignaturesClick}
                      >
                        {t('user.global_signatures.common.buy_signatures')}
                      </button>
                    </div>
                  </div>
                  <div className="global-signatures__content--side-images">
                    <img className="display-desktop" src={RightDecoration} alt="Right Decoration" />
                  </div>
                  <div className="global-signatures__content--side-images global-signatures__content--tablet-img-bottom">
                    <img src={BottomLeftDecoration} alt="Bottom Left Decoration" />
                    <img src={BottomRightDecoration} alt="Bottom Right Decoration" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalAdditionalSignatures
        t={t}
        user={user}
        active={modalAdditionalSignatures.active}
        onCloseClick={closeAdditionalSignaturesModal}
        signaturesProducts={signatureProducts}
        onBuyClick={onBuyClick}
      />
    </main>
  )
}