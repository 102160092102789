// @flow

import type { Action } from './action'
import type { Type } from '../components/StatusMessages/StatusMessage/StatusMessage'

let nextStatusMessageId = 0

export type ActionAddStatusMessage = Action<'ADD_STATUS_MESSAGE'> & {
  +id: number,
  +statusMessageType: Type,
  +message: string
}
export type ActionRemoveStatusMessage = Action<'REMOVE_STATUS_MESSAGE'> & {
  +id: number,
}

export const addStatusMessage = (type: Type, message: string): ActionAddStatusMessage => ({
  type: 'ADD_STATUS_MESSAGE',
  id: nextStatusMessageId++,
  statusMessageType: type,
  message
})

export const removeStatusMessage = (id: number): ActionRemoveStatusMessage => ({
  type: 'REMOVE_STATUS_MESSAGE',
  id
})

export const addStatusMessageWithTimeout = addStatusMessage