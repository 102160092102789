function ProductFieldObject(id, name, brand, category, variant, price, quantity = 1, coupon, listPosition, gaPercentage) {
  if (!id && !name) {
    throw new Error('One of id or name must be set')
  }

  if (id) { this.id = id || null }
  if (name) { this.name = name || null }
  if (brand) { this.brand = brand || null }
  if (category) { this.category = category || null }
  if (variant) { this.variant = variant || null }
  if (price) { this.price = price || null }
  if (quantity) { this.quantity = quantity || null }
  if (coupon) { this.coupon = coupon || null }
  // eslint-disable-next-line camelcase
  if (listPosition) { this.list_position = listPosition || null }
  if (gaPercentage) { this.gaPercentage = gaPercentage || 1 }
}

ProductFieldObject.fromProduct = product => {
  return new ProductFieldObject(product.id, product.name, null, product.category, null, product.price, product.gaPercentage)
}

export { ProductFieldObject }