import React from 'react'
import { Redirect } from 'react-router-dom'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import './Register.sass'
import FormRegister from '~/js/components/FormRegister'
import Language from '~/js/components/Language'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import * as userService from '~/js/services/user/user'
import PropTypes from 'prop-types'

class Register extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      updateContacts: new URL(location.href).searchParams.get('update_contacts'),
      redirectTo: null,
    }
  }

  onFormSubmit = (values, { setSubmitting }) => {
    const { user, fetchUser, confirmEmailClick } = this.props
    userService
      .register(values)
      .then(({ data }) => {
        const redirectTo = user.email ? data.targetPath : generatePath(ROUTE_NAMES.USER_CONFIRM_EMAIL)
        if (user.email) {
          confirmEmailClick({
            email: user.email,
            phone: user.phoneNumber,
            acceptNewsletter: values.acceptNewsletter
          })
        }

        return new Promise(res => {
          setSubmitting(false)
          this.setState({ redirectTo }, res)
        })
      })
      .then(() => fetchUser())
      .catch(err => {
        setSubmitting(false)
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  render() {
    const { t, user } = this.props
    const { redirectTo } = this.state

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    return (
      <section id="login" className="login-form-container">
        <div className="grid">
          <a href={generatePath(ROUTE_NAMES.HOME_PAGE)}>
            <img src={markIdLogo} alt="Mark ID" className="login__logo" />
          </a>
          <div className="login__container">
            {user.authMethod !== 'simplysign' && (
              <div className="login__user-info">
                <h5>{t('user.settings.personal_details')}</h5>
                <div className="info-details">
                  <ul>
                    <li>
                      <div className="info-details__label">
                        <label>{t('common.name')}</label>
                      </div>
                      <div className="info-details__info">
                        <p>{user.name}</p>
                      </div>
                    </li>
                    <li>
                      <div className="info-details__label">
                        <label>{t('common.surname')}</label>
                      </div>
                      <div className="info-details__info">
                        <p>{user.surname}</p>
                      </div>
                    </li>
                    <li>
                      <div className="info-details__label">
                        <label>{t('user.login.person_code')}</label>
                      </div>
                      <div className="info-details__info">
                        <p>{user.personalCode}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            <div className="login__user-email">
              <FormRegister
                t={t}
                onSubmit={this.onFormSubmit}
                updateContacts={this.state.updateContacts}
                user={user}
              />
            </div>
          </div>
        </div>
        <Language />
        <StatusMessages />
      </section>
    )
  }
}

export default Register

Register.propTypes = {
  user: PropTypes.object,
  fetchUser: PropTypes.func,
  showStatusMessage: PropTypes.func,
  t: PropTypes.func,
  confirmEmailClick: PropTypes.func,
}
