import React from 'react'
import PropTypes from 'prop-types'

export const STATUS_WAITING = 'waiting'
export const STATUS_OK = 'signed'
export const STATUS_DANGER = 'not-signed'
export const STATUS_REVIEWING = 'reviewing'

export default class Status extends React.Component {
  render() {
    return (
      <div>
        {this.props.level && <div className="status status--signlevel">{this.props.level}</div>}
        <div className={`status status--${this.props.type}`}>{this.props.text}</div>
      </div>
    )
  }
}

Status.propTypes = {
  level: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
}