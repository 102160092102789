import React from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'

const indicatorSeparatorStyle = {
  display: 'none',
}

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}

export default function Select(props) {
  return <ReactSelect
    {...props}
    components={{ IndicatorSeparator }}
    theme={theme => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary: '#0096C7',
      },
    })}
  />
}

IndicatorSeparator.propTypes = {
  innerProps: PropTypes.any,
}