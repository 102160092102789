import React from 'react'
import PropTypes from 'prop-types'

export default class LicensesList extends React.Component {
  render() {
    return (
      <ul className="table__body">
        {this.props.children}
      </ul>
    )
  }
}

LicensesList.propTypes = {
  children: PropTypes.array
}