import React from 'react'
import Modal from '../Modal'
import {Link} from 'react-router-dom'
import {generatePath} from '../../routes/router'
import {ROUTE_NAMES} from '../../routes/config'
import {SETTINGS_COMPANY_USERS} from '../../models/Settings'

export default class ModalRemoveCompanyUsers extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup--session"
        active={this.props.active}>
        <div className="ta-center">
          <h3 className="standard-title">{t('user.settings.company_user.company_users_should_be_removed', { query: this.props.numberOfUsersToBeRemoved })}</h3>
          <br/>
          <Link className="btn btn--secondary" to={generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_COMPANY_USERS })}>
            {t('user.settings.company_user.open_company_users_list')}
          </Link>
        </div>
        <br/>
        <div className="center-element">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}>
            {t('common.close')}
          </button>
        </div>
      </Modal>
    )
  }
}