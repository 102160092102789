import { connect } from 'react-redux'
import { pushGTagNeoPayPurchaseSuccess } from '~/js/actions'
import PricingSuccess from '~/js/routes/app/User/PricingSuccess'

const mapDispatchToProps = dispatch => ({
  pushGTagNeoPayPurchaseSuccess: (product, transaction, user) => dispatch(pushGTagNeoPayPurchaseSuccess(product, transaction, user)),
})

export default connect(
  null,
  mapDispatchToProps
)(PricingSuccess)