import React from 'react'
import { withTranslation } from 'react-i18next'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'

class ModalUnAssignSubscription extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}
      >
        <div className="center-element center-element--space-between">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn--red"
            onClick={this.props.onUnAssignSubscriptionBtnClick}
          >
            {t('user.settings.company_user.unassign')}
          </button>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalUnAssignSubscription)

ModalUnAssignSubscription.propTypes = {
  t: PropTypes.func,
  onCloseClick: PropTypes.func,
  onUnAssignSubscriptionBtnClick: PropTypes.func,
}