import React from 'react'
import * as Yup from 'yup'
import Modal from '../Modal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

export default class ModalSimplySignPin extends React.Component {
  onPinNumberSubmit = values => {
    this.props.setSimplySignPinNo(values.pinNumber)
  }

  render() {
    const { t } = this.props
    const initialValues = { pinNumber: this.props.name }
    const validationSchema = Yup.object().shape({
      pinNumber: Yup.string()
        .required(t('validation.field_is_required'))
    })

    return (
      <Modal
        size="small"
        {...this.props}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onPinNumberSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.pinNumber && props.touched.pinNumber ? ' form-group--error' : '')}>
                <label>{t('simply_sign.pin_number')}</label>
                <Field className="form-group__input" type="password" name="pinNumber" />
                <ErrorMessage className="form-group__error" name="pinNumber" component="span" />
              </div>

              <button
                type="submit"
                className="btn btn--primary ta-center mt-2"
                style={{ margin: '32px auto 0 auto' }}
                disabled={props.isSubmitting}
              >
                {t('submit')}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

ModalSimplySignPin.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  loading: PropTypes.bool,
  setSimplySignPinNo: PropTypes.func,
}