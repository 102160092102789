import React from 'react'

function Section(props) {
  let className = 'section'

  if (props.className) {
    className += ` ${props.className}`
  }

  return (
    <div className={className}>
      {props.title && <h2 className="headline headline--standard">{props.title}</h2>}
      {props.children}
    </div>
  )
}

export default Section