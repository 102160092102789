import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Modal from '../Modal'
import Select from '../Select'
import Tooltip from '../Tooltip'

export default function ModalCompanyUser(props) {
  const { isAdmin, isManager, name, surname, email, role, edit, addCompanyUser, editCompanyUser, isValidEmail } = props
  const { t } = useTranslation()

  const roles = [
    { value: 3, label: t('user.settings.company_user.worker') },
  ]
  if (isAdmin || isManager) {
    roles.unshift({ value: 2, label: t('user.settings.company_user.manager') })
  }
  if (isAdmin) {
    roles.unshift({ value: 1, label: t('user.settings.company_user.admin') })
  }

  const selectedRole = edit ? roles.find(r => r.label === t(`user.settings.company_user.${role}`)) : roles[0]
  const initialValues = {
    name,
    surname,
    email,
    role: selectedRole.value
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation.field_is_required')),
    surname: Yup.string()
      .required(t('validation.field_is_required')),
    email: Yup.string().email(t('validation.email_must_be_a_valid_email'))
      .required(t('validation.field_is_required'))
      .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail)
  })

  function onSubmit(values) {
    const submitValues = [
      values.name,
      values.surname,
      values.email,
      values.role,
    ]

    return edit ? editCompanyUser(submitValues) : addCompanyUser(submitValues)
  }

  return (
    <Modal
      {...props}
      className="modal-company-user"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formikProps => (
          <Form>
            <div className={'form-group' + (formikProps.errors.name && formikProps.touched.name ? ' form-group--error' : '')}>
              <label>{t('user.settings.company_user.name')}</label>
              <Field className="form-group__input" type="text" name="name" />
              <ErrorMessage className="form-group__error" name="name" component="span" />
            </div>

            <div className={'form-group' + (formikProps.errors.surname && formikProps.touched.surname ? ' form-group--error' : '')}>
              <label>{t('user.settings.company_user.surname')}</label>
              <Field className="form-group__input" type="text" name="surname" />
              <ErrorMessage className="form-group__error" name="surname" component="span" />
            </div>

            <div className={'form-group' + (formikProps.errors.email && formikProps.touched.email ? ' form-group--error' : '')}>
              <label>{t('user.settings.company_user.email')}</label>
              <Field className="form-group__input" type="text" name="email" />
              <ErrorMessage className="form-group__error" name="email" component="span" />
            </div>

            <div className={'form-group' + (formikProps.errors.role && formikProps.touched.role ? ' form-group--error' : '')}>
              <label className="form-user-settings"><span className="form-user-settings__span">{t('user.settings.company_user.role')}</span>
                <Tooltip text={t('user.settings.company_user.role_description')} custom={true}>
                  <span className="info-icon" />
                </Tooltip>
              </label>
              <Select
                name="roles"
                options={roles}
                defaultValue={selectedRole}
                placeholder={t('common.select')}
                onChange={option => formikProps.setFieldValue('role', option.value)}
                onBlur={formikProps.handleBlur}
              />
              <ErrorMessage className="form-group__error" name="role" component="span" />
            </div>

            <button
              type="submit"
              className="btn btn--primary ta-center mt-2"
              style={{ margin: '32px auto 0 auto' }}
              disabled={formikProps.isSubmitting}
            >
              {edit ? t('user.settings.company_user.edit_user') : t('user.settings.company_user.add_user')}
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

ModalCompanyUser.propTypes = {
  isAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  edit: PropTypes.bool,
  addCompanyUser: PropTypes.func,
  editCompanyUser: PropTypes.func,
  isValidEmail: PropTypes.func,
}