import { connect } from 'react-redux'
import {
  removeCartItem,
  clearCart,
  pushGTagAddPaymentInfo,
  pushGTagBeginCheckout,
  pushGTagPurchaseSuccess
} from '~/js/actions'
import PricingCheckout from '~/js/routes/app/User/PricingCheckout'

const mapStateToProps = state => ({
  cart: state.cart
})

const mapDispatchToProps = dispatch => ({
  removeCartItem: (id) => dispatch(removeCartItem(id)),
  clearCart: () => dispatch(clearCart()),
  pushGTagAddPaymentInfo: (product, subtotal, discountedSubtotal, coupon) => dispatch(pushGTagAddPaymentInfo(product, subtotal, discountedSubtotal, coupon)),
  pushGTagBeginCheckout: (product, subtotal, discountedSubtotal, coupon) => dispatch(pushGTagBeginCheckout(product, subtotal, discountedSubtotal, coupon)),
  pushGTagPurchaseSuccess: (product, subtotal, discountedSubtotal, coupon, vat, transactionId, user) => dispatch(pushGTagPurchaseSuccess(product, subtotal, discountedSubtotal, coupon, vat, transactionId, user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingCheckout)