import React from 'react'
import ControlButtons, { ControlButton } from '../ControlButtons'
import PropTypes from 'prop-types'

export default class ControlButtonsLicense extends React.Component {
  render() {
    const { t, noText } = this.props

    return (
      <ControlButtons
        className={this.props.className}
        inline={true}
      >
        {this.props.canAssignSubscription && (
          <ControlButton
            type="add"
            title={t('user.settings.company_user.assign_license')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onAssignSubscriptionClick}
          />
        )}

        {this.props.canUnAssignSubscription && (
          <ControlButton
            type="delete"
            title={t('common.remove')}
            showLabel={!noText}
            showTooltip={noText}
            onClick={this.props.onUnAssignSubscriptionClick}
          />
        )}
      </ControlButtons>
    )
  }
}

ControlButtonsLicense.propTypes = {
  t: PropTypes.func,
  noText: PropTypes.bool,
  className: PropTypes.string,
  canAssignSubscription: PropTypes.bool,
  onAssignSubscriptionClick: PropTypes.func,
  canUnAssignSubscription: PropTypes.bool,
  onUnAssignSubscriptionClick: PropTypes.func,
}