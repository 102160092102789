import React from 'react';

export default class AppHeader extends React.Component {
  render() {
    return (
      <div id="header" className={this.props.className}>
        {!this.props.children && (
          <div className="grid grid--flex">
            <div className="header__left">
              {this.props.title && <h1 className="page-title">{this.props.title}</h1>}
              {this.props.left}
            </div>
            <div className="header__right">
              {this.props.right}
            </div>
            {this.props.bottom}
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}