import PropTypes from 'prop-types'
import { DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED } from '~/js/models/Document'
import StatusSigner from '~/js/components/StatusSigner'
import InformationBar from '~/js/components/InformationBar'
import Info from '../../../../img/icons/info-slick-white.svg'

export default function UsbDocumentMember({ t, document }) {
  return (
    <div className="usb-document-members">
      <ul>
        {typeof document.members === 'undefined' || document.members.length === 0
          ? <InformationBar
            icon={Info}
            iconAlt="Info"
            informationText={t('user.document.document_has_no_members')}
            informationClass='info'
          />
          : document.members.map((member) => (
            <li key={member.id}>
              <span className="usb-document-members__name">
                {member.name} {member.surname}
              </span>
              <span className="usb-document-information__email" >
                ({member.email})
              </span>
              <span className="usb-document-information__status">
                <StatusSigner t={t} status={member.signStatus || DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED} signatureLevelDesc={member.signatureLevel && member.signatureLevel.toLowerCase() === 'qesig' ? member.signatureLevelDesc : null} />
              </span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

UsbDocumentMember.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
}

