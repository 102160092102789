import { connect } from 'react-redux'
import { removeStatusMessage } from '~/js/actions'
import StatusMessages from '~/js/components/StatusMessages'

const mapStateToProps = state => ({
  messages: state.statusMessages
})

const mapDispatchToProps = dispatch => ({
  onCloseClick: id => dispatch(removeStatusMessage(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusMessages)