import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from './serviceHelpers'

export function getWorkspaces(user, offset, limit, query, list) {
  const params = { offset, limit, list }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace(user)}/workspace`, { params })
    .then(({ data }) => data)
}

export function getMatchingEmailWorkspaces(user, signerId) {
  const params = { signerId }

  return apiClient
    .get(`v1/${getUserNamespace(user)}/matching-email-workspace`, { params })
    .then(({ data }) => data)
}

export function getWorkspacesBySessionId(sessionId, offset, limit) {
  const params = { offset, limit }

  return apiClient
    .get('v1/session/workspace', { headers: { 'X-SESSION-ID': sessionId }, params })
    .then(({ data }) => data)
}

export function getWorkspace(id) {
  return apiClient
    .get(`v1/${getUserNamespace()}/workspace/${id}`)
    .then(({ data }) => data)
}

export function addWorkspace(params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/workspace`, params)
    .then(({ data }) => data)
}

export function removeWorkspace(id) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/workspace/${id}`)
    .then(({ data }) => data)
}

export function joinWorkspace(workspaceId, businessPersonId) {
  return apiClient
    .post(`user/workspace/${workspaceId}/person/${businessPersonId}/join`)
    .then(({ data }) => data)
}

export function quitWorkspace(workspaceId) {
  return apiClient
    .post(`v1/${getUserNamespace()}/business-person/quit/${workspaceId}`)
    .then(({ data }) => data)
}

export function resendConfirmationEmail(values) {
  return apiClient
    .post(`/${getUserNamespace()}/resend-confirmation-email`, {
      email: values
    })
    .then(res => {
      return res.data
    })
}

export function workspacesForProfessional(user) {
  return apiClient
    .get(`v1/${getUserNamespace(user)}/workspace/assignable/professional`)
    .then(({ data }) => data)
}