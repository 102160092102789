// @flow

export const PM_TYPE_SUCCESS = 'success'
export const PM_TYPE_ERROR = 'error'
export const PM_TYPE_REJECTION = 'reject'

type Message = {
  key: string,
  message: string
}

export const postMessageToParentDocument = (message: Message): void => {
  parent.postMessage(message, '*')
}

export const postSigningInfoToParentDocument = (signMessageType: string, message: string): void => {
  if ([PM_TYPE_ERROR, PM_TYPE_REJECTION, PM_TYPE_SUCCESS].includes(signMessageType)) {
    const key = `sign.${signMessageType}`
    postMessageToParentDocument({
      key, message
    })
  }
}
