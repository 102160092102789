import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';
import Modal from "../Modal";

class ModalWorkspace extends React.Component {
  onWorkspaceSubmit = values => {
    this.props.addWorkspace([
      values.companyName,
      values.registrationCode,
      values.address,
      values.email
    ])
  }

  render() {
    const { t } = this.props;

    let initialValues = { companyName: '', registrationCode: '', address: '', email: '' }
    let validationSchema = Yup.object().shape({
      companyName: Yup.string()
        .required(t('validation.field_is_required')),
      registrationCode: Yup.string()
        .required(t('validation.field_is_required')),
      address: Yup.string()
        .required(t('validation.field_is_required')),
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email')),
    })

    return (
      <Modal {...this.props}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onWorkspaceSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.companyName && props.touched.companyName ? ' form-group--error' : '')}>
                <label>{t('user.settings.workspace.company_name')}</label>
                <Field className="form-group__input" type="text" name="companyName" />
                <ErrorMessage className="form-group__error" name="companyName" component="span" />
              </div>

              <div className={'form-group' + (props.errors.registrationCode && props.touched.registrationCode ? ' form-group--error' : '')}>
                <label>{t('user.settings.workspace.registration_code')}</label>
                <Field className="form-group__input" type="text" name="registrationCode" />
                <ErrorMessage className="form-group__error" name="registrationCode" component="span" />
              </div>

              <div className={'form-group' + (props.errors.address && props.touched.address ? ' form-group--error' : '')}>
                <label>{t('user.settings.workspace.address')}</label>
                <Field className="form-group__input" type="text" name="address" />
                <ErrorMessage className="form-group__error" name="address" component="span" />
              </div>

              <div className={'form-group' + (props.errors.email && props.touched.email ? ' form-group--error' : '')}>
                <label>{t('user.settings.workspace.email')}</label>
                <Field className="form-group__input" type="text" name="email" />
                <ErrorMessage className="form-group__error" name="email" component="span" />
              </div>

              <button
                type="submit"
                className="btn btn--primary ta-center mt-2"
                style={{ margin: '32px auto 0 auto' }}
                disabled={props.isSubmitting}
              >
                {t('user.settings.workspace.add_workspace')}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

export default withTranslation()(ModalWorkspace);
