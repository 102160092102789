import React from 'react'
import _debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

export default class InputSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tempValue: props.value || '',
      debouncedOnChange: _debounce(props.onChange, props.wait),
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { props } = this

    if (prevProps.onChange !== props.onChange) {
      this.setState({ debouncedOnChange: _debounce(props.onChange, props.wait) })
    }

    if (prevProps.value !== props.value) {
      this.setState({ tempValue: props.value })
    }
  }

  onChange(e) {
    const tempValue = e.target.value.replace(/\s+/g, ' ')

    if (tempValue !== this.state.tempValue) {
      this.setState({ tempValue })
      this.state.debouncedOnChange(tempValue)
    }
  }

  render() {
    const { props } = this

    return (
      <input
        type="text"
        onChange={this.onChange}
        placeholder={props.placeholder}
        value={this.state.tempValue}
      />
    )
  }
}

InputSearch.defaultProps = {
  placeholder: 'Search',
  wait: 500,
}

InputSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  wait: PropTypes.number,
  placeholder: PropTypes.string,
}