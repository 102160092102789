import React from 'react'
import PropTypes from 'prop-types'
import { ValidateDocumentPreview } from './ValidateDocumentPreview'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'

export class ValidateDocument extends React.Component {
  constructor(props) {
    super(props)

    this.showPage = this.showPage.bind(this)
    this.validationFetchCompleted = this.validationFetchCompleted.bind(this)

    let filesArray = JSON.parse(localStorage.getItem('validation_files')).reverse()

    this.state = {
      filesArray,
      validationComponentProps: [],
    }
  }

  componentDidMount() {
    this.showPage(1, 1)
  }

  /**
   * User can visit one page forward or backward and cannot move way forward if current one's validation is still loading
   * When a user has forwarded to a page, that will mean all the previous pages' validation have already been loaded
   */
  showPage(toPageNumber, currentPageNumber) {
    let { validationComponentProps, filesArray } = this.state
    let { showStatusMessage, t } = this.props

    let arrayIndex = toPageNumber - 1

    if (arrayIndex < 0 || arrayIndex > validationComponentProps.length ) {
      return
    }

    // If the inteded page number is valid and the pages component's hasn't loaded yet
    if (filesArray[arrayIndex] && toPageNumber <= filesArray.length && !validationComponentProps[arrayIndex]) {
      // If current page number is valid and intended page number is greater than current one and the cuurent one is loading
      // then show a validation ongoing message, otherwise load intended component
      if (currentPageNumber && currentPageNumber < toPageNumber && validationComponentProps[currentPageNumber - 1].loading === true) {
        return showStatusMessage(SM_TYPE_ERROR, t('user.document.validation_is_going_on'))
      } else {
        validationComponentProps.push({
          pageNumber: toPageNumber,
          loading: true,
          count: filesArray.length,
          uuid: filesArray[arrayIndex],
          active: false,
          ...this.props
        })
      }
    }

    validationComponentProps.map((current, index) => {
      validationComponentProps[index].active = arrayIndex === index ? true : false
    })

    this.setState(prevState => {
      return {
        ...prevState,
        validationComponentProps
      }
    })
  }

  validationFetchCompleted(pageNumber) {
    let { validationComponentProps } = this.state

    let arrayIndex = pageNumber - 1

    if (arrayIndex < 0 || arrayIndex > validationComponentProps.length || !validationComponentProps[arrayIndex]) {
      return
    }

    validationComponentProps[arrayIndex].loading = false

    this.setState(prevState => {
      return {
        ...prevState,
        validationComponentProps
      }
    })
  }

  render() {
    const { validationComponentProps } = this.state
    return validationComponentProps.map((currentComponentProps, index) => {
      return <ValidateDocumentPreview
        key={index}
        showPage={this.showPage}
        validationFetchCompleted={this.validationFetchCompleted}
        { ...currentComponentProps }
      />
    })
  }
}

ValidateDocument.propTypes = {
  t: PropTypes.func,
  children: PropTypes.any,
  showStatusMessage: PropTypes.func
}