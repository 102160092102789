import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import PropTypes from 'prop-types'
import Language from '../Language'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import { ROUTE_NAMES, ROUTE_PATHS } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { SETTINGS_WORKSPACES } from '~/js/models/Settings'
import ModalAdditionalSignatures from '../ModalAdditionalSignatures/ModalAdditionalSignatures'
import { formatWorkspaces } from '~/js/utils/user'
import ModalAdditionalAndGlobalSignatures
  from '~/js/components/ModalAdditionalAndGlobalSignatures/ModalAdditionalAndGlobalSignatures'
import { findSignatureProducts, getGlobalProductsList } from '../../utils/common'
import * as tawkTo from '../../bootstrap/tawk.to'
import { AUTH_TYPE_ZEALID } from '../../services/user/user'

class MobileHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      workspacesHidden: true,
      hamburgerNotActive: true,
      customLogoUrl: props.user.logoUrl,
      modalAdditionalSignatures: { active: false },
      modalAdditionalAndGlobalSignatures: { active: false },
    }

    this.hamburger = this.hamburger.bind(this)
    this.popupClose = this.popupClose.bind(this)
    this.onBuyClick = this.onBuyClick.bind(this)
    this.onWorkspaceSwitch = this.onWorkspaceSwitch.bind(this)
    this.onBuyAdditionalSignaturesClick = this.onBuyAdditionalSignaturesClick.bind(this)
    this.closeAdditionalSignaturesModal = this.closeAdditionalSignaturesModal.bind(this)
    this.closeAdditionalAndGlobalSignaturesModal = this.closeAdditionalAndGlobalSignaturesModal.bind(this)
    this.isGlobalUser = this.isGlobalUser.bind(this)
  }

  componentDidMount() {
    tawkTo.embedScript(tawkTo.hideWidget)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hamburgerNotActive !== this.state.hamburgerNotActive) {
      if (this.state.hamburgerNotActive) {
        this.props.enableScroll()
      } else {
        this.props.disableScroll()
      }
    }
  }

  componentWillUnmount() {
    this.props.enableScroll()
  }

  popupClose(event) {
    event.preventDefault()

    this.setState(prevState => ({ workspacesHidden: !prevState.workspacesHidden }))
  }

  hamburger() {
    this.setState(prevState => ({ hamburgerNotActive: !prevState.hamburgerNotActive }))

    document.querySelector('#header-menu').classList.toggle('js-show-menu')
    document.querySelector('#mobile-header').classList.toggle('js-menu-show')
  }

  onWorkspaceSwitch(workspace) {
    if (workspace.active || !workspace.canSwitchTo) {
      return
    }

    return new Promise(res => this.setState({ workspacesHidden: true }, res))
      .then(() => this.props.onWorkspaceSwitch(workspace.id))
  }

  onBuyAdditionalSignaturesClick() {
    if (this.isGlobalUser()) {
      this.setState({ modalAdditionalSignatures: { active: true } })
    } else {
      this.setState({ modalAdditionalAndGlobalSignatures: { active: true } })
    }
  }

  closeAdditionalSignaturesModal() {
    this.setState({ modalAdditionalSignatures: { active: false } })
  }

  closeAdditionalAndGlobalSignaturesModal() {
    this.setState({ modalAdditionalAndGlobalSignatures: { active: false } })
  }

  onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const product = this.props.products.find(itm => itm.id === selectedProductId)

    this.props.clearCart()
    this.props.addCartItem(product)
    this.props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  isGlobalUser() {
    const { user } = this.props
    const countries = ['LT', 'LV', 'EE']

    return !countries.includes(user.country) && user.authMethod === AUTH_TYPE_ZEALID
  }

  render() {
    const { workspacesHidden, hamburgerNotActive } = this.state
    const { t, user, canShowWorkspaceSwitch, signatureProducts } = this.props
    const workspaces = formatWorkspaces(user, this.props.workspaces)
    const activeWorkspace = workspaces.find(w => w.active)
    const totalSignatures = user.boughtSignatures + user.subscriptionSignaturesLeft
    let signaturesLeftElm

    if (user.subscriptionPayType === 'prepaid') {
      const subscriptionSignaturesLimit = user.subscriptionSignaturesLimit + user.boughtSignatures
      signaturesLeftElm = t('signatures_left') + `${totalSignatures}/${subscriptionSignaturesLimit}`
    } else if (user.subscriptionPayType === 'postpaid') {
      signaturesLeftElm = t('signatures_left') + t('unlimited').toLowerCase()
    }

    const validationInfoElm = (user.validation.allowed > 0 || user.subscription === 'start' || user.subscription === 'no_plan') ? `${t('document_validations')}: ${user.validation.left}/${user.validation.allowed}` : null

    return (
      <React.Fragment>
        <header id="mobile-header">
          <a className={`hamburger ${hamburgerNotActive ? '' : 'active'}`} href="#" onClick={this.hamburger}>
            <i>
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </i>
          </a>
          <div className="sidebar__logo">
            <Link to={generatePath(ROUTE_NAMES.USER_DASHBOARD)}>
              { this.state.customLogoUrl !== '' && <img src={this.state.customLogoUrl} alt="Logo" /> }
            </Link>
          </div>
        </header>
        <div id="header-menu">
          <div className={`sidebar__top ${workspacesHidden ? '' : 'js-show'}`}>
            {activeWorkspace && (
              <div className="sidebar__user js-sidebar__user" onClick={() => canShowWorkspaceSwitch && this.setState({ workspacesHidden: false })}>
                <div className="sidebar__user-logo">
                  <img src={activeWorkspace.isBusiness ? Company : User} alt={activeWorkspace.isBusiness ? 'Company' : 'User'} />
                </div>
                <div className="sidebar__user-info shrink-hide">
                  <div className="sidebar__user-plan-info">
                    <span className={!activeWorkspace.isBusiness ? 'capitalized-text' : null}>{activeWorkspace.isBusiness ? activeWorkspace.name : activeWorkspace.name.toLowerCase()}</span>
                    {activeWorkspace.isBusiness && (<span>{user.fullName}</span>)}
                    {!this.isGlobalUser() && <p>{signaturesLeftElm}</p>}
                    {user.subscription !== 'flexible' &&
                    <p>{t('user.pricing.additional_signatures')}: {user.boughtSignatures || 0}</p>
                    }
                    {(user.subscription !== 'flexible' && (user.globalSignatures > 0 || user.authMethod === AUTH_TYPE_ZEALID)) &&
                    <p>{this.isGlobalUser() ? t('signatures_left') : t('user.pricing.global_signatures_left')} {user.globalSignatures}</p>
                    }
                    {validationInfoElm && <p>{validationInfoElm}</p>}
                  </div>
                  <p>{t('user.settings.current_plan')}: {t(`user.pricing.${user.subscription}`)}</p>
                </div>
              </div>
            )}
            <div className="sidebar__menu">
              <ul>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--documents
                    ${(this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_PREVIEW)) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_DOCUMENTS)}
                    onClick={this.hamburger}
                  >
                    <p className="shrink-hide">{t('user.documents')}</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--validate-document ${(this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW)) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE)}
                    onClick={this.hamburger}
                  >
                    <p className="shrink-hide">{t('validate_document')}</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebar__user__overlay" />
            <OutsideClickHandler onOutsideClick={() => this.setState({ workspacesHidden: true })}>
              <div className="sidebar__user--more-users js-sidebar-more-users">
                <div className="popup__close js-popup__close" onClick={this.popupClose} />
                <ul>
                  {workspaces.map(workspace => {
                    let userClassName = 'sidebar__user'

                    if (workspace.active) {
                      userClassName += ' sidebar__user--active'
                    } else if (!workspace.canSwitchTo) {
                      userClassName += ' sidebar__user--disabled'
                    }

                    return (
                      <li key={`mh-workspace-${workspace.id}`}>
                        <a
                          className={userClassName}
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            this.onWorkspaceSwitch(workspace)
                          }}
                        >
                          <div className="sidebar__users-logo">
                            <img
                              src={workspace.isBusiness ? Company : User}
                              alt={workspace.isBusiness ? 'Company' : 'User'}
                            />
                          </div>
                          <div className="sidebar__user-info"><span>{workspace.name}</span></div>
                        </a>
                      </li>
                    )
                  })}
                </ul>
                <div className="sidebar__user-new">
                  <Link
                    id="user__add-new"
                    to={generatePath(ROUTE_NAMES.USER_PRICING)}
                  >
                    {t('new_workspace')}
                  </Link>
                  <Link
                    id="user__view-all"
                    to={generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_WORKSPACES })}
                  >
                    {t('view_all')}
                  </Link>
                </div>
              </div>
            </OutsideClickHandler>
          </div>
          <div className="sidebar__bottom">
            {(user.subscription === 'start' || user.subscription === 'no_plan') && (
              <div className="sidebar__upgrade sidebar__upgrade--start">
                <Link
                  className="btn btn--green"
                  to={generatePath(ROUTE_NAMES.USER_PRICING)}
                  onClick={this.hamburger}
                >
                  {t('user.settings.upgrade_plan')}
                </Link>
                <span className="btn btn--secondary" onClick={() => this.onBuyAdditionalSignaturesClick()}>
                  {this.isGlobalUser() ? t('user.settings.buy_signatures') : t('user.pricing.buy_more_signatures')}
                </span>
              </div>
            )}
            {(user.subscription === 'teams' || user.subscription === 'professional') && (
              <div className="sidebar__upgrade">
                <Link
                  className="btn btn--green"
                  to={generatePath(ROUTE_NAMES.USER_PRICING)}
                  onClick={() => this.onBuyAdditionalSignaturesClick()}
                >
                  {this.isGlobalUser() ? t('user.settings.buy_signatures') : t('user.pricing.buy_more_signatures')}
                </Link>
              </div>
            )}
            <div className="sidebar__user-settings">
              <ul>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--pricing ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_PRICING) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_PRICING)}
                    onClick={this.hamburger}
                  >
                    <p className="shrink-hide">{t('pricing')}</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--global-signatures ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_GLOBAL_SIGNATURES) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_GLOBAL_SIGNATURES)}
                    onClick={this.hamburger}
                  >
                    <p className="shrink-hide">{this.isGlobalUser() ? t('user.pricing.signing_with_zealiD') : t('user.pricing.global_signatures')}</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--settings ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_SETTINGS) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_SETTINGS)}
                    onClick={this.hamburger}
                  >
                    <p className="shrink-hide">{t('settings')}</p>
                  </Link>
                </li>
                <li>
                  <a
                    className="sidebar__menu-item sidebar__menu-item--faq"
                    href={t('helpdesk.landing')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="shrink-hide">{t('faq')}</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="sidebar__menu-item sidebar__menu-item--messenger" onClick={tawkTo.toggleLiveChat}>{t('messenger')}</a>
                </li>
                <li>
                  <Language />
                </li>
                <li>
                  <a
                    className="validate-document sidebar__menu-item sidebar__menu-item--logout"
                    href={generatePath(ROUTE_NAMES.USER_LOGOUT)}
                  >
                    <p className="shrink-hide">{t('logout')}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ModalAdditionalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalSignatures.active}
          onCloseClick={this.closeAdditionalSignaturesModal}
          signaturesProducts={signatureProducts.filter(p => p.name.includes(user.subscription))}
          onBuyClick={this.onBuyClick}
        />
        <ModalAdditionalAndGlobalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalAndGlobalSignatures.active}
          onCloseClick={this.closeAdditionalAndGlobalSignaturesModal}
          signaturesProducts={findSignatureProducts(signatureProducts, user.subscription)}
          globalSignaturesProducts={getGlobalProductsList(user.subscription, signatureProducts)}
          onBuyClick={this.onBuyClick}
        />
      </React.Fragment>
    )
  }
}

MobileHeader.propTypes = {
  user: PropTypes.object,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
  history: PropTypes.object.isRequired,
  canShowWorkspaceSwitch: PropTypes.bool,
  onWorkspaceSwitch: PropTypes.func,
  t: PropTypes.func,
  workspaces: PropTypes.array,
  path: PropTypes.string,
  enableScroll: PropTypes.func,
  disableScroll: PropTypes.func,
  products: PropTypes.array,
  signatureProducts: PropTypes.array,
}

export default withTranslation()(MobileHeader)
