import * as userService from '~/js/services/user/user'

export const ACTION_UPDATE_VALIDATION_COUNT = 'UPDATE_VALIDATION_COUNT'

const receiveValidationInfo = (user, validationInfo) => ({
  type: ACTION_UPDATE_VALIDATION_COUNT,
  validationInfo
})

export const fetchValidationInfo = (user) => dispatch => {
  const { promise, cancel } = userService.validationInfo(user)

  return {
    promise: promise.then(data => {
      dispatch(receiveValidationInfo(user, data))
    }).catch(err => Promise.reject(err)),
    cancel
  }
}