// @flow

import moment from 'moment'

/**
 * @param {moment.MomentInput} date
 */
export function formatDate(date: any): string {
  return moment(date).format('YYYY-MM-DD')
}

/**
 * @param {moment.MomentInput} date
 */
export function formatDateTime(date: any): string {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * @param {moment.MomentInput} date
 */
export function formatDateTimeNoSeconds(date: any): string {
  return moment(date).format('YYYY-MM-DD HH:mm')
}

/**
 * @param {moment.MomentInput} date
 * @param {number} numOfYear
 */
export function addYearWithDate(date: any, numOfYear: number): string {
  return moment(date).add(numOfYear, 'year').format('YYYY-MM-DD HH:mm:ss')
}