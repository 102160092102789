// @flow

import * as React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'

const hubspotFormUrl = 'https://markid.lt/:locale/hubspot/show-form/'
const formIdMap: Map<string, number> = new Map([
  ['hero', 1],
  ['aml', 2],
  ['ident', 3],
  ['checkout', 4],
])

type Props = {
  type: string
}

export default function ModalHubspotForm(props: Props): React.Node {
  const { type } = props
  const { i18n } = useTranslation()
  const formId = formIdMap.get(type) || 0
  const url = hubspotFormUrl.replace(':locale', i18n.language) + formId

  return (
    <Modal {...props}>
      <div className="modal-hubspot-form" data-pd-webforms={url}>
        <iframe
          className="modal-hubspot-form__iframe"
          src={url}
          scrolling="no"
          seamless="seamless"
        />
      </div>
    </Modal>
  )
}
