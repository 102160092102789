import React from 'react'
import ModalContact from '../ModalContact'
import ModalCategory from '../ModalCategory'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'
import * as settingsService from '~/js/services/user/settings'
import ContactCategoriesList from '../ContactCategoriesList/ContactCategoriesList'
import ContactCategory from '../ContactCategoriesList/ContactCategory'
import ContactsList from '../ContactsList'
import Contact from '../ContactsList/Contact/Contact'
import ModalRemove from '../ModalRemove'
import Pagination from '~/js/components/Pagination'
import ModalAssignContacts from '../ModalAssignContacts'
import { getUserWorkspace } from '~/js/routes/router'
import InputSearch from '../InputSearch'
import PropTypes from 'prop-types'

const pageSize = 10

export default class Contacts extends React.Component {
  constructor(props) {
    super(props)

    const queryString = this.parseQueryString()
    const pageCategories = queryString.page || 1

    this.state = {
      accordionActive: false,
      modalContact: {
        active: false,
        contactId: null
      },
      modalCategory: {
        active: false,
        contactCategoryIndex: undefined
      },
      categories: [],
      allCategories: [],
      contacts: [],
      paginationCategories: {
        page: pageCategories,
        offset: this.getOffset(pageCategories),
        limit: pageSize,
        total: undefined,
      },
      modalRemoveContactCategory: {
        active: false,
        contactCategoryIndex: undefined
      },
      modalRemoveContact: {
        active: false,
        contactId: null
      },
      modalAssignContact: {
        active: false,
        contactCategoryIndex: undefined,
        contactCategoryName: undefined
      },
      searchValue: '',
      modalAssignContactsSearchValue: ''
    }

    this.addCategory = this.addCategory.bind(this)
    this.addContact = this.addContact.bind(this)
    this.editCategory = this.editCategory.bind(this)
    this.editContact = this.editContact.bind(this)
    this.assignContactsToCategory = this.assignContactsToCategory.bind(this)
    this.getAllCategories()
    this.fetchCategories()
    this.fetchContacts()
  }

  getOffset(page) {
    return (page - 1) * pageSize
  }

  parseQueryString() {
    const search = this.props.location.search || ''
    const match = search.match(/page=(\d)?/)

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    }
  }

  onPaginationNextClick = () => {
    this.setPaginationPage(this.state.paginationCategories.page + 1)
  }

  onPaginationPreviousClick = () => {
    this.setPaginationPage(this.state.paginationCategories.page - 1)
  }

  onModalAssignContactsSearchChange = value => {
    this.setState({ modalAssignContactsSearchValue: value })
    this.fetchContacts()
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      paginationCategories: {
        ...prevState.paginationCategories,
        page: page,
        offset: this.getOffset(page),
      }
    }))
  }

  onAccordionClick = () => {
    this.setState(prevState => ({ accordionActive: !prevState.accordionActive }))
  }

  onAddNewContactClick = () => {
    this.setState({
      modalContact: {
        active: true
      }
    })
  }

  onAddNewCategoryClick = () => {
    this.setState({
      modalCategory: {
        active: true
      }
    })
  }

  onContactCategoryDeleteClick = index => {
    this.setState({
      modalRemoveContactCategory: {
        active: true,
        contactCategoryIndex: index,
      },
    })
  }

  onContactCategoryEditClick = index => {
    this.setState({
      modalCategory: {
        active: true,
        contactCategoryIndex: index
      }
    })
  }

  getCategory = () => {
    const { modalCategory, categories } = this.state

    return categories[modalCategory.contactCategoryIndex]
  }

  getContact = () => {
    const { modalContact, contacts } = this.state

    return contacts.find(contact => contact.id === modalContact.contactId)
  }

  onModalRemoveContactCategoryClick = () => {
    const { t } = this.props
    const { modalRemoveContactCategory, categories } = this.state
    const category = categories[modalRemoveContactCategory.contactCategoryIndex]

    settingsService
      .removeContactCategory(category.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contact_category_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemoveContactCategory: { active: false } })
        this.fetchCategories()
        this.fetchContacts()
      })
  }

  onContactDeleteClick = contactId => {
    this.setState({
      modalRemoveContact: {
        active: true,
        contactId: contactId,
      },
    })
  }

  onContactEditClick = contactId => {
    this.setState({
      modalContact: {
        active: true,
        contactId: contactId,
      },
    })
  }

  onModalRemoveContactClick = () => {
    const { t } = this.props
    const { modalRemoveContact, contacts } = this.state
    const contact = contacts.find(contactItem => contactItem.id === modalRemoveContact.contactId)

    settingsService
      .removeContact(contact.id)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contact_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemoveContact: { active: false } })
        this.fetchContacts()
        this.fetchCategories()
      })
  }

  onAssignContactClick = index => {
    const { categories } = this.state

    this.setState({
      modalAssignContact: {
        active: true,
        contactCategoryIndex: index,
        contactCategoryName: categories[index].name,
      },
    })
  }

  addCategory([categoryName]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/contact/category`, {
        name: categoryName,
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.category_was_successfully_added')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCategory: { active: false } })
        this.fetchContacts()
        this.fetchCategories()
        this.getAllCategories()
      })
  }

  editCategory([categoryName]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/contact/category/` + (this.getCategory()).id, {
        name: categoryName,
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.category_was_successfully_edited')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCategory: { active: false, contactCategoryIndex: undefined } })
        this.fetchCategories()
        this.fetchContacts()
      })
  }

  addContact([name, surname, email, phoneNumber, companyName, commentary, category]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/contact`, {
        name: name,
        surname: surname,
        email: email,
        phoneNumber: phoneNumber,
        companyName: companyName,
        commentary: commentary,
        category: category
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contact_was_successfully_added')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalContact: { active: false } })
        this.fetchContacts()
        this.fetchCategories()
      })
  }

  editContact([name, surname, email, phoneNumber, companyName, commentary, category]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/contact/` + this.state.modalContact.contactId, {
        name: name,
        surname: surname,
        email: email,
        phoneNumber: phoneNumber,
        companyName: companyName,
        commentary: commentary,
        category: category
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contact_was_successfully_edited')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalContact: { active: false, contactId: null } })
        this.fetchContacts()
        this.fetchCategories()
      })
  }

  getAllCategories() {
    settingsService
      .getAllContactCategories()
      .then(data => this.setState({
        allCategories: data.data
      }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchCategories() {
    const { paginationCategories, searchValue } = this.state

    settingsService
      .getCategories(paginationCategories.offset, paginationCategories.limit, searchValue)
      .then(data => this.setCategories(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCategories(data) {
    const { data: categories, totalCount } = data

    this.setState(prevState => ({
      categories,
      paginationCategories: {
        ...prevState.paginationCategories,
        total: totalCount,
      },
    }))
  }

  fetchContacts() {
    const { modalAssignContactsSearchValue } = this.state

    settingsService
      .getContacts(modalAssignContactsSearchValue)
      .then(data => this.setContacts(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchContactsBySearchValue(value) {
    settingsService
      .getContacts(value)
      .then(data => this.setContacts(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setContacts(data) {
    const { data: contacts } = data

    this.transformFetchedContacts(contacts)
    this.setState({
      contacts: contacts
    })
  }

  transformFetchedContacts(contacts) {
    contacts.forEach(contact => {
      contact.selected = false
    })
  }

  onContactSelect = index => {
    const { contacts } = this.state

    contacts[index].selected = !contacts[index].selected

    this.setState({ contacts })
  }

  assignContactsToCategory(contacts) {
    const { t } = this.props
    const { modalAssignContact, categories } = this.state

    const category = categories[modalAssignContact.contactCategoryIndex]
    const selectedContacts = []

    contacts.forEach(contact => {
      if (contact.selected) {
        selectedContacts.push(contact)
      }
    })

    return apiClient
      .post(`v1/${getUserWorkspace()}/contact/assign/${category.id}`, {
        contacts: selectedContacts
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.contact.contacts_were_successfully_assigned', { total: selectedContacts.length })))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalAssignContact: { active: false }, modalAssignContactsSearchValue: '' })
        this.fetchContacts()
        this.fetchCategories()
      })
  }

  onSearchChange = value => {
    this.setState({ accordionActive: value.length > 0, searchValue: value, paginationCategories: { page: 1, offset: this.getOffset(1), limit: pageSize } })
    this.setPaginationPage(this.state.paginationCategories.page)
    this.fetchCategories()
    this.fetchContactsBySearchValue(value)
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.paginationCategories.page !== prevState.paginationCategories.page) {
      this.fetchCategories()
    }

    if (state.modalAssignContact.active === false && state.modalAssignContactsSearchValue !== prevState.modalAssignContactsSearchValue) {
      this.fetchContacts()
    }
  }

  render() {
    const { t } = this.props
    const contact = this.getContact()

    return (
      <React.Fragment>
        <div className="settings__top">
          <div className="settings__top__left">
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.state.searchValue}
                onChange={this.onSearchChange}
                wait={this.props.wait}
              />
            </div>
          </div>
          <div className="settings__top__right">
            <a className="btn btn--secondary js-open-popup" onClick={this.onAddNewCategoryClick}>
              {t('user.settings.contact.plus_add_category')}
            </a>
            <a className="btn btn--primary js-open-popup" onClick={this.onAddNewContactClick}>
              {t('user.settings.contact.plus_add_new_contact')}
            </a>
          </div>
        </div>
        <div className="settings__accordion-list accordion">
          <div className="wrapped-section wrapped-section--shrink">
            <div className={`accordion__element ${this.state.accordionActive ? 'active' : ''}`}>
              <div className="accordion__title">
                <div className="accordion__clickable" onClick={this.onAccordionClick}>
                  <h2 className="headline headline--standard">{t('user.settings.contact.all_contacts')}
                    <span
                      className="settings__contacts-count"
                    >{this.state.contacts.length}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="accordion__content">
                <div className="table table--settings table--contacts">
                  <ul className="table__head">
                    <li>
                      <label>{t('user.settings.contact.full_name')}</label>
                    </li>
                    <li>
                      <label>{t('user.settings.contact.email')}</label>
                    </li>
                    <li>
                      <label>{t('user.settings.contact.category')}</label>
                    </li>
                    <li>
                      <label>{t('user.settings.contact.phone_number')}</label>
                    </li>
                    <li>
                      <label>{t('user.settings.contact.company_name')}</label>
                    </li>
                    <li>
                      <label>{t('user.settings.contact.commentary')}</label>
                    </li>
                  </ul>
                  <ContactsList>
                    {this.state.contacts.map((contactsItem) => (
                      <Contact
                        key={contactsItem.id}
                        t={t}
                        id={contactsItem.id}
                        name={contactsItem.name}
                        surname={contactsItem.surname}
                        email={contactsItem.email}
                        phoneNumber={contactsItem.phoneNumber}
                        companyName={contactsItem.companyName}
                        commentary={contactsItem.commentary}
                        category={contactsItem.category}
                        showStatusMessage={this.props.showStatusMessage}
                        contacts={this.state.contacts}
                        fetchContacts={this.fetchContacts}
                        onContactDeleteClick={() => this.onContactDeleteClick(contactsItem.id)}
                        onContactEditClick={() => this.onContactEditClick(contactsItem.id)}
                      />
                    ))}
                  </ContactsList>
                </div>
              </div>
            </div>
          </div>
          <ContactCategoriesList>
            {this.state.categories.map((category, index) => (
              <ContactCategory
                key={category.id}
                t={t}
                fetchContacts={this.fetchContacts}
                categoryName={category.name}
                totalContacts={category.totalContacts}
                onContactCategoryDeleteClick={this.onContactCategoryDeleteClick.bind(this, index)}
                onContactCategoryEditClick={this.onContactCategoryEditClick.bind(this, index)}
                onContactDeleteClick={this.onContactDeleteClick}
                onContactEditClick={this.onContactEditClick}
                contacts={this.state.contacts}
                onAssignContactClick={this.onAssignContactClick.bind(this, index)}
              />
            ))}
          </ContactCategoriesList>
        </div>
        <Pagination
          t={t}
          offset={this.state.paginationCategories.offset}
          limit={this.state.paginationCategories.limit}
          total={this.state.paginationCategories.total}
          onNextClick={this.onPaginationNextClick}
          onPreviousClick={this.onPaginationPreviousClick}
        />
        <ModalContact
          active={this.state.modalContact.active}
          t={t}
          title={this.state.modalContact.contactId ? t('user.settings.contact.edit_contact') : t('user.settings.contact.add_new_contact')}
          edit={!!this.state.modalContact.contactId}
          name={this.state.modalContact.contactId ? contact.name : ''}
          surname={this.state.modalContact.contactId ? contact.surname : ''}
          email={this.state.modalContact.contactId ? contact.email : ''}
          phoneNumber={this.state.modalContact.contactId ? contact.phoneNumber : ''}
          companyName={this.state.modalContact.contactId ? contact.companyName : ''}
          commentary={this.state.modalContact.contactId ? contact.commentary : ''}
          category={this.state.modalContact.contactId ? contact.category : ''}
          addContact={this.addContact}
          onCloseClick={() => this.setState({ modalContact: { active: false, contactId: null } })}
          categories={this.state.allCategories}
          editContact={this.editContact}
        />
        <ModalCategory
          active={this.state.modalCategory.active}
          t={t}
          title={this.state.modalCategory.contactCategoryIndex !== undefined ? t('user.settings.contact.edit_category') : t('user.settings.contact.add_new_category')}
          edit={this.state.modalCategory.contactCategoryIndex !== undefined}
          name={this.state.modalCategory.contactCategoryIndex !== undefined ? (this.getCategory()).name : ''}
          editCategory={this.editCategory}
          addCategory={this.addCategory}
          onCloseClick={() => this.setState({ modalCategory: { active: false, contactCategoryIndex: undefined } })}
        />
        <ModalRemove
          active={this.state.modalRemoveContactCategory.active}
          title={t('user.settings.contact.remove_contact_category')}
          message={t('user.settings.contact.are_you_sure_you_want_to_remove_category')}
          onCloseClick={() => this.setState({ modalRemoveContactCategory: { active: false } })}
          onRemoveClick={this.onModalRemoveContactCategoryClick}
        />
        <ModalRemove
          active={this.state.modalRemoveContact.active}
          title={t('user.settings.contact.remove_contact')}
          message={t('user.settings.contact.are_you_sure_you_want_to_remove')}
          onCloseClick={() => this.setState({ modalRemoveContact: { active: false } })}
          onRemoveClick={this.onModalRemoveContactClick}
        />
        <ModalAssignContacts
          active={this.state.modalAssignContact.active}
          title={t('user.settings.contact.assign_contacts')}
          t={t}
          assignContact={this.assignContact}
          onContactSelect={this.onContactSelect}
          fetchContacts={this.fetchContacts}
          contacts={this.state.contacts}
          onSearchChange={this.onModalAssignContactsSearchChange}
          searchValue={this.state.modalAssignContactsSearchValue}
          contactCategoryName={this.state.modalAssignContact.contactCategoryName}
          onCloseClick={() => this.setState({ modalAssignContact: { active: false }, modalAssignContactsSearchValue: '' })}
          assignContactsToCategory={this.assignContactsToCategory}
          showStatusMessage={this.props.showStatusMessage}
        />
      </React.Fragment>
    )
  }
}

Contacts.defaultProps = {
  wait: 500,
}

Contacts.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  wait: PropTypes.number,
}
