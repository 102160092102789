import {
  ACTION_TOGGLE_SIDEBAR_SHRINK,
} from '~/js/actions'

export default (state = { isShrinked: localStorage.getItem('shrink') === '1' }, action) => {
  switch (action.type) {
  case ACTION_TOGGLE_SIDEBAR_SHRINK:
    localStorage.setItem('shrink', state.isShrinked === true ? '0' : '1')
    return Object.assign({}, state, {
      isShrinked: !state.isShrinked
    })

  default:
    return state
  }
}