import React from 'react'
import { withTranslation } from 'react-i18next'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as workspaceService from '~/js/services/user/workspace'
import { getUserNamespace } from '../../../../services/user/serviceHelpers'
import ModalWorkspace from '../../../../components/ModalWorkspace/ModalWorkspace'
import { generatePath } from '../../../router'
import { ROUTE_NAMES } from '../../../config'
import { formatWorkspaces } from '~/js/utils/user'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class SubscriptionWorkspace extends React.Component {
  constructor(props) {
    super(props)
    const { cart } = props
    const product = typeof cart[0] !== 'undefined' ? cart[0].product : null

    this.state = {
      redirectTo: null,
      workspaces: [],
      modalCreateNewWorkspace: {
        active: false
      },
      product
    }

    this.addWorkspace = this.addWorkspace.bind(this)
    this.fetchWorkspaces()
  }

  fetchWorkspaces() {
    this.state.product && this.state.product.name === 'professional' ?
      workspaceService
        .workspacesForProfessional(this.props.user)
        .then(({ data }) => {
          this.setState({ workspaces: data.map(obj => ({ ...obj, id: obj.isBusiness ? obj.id : null, active: (this.props.user.workspaceId === null && !obj.isBusiness) || this.props.user.workspaceId === obj.id })) })
        })
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      : workspaceService
        .getWorkspaces(this.props.user)
        .then(data => this.setWorkspaces(data))
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setWorkspaces(data) {
    const { data: workspaces } = data
    const { user } = this.props

    this.setState({
      workspaces: formatWorkspaces(user, workspaces),
    })
  }

  onWorkspaceClick(workspace) {
    new Promise(() => {
      if (!workspace.active) {
        return this.props.switchWorkspace(workspace.id)
      }
    })
      .then(
        this.props.history.push({
          pathname: `/${getUserNamespace()}/pricing/checkout`,
          search: `?workspaceId=${workspace.id}`,
        })
      )
  }

  addWorkspace([name, registrationCode, address, email]) {
    return workspaceService
      .addWorkspace({
        name: name,
        registrationCode: registrationCode,
        address: address,
        email: email
      })
      .then(data => {
        this.props.switchWorkspace(data.workspaceId)
        this.props.history.push(generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCreateNewWorkspace: { active: false } })
      })
  }

  onAddNewWorkspaceClick() {
    this.setState({
      modalCreateNewWorkspace: {
        active: true
      }
    })
  }

  render() {
    const { t } = this.props
    const { workspaces, product } = this.state

    return (
      <React.Fragment>
        <div className="page-container--full-height" id="page-container">
          <section id="login">
            <div className="grid">
              <Link to={generatePath(ROUTE_NAMES.USER_DASHBOARD)}>
                <img src={markIdLogo} alt="Mark ID" className="login__logo"/>
              </Link>
              <div className="login__container">
                <h5 className="ta-center">{t('user.pricing.choose_workspace_to_assign_subscription')}</h5>
                <div className="login__user">
                  <ul>
                    {workspaces.map((workspace, index) => {
                      if (workspace.id !== null || product.name !== 'teams') {
                        return (
                          <li key={`workspace-${index}`}>
                            <a
                              className='sidebar__user'
                              href="#"
                              onClick={e => { e.preventDefault(); return this.onWorkspaceClick(workspace) }}>
                              <div className="sidebar__user-logo">
                                <img src={workspace.isBusiness ? Company : User} alt={workspace.isBusiness ? 'Company' : 'User'} />
                              </div>
                              <div className="sidebar__user-info">
                                <span>{workspace.name}</span>
                              </div>
                            </a>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </div>
                <div className="center-element">
                  <button className="btn btn--primary ta-center" onClick={() => this.onAddNewWorkspaceClick()}>
                    {t('user.settings.workspace.plus_add_new_workspace')}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ModalWorkspace
          active={this.state.modalCreateNewWorkspace.active}
          title={t('user.settings.workspace.create_new_workspace')}
          addWorkspace={this.addWorkspace}
          onCloseClick={() => this.setState({ modalCreateNewWorkspace: { active: false } })} />
      </React.Fragment>
    )
  }
}

SubscriptionWorkspace.propTypes = {
  user: PropTypes.object,
  showStatusMessage: PropTypes.func,
  switchWorkspace: PropTypes.func,
  cart: PropTypes.array,
  product: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object,
}

export default withTranslation()(SubscriptionWorkspace)
