import React from 'react'
import { formatDateTimeNoSeconds } from '~/js/utils/date'
import {ControlButton} from '../../ControlButtons'
import {
  DOCUMENT_COMMENT_ACCESS_PRIVATE
} from '../../../models/Document'

export default class DocumentComment extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <li>
        <div className="info-details__label--comments">
          <b>{this.props.authorName}</b><br/>
          {formatDateTimeNoSeconds(this.props.date)} <br/>
          {this.props.access === DOCUMENT_COMMENT_ACCESS_PRIVATE ?
            <span><b>{t('user.document.private_comment')}</b></span> : null}
        </div>
        <div className="info-details__info--comments">
          <p>{this.props.commentaryText}</p>
        </div>
        {this.props.canRemove ?
          <ControlButton
            type="delete"
            title={t('common.remove')}
            showLabel={false}
            showTooltip={true}
            onClick={this.props.onRemoveClick}/> : null
        }
      </li>
    )
  }
}