import { connect } from 'react-redux'
import {
  addStatusMessageWithTimeout,
  getSessionStatus
} from '~/js/actions'
import NeopayUserCallbackRoute from '~/js/routes/app/NeopayUserCallback'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessageWithTimeout(type, message)),
  getSessionStatus: () => dispatch(getSessionStatus())
})

export default connect(
  null,
  mapDispatchToProps
)(NeopayUserCallbackRoute)