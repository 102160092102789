import React, { useState } from 'react'
import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'

export default function CreditCardSerialNumberInput({ name }) {
  const [ccNumber, setCcNumber] = useState('')
  const { handleChange } = useFormikContext()

  const formatAndSetCcNumber = e => {
    const inputVal = e.target.value.replace(/ /g, '')
    let inputNumbersOnly = inputVal.replace(/\D/g, '')

    if (inputNumbersOnly.length > 20) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 20)
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g)

    let spacedNumber = ''
    if (splits) {
      spacedNumber = splits.join(' ')
    }
    setCcNumber(spacedNumber)
  }

  const onChange = e => {
    handleChange(e)
    formatAndSetCcNumber(e)
  }

  return (
    <Field className="form-group__input" type="text" name={name} value={ccNumber} onChange={onChange} onBlur={onChange} />
  )
}

CreditCardSerialNumberInput.propTypes = {
  name: PropTypes.string,
}
