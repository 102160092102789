import React from 'react'
import Modal from '../Modal'
import {Link} from 'react-router-dom'
import {generatePath} from '../../routes/router'
import {ROUTE_NAMES} from '../../routes/config'

export default class ModalMaximumNumberOfUsersReached extends React.Component {
  render() {
    const { t } = this.props

    return (
      <Modal
        className="popup--session"
        active={this.props.active}
        {...this.props}>
        <div className="ta-center">
          <h3 className="standard-title">{t('user.settings.company_user.maximum_number_of_users_reached')}</h3>
          <br/>
          <div className="center-element">
            <button className="btn btn--secondary" onClick={this.props.onAddAdditionalUsersClick}>
              {t('user.settings.company_user.click_here_to_add_more_users')}
            </button>
          </div>
        </div>
        <br/>
        <div className="center-element">
          <button
            className="btn btn--grey"
            onClick={this.props.onCloseClick}>
            {t('common.close')}
          </button>
        </div>
      </Modal>
    )
  }
}