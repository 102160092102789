import React from 'react'
import * as Yup from 'yup'
import Modal from '../Modal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Select from '~/js/components/Select'
import PropTypes from 'prop-types'
import withEmailValidation from '~/js/hoc/withEmailValidation'

class ModalContact extends React.Component {
  onContactSubmit = (values) => {
    this.props.addContact([
      values.name,
      values.surname,
      values.email,
      values.phoneNumber,
      values.companyName,
      values.commentary,
      values.category
    ])
  }

  onContactEditSubmit = (values) => {
    this.props.editContact([
      values.name,
      values.surname,
      values.email,
      values.phoneNumber,
      values.companyName,
      values.commentary,
      values.category
    ])
  }

  render() {
    const { t, isValidEmail } = this.props
    const category = this.props.categories.find(currentCategory => currentCategory.name === this.props.category)

    const initialValues = {
      name: this.props.name,
      surname: this.props.surname,
      email: this.props.email,
      phoneNumber: this.props.phoneNumber,
      companyName: this.props.companyName,
      commentary: this.props.commentary,
      category: category ? category.id : null }
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required(t('validation.field_is_required')),
      surname: Yup.string()
        .required(t('validation.field_is_required')),
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail)
    })

    return (
      <Modal
        size="small"
        {...this.props}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.props.edit ? this.onContactEditSubmit : this.onContactSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.name && props.touched.name ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.name')}</label>
                <Field className="form-group__input" type="text" name="name" />
                <ErrorMessage className="form-group__error" name="name" component="span" />
              </div>

              <div className={'form-group' + (props.errors.surname && props.touched.surname ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.surname')}</label>
                <Field className="form-group__input" type="text" name="surname" />
                <ErrorMessage className="form-group__error" name="surname" component="span" />
              </div>

              <div className={'form-group' + (props.errors.email && props.touched.email ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.email')}</label>
                <Field className="form-group__input" type="text" name="email" />
                <ErrorMessage className="form-group__error" name="email" component="span" />
              </div>

              <div className={'form-group' + (props.errors.phoneNumber && props.touched.phoneNumber ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.phone_number')}</label>
                <Field className="form-group__input" type="text" name="phoneNumber" />
                <ErrorMessage className="form-group__error" name="phoneNumber" component="span" />
              </div>

              <div className={'form-group' + (props.errors.companyName && props.touched.companyName ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.company_name')}</label>
                <Field className="form-group__input" type="text" name="companyName" />
                <ErrorMessage className="form-group__error" name="companyName" component="span" />
              </div>

              <div className={'form-group' + (props.errors.commentary && props.touched.commentary ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.commentary')}</label>
                <Field className="form-group__input" type="text" name="commentary" />
                <ErrorMessage className="form-group__error" name="commentary" component="span" />
              </div>

              <div className={'form-group' + (props.errors.category && props.touched.category ? ' form-group--error' : '')}>
                <label>{t('user.settings.contact.category')}</label>
                <Select
                  name="categories"
                  menuPlacement='top'
                  defaultValue={category ? { label: category.name, value: category } : null}
                  options={this.props.categories.map(categoryItem => ({ value: categoryItem, label: categoryItem.name }))}
                  placeholder={t('common.select')}
                  onChange={option => props.setFieldValue('category', option.value.id)}
                  onBlur={props.handleBlur}
                />
                <ErrorMessage className="form-group__error" name="category" component="span" />
              </div>

              <button
                type="submit"
                className="btn btn--primary ta-center mt-2"
                style={{ margin: '32px auto 0 auto' }}
                disabled={props.isSubmitting}
              >
                {this.props.edit ? t('user.settings.contact.edit_contact') : t('user.settings.contact.add_contact')}
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

ModalContact.propTypes = {
  addContact: PropTypes.func,
  editContact: PropTypes.func,
  t: PropTypes.func,
  categories: PropTypes.array,
  category: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  companyName: PropTypes.string,
  commentary: PropTypes.string,
  edit: PropTypes.bool,
  isValidEmail: PropTypes.func,
}

export default (withEmailValidation(ModalContact))