import React from 'react'
import ReactDatetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import 'react-datetime/dist/react-datetime.cjs'
import moment from 'moment'
import i18n from '../../i18n'
import PropTypes from 'prop-types'

export default class DateTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      locale: i18n.language
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locale !== i18n.language) {
      this.setState({ locale: i18n.language })
    }
  }

  render() {
    const yesterday = moment().subtract(1, 'day')
    const disablePastDates = current => {
      if (!this.props.showPastDate) {
        return current.isAfter(yesterday)
      }

      return true
    }

    return (
      <ReactDatetime
        locale={this.state.locale}
        isValidDate={disablePastDates}
        {...this.props}
      />
    )
  }
}

DateTime.propTypes = {
  showPastDate: PropTypes.bool,
}