import React from 'react'
import PropTypes from 'prop-types'
import History from '~/js/components/History'

export default class UsbDocumentsHistory extends React.Component {
  render() {
    const { t, document } = this.props

    return (
      <div className="usb-document-history">
        {document.historyRecords &&
          <History
            t={t}
            historyRecords={document.historyRecords}
          />
        }
      </div>
    )
  }
}

UsbDocumentsHistory.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
}