import React from 'react'
import { useTranslation } from 'react-i18next'
import Login from '~/js/containers/LoginRoute'
import LtID from '~/img/lt-id.png'
import * as userService from '~/js/services/user/user'

function LtIdRegister(props) {
  const { t } = useTranslation()

  return <Login
    title={t('user.login.register_ltid')}
    imgSrc={LtID}
    btnSubmitText={t('common.register')}
    registerMode="ltId"
    authType={userService.AUTH_TYPE_LTID}
    {...props}
  />
}

export default LtIdRegister