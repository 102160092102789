import React from 'react'
import Dropzone from 'dropzone'
import { withTranslation } from 'react-i18next'
import uploadDocuments from '~/img/illustration/upload-documents.svg'
import uploadDocumentsValidation from '~/img/illustration/empty-search.svg'
import { Route, withRouter } from 'react-router-dom'
import { SM_TYPE_ERROR } from '../StatusMessages'
import { getUserWorkspace } from '~/js/routes/router'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import { DOCUMENT_UPLOAD_TYPE_UPLOAD, DOCUMENT_UPLOAD_TYPE_VALIDATION } from '~/js/models/Document'
import PropTypes from 'prop-types'
import * as documentService from '~/js/services/user/document.js'
import { getMarkSignAppHost } from '../../utils/host'

const supportedFileTypes = ['PDF', 'ADOC', 'ASICE', 'BDOC', 'XML']
const PARALLEL_UPLOADS = 5
const MAX_FILES = 10000

const FILE_TYPES = {
  pdf: 'PDF',
  asice: 'ASICE',
  adoc: {
    name: 'ADoc',
    categories: {
      cedoc: { name: 'document_upload.adoc.cedoc', isPersonal: true },
      bedoc: { name: 'document_upload.adoc.bedoc', isPersonal: false },
      gedoc: { name: 'document_upload.adoc.gedoc', isPersonal: false },
      ggedoc: { name: 'document_upload.adoc.ggedoc', isPersonal: false },
    }
  },
  bdoc: 'BDoc',
  xml: 'XML',
}

const DEFAULT_FILE_TYPE = Object.keys(FILE_TYPES)[0]
const DEFAULT_SUB_FILE_TYPE = null

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props)

    this.dropzone = this.dropzone.bind(this)
    this.onFileTypeChange = this.onFileTypeChange.bind(this)
    this.onFileTypeCategoryChange = this.onFileTypeCategoryChange.bind(this)
    this.onFileTypeCategoryBackButtonClick = this.onFileTypeCategoryBackButtonClick.bind(this)
    this.onSending = this.onSending.bind(this)
    this.selectedFileTypeIn = this.selectedFileTypeIn.bind(this)
    this.setSelectedFiles = this.setSelectedFiles.bind(this)
    this.onAdocFileContentsBackButtonClick = this.onAdocFileContentsBackButtonClick.bind(this)
    this.onAdocFileContentsMainButtonClick = this.onAdocFileContentsMainButtonClick.bind(this)
    this.onUploadAdocDocument = this.onUploadAdocDocument.bind(this)
    this.isUserBusinessPerson = this.isUserBusinessPerson.bind(this)
    this.enableUploadAtOnce = this.enableUploadAtOnce.bind(this)
    this.adjustStatusCount = this.adjustStatusCount.bind(this)
    this.resetStatusCount = this.resetStatusCount.bind(this)
    this.getStatusCount = this.getStatusCount.bind(this)
    this.seafDropzone = null

    this.state = {
      fileType: DEFAULT_FILE_TYPE,
      fileTypeCategory: DEFAULT_SUB_FILE_TYPE,
      showFileTypeCategory: false,
      selectedFiles: [],
      showAdocContentFiles: false,
      isUploading: false,
      statusCount: {
        total: 0,
        success: 0,
        error: 0,
      },
    }
  }

  resetStatusCount() {
    this.setState(prevState => {
      return {
        ...prevState,
        statusCount: {
          ...prevState.statusCount,
          total: 0,
          success: 0,
          error: 0,
        }
      }
    })
  }

  adjustStatusCount({ total, success, error }) {
    this.setState(prevState => {
      return {
        ...prevState,
        statusCount: {
          ...prevState.statusCount,
          total: prevState.statusCount.total + (total || 0),
          success: prevState.statusCount.success + (success || 0),
          error: prevState.statusCount.error + (error || 0),
        }
      }
    })
  }

  getStatusCount(status) {
    return (status && this.state.statusCount[status]) || this.state.statusCount
  }

  dropzone(params) {
    const { t, showStatusMessage, onUploadSuccess, maxFileCount, onMaxFileCountExceeded, uploadType, maxFileSizeLimit, multipleFiles, onBatchUploadSuccess } = this.props
    const selectedFileTypeIn = this.selectedFileTypeIn
    const setSelectedFiles = this.setSelectedFiles
    const adjustStatusCount = this.adjustStatusCount
    const resetStatusCount = this.resetStatusCount
    const getStatusCount = this.getStatusCount

    let uploadedFilesId = []
    let currentQueuedFiles = []
    let totalQueuedFiles = 0
    let currentInBatchRequestCount = 0
    let currentInBatchResponseCount = 0
    let filesizeExceededCount = 0
    Dropzone.autoDiscover = false

    const firstState = document.querySelector('.dz-message'),
      secondState = document.querySelector('#dropzone-previews'),
      thirdState = document.querySelector('.upload-status--success'),
      previewNode = document.querySelector('#dropzone-dummy-uploadItemTemplate')

    const gotoInitialViewState = () => {
      firstState.style.display = 'block'
      secondState.style.display = 'none'
      thirdState.style.display = 'none'
    }

    const showUnifiedMessageOnError = () => {
      const statusCount = getStatusCount()
      if (statusCount.total > 1 && statusCount.error > 0) {
        const message = statusCount.total === statusCount.error
          ? t('user.document.no_file_uploaded')
          : t('user.document.some_files_not_uploaded')
        showStatusMessage(SM_TYPE_ERROR, message)
        gotoInitialViewState()
        return true
      }

      resetStatusCount()
      return false
    }

    secondState.style.display = 'none'

    const seafDropzone = new Dropzone('#upload-content', {
      url: `https://${getMarkSignAppHost()}/api/${getUserWorkspace()}/documents/upload?type=${uploadType}`,
      withCredentials: true,
      maxFilesize: maxFileSizeLimit.fileLimitInMB,
      previewTemplate: previewNode.innerHTML,
      autoQueue: true,
      autoProcessQueue: false,
      previewsContainer: secondState,
      parallelUploads: multipleFiles ? PARALLEL_UPLOADS : 1,
      uploadMultiple: uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD,
      timeout: null,
      init() {
        if (this.options.maxFiles === 1) {
          this.hiddenFileInput.removeAttribute('multiple')
        }

        this.on('maxfilesexceeded', function() {
          this.removeAllFiles(true)
          gotoInitialViewState()
        })
      },
      ...params
    })

    seafDropzone.on('totaluploadprogress', function(progress) {
      const $circle = document.querySelector('#loader #bar')
      if ($circle === undefined || $circle === null) {
        return
      }

      let val = parseInt(progress)

      if (isNaN(val)) {
        return
      }

      const r = $circle.getAttribute('r')
      const c = Math.PI * (r * 2)

      val = Math.min(val, 100)
      val = Math.max(val, 0)

      $circle.style.strokeDashoffset = ((100 - val) / 100) * c
    })

    seafDropzone.on('drop', function() {
      firstState.style.display = 'none'
      thirdState.style.display = 'none'
    })

    seafDropzone.on('addedfile', function() {
      firstState.style.display = 'none'
      thirdState.style.display = 'none'
    })

    seafDropzone.on('addedfiles', function(files) {
      firstState.style.display = 'none'
      secondState.style.display = 'block'
      thirdState.style.display = 'none'

      // Handling of maximum files allowed is being done in here instead of using the option maxFiles, cause in current configuration,
      // the dropzone just removes the first maximum files allowed amount files, and then upload the remaining
      // so, maximum files allowed feature is being handled here
      let maxFiles = null
      if (maxFileCount > 0) {
        maxFiles = maxFileCount
      } else if ((selectedFileTypeIn(['pdf', 'xml']) && uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD) || uploadType === DOCUMENT_UPLOAD_TYPE_VALIDATION) {
        maxFiles = !selectedFileTypeIn(['pdf', 'xml']) ? null : (multipleFiles ? MAX_FILES : 1)
      }

      if (filesizeExceededCount === files.length || (filesizeExceededCount > 0 && this.options.uploadMultiple)) {
        gotoInitialViewState()
        resetStatusCount()
        this.removeAllFiles()
        showStatusMessage(SM_TYPE_ERROR, t(
          'user.document.upload.file_sizes_exceed_limit', { size: maxFileSizeLimit.fileLimitInMB }
        ))
      } else if (maxFiles !== null && files.length > maxFiles) {
        this.removeAllFiles(true)
        gotoInitialViewState()
        maxFileCount && typeof onMaxFileCountExceeded === 'function' ? onMaxFileCountExceeded() : showStatusMessage(SM_TYPE_ERROR, t('user.document.upload_file_count_exceeds'))
      } else {
        adjustStatusCount({ total: files.length })
        setSelectedFiles(files)
        if (selectedFileTypeIn(['adoc']) && files.length > 1) {
          gotoInitialViewState()
          this.disable()
        } else {
          if (this.options.uploadMultiple) {
            // When uploadMultiple is enabled (which enables the feature of sending all files in a single request),
            // the processQueue() function takes only first parallelUploads amount of file and processes those, so, if the total
            // file count exceeds parallelUploads amount, then the queue never gets emptied, so the process does not proceed further
            // and, "complete" event isn't fired, hence the process just remain unfinished
            // have a look at processQueue() function in api/node_modules/dropzone/src/dropzone.js
            this.processFiles(this.getQueuedFiles())
          } else {
            currentQueuedFiles = this.getQueuedFiles()
            totalQueuedFiles = currentQueuedFiles.length
            this.uploadInBatchFromQueuedFiles()
          }
        }
      }
    })

    seafDropzone.on('complete', function(file) {
      // this one is for keeping track of multiple document ids with uploadMultiple false
      // even if uploadMultiple is true, this method is called for each uploaded file
      if (!this.options.uploadMultiple && file.accepted) {
        const response = JSON.parse(file.xhr.response)
        response.uuid && (uploadedFilesId.includes(response.uuid) || uploadedFilesId.push(response.uuid))
        currentInBatchResponseCount += 1
        totalQueuedFiles -= 1
        // If totalQueuedFiles === 0, then this condition will be fulfilled by the listener of 'queuecomplete' event
        if (uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD && currentInBatchResponseCount === currentInBatchRequestCount && totalQueuedFiles > 0) {
          if (typeof onBatchUploadSuccess === 'function' && file.status === 'success') {
            const toBeSavedFilesId = uploadedFilesId
            uploadedFilesId = []
            onBatchUploadSuccess(toBeSavedFilesId)
              .then(() => this.uploadInBatchFromQueuedFiles())
          } else {
            this.uploadInBatchFromQueuedFiles()
          }
        } else if (uploadType === DOCUMENT_UPLOAD_TYPE_VALIDATION) {
          this.uploadInBatchFromQueuedFiles()
        }
      }
      localStorage.setItem('validation_files', JSON.stringify(uploadedFilesId))
    })

    seafDropzone.on('success', function() {
      adjustStatusCount({ success: 1 })
    })

    seafDropzone.on('successmultiple', function(files, response) {
      // this one is for keeping track of multiple document ids with uploadMultiple true
      // main need is of the second param response.
      // In the files variable the response are with each file even though responses are same
      response.uuid.forEach(uuid => {
        uploadedFilesId.includes(uuid) || uploadedFilesId.push(uuid)
      })
      localStorage.setItem('validation_files', JSON.stringify(uploadedFilesId))
    })

    seafDropzone.uploadInBatchFromQueuedFiles = function() {
      const batchFiles = currentQueuedFiles.splice(0, this.options.parallelUploads)
      currentInBatchRequestCount = batchFiles.length
      currentInBatchResponseCount = 0
      batchFiles.forEach(file => this.processFile(file))
    }

    function onFileError(file) {
      let resolved = false
      const fileName = file === null ? '' : file.name.toUpperCase()

      if (supportedFileTypes.indexOf(fileName.split('.').pop()) === -1) {
        showStatusMessage(SM_TYPE_ERROR, t('user.document.invalid_file_type'))
        resolved = true
      } else if (file.size > maxFileSizeLimit.fileLimitInByte) {
        showStatusMessage(SM_TYPE_ERROR, t('user.document.file_size_is_too_big', { size: maxFileSizeLimit.fileLimitInMB }))
        resolved = true
      }

      return resolved
    }

    seafDropzone.on('error', function(file, response) {
      if ((response === 'Upload canceled.' && !selectedFileTypeIn(['adoc'])) || response !== 'Upload canceled.') {
        adjustStatusCount({ error: 1 })
      }

      this.removeFile(file)
      if (file.size > parseFloat(maxFileSizeLimit.fileLimitInMB) * 1024 * 1024) {
        filesizeExceededCount++
        return
      }

      if (this.options.uploadMultiple) {
        return
      }

      this.files = this.files.filter(f => f.status !== 'error')

      if (getStatusCount('total') === 1) {
        gotoInitialViewState()

        if (onFileError(file)) {
          return
        }

        if (this.files.length > MAX_FILES) {
          showStatusMessage(SM_TYPE_ERROR, t('user.document.too_many_files'))
        } else if (typeof response === 'string') {
          showStatusMessage(SM_TYPE_ERROR, response)
        } else if (response && typeof response.message !== 'undefined') {
          showStatusMessage(SM_TYPE_ERROR, response.message)
        }
      }
    })

    seafDropzone.on('errormultiple', function(files, message) {
      // This event is fired when options.uploadMultiple is true and uploading request gets error response
      gotoInitialViewState()

      const isResolved = files.reduce(
        (acc, f) => filesizeExceededCount > 0 || (acc && onFileError(f)),
        true
      )

      if (isResolved) {
        return
      }

      if (typeof message === 'object' && message.message) {
        showStatusMessage(SM_TYPE_ERROR, message.message)
      } else if (typeof message === 'string') {
        showStatusMessage(SM_TYPE_ERROR, message)
      }
    })

    seafDropzone.on('queuecomplete', function() {
      // queuecomplete event is fired when the total queue gets completed, regardless of whether options.uploadMultiple
      // is true or false
      filesizeExceededCount = 0
      const callback = () => {
        firstState.style.display = 'none'
        secondState.style.display = 'none'
        thirdState.style.display = 'block'
      }

      if (uploadedFilesId.length > 0) {
        onUploadSuccess(uploadedFilesId.flatMap(x => x), callback, getStatusCount('success') > 1)
      }

      // Showing unified message for multiple files uploaded in multiple consecutive requests
      showUnifiedMessageOnError() && this.removeAllFiles()
    })
    this.props.disableComponent && seafDropzone.disable()

    // this event is fired for each file
    seafDropzone.on('sending', this.onSending)

    return seafDropzone
  }

  isUserBusinessPerson() {
    return !!(this.props.user.companyName && this.props.user.workspaceId)
  }

  selectedFileTypeIn(types) {
    return types.includes(this.state.fileType)
  }

  setSelectedFiles(files) {
    this.setState({ selectedFiles: [...files].reduce((accumulator, file, index) => {
      accumulator.push({
        main: index === 0,
        file
      })
      return accumulator
    }, []), showAdocContentFiles: this.selectedFileTypeIn(['adoc']) && files.length > 1 && this.state.statusCount.error === 0 })
  }

  onAdocFileContentsBackButtonClick() {
    this.setState({ showAdocContentFiles: false }, () => this.resetStatusCount())
    this.seafDropzone.enable()
  }

  onAdocFileContentsMainButtonClick(fileIndex) {
    return () => {
      this.setState(prevState => ({
        selectedFiles: prevState.selectedFiles.map((selectedFile, index) => {
          selectedFile.main = index === fileIndex
          return selectedFile
        })
      }))
    }
  }

  onUploadAdocDocument(e) {
    e.preventDefault()
    const uploadedFilesId = []
    const { uploadType, onUploadSuccess, showStatusMessage } = this.props
    const { fileType, fileTypeCategory } = this.state
    let mainFile = null
    const attachments = [], appendices = []
    this.state.selectedFiles.map((selectedFile) => {
      if (selectedFile.main) {
        mainFile = selectedFile.file
      } else {
        appendices.push(selectedFile.file)
      }
    })
    const params = {
      uploadType, fileType, fileTypeCategory
    }
    this.setState({ isUploading: true })
    documentService
      .uploadAdocDocumentContents(mainFile, attachments, appendices, params)
      .then((data) => {
        if (data.uuid) {
          data.uuid.forEach(uuid => {
            uploadedFilesId.includes(uuid) || uploadedFilesId.push(uuid)
          })
          uploadedFilesId && localStorage.setItem('validation_files', JSON.stringify(uploadedFilesId))
          uploadedFilesId && onUploadSuccess(uploadedFilesId[0])
        } else if (data.errors) {
          data.errors.forEach(error => {
            showStatusMessage(SM_TYPE_ERROR, error)
          })
        }
      })
      .catch(error => {
        showStatusMessage(SM_TYPE_ERROR, error.message)
      })
      .finally(() => {
        this.setState({ isUploading: false })
      })
  }

  onSending(file, xhr, formData) {
    // this event is fired for each file
    formData.has('fileType') || formData.append('fileType', this.state.fileType)
    formData.has('fileTypeCategory') || formData.append('fileTypeCategory', this.state.fileTypeCategory)
    formData.append('signaturesPlacingPosition', this.props.user.defaultSignaturePosition)
  }

  enableUploadAtOnce() {
    return this.props.uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD && !this.selectedFileTypeIn(['pdf', 'xml'])
  }

  componentDidMount() {
    this.seafDropzone = this.dropzone({
      uploadMultiple: this.enableUploadAtOnce()
    })
    this.isContentShrink()
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink' && !document.querySelector('main').classList.contains('shrink')) {
      document.querySelector('main').classList.add('shrink')
    }
  }

  onFileTypeChange(id) {
    return (e) => {
      e.stopPropagation()
      this.setState(prevState => {
        let fileTypeCategory = null
        if (FILE_TYPES[id].categories) {
          if (FILE_TYPES[id].categories[prevState.fileTypeCategory]) {
            fileTypeCategory = prevState.fileTypeCategory
          } else {
            const isUserBusinessPerson = this.isUserBusinessPerson()
            const found = Object.entries(FILE_TYPES[id].categories).find(elm => (elm[1].isPersonal && !isUserBusinessPerson) || (!elm[1].isPersonal && isUserBusinessPerson))
            fileTypeCategory = found ? found[0] : Object.entries(FILE_TYPES[id].categories)[0] || null
          }
        }
        return {
          fileType: id,
          showFileTypeCategory: !!FILE_TYPES[id].categories,
          fileTypeCategory: fileTypeCategory
        }
      }, () => {
        this.seafDropzone && this.seafDropzone.destroy()
        this.seafDropzone = this.dropzone({
          uploadMultiple: this.enableUploadAtOnce()
        })
      })
    }
  }

  onFileTypeCategoryChange(subId) {
    return (e) => {
      e.stopPropagation()
      const isPersonal = FILE_TYPES[this.state.fileType].categories[subId].isPersonal
      if ((isPersonal && !this.isUserBusinessPerson()) || (!isPersonal && this.isUserBusinessPerson())) {
        this.setState({ showFileTypeCategory: false, fileTypeCategory: subId })
      }
    }
  }

  onFileTypeCategoryBackButtonClick() {
    this.setState({ showFileTypeCategory: false })
  }

  render() {
    const { t, uploadType } = this.props
    const { showAdocContentFiles, showFileTypeCategory, fileType, fileTypeCategory, selectedFiles, isUploading } = this.state

    let mainClassName = 'no-pad-bottom'

    if (this.props.documentsList) {
      mainClassName = 'documents-list'
    }

    return (
      <main className={mainClassName}>
        {!this.props.documentsList && (
          <div id='header'>
            <div className="grid">
              <h1 className="page-title">{t('upload_document')}</h1>
            </div>
          </div>
        )}
        <section id="upload">
          <div className="grid grid--flex">
            <form className="upload__content ta-center dropzone needsclick" action="" id="upload-content">
              <div className="upload__box dz-message needsclick">
                <Route exact path={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD)}>
                  <img src={uploadDocuments} alt="uploadDocuments" />
                </Route>
                <Route exact path={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE)}>
                  <img src={uploadDocumentsValidation} alt="uploadDocumentsValidation" />
                </Route>
                {!showAdocContentFiles &&
                  <div className="box__input">
                    <div className="upload__filetypes">
                      {uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD && showFileTypeCategory !== true &&
                        <>
                          <div className="upload__filetypes-header">{t('document_upload.select_container_format')}</div>
                          <div className="upload__filetypes-container" htmlFor="fileType">
                            {Object.entries(FILE_TYPES).map(([key, value]) => (
                              <div key={key} onClickCapture={this.onFileTypeChange(key)} className={'upload__filetypes-item' + (fileType === key ? ' active' : '')}>
                                <div className={`upload__filetypes-icon upload__filetypes-icon--${key}`}></div>
                                <div className={`upload__filetypes-label upload__filetypes-label--${key}`}>{value.name ? value.name : value}</div>
                              </div>
                            ))}
                          </div>
                        </>
                      }
                      {uploadType === DOCUMENT_UPLOAD_TYPE_UPLOAD && showFileTypeCategory === true && FILE_TYPES[fileType].categories &&
                        <div className="upload__list">
                          <div className="upload__list-header" onClickCapture={this.onFileTypeCategoryBackButtonClick}>
                            <span className="upload__list-header-back"></span>
                            <span className="upload__list-header-text">{t('document_upload.select_category')}</span>
                          </div>
                          <div className="upload__list-container">
                            {Object.entries(FILE_TYPES[fileType].categories).map(([key, value]) => {
                              return (
                                <div
                                  key={key}
                                  onClickCapture={this.onFileTypeCategoryChange(key)}
                                  className={'upload__list-item' + (fileTypeCategory === key ? ' active' : '') + ((value.isPersonal && !this.isUserBusinessPerson()) || (!value.isPersonal && this.isUserBusinessPerson()) ? '' : ' disable')}
                                >
                                  <div className="upload__list-item-label">{t(value.name)}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      }
                    </div>
                    <label htmlFor="file">
                      <h3 className="box__input__title hide-tablet">{t('drop_your_documents_here_or')}<span>{t('browse')}</span></h3>
                      <a
                        className="btn btn--primary show-tablet" href="#"
                        onClick={e => e.preventDefault()}
                      >
                        {t('upload')}
                      </a>
                      <p className="box__input-supportedfiles">{t('user.document.only_some_files_supported', { size: this.props.maxFileSizeLimit.fileLimitInMB })}</p>
                    </label>
                  </div>
                }
                {showAdocContentFiles &&
                  <div className="box__input">
                    <div className="upload__filetypes">
                      <div className="upload__filetypes-header">{t('document_upload.select_main_adoc_document')}</div>
                    </div>
                    <div className="upload__list">
                      <div className="upload__list-header" onClickCapture={this.onAdocFileContentsBackButtonClick}>
                        <span className="upload__list-header-back"></span>
                        <span className="upload__list-header-text">{t('document_upload.files')}</span>
                      </div>
                      <div className="main-file-selection-container upload__list-container">
                        {selectedFiles.map((selectedFile, index) => (
                          <div key={selectedFile.file.name} className="upload__list-item--adocfile">
                            <div className="upload__list-item--adocfile-name">{selectedFile.file.name}</div>
                            <div className={`upload__list-item--adocfile-btn ${selectedFile.main ? 'main' : ''}`} onClickCapture={this.onAdocFileContentsMainButtonClick(index)}>{selectedFile.main ? t('common.main') : t('document_upload.choose_as_main')}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <button
                        className={`btn btn--primary ${isUploading ? 'loading' : ''}`}
                        disabled={isUploading}
                        onClick={this.onUploadAdocDocument}
                      >
                        {t('common.continue')}
                      </button>
                    </div>
                  </div>
                }
              </div>
              <div className="dropzone-uploads__inner dropzone-preview" id="dropzone-previews">
                <div id="progressBar1">
                  <div className="progressbar" data-pct="100" role="progressbar">
                    <div className="loader_background-border">
                      <div id="loader" />
                    </div>
                  </div>
                  <h3 id="uploading-msg">
                    {this.state.statusCount.total > 0 &&
                      <>
                        {t('user.document.upload.total_files')}: {this.state.statusCount.total}
                        <br />
                        {t('user.document.upload.successfully_uploaded')}: {this.state.statusCount.success}
                        {this.state.statusCount.error > 0 &&
                          <>
                            <br />
                            {t('user.document.upload.error_uploading')}: {this.state.statusCount.error}
                          </>
                        }
                      </>
                    }
                  </h3>
                </div>

                <div className="upload-item" id="dropzone-uploadItemTemplate"></div>
              </div>

              <div id="dropzone-dummy-uploadItemTemplate">
                <div style={{ display: 'none' }}></div>
              </div>

              <div className="upload-status upload-status--success">
                <div className="success__icon" />
                <h3>{t('user.document.file_successfully_uploaded')}</h3>
              </div>
            </form>
          </div>
        </section>
      </main>
    )
  }
}

const RouterDropzoneComponent = withRouter(DropzoneComponent)
export default withTranslation()(RouterDropzoneComponent)

DropzoneComponent.propTypes = {
  showStatusMessage: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  multipleFiles: PropTypes.bool,
  uploadType: PropTypes.string,
  t: PropTypes.func,
  documentsList: PropTypes.bool,
  disableComponent: PropTypes.bool,
  maxFileCount: PropTypes.any,
  onMaxFileCountExceeded: PropTypes.any,
  user: PropTypes.object,
  maxFileSizeLimit: PropTypes.object,
  onBatchUploadSuccess: PropTypes.func,
}
