import React from 'react'
import { Link } from 'react-router-dom'
import ControlButtonsDocument from '~/js/components/ControlButtonsDocument'
import StatusDocument from '~/js/components/StatusDocument'
import Tooltip from '~/js/components/Tooltip'
import * as dateUtils from '~/js/utils/date'
import { DOCUMENT_STATUS_SAVED_DRAFT } from '~/js/models/Document'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import PropTypes from 'prop-types'

export default function Document(props) {
  const { notViewed } = props
  const uploadDate = dateUtils.formatDateTime(props.uploadDate)
  const { t } = props

  const previewPath = props.status !== DOCUMENT_STATUS_SAVED_DRAFT
    ? generatePath(ROUTE_NAMES.USER_DOCUMENT_PREVIEW, { documentId: props.uuid })
    : generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW, { documentId: props.uuid })

  return (
    <li>
      <div className="document-list__element">
        <div className="document-list__part">
          <div className="document-list__checkbox">
            <div className="form-group__checkbox form-group__checkbox--single">
              <input
                className="checkbox__document" type="checkbox" id={props.uuid}
                checked={props.selected ? props.selected : false} onChange={props.onSelect}
              />
              <label htmlFor={props.uuid} />
            </div>
          </div>
          <Link
            className="document-list__preview"
            to={{
              pathname: previewPath,
              searchValue: props.searchValue
            }}
          >
            <div className={`document-list__icon document-list__icon document-list__icon--${props.type}`} />
            {notViewed && (
              <span className="document-list__preview document-list__preview--not-viewed" />
            )}
            <div className={`document-list__info ${notViewed && 'document-list__info--not-viewed'}`}>
              <h3 className="document-list__title">{notViewed ? <p className='strong-text'>{props.title}</p> : props.title}</h3>
              {props.showCategories && (
                props.categories.map((category) => (
                  <span className="document-category" key={category.id} style={{ color: '#' + category.color }}>
                    {category.name}
                  </span>
                )
                )
              )}
              <span className={notViewed ? 'not-viewed-document' : ''}>{notViewed ? <p className='strong-text-grey'>{uploadDate}</p> : uploadDate}</span>
            </div>
          </Link>
        </div>
        <div className="document-list__part document-list__part--overflow">
          <div className="document-list__shared">
            <Tooltip
              text={`${t('user.document.signed')}/${t('user.document.invited')}`}
              inline={true}
            >
              <i className="icon" />
            </Tooltip>
            <span>
              <Tooltip text={t('user.document.signed')} inline={true}>{props.signedCount}</Tooltip>
              /
              <Tooltip text={t('user.document.invited')} inline={true}>{props.invitedCount}</Tooltip>
            </span>
          </div>
          <div className="document-list__status">
            <StatusDocument t={t} status={props.status} />
          </div>
          <ControlButtonsDocument
            t={t}
            className="document-list__controls"
            noText={true}
            showRemove={props.showRemove}
            showView={false}
            showShare={true}
            showMigrate={false}
            onViewClick={props.onViewClick}
            onDownloadClick={props.onDownloadClick}
            onShareClick={props.onShareClick}
            onRemoveClick={props.onDeleteClick}
          />
        </div>
      </div>
    </li>
  )
}

Document.propTypes = {
  t: PropTypes.func,
  notViewed: PropTypes.bool,
  uploadDate: PropTypes.string,
  status: PropTypes.string,
  uuid: PropTypes.string,
  selected: PropTypes.bool,
  searchValue: PropTypes.string,
  showCategories: PropTypes.bool,
  categories: PropTypes.array,
  type: PropTypes.string,
  title: PropTypes.string,
  signedCount: PropTypes.number,
  invitedCount: PropTypes.number,
  showRemove: PropTypes.bool,
  onViewClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onShareClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onSelect: PropTypes.func,
}