// eslint-disable-next-line no-undef
const env = process.env

export const ENV_LOCAL = 'local'
export const ENV_DEV = 'dev'
export const ENV_STAGE = 'stage'
export const ENV_PROD = 'prod'
export const isDebug = env.NODE_ENV === ENV_LOCAL || env.NODE_ENV === ENV_DEV

export default {
  /**
   * @param {string} key
   * @returns {boolean}
   */
  has(key) {
    return typeof env[key] !== 'undefined'
  },
  /**
   * @param {string} key
   * @returns {string|undefined}
   */
  get(key) {
    return this.has(key) ? env[key] : undefined
  },
}