import React from 'react'
import PropTypes from 'prop-types'

export default class Tab extends React.Component {
  render() {
    return (
      <li className={this.props.temporaryTab ? 'temporary-tab' : 'login-tabs'}>
        <a
          className={this.props.active ? ' active' : ''}
          href="#"
          onClick={e => { e.preventDefault(); this.props.onClick() }}
        >
          <img src={this.props.image} alt={this.props.alt} />
          <p>{this.props.title}</p>{this.props.number ? <span>{this.props.number}</span> : ''}
        </a>
      </li>
    )
  }
}

Tab.propTypes = {
  temporaryTab: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  image: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.any,
}