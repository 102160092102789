import React from 'react'

export default class DocumentCommentsList extends React.Component {
  render() {
    return (
      <ul>
        {this.props.children}
      </ul>
    )
  }
}