import { connect } from 'react-redux'
import { addCartItem, clearCart } from '~/js/actions'
import UsersQuantityChange from '~/js/routes/app/User/UsersQuantityChange'

const mapStateToProps = state => ({
  products: state.productStore.allProducts,
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersQuantityChange)