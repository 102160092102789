import React from 'react'
import { useTranslation } from 'react-i18next'
import Login from '~/js/containers/LoginRoute'
import MobileID from '~/img/mobile-id-sm.svg'
import * as userService from '~/js/services/user/user'

function MobileIdLogin(props) {
  const { t } = useTranslation()

  return <Login
    title={t('user.login.login_mobileid')}
    imgSrc={MobileID}
    btnSubmitText={t('login')}
    loginMode="mobileId"
    authType={userService.AUTH_TYPE_MOBILEID}
    {...props}
  />
}

export default MobileIdLogin