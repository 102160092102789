import React from 'react'
import { useTranslation } from 'react-i18next'
import ControlButtonsCompanyUser from '../../ControlButtonsCompanyUser'
import PropTypes from 'prop-types'

export default function CompanyUser(props) {
  const { t } = useTranslation()

  return (
    <li className="table__row">
      <div className="table__body__col">
        <p><strong>{props.name + ' ' + props.surname}</strong></p>
        {props.person ?
          <span className="user__status user__status--active">
            <p>{t('user.settings.company_user.status_active')}</p>
          </span>
          :
          <span className="user__status user__status--active">
            <p>{t('user.settings.company_user.status_pending')}</p>
          </span>
        }
      </div>
      <div className="table__body__col"><a href={`mailto:${props.email}`}>{props.email}</a></div>
      <div className="table__body__col">
        <p>{t('user.settings.company_user.'+props.role)}</p>
      </div>
      {props.person ?
        <div className="table__body__col table__body__col--status user__status user__status--active">
          <p>{t('user.settings.company_user.status_active')}</p>
        </div>
        :
        <div className="table__body__col table__body__col--status user__status user__status--pending">
          <p>{t('user.settings.company_user.status_pending')}</p>
        </div>
      }
      <div className="table__body__col">
        {props.assignedCategories.map((category, index) => (
          <p style={{ marginTop: '10px' }} key={index}>
            {category.name}
            {props.canAssignCategory && (
              <a className="remove-category" onClick={() => props.onDocumentCategoryUnassign(category, props.index)} />
            )}
          </p>
        )
        )}
        {props.canAssignCategory && (
          <a
            className="btn btn--icon btn--add"
            onClick={() => props.onAssignDocumentCategoryClick()}
            id="assign-category">
            {t('user.document.assign_category')}
          </a>
        )}
      </div>
      <ControlButtonsCompanyUser
        t={t}
        noText={true}
        className="table__body__col table__body__col--overflow"
        canUpdate={props.canUpdate}
        canDelete={props.canDelete}
        onEditClick={props.onEditClick}
        onRemoveClick={props.onDeleteClick}
        canAssignSubscription={props.canAssignSubscription}
        onAssignSubscriptionClick={props.onAssignSubscriptionClick}
      />
    </li>
  )
}

CompanyUser.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  person: PropTypes.object,
  assignedCategories: PropTypes.array,
  canAssignCategory: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  onAssignDocumentCategoryClick: PropTypes.func,
  onDocumentCategoryUnassign: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  canAssignSubscription: PropTypes.bool,
  onAssignSubscriptionClick: PropTypes.func,
}