import { connect } from 'react-redux'
import { addCartItem, addStatusMessage, clearCart, disableScroll, enableScroll } from '~/js/actions'
import MobileHeader from '~/js/components/MobileHeader'

const mapStateToProps = state => ({
  user: state.user.user,
  workspaces: state.user.workspaces,
  products: state.productStore.allProducts,
  signatureProducts: state.productStore.signatureProducts,
})

const mapDispatchToProps = dispatch => ({
  addCartItem: (product, count) => dispatch(addCartItem(product, count)),
  clearCart: () => dispatch(clearCart()),
  enableScroll: () => dispatch(enableScroll()),
  disableScroll: () => dispatch(disableScroll()),
  showStatusMessage: (type, message) => dispatch(addStatusMessage(type, message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader)