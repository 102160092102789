import { ACTION_SET_PLAN_PRODUCTS } from '~/js/actions'
import { CATEGORY_SIGNATURE } from '~/js/models/Product'

export default (
  state = {
    allProducts: [],
    signatureProducts: [],
  },
  action
) => {
  switch (action.type) {
  case ACTION_SET_PLAN_PRODUCTS:
    return Object.assign({}, state, {
      allProducts: action.products,
      signatureProducts: action.products.filter(p => p.categories.includes(CATEGORY_SIGNATURE))
    })

  default:
    return state
  }
}