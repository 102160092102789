import React from 'react'
import PropTypes from 'prop-types'
import { CompanyUsersList, CompanyUser } from '~/js/components/CompanyUsersList'
import ModalCompanyUser from '~/js/components/ModalCompanyUser'
import Pagination from '~/js/components/Pagination'
import ModalRemove from '~/js/components/ModalRemove'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as settingsService from '~/js/services/user/settings'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from '../../services/user/serviceHelpers'
import Tooltip from '../Tooltip'
import ModalAssignDocumentCategory from '../ModalAssignDocumentCategory'
import * as documentService from '~/js/services/user/document'
import { getUserWorkspace, generatePath } from '~/js/routes/router'
import ModalMaximumNumberOfUsersReached from '../ModalMaximumNumberOfUsersReached'
import { ROUTE_NAMES } from '~/js/routes/config'
import ModalAssignSubscription from '~/js/components/ModalAssignSubscription'
import InputSearch from '~/js/components/InputSearch'
import WithEmailValidation from '~/js/hoc/withEmailValidation'

const pageSize = 10

class CompanyUsers extends React.Component {
  constructor(props) {
    super(props)

    const queryString = this.parseQueryString()
    const page = queryString.page || 1

    this.state = {
      modalCompanyUser: {
        active: false
      },
      companyUsers: [],
      pagination: {
        page: page,
        offset: this.getOffset(page),
        limit: pageSize,
        total: undefined,
      },
      modalRemove: {
        active: false,
        companyUserIndex: undefined,
      },
      modalAssignSubscription: {
        active: false,
        companyUserIndex: undefined,
      },
      searchValue: '',
      searchValueCategories: '',
      assignedCategories: [],
      documentCategories: [],
      modalAssignDocumentCategory: {
        active: false,
        companyUserIndex: undefined,
      },
      licenses: [],
      modalMaximumNumberOfUsersReached: {
        active: false
      }
    }

    this.onAddNewCompanyUserClick = this.onAddNewCompanyUserClick.bind(this)
    this.onModalRemoveCompanyUserClick = this.onModalRemoveCompanyUserClick.bind(this)
    this.addCompanyUser = this.addCompanyUser.bind(this)
    this.editCompanyUser = this.editCompanyUser.bind(this)
    this.onPaginationNextClick = this.onPaginationNextClick.bind(this)
    this.onPaginationPreviousClick = this.onPaginationPreviousClick.bind(this)
    this.onAssignCategoriesToCompanyUser = this.onAssignCategoriesToCompanyUser.bind(this)
    this.onDocumentCategoryUnassign = this.onDocumentCategoryUnassign.bind(this)
    this.onAddAdditionalUsersClick = this.onAddAdditionalUsersClick.bind(this)
    this.onCategorySelect = this.onCategorySelect.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onAssignSubscriptionBtnClick = this.onAssignSubscriptionBtnClick.bind(this)
    this.onModalAssignSubscriptionClick = this.onModalAssignSubscriptionClick.bind(this)

    this.fetchCompanyPeople()
    this.fetchLicenses()
    this.fetchCategories()
  }

  getOffset(page) {
    return (page - 1) * pageSize
  }

  onAddNewCompanyUserClick() {
    const { user } = this.props

    if (user.subscription === 'start' || user.subscription === 'no_plan') {
      this.props.history.push(generatePath(ROUTE_NAMES.USER_PRICING))
    } else {
      if (this.state.licenses.length === this.getActiveLicenses() && !this.props.user.subscription.includes('flexible')) {
        this.setState({
          modalMaximumNumberOfUsersReached: {
            active: true
          }
        })
      } else {
        this.setState({
          modalCompanyUser: {
            active: true
          }
        })
      }
    }
  }

  onModalRemoveCompanyUserClick() {
    const { t } = this.props
    const { companyUsers, modalRemove } = this.state
    const companyUser = companyUsers[modalRemove.companyUserIndex]

    settingsService
      .removeCompanyUser(companyUser.uuid)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.user_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalRemove: { active: false } })
        this.fetchCompanyPeople()
        this.fetchLicenses()
        this.props.fetchUser()
      })
  }

  onModalAssignSubscriptionClick() {
    const { t, workspaceId } = this.props
    const { companyUsers, modalAssignSubscription } = this.state
    const companyUser = companyUsers[modalAssignSubscription.companyUserIndex]

    settingsService
      .assignSubscription(workspaceId, companyUser.uuid)
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.license_was_successfully_assigned')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalAssignSubscription: { active: false } })
        this.fetchCompanyPeople()
        this.fetchLicenses()
        this.props.fetchUser()
      })
  }

  fetchCompanyPeople() {
    const { pagination, searchValue } = this.state

    settingsService
      .getBusinessPeople(pagination.offset, pagination.limit, this.props.workspaceId, searchValue)
      .then(data => this.setCompanyUsers(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchLicenses() {
    settingsService
      .getAllBusinessLicenses()
      .then(data => this.setState({ licenses: data.data }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  addCompanyUser([name, surname, email, role]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserNamespace()}/company/${this.props.workspaceId}/person`, {
        name: name,
        surname: surname,
        email: email,
        role: role
      })
      .then(res => {
        return res.data
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.user_was_successfully_invited')))
      .finally(() => {
        this.setState({ modalCompanyUser: { active: false } })
        this.fetchCompanyPeople()
        this.props.fetchUser()
        this.fetchLicenses()
        this.props.fetchUser()
      })
  }

  editCompanyUser([name, surname, email, role, license]) {
    const { t } = this.props
    const companyUser = this.getCompanyUser(this.state)

    return apiClient
      .post(`/v1/${getUserWorkspace()}/company/company-user/${companyUser.uuid}`, {
        name: name,
        surname: surname,
        email: email,
        role: role,
        businessSubscriptionLicense: license
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.company_user.user_was_successfully_edited')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCompanyUser: { active: false, companyUserIndex: null } })
        this.fetchCompanyPeople()
        this.fetchLicenses()
      })

  }

  getCompanyUser(state) {
    return state.companyUsers[state.modalCompanyUser.companyUserIndex]
  }

  setCompanyUsers(data) {
    const { data: companyUsers, totalCount } = data

    this.setState(prevState => ({
      companyUsers,
      pagination: {
        ...prevState.pagination,
        total: totalCount,
      },
    }))
  }

  parseQueryString() {
    const search = this.props.location.search || ''
    const match = search.match(/page=(\d)?/)

    return {
      page: match ? parseInt(match[1], 10) : undefined,
    }
  }

  onPaginationNextClick() {
    this.setPaginationPage(this.state.pagination.page + 1)
  }

  onPaginationPreviousClick() {
    this.setPaginationPage(this.state.pagination.page - 1)
  }

  setPaginationPage(page) {
    this.props.history.push({
      search: `?page=${page}`,
    })

    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
        offset: this.getOffset(page),
      }
    }))
  }

  onCompanyUserDeleteClick(index) {
    this.setState({
      modalRemove: {
        active: true,
        companyUserIndex: index,
      },
    })
  }

  onAssignSubscriptionBtnClick(index) {
    this.setState({
      modalAssignSubscription: {
        active: true,
        companyUserIndex: index,
      },
    })
  }

  onCompanyUserEditClick(index) {
    this.setState({
      modalCompanyUser: {
        active: true,
        companyUserIndex: index,
      },
    })
  }

  fetchCategories(assignedCategories) {
    const { searchValue } = this.state

    documentService
      .searchDocumentCategories(searchValue, assignedCategories)
      .then(data => this.setCategories(data))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCategories(data) {
    const { data: categories } = data

    this.transformFetchedCategories(categories)
    this.setState({
      documentCategories: categories
    })
  }

  transformFetchedCategories(categories) {
    categories.forEach(category => {
      category.selected = false
    })
  }

  onAssignDocumentCategoryClick(index) {
    const assignedCategories = []
    this.state.companyUsers[index].permissions.documentCategories.forEach(category => assignedCategories.push(category.id))
    this.fetchCategories(assignedCategories)
    this.setState({
      modalAssignDocumentCategory: {
        active: true,
        companyUserIndex: index
      }
    })
  }

  onAssignCategoriesToCompanyUser(categories, companyUserIndex) {
    const { t } = this.props

    const selectedCategories = []

    categories.forEach(category => {
      if (category.selected) {
        selectedCategories.push(category)
      }
    })

    return apiClient
      .post(`v1/${getUserNamespace()}/company-user/assign-category/${this.state.companyUsers[companyUserIndex].uuid}`, {
        categories: selectedCategories
      })
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.categories_were_successfully_assigned'))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalAssignDocumentCategory: { active: false } })
        this.fetchCompanyPeople()
        this.fetchCategories()
      })
  }

  onDocumentCategoryUnassign(category, companyUserIndex) {
    this.unassignDocumentCategory(category, companyUserIndex)
    this.fetchCategories()
  }

  onAddAdditionalUsersClick() {
    this.props.history.push({
      pathname: generatePath(ROUTE_NAMES.USER_USERS_QUANTITY),
      state: { licencesCount: this.state.licenses.length, subscriptionProduct: this.props.user.subscriptionProduct, companyUsersQuantity: this.state.licenses.length }
    })
  }

  unassignDocumentCategory(category, companyUserIndex) {
    const { t } = this.props

    return apiClient
      .post(`v1/${getUserWorkspace()}/company-user/unassign-category/${this.state.companyUsers[companyUserIndex].uuid}/${category.id}`)
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_unassigned'))
        this.fetchCompanyPeople()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onCategorySelect(index) {
    const { documentCategories } = this.state

    documentCategories[index].selected = !documentCategories[index].selected

    this.setState({ documentCategories })
  }

  onSearchChangeCategories(value) {
    this.setState({ searchValueCategories: value })
    this.fetchCategories()
  }

  onModalClose() {
    this.setState({ modalAssignDocumentCategory: { active: false }, searchValue: '' })
    this.fetchCategories()
  }

  getActiveLicenses() {
    return this.state.licenses.filter(license => license.businessPerson).length
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.pagination.page !== prevState.pagination.page) {
      this.fetchCompanyPeople()
    }
  }

  onSearchChange = value => {
    this.setState({ searchValue: value, pagination: { page: 1, offset: this.getOffset(1), limit: pageSize } })
    this.setPaginationPage(this.state.pagination.page)
    this.fetchCompanyPeople()
  }

  render() {
    const { t, user } = this.props
    const companyUser = this.getCompanyUser(this.state)

    return (
      <React.Fragment>
        <div className="settings__top">
          <div className="settings__top__left">
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.state.searchValue}
                onChange={this.onSearchChange}
                wait={this.props.wait}
                placeholder={t('common.search')}
              />
            </div>
          </div>
          <div className="settings__top__right">
            {!user.subscription.includes('flexible') && (
              <span className="document-list__shared" style={{ marginRight: '20px' }}>
                <i className="icon" />
                <span>
                  <Tooltip text={t('user.settings.company_user.active_users')} inline={true}>{this.getActiveLicenses()}</Tooltip>
                  /
                  <Tooltip text={t('user.settings.company_user.all_users')} inline={true}>{this.state.licenses.length}</Tooltip>
                </span>
              </span>
            )}
            {(user.permissions.isAdmin || user.permissions.isManager) && (
              <a
                className="btn btn--primary js-open-popup"
                onClick={() => this.onAddNewCompanyUserClick()}
              >
                {t('user.settings.plus_add_new_user')}
              </a>
            )}
          </div>
        </div>
        <div className="users-list users-list--company">
          <div className="table table--settings">
            <ul className="table__head">
              <li>
                <label>{t('user.settings.company_user.fullname')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.email')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.role')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.status')}</label>
              </li>
              <li>
                <label>{t('user.settings.company_user.assigned_categories')}</label>
              </li>
            </ul>
            <CompanyUsersList>
              {this.state.companyUsers.map((u, index) => (
                <CompanyUser
                  key={u.uuid}
                  t={t}
                  name={u.name}
                  surname={u.surname}
                  email={u.email}
                  role={u.role}
                  person={u.person}
                  canUpdate={u.permissions.canUpdate}
                  canDelete={u.permissions.canDelete}
                  canAssignCategory={user.permissions.isAdmin || (!user.permissions.isWorker && u.role === 'worker')}
                  canAssignSubscription={u.person && !u.subscription}
                  assignedCategories={u.permissions.documentCategories}
                  index={index}
                  onAssignDocumentCategoryClick={this.onAssignDocumentCategoryClick.bind(this, index)}
                  onDocumentCategoryUnassign={this.onDocumentCategoryUnassign}
                  onAssignSubscriptionClick={this.onAssignSubscriptionBtnClick.bind(this, index)}
                  onDeleteClick={this.onCompanyUserDeleteClick.bind(this, index)}
                  onEditClick={this.onCompanyUserEditClick.bind(this, index)}
                />
              ))}
            </CompanyUsersList>
          </div>
          <Pagination
            t={t}
            offset={this.state.pagination.offset}
            limit={this.state.pagination.limit}
            total={this.state.pagination.total}
            onNextClick={this.onPaginationNextClick}
            onPreviousClick={this.onPaginationPreviousClick}
          />
        </div>
        <ModalCompanyUser
          active={this.state.modalCompanyUser.active}
          title={this.state.modalCompanyUser.companyUserIndex !== undefined ? t('user.settings.company_user.edit_user') : t('user.settings.company_user.add_new_user')}
          edit={this.state.modalCompanyUser.companyUserIndex !== undefined && companyUser}
          name={this.state.modalCompanyUser.companyUserIndex !== undefined && companyUser ? companyUser.name : ''}
          surname={this.state.modalCompanyUser.companyUserIndex !== undefined && companyUser ? companyUser.surname : ''}
          role={this.state.modalCompanyUser.companyUserIndex !== undefined && companyUser ? companyUser.role : ''}
          email={this.state.modalCompanyUser.companyUserIndex !== undefined && companyUser ? companyUser.email : ''}
          isAdmin={user.permissions.isAdmin}
          isManager={user.permissions.isManager}
          licenses={this.state.licenses}
          addCompanyUser={this.addCompanyUser}
          editCompanyUser={this.editCompanyUser}
          onCloseClick={() => this.setState({ modalCompanyUser: { active: false } })}
          isValidEmail={this.props.isValidEmail}
        />
        <ModalRemove
          active={this.state.modalRemove.active}
          title={t('user.settings.company_user.remove_user')}
          message={t('user.settings.company_user.are_you_sure_you_want_to_remove')}
          onCloseClick={() => this.setState({ modalRemove: { active: false } })}
          onRemoveClick={this.onModalRemoveCompanyUserClick}
        />
        <ModalAssignSubscription
          active={this.state.modalAssignSubscription.active}
          title={t('user.settings.company_user.assign_license')}
          message={t('user.settings.company_user.are_you_sure_you_want_to_assign')}
          onCloseClick={() => this.setState({ modalAssignSubscription: { active: false } })}
          onAssignSubscriptionClick={this.onModalAssignSubscriptionClick}
        />
        <ModalAssignDocumentCategory
          active={this.state.modalAssignDocumentCategory.active}
          title={t('user.document.assign_category')}
          t={t}
          assignCategory={this.onAssignCategoriesToCompanyUser}
          onCloseClick={() => this.onModalClose()}
          showStatusMessage={this.props.showStatusMessage}
          documentCategories={this.state.documentCategories}
          onCategorySelect={this.onCategorySelect}
          onSearchChange={this.onSearchChangeCategories}
          searchValue={this.state.searchValueCategories}
          fetchCategories={this.fetchCategories}
          companyUserIndex={this.state.modalAssignDocumentCategory.companyUserIndex}
        />
        <ModalMaximumNumberOfUsersReached
          active={this.state.modalMaximumNumberOfUsersReached.active}
          onCloseClick={() => this.setState({ modalMaximumNumberOfUsersReached: { active: false } })}
          onAddAdditionalUsersClick={this.onAddAdditionalUsersClick}
          t={t}
        />
      </React.Fragment>
    )
  }
}

CompanyUsers.propTypes = {
  workspaceId: PropTypes.number,
  user: PropTypes.object,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  t: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  wait: PropTypes.number,
  isValidEmail: PropTypes.func,
}

export default (WithEmailValidation(CompanyUsers))