import React from 'react'
import Modal from '../Modal'
import DocumentViewer from '../DocumentViewer'
import PropTypes from 'prop-types'

export default class ModalDocumentViewer extends React.Component {
  render() {
    return (
      <Modal
        className="popup--pdf-view"
        size="full"
        {...this.props}
      >
        <DocumentViewer
          t={this.props.t}
          isTemporary={this.props.isTemporary}
          temporarySignLinkUuId={this.props.temporarySignLinkUuId}
          document={this.props.document}
          showStatusMessage={this.props.showStatusMessage}
        />
      </Modal>
    )
  }
}

ModalDocumentViewer.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
  showStatusMessage: PropTypes.func,
  isTemporary: PropTypes.bool,
  temporarySignLinkUuId: PropTypes.string
}