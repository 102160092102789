import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import Select from '~/js/components/Select'
import { components } from 'react-select'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

export default class ModalDocumentMigrationCompany extends React.Component {
  constructor(props) {
    super(props)

    this.onMigrateDocumentSubmit = this.onMigrateDocumentSubmit.bind(this)
  }

  onMigrateDocumentSubmit(values) {
    if (!this.props.user.workspaceId) {
      this.props.migrateDocument([values.workspace.id])
    } else {
      this.props.migrateDocumentToWorkspace(
        values.workspace.id || this.props.user.id, values.workspace.isBusiness
      )
    }
  }

  render() {
    const { t } = this.props
    const initialValues = { workspace: '' }
    let validationSchema = Yup.object().shape({
      workspace: Yup.object()
        .required(t('validation.field_is_required')),
    })

    const NoOptionsMessage = props => {
      return (
        <components.NoOptionsMessage {...props}>
          <span>{t('common.another_account_not_found')}</span>
        </components.NoOptionsMessage>
      )
    }

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onMigrateDocumentSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.company && props.touched.company ? ' form-group--error' : '')}>
                <label>{t('user.document.other_accounts')}</label>
                <Select
                  name="workspace"
                  components={{ NoOptionsMessage }}
                  options={this.props.allWorkspaces.filter(workspace => !workspace.active).map(workspace => ({
                    value: workspace,
                    label: workspace.name + (workspace.isBusiness ? ' ' + workspace.registrationCode : '')
                  }))}
                  placeholder={t('common.select')}
                  onChange={option => props.setFieldValue('workspace', option.value)}
                  onBlur={props.handleBlur}
                />
                <ErrorMessage className="form-group__error" name="workspace" component="span" />
              </div>

              <div className="center-element center-element--space-between">
                <button
                  className="btn btn--grey"
                  onClick={this.props.onCloseClick}>
                  {t('common.cancel')}
                </button>
                <button
                  type="submit"
                  className="btn btn--primary"
                  disabled={props.isSubmitting}
                >
                  {t('common.migrate')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    )
  }
}

ModalDocumentMigrationCompany.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
  onCloseClick: PropTypes.func,
  personType: PropTypes.string,
  migrateDocument: PropTypes.func,
  companies: PropTypes.array,
  migrateDocumentToWorkspace: PropTypes.func,
  allWorkspaces: PropTypes.array,
}