import React, { useEffect, useState } from 'react'
import StatusMessage from './StatusMessage'
import PropTypes from 'prop-types'

export default function StatusMessages({ messages, className, aboveBottomBar, fullWidth, onCloseClick }) {
  const [fadingMessages, setFadingMessages] = useState([])

  function checkMessages() {
    const now = new Date()
    const newFadingMessages = [...fadingMessages]

    messages.forEach((msg) => {
      if ((msg.createdAt.getTime() + 4000 - now.getTime()) < 0) {
        if (newFadingMessages.indexOf(msg.id) === -1) {
          newFadingMessages.push(msg.id)
        }
        setTimeout(() => onCloseClick(msg.id), 2000)
      }
    })

    setFadingMessages(newFadingMessages)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkMessages()
    }, 1000)

    if (!messages.length) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  })

  return (
    <div className="statusbar__parent" >
      {messages.map((msg, i) => (
        <div key={i} onClick={() => onCloseClick(msg.id)}>
          <StatusMessage
            className={className}
            id={msg.id}
            key={i}
            active={true}
            fading={fadingMessages.indexOf(msg.id) !== -1}
            aboveBottomBar={aboveBottomBar}
            fullWidth={fullWidth}
            onCloseClick={() => onCloseClick(msg.id)}
            {...msg}
          />
        </div>
      ))}
    </div>
  )
}

StatusMessages.defaultProps = {
  messages: [],
}

StatusMessages.propTypes = {
  messages: PropTypes.array,
  className: PropTypes.string,
  aboveBottomBar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onCloseClick: PropTypes.func,
}