import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tab, TabsList } from '../TabsList'
import UsbSigning from '../UsbSigningTabs/UsbSigning'

const tabs = [
  {
    id: 1,
    name: 'user.document.usb_device_shipped_card',
  },
]

export default function UsbSigningTabs({
  onStartSigning,
  loading,
  isDocumentsSigned,
  usbErrorMessage,
  selectedAllCanBeSigned,
}) {
  const [ activeTabId, setActiveTabId ] = React.useState(1)
  const { t } = useTranslation()

  return (
    <>
      <TabsList centered={false}>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            active={tab.id === activeTabId}
            title={t(tab.name)}
            onClick={() => setActiveTabId(tab.id)}
          />
        ))}
      </TabsList>
      <div className="tabs-content">
        <div className={'tabs-content__tab tabs-content__information' + (activeTabId === 1 ? ' active' : '')}>
          <UsbSigning
            isDocumentsSigned={isDocumentsSigned}
            loading={loading}
            onStartSigning={onStartSigning}
            usbErrorMessage={usbErrorMessage}
            selectedAllCanBeSigned={selectedAllCanBeSigned}
          />
        </div>
      </div>
    </>
  )
}

UsbSigningTabs.propTypes = {
  onStartSigning: PropTypes.func,
  loading: PropTypes.bool,
  isDocumentsSigned: PropTypes.bool,
  usbErrorMessage: PropTypes.object,
  selectedAllCanBeSigned: PropTypes.bool,
}
