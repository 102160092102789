import React from 'react'
import { useTranslation } from 'react-i18next'
import FormGroupCopy from '~/js/components/FormGroupCopy'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'

export default function ModalGenerateLink(props) {
  const { t } = useTranslation()
  if (!props.active) {return null}

  return (
    <Modal
      {...props}
      onCloseClick={props.onCloseClick}
    >
      <div className="tabs-content">
        <div className='tabs-content__tab active'>
          <FormGroupCopy
            t={t}
            value={props.signingLink}
            expireAfter={props.expireAfter}
            signingLinkError={props.signingLinkError}
          />
        </div>
      </div>
    </Modal>
  )
}

ModalGenerateLink.propTypes = {
  access: PropTypes.string,
  documentUuid: PropTypes.string,
  onCloseClick: PropTypes.func,
  active: PropTypes.bool,
  t: PropTypes.func,
  addSigner: PropTypes.func,
  addSigners: PropTypes.func,
  showStatusMessage: PropTypes.func,
  isBusinessPerson: PropTypes.bool,
  upload: PropTypes.bool,
  signingLink: PropTypes.string,
  signingLinkError: PropTypes.string,
  expireAfter: PropTypes.string,
}
