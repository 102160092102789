import React from 'react'
import PDFObject from 'pdfobject'
import PropTypes from 'prop-types'

export default class PdfViewer extends React.Component {
  constructor(props) {
    super(props)

    this.pdfViewer = React.createRef()
  }

  componentDidMount() {
    this.renderPdfViewer()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url || prevProps.version !== this.props.version) {
      this.renderPdfViewer()
    }
  }

  renderPdfViewer() {
    const { url, getBase64Promise } = this.props
    const pdfOpenParams = '#toolbar=0&navpanes=0&scrollbar=0&view=FitH'

    if (getBase64Promise) {
      getBase64Promise()
        .then(data => {
          PDFObject.embed(data+pdfOpenParams, this.pdfViewer.current)
        })
      return
    }

    PDFObject.embed(url+pdfOpenParams, this.pdfViewer.current)
  }

  render() {
    return (
      <div ref={this.pdfViewer} className="pdf-viewer" />
    )
  }
}

PdfViewer.propTypes = {
  url: PropTypes.string,
  version: PropTypes.string,
  getBase64Promise: PropTypes.func,
}