import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import markSignLogo from '~/img/marksign-logo-blue.svg'

class ModalNewsletterDecision extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props
    const initialValues = {
      isNewsletterAccepted: false,
    }
    const validationSchema = Yup.object().shape({
      isNewsletterAccepted: Yup.bool(),
    })

    const onCloseClick = this.props.onNewsletterDecisionModalClose

    if (!this.props.active) {
      return null
    }

    return (
      <div className="popup popup__user modal-newsletter-decision js-active">
        <div className="popup__overlay" />
        <OutsideClickHandler
          onOutsideClick={onCloseClick}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.props.onNewsletterDecisionSubmit}
            innerRef={this.formRef}
          >
            {(props => (
              <Form>
                <div className="popup__content">
                  <img className="logo" src={markSignLogo}></img>
                  <a className="popup__close js-popup__close" onClick={e => {e.preventDefault(); onCloseClick()}} />
                  <h4 className="ta-center title">{t('newsletterdecisionmodal.title')}</h4>
                  <p className="ta-center description">{t('newsletterdecisionmodal.description')}</p>
                  <div className={'form-group form-group__checkbox' + (props.errors.isNewsletterAccepted && props.touched.isNewsletterAccepted ? ' form-group--error' : '')}>
                    <Field type="checkbox" name="isNewsletterAccepted" id="accept-newsletter" />
                    <label htmlFor="accept-newsletter" className="newsletter-checkbox">
                      {t('newsletterdecisionmodal.checkbox_label')}
                    </label>
                    <ErrorMessage className="form-group__error" name="isNewsletterAccepted" component="span" />
                  </div>

                  <button
                    type="submit"
                    className="btn btn--primary btn-submit ta-center"
                    disabled={props.isSubmitting}
                  >
                    {t('newsletterdecisionmodal.button')}
                  </button>

                  <p className="ta-center t-and-c">
                    <Trans i18nKey="newsletterdecisionmodal.terms_conditions">
                      <a href={t('helpdesk.privacy_policy')} target="_blank" rel="noreferrer">Privatumo politika</a>
                      <a href={t('helpdesk.terms_and_conditions')} target="_blank" rel="noreferrer">Naudojimosi sąlygomis</a>
                    </Trans>
                  </p>
                </div>
              </Form>
            ))}
          </Formik>
        </OutsideClickHandler>
      </div>
    )
  }
}

export default withTranslation()(ModalNewsletterDecision)

ModalNewsletterDecision.propTypes = {
  active: PropTypes.bool,
  t: PropTypes.func,
  onNewsletterDecisionSubmit: PropTypes.func,
  onNewsletterDecisionModalClose: PropTypes.func,
}