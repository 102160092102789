import React from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import logoUrl from '../../../img/marksign-logo-blue.svg'
import AppLogo from './AppLogo'

export default class IframeLoginLogo extends React.Component {
  static propTypes = {
    whiteLabelInfo: PropTypes.object,
  }

  render() {
    const { whiteLabelInfo } = this.props

    if (!whiteLabelInfo) {
      return <div className="iframe-logo" />
    }

    const businessLogoUrl = whiteLabelInfo?.businessWhiteLabelLogoUrl ?? logoUrl
    const businessLogoLinkUrl = whiteLabelInfo ? whiteLabelInfo.businessWhiteLabelLogoLinkUrl : `https://${config.get('MARKSIGN_HOST')}`

    return (
      <div className="iframe-logo">
        <a href={businessLogoLinkUrl} target="_blank" rel="noreferrer">
          <AppLogo imgSrc={businessLogoUrl} />
        </a>
      </div>
    )
  }
}
