import {
  SAVE_DOCUMENT_FILTER_HISTORY_INFO,
  SAVE_DOCUMENT_PAGINATION_HISTORY_INFO,
  SAVE_DOCUMENT_SEARCH_FIELD_HISTORY_INFO,
  SAVE_DOCUMENT_SELECT_ALL_HISTORY_INFO,
  SAVE_DOCUMENT_TABS_HISTORY_INFO,
  SAVE_SELECTED_CAN_DELETE_DOCUMENTS_INFO,
  SAVE_SELECTED_DOCUMENTS_HISTORY_INFO,
} from '~/js/actions'

export default (
  state = {
    searchFieldHistory: '',
    tabListHistory: null,
    documentFilterHistory: null,
    selectAllHistory: false,
    selectedDocumentHistory: [],
    selectedCanDeleteDocumentsHistory: [],
    paginationHistory: {
      page: null,
      offset: null,
      pageSize: null
    }
  },
  action
) => {
  switch (action.type) {
  case SAVE_DOCUMENT_SEARCH_FIELD_HISTORY_INFO:
    return {
      ...state,
      searchFieldHistory: action.inputFieldValue
    }
  case SAVE_DOCUMENT_TABS_HISTORY_INFO:
    return {
      ...state,
      tabListHistory: action.tabListHistory
    }
  case SAVE_DOCUMENT_FILTER_HISTORY_INFO:
    return {
      ...state,
      documentFilterHistory: action.documentFilterHistory
    }
  case SAVE_DOCUMENT_SELECT_ALL_HISTORY_INFO:
    return {
      ...state,
      selectAllHistory: action.selectAllHistory
    }
  case SAVE_SELECTED_DOCUMENTS_HISTORY_INFO:
    return {
      ...state,
      selectedDocumentHistory: action.selectedDocumentHistory
    }
  case SAVE_DOCUMENT_PAGINATION_HISTORY_INFO:
    return {
      ...state,
      paginationHistory: {
        page: action.paginationHistoryPage,
        offset: action.paginationHistoryOffset,
        pageSize: action.paginationHistoryPageSize
      }
    }
  case SAVE_SELECTED_CAN_DELETE_DOCUMENTS_INFO:
    return {
      ...state,
      selectedCanDeleteDocumentsHistory: action.selectedCanDeleteDocumentsHistory
    }
  default:
    return state
  }
}
