import { connect } from 'react-redux'
import {
  addStatusMessageWithTimeout,
  setSessionId,
  setSerialNumber
} from '~/js/actions'
import SmartCardLoginRoute from '~/js/routes/app/SmartCardLogin'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessageWithTimeout(type, message)),
  setSessionId: sessionId => dispatch(setSessionId(sessionId)),
  setSerialNumber: serialNumber => dispatch(setSerialNumber(serialNumber)),
})

export default connect(
  null,
  mapDispatchToProps
)(SmartCardLoginRoute)