import React from 'react'
import './Tooltip.sass'
import PropTypes from 'prop-types'

export default class Tooltip extends React.Component {
  render() {
    let className = 'tooltip ' + this.props.className ?? ''

    if (this.props.inline) {
      className += ' tooltip--inline'
    }

    if (this.props.newLine) {
      className += ' tooltip--new-line'
    }

    if (this.props.newLineBig) {
      className += ' tooltip--new-line-big'
    }

    if (this.props.alignMiddle) {
      className += ' tooltip--align-middle'
    }

    if (this.props.img) {
      className += ' tooltip--img'
    }

    if (this.props.custom) {
      className = 'tooltip_custom tooltip_custom--new-line'
    }

    return (
      <div className={className} data-tooltip={this.props.text}>
        {this.props.children}
      </div>
    )
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  newLine: PropTypes.bool,
  newLineBig: PropTypes.bool,
  alignMiddle: PropTypes.bool,
  img: PropTypes.bool,
  custom: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.object
}