import { connect } from 'react-redux'
import {
  addStatusMessageWithTimeout,
  fetchUser,
  switchWorkspace,
  resetUser,
  fetchProducts,
  triggerUserLoggedInEvent
} from '../actions'
import UserRoute from '../routes/app/User'

const mapDispatchToProps = dispatch => ({
  showStatusMessage: (type, message) => dispatch(addStatusMessageWithTimeout(type, message)),
  fetchUser: () => dispatch(fetchUser()),
  fetchProducts: () => dispatch(fetchProducts()),
  switchWorkspace: workspaceId => dispatch(switchWorkspace(workspaceId)),
  resetUser: () => dispatch(resetUser()),
  triggerUserLoggedInEvent: (user, location) => dispatch(triggerUserLoggedInEvent(user, location))
})

const mapStateToProps = state => ({
  userIsFetching: state.user.isFetching,
  userIsSwitching: state.user.isSwitching,
  user: state.user.user,
  workspaces: state.user.workspaces,
  userLastRequestTime: state.user.lastRequestTime,
  scrollEnabled: state.page.scrollEnabled,
})

const userRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRoute)

export default userRoute