import React from 'react'
import PropTypes, { bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Select from '~/js/components/Select'

const purposeOptions = [
  { value: 'e_signature', label: 'user.document.e_signature' },
  { value: 'copy_authentication', label: 'user.document.copy_authentication' },
  { value: 'acknowledgement', label: 'user.document.acknowledgement' },
  { value: 'visa', label: 'user.document.visa' },
  { value: 'review', label: 'user.document.review' },
  { value: 'confirmation', label: 'user.document.confirmation' },
]

export default function MemberSigningPurpose(props) {
  const { purpose, onChangeCallback, showDataOnly } = props
  const { t } = useTranslation()
  const defaultOption = purposeOptions.find(option => option.value === purpose) || purposeOptions[0]
  const selectOptions = [
    { label: t('common.purpose'), options: purposeOptions },
  ]

  return (showDataOnly
    ? <span className="purpose purpose--info">
      {t('common.purpose')}: {t(defaultOption.label)}
    </span>
    : <Select
      name="purpose"
      options={selectOptions}
      defaultValue={[defaultOption]}
      getOptionLabel={opt => t(opt.label)}
      onChange={option => {
        typeof onChangeCallback === 'function' && onChangeCallback(option.value)
      }}
      {...props}
    />
  )
}

MemberSigningPurpose.propTypes = {
  purpose: PropTypes.string,
  onChangeCallback: PropTypes.func,
  showDataOnly: bool,
}

MemberSigningPurpose.defaultProps = {
  showDataOnly: false,
  purpose: purposeOptions[0].value,
}