import React from 'react'

export default class HistoryRecordsList extends React.Component {
  render() {
    let className = 'info-details__history-list'

    if (this.props.showAll) {
      className += ' js-show-all'
    }

    return (
      <ul className={className}>
        {this.props.children}
      </ul>
    )
  }
}