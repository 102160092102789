import React from 'react'
import ContactsList from '../../ContactsList'
import Contact from '../../ContactsList/Contact/Contact'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'

export default class ContactCategory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accordionActive: false,
      accordionControlsActive: false
    }
  }

  onAccordionClick = () => {
    this.setState(prevState => ({ accordionActive: !prevState.accordionActive }))
  }

  onAccordionControlsClick = () => {
    this.setState(prevState => ({ accordionControlsActive: !prevState.accordionControlsActive }))
  }

  onAccordionControlsOutsideClick = () => {
    this.setState({ accordionControlsActive: false })
  }

  render() {
    const { t } = this.props

    return (
      <div className={`wrapped-section wrapped-section--shrink ${this.state.accordionControlsActive ? 'js-show-controls' : ''}`}>
        <div className={`accordion__element ${this.state.accordionActive ? 'active' : ''}`}>
          <div className="accordion__title">
            <div className="accordion__clickable" onClick={this.onAccordionClick}>
              <h2 className="headline headline--standard">{this.props.categoryName}
                <span
                  className="settings__contacts-count"
                >{this.props.totalContacts}
                </span>
              </h2>
            </div>
            <div className="accordion__controls">
              <a className="btn btn--add btn--icon js-open-popup" onClick={this.props.onAssignContactClick}>
                {t('user.settings.contact.assign_contacts')}
              </a>
              <OutsideClickHandler onOutsideClick={this.onAccordionControlsOutsideClick}>
                <a className="btn btn--dots btn--icon js-show-controls" onClick={this.onAccordionControlsClick} />
              </OutsideClickHandler>
            </div>
            <div className="accordion__controls-menu">
              <button
                className="controls__btn controls__btn--edit js-open-popup" type="button"
                onClick={this.props.onContactCategoryEditClick}
              ><i className="icon icon--bigger" />
                <p>{t('common.edit')}</p>
              </button>
              <button
                className="controls__btn controls__btn--delete js-open-popup" type="button"
                onClick={this.props.onContactCategoryDeleteClick}
              ><i className="icon icon--bigger" />
                <p>{t('common.remove')}</p>
              </button>
            </div>
          </div>
          <div className="accordion__content">
            <div className="table table--settings table--contacts">
              <ul className="table__head">
                <li>
                  <label>{t('user.settings.contact.full_name')}</label>
                </li>
                <li>
                  <label>{t('user.settings.contact.email')}</label>
                </li>
                <li>
                  <label>{t('user.settings.contact.category')}</label>
                </li>
                <li>
                  <label>{t('user.settings.contact.phone_number')}</label>
                </li>
                <li>
                  <label>{t('user.settings.contact.company_name')}</label>
                </li>
                <li>
                  <label>{t('user.settings.contact.commentary')}</label>
                </li>
              </ul>
              <ContactsList>
                {this.props.contacts.filter(contact => contact.category === this.props.categoryName).map((contact) => {
                  return (
                    <Contact
                      key={contact.id}
                      t={t}
                      id={contact.id}
                      name={contact.name}
                      surname={contact.surname}
                      email={contact.email}
                      phoneNumber={contact.phoneNumber}
                      companyName={contact.companyName}
                      commentary={contact.commentary}
                      category={contact.category}
                      onContactDeleteClick={() => this.props.onContactDeleteClick(contact.id)}
                      onContactEditClick={() => this.props.onContactEditClick(contact.id)}
                    />
                  )
                })}
              </ContactsList>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContactCategory.propTypes = {
  categoryName: PropTypes.string,
  totalContacts: PropTypes.number,
  onAssignContactClick: PropTypes.func,
  onContactCategoryEditClick: PropTypes.func,
  onContactCategoryDeleteClick: PropTypes.func,
  contacts: PropTypes.object,
  onContactDeleteClick: PropTypes.func,
  onContactEditClick: PropTypes.func,
  t: PropTypes.func,
}