import React from 'react'
import { useTranslation } from 'react-i18next'
import Login from '~/js/containers/LoginRoute'
import MobileID from '~/img/mobile-id.svg'
import * as userService from '~/js/services/user/user'

function MobileIdRegister(props) {
  const { t } = useTranslation()

  return <Login
    title={t('user.login.register_mobileid')}
    imgSrc={MobileID}
    btnSubmitText={t('common.register')}
    registerMode="mobileId"
    authType={userService.AUTH_TYPE_MOBILEID}
    {...props}
  />
}

export default MobileIdRegister