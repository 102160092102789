import React from 'react'
import DocumentCommentsList, { DocumentComment } from '../DocumentCommentsList'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { DOCUMENT_COMMENT_ACCESS_PRIVATE, DOCUMENT_COMMENT_ACCESS_PUBLIC } from '../../models/Document'
import ModalRemove from '../ModalRemove'
import IconTooltip from '../../components/IconTooltip/IconTooltip'
import PropTypes from 'prop-types'

export default class DocumentComments extends React.Component {
  constructor() {
    super()

    this.state = {
      privateComment: false,
      modalRemoveComment: {
        active: false,
        commentIndex: undefined
      }
    }

    this.onRemoveCommentClick = this.onRemoveCommentClick.bind(this)
    this.onPrivateCommentSelect = this.onPrivateCommentSelect.bind(this)
    this.onDocumentCommentSubmit = this.onDocumentCommentSubmit.bind(this)
    this.onModalRemoveCommentClick = this.onModalRemoveCommentClick.bind(this)
  }

  onDocumentCommentSubmit(values, { resetForm }) {
    this.props.addComment([
      values.commentaryText,
      this.state.privateComment ? DOCUMENT_COMMENT_ACCESS_PRIVATE : DOCUMENT_COMMENT_ACCESS_PUBLIC
    ])
    this.setState({ privateComment: false })
    resetForm()
  }

  onPrivateCommentSelect() {
    this.setState({ privateComment: !this.state.privateComment })
  }

  onRemoveCommentClick(index) {
    this.setState({
      modalRemoveComment: {
        active: true,
        commentIndex: index
      }
    })
  }

  onModalRemoveCommentClick() {
    this.props.removeComment(this.props.documentComments[this.state.modalRemoveComment.commentIndex].id)
    this.setState({
      modalRemoveComment: {
        active: false
      }
    })
  }

  render() {
    const { t, document } = this.props

    const initialValues = { commentaryText: '' }
    const validationSchema = Yup.object().shape({
      commentaryText: Yup.string()
        .required(t('validation.field_is_required')),
    })

    return (
      <div className="wrapped-section">
        <div className="wrapped-section__headline">
          <h2 className="d-flex align-items-center headline headline--comments">
            {t('user.document.comments')}
            {
              !document.permissions.canComment && (
                <IconTooltip tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
              )
            }
          </h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={this.onDocumentCommentSubmit}
        >
          {props => (
            <Form>
              <div className={'form-group' + (props.errors.commentaryText && props.touched.commentaryText ? ' form-group--error' : '')}>
                <Field className="form-group__input form-group__input--commentary-text" component="textarea" name="commentaryText" disabled={!document.permissions.canComment} />
                <ErrorMessage className="form-group__error" name="commentaryText" component="span" />
              </div>

              <div className="form-group__submit-commentary">
                <button
                  type="submit"
                  className="btn btn--primary ta-center mt-2"
                  disabled={!document.permissions.canComment}
                >
                  {t('user.document.add_comment')}
                </button>

                <div className={'form-group__checkbox private-comment-checkbox' + (document.permissions.canComment ? '' : ' disabled-checkbox')}>
                  <input
                    className="checkbox__document form-group__checkbox"
                    type="checkbox"
                    id="private-comment"
                    checked={this.state.privateComment}
                    onChange={this.onPrivateCommentSelect}
                    disabled={!document.permissions.canComment}
                  />
                  <label htmlFor="private-comment">
                    {t('user.document.private_comment')}
                  </label>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="info-details">
          <DocumentCommentsList>
            {this.props.documentComments.map((comment, index) => (
              <DocumentComment
                key={comment.id}
                t={this.props.t}
                date={comment.createdAt}
                commentaryText={comment.commentaryText}
                authorName={comment.personName}
                access={comment.access}
                onRemoveClick={this.onRemoveCommentClick.bind(this, index)}
                canRemove={comment.permissions.canRemove}
              />
            ))}
          </DocumentCommentsList>
        </div>
        <ModalRemove
          active={this.state.modalRemoveComment.active}
          title={t('user.document.remove_comment')}
          message={t('user.document.are_you_sure_you_want_to_remove_comment')}
          onCloseClick={() => this.setState({ modalRemoveComment: { active: false } })}
          onRemoveClick={this.onModalRemoveCommentClick}
        />
      </div>
    )
  }
}

DocumentComments.propTypes = {
  t: PropTypes.func,
  addComment: PropTypes.func,
  removeComment: PropTypes.func,
  document: PropTypes.object,
  documentComments: PropTypes.array,
}