import React from 'react'
import { withTranslation } from 'react-i18next'
import ModalShare from '../ModalShare/ModalShare'
import StatusSigner from '../StatusSigner'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ControlButton, ControlButtons } from '~/js/components/ControlButtons'
import Tooltip from '~/js/components/Tooltip'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import {
  DOCUMENT_ACCESS_PRIVATE,
  DOCUMENT_ACCESS_PUBLIC,
  DOCUMENT_SIGNER_STATUS_SIGNED,
  DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED,
  DOCUMENT_SIGNER_STATUS_CONFIRMED,
  DOCUMENT_SIGNER_STATUS_DENIED,
  DOCUMENT_SIGNER_STATUS_PENDING,
  DOCUMENT_SIGNER_STATUS_REVIEWED
} from '~/js/models/Document'
import * as documentService from '~/js/services/user/document'
import IconTooltip from '../IconTooltip/IconTooltip'
import PropTypes from 'prop-types'
import MemberSigningPurpose from '~/js/components/MemberSigningPurpose/MemberSigningPurpose'
import Loader from '../../components/Loader'
import Member from './Member'
import { DOCUMENT_SIGNER_STATUS_PENDING_REVIEW } from '../../models/Document'

class Members extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalShare: {
        access: this.props.access,
        active: false
      },
    }

    this.onAddNewMembersClick = this.onAddNewMembersClick.bind(this)
    this.resendInvitation = this.resendInvitation.bind(this)
    this.onAddSigner = this.onAddSigner.bind(this)
    this.onAddSigners = this.onAddSigners.bind(this)
  }

  onAddNewMembersClick() {
    this.setState({
      modalShare: {
        active: true,
        access: this.props.access
      }
    })
  }

  resendInvitation(member) {
    const { t } = this.props

    documentService
      .resendSignerInvitation(this.props.documentId, member.id)
      .then(data => {
        this.props.showStatusMessage(
          SM_TYPE_SUCCESS,
          t(member.signStatus === DOCUMENT_SIGNER_STATUS_PENDING_REVIEW ? 'user.document.review_reminder_was_successfully_sent' : 'user.document.sign_reminder_was_successfully_sent')
        )

        if (typeof data.signer !== 'undefined') {
          this.props.updateMember(member, data.signer)
        }
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onAddSigner(signer) {
    let isEmailAlreadyExist
    const { members, uploadedSignedMembers, t } = this.props
    const signerEmail = (signer && signer.length > 2) ? signer[2] : ''
    const signerName = (signer && signer.length > 0) ? signer[0] : ''
    const signerSurname = (signer && signer.length > 1) ? signer[1] : ''
    this.setState({ modalShare: false })

    if (signerEmail && members) {
      isEmailAlreadyExist = members.find(member => member.email === signerEmail)
    }

    if (isEmailAlreadyExist) {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.email_already_exists_in_members'))
      return
    }

    const isMemberExistsInDocument = uploadedSignedMembers.find(member => member.name === signerName && member.surname === signerSurname && member.signatureLevel.toLowerCase() === 'qesig')

    if (isMemberExistsInDocument) {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.member_already_exists_in_list'))
      return
    }

    this.props.addSigner(signer)
  }

  onAddSigners(signers) {
    this.setState({ modalShare: false })
    this.props.addSigners(signers)
  }

  render() {
    const { t, access, members, documentId, isTemporary, isSigningOrderImportant, onSigningOrderImportantChange, uploadedSignedMembers, user, upload: isUpload } = this.props

    return (
      <div className="wrapped-section">
        <div className="wrapped-section__headline wrapped-section__headline--two">
          <h2 className="headline headline--members">{!isTemporary ? t('members') : t('signers')}</h2>
        </div>
        <div className="info-details info-details--wider info-details--signing-order">
          {isUpload ?
            <div className={'form-group__checkbox form-group__checkbox--members' + ((user.subscription === 'start' || user.subscription === 'no_plan') ? ' disabled-checkbox' : '')}>
              <input
                className="checkbox__document"
                type="checkbox"
                id="signing-order"
                onChange={onSigningOrderImportantChange}
                disabled={user.subscription === 'start' || user.subscription === 'no_plan'}
              />
              <label htmlFor="signing-order">
                {t('user.document.signing_order')}
                &nbsp;
                <Tooltip
                  text={t('user.document.signing_order_explanation')}
                  className='d-inline align-baseline ps-1'
                  newLine={true}
                >
                  <span className="info-icon-grey" />
                </Tooltip>
              </label>
              <div className="pro-icon__mobile-adjustment">
                {(user.subscription === 'start' || user.subscription === 'no_plan') &&
                  <IconTooltip tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
                }
              </div>
            </div> : null
          }
          {this.props.canInviteSigner && (this.props.members.length > 0 || this.props.uploadedSignedMembers.length > 0) && this.props.memberLoading !== true
            ? <a className="btn btn--icon btn--add" onClick={this.onAddNewMembersClick} id="add-user">{t('add_new_members')}</a>
            : null
          }
        </div>
        {(this.props.members.length === 0 && this.props.uploadedSignedMembers.length === 0) && this.props.memberLoading !== true && (
          <div className="sharing-settings">
            <div className="info-details info-details--wider">
              {isUpload &&
                <ul>
                  <li>
                    <div className="info-details__label">
                      <label>{t('sharing_settings')}</label>
                    </div>
                    <div className="info-details__info">
                      <div className="form-group__radio">
                        <input
                          className="popup-radio-value" type="radio" name="share" id="radio2"
                          defaultChecked={this.props.access === DOCUMENT_ACCESS_PRIVATE}
                          defaultValue="private" onChange={this.props.onAccessChange}
                        />
                        <label htmlFor="radio2">{t('private')}</label>
                      </div>
                      <div className="form-group__radio">
                        <input
                          className="popup-radio-value" type="radio" name="share" id="radio1"
                          defaultChecked={this.props.access === DOCUMENT_ACCESS_PUBLIC}
                          defaultValue="public" onChange={this.props.onAccessChange}
                        />
                        <label htmlFor="radio1">{t('public')}</label>
                      </div>
                    </div>
                  </li>
                </ul>
              }
              {this.props.canInviteSigner && this.props.members.length === 0 && this.props.uploadedSignedMembers.length === 0 && (
                <div
                  className="js-open-popup info-details__gray-area info-details__gray-area--clickable js-popup-value"
                  id="open-share-popup" onClick={this.onAddNewMembersClick}
                >
                  <a
                    className="btn btn--icon btn--add" href="#"
                    onClick={e => e.preventDefault()}
                  >
                    {t('add_new_members')}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
        {this.props.memberLoading !== true && (
          <div className="info-details info-details--wider">
            <ul className={!isUpload ? ' info-details--with-purpose' : ''}>
              {uploadedSignedMembers.slice(0).map((member, index) => {
                return (
                  <li key={member.id}>
                    <Member
                      user={user}
                      index={index}
                      member={member}
                      {...this.props}
                    />
                  </li>
                )
              })}
              <DragDropContext onDragEnd={this.props.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(providedParent) => (
                    <div
                      {...providedParent.droppableProps}
                      ref={providedParent.innerRef}
                    >
                      {members.slice(0).map((member, index) =>
                        <Draggable
                          key={member.id || `draggable${index + 1}`}
                          draggableId={`${member.id || `draggable${index + 1}`}`}
                          index={index}
                          isDragDisabled={!isSigningOrderImportant || !isUpload || isTemporary}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <li key={isTemporary || !member.email ? index : member.email} className={'info-details--members__inline' + (isUpload && isSigningOrderImportant ? ' info-details__row info-details__row--members' : '')}>
                                <div className="info-details__label status-tooltip--hover">
                                  <label className="strong-text">
                                    {isUpload || isSigningOrderImportant ?
                                      <span className="info-details__label--index">{index + 1}</span> : null
                                    }
                                    {!isUpload && member.isOwner && (
                                      <span
                                        className="info-details__status info-details__status--owner status-tooltip"
                                        data-tooltip={t('user.document.owner')}
                                      />
                                    )}
                                    {member.name} {member.surname} {member.email && `(${member.email})`}
                                  </label>
                                </div>
                                {!isUpload
                                  ? (
                                    <div className="info-details__info info-details__info--members">
                                      {member.signStatus !== 'reviewing' && (
                                        <StatusSigner t={t} status={member.signStatus} signatureLevelDesc={member.signatureLevel && member.signatureLevel.toLowerCase() === 'qesig' ? member.signatureLevelDesc : null} />
                                      )}
                                      {member.signStatus === DOCUMENT_SIGNER_STATUS_DENIED && (
                                        <span className="purpose purpose--info">
                                          {t('user.document.deny_document_reason')}: {member.documentRejectionReason}
                                        </span>
                                      )}
                                      {(
                                        member.email && member.email.length > 0)
                                          && member.canResendInvitation
                                          && this.props.canInviteSigner
                                          &&
                                          (member.signStatus === DOCUMENT_SIGNER_STATUS_PENDING || member.signStatus === DOCUMENT_SIGNER_STATUS_PENDING_REVIEW)
                                          && (
                                            user.subscription !== 'start' || user.subscription !== 'no_plan' ?
                                              <a className="btn btn--icon btn--reminder" onClick={() => this.resendInvitation(member)}>
                                                {t('send_reminder')}
                                              </a> : (
                                                <>
                                                  <div className="btn btn--icon btn--reminder__disabled">
                                                    {t('send_reminder')}
                                                  </div>
                                                  <div className="pro-icon__mobile-adjustment">
                                                    <IconTooltip tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
                                                  </div>
                                                </>
                                              )
                                          )}
                                      {!isTemporary && (
                                        <div className="info-details__purpose">
                                          <div className="document-list__select-filters">
                                            <MemberSigningPurpose
                                              purpose={member.purpose}
                                              onChangeCallback={(memberPurpose) => this.props.addPurposeInMember(index)(memberPurpose)}
                                              onBlur={this.props.handleBlur}
                                              showDataOnly={!(
                                                member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED
                                                && member.signStatus !== DOCUMENT_SIGNER_STATUS_CONFIRMED
                                                && member.signStatus !== DOCUMENT_SIGNER_STATUS_DENIED
                                                && member.signStatus !== DOCUMENT_SIGNER_STATUS_REVIEWED
                                                && this.props.canInviteSigner)}
                                            />
                                          </div>
                                          {member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED && member.signStatus !== DOCUMENT_SIGNER_STATUS_REVIEWED && this.props.canInviteSigner && (
                                            <ControlButtons size="small">
                                              <ControlButton
                                                type="delete"
                                                title={t('common.remove')}
                                                showLabel={false}
                                                showTooltip={true}
                                                onClick={this.props.removeMember.bind(this, index)}
                                              />
                                            </ControlButtons>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="info-details__info info-details__info--members">
                                      {member.signStatus !== 'reviewing' && (
                                        <StatusSigner t={t} status={member.signStatus || DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED} signatureLevelDesc={member.signatureLevel && member.signatureLevel.toLowerCase() === 'qesig' ? member.signatureLevelDesc : null} />
                                      )}
                                      {member.signStatus !== DOCUMENT_SIGNER_STATUS_SIGNED && member.signStatus !== DOCUMENT_SIGNER_STATUS_REVIEWED && (
                                        <div className="info-details__purpose">
                                          <div className="document-list__select-filters">
                                            <MemberSigningPurpose
                                              purpose={member.purpose}
                                              onChangeCallback={(memberPurpose) => this.props.addPurposeInMember(index)(memberPurpose)}
                                              onBlur={this.props.handleBlur}
                                            />
                                          </div>
                                          <ControlButtons size="small">
                                            <ControlButton
                                              type="delete"
                                              title={t('common.remove')}
                                              showLabel={false}
                                              showTooltip={true}
                                              onClick={this.props.onRemoveClick.bind(this, index)}
                                            />
                                          </ControlButtons>
                                        </div>
                                      )}
                                    </div>
                                  )
                                }
                              </li>
                              {provided.placeholder}
                            </div>)}
                        </Draggable>)}
                    </div>)}
                </Droppable>
              </DragDropContext>
            </ul>
          </div>
        )}
        {this.props.memberLoading === true && (
          <Loader loadingText={t('user.document.document_check')} />
        )}
        <ModalShare
          size="small"
          active={this.state.modalShare.active}
          access={access}
          documentUuid={documentId}
          title={t('share_document')}
          addSigner={this.onAddSigner}
          addSigners={this.onAddSigners}
          onCloseClick={() => this.setState({ modalShare: { active: false, access: access } })}
          showStatusMessage={this.props.showStatusMessage}
          isBusinessPerson={this.props.isBusinessPerson}
          upload={isUpload}
        />
      </div>
    )
  }
}

Members.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  access: PropTypes.string,
  isSigningOrderImportant: PropTypes.bool,
  onSigningOrderImportantChange: PropTypes.func,
  documentId: PropTypes.string,
  updateMember: PropTypes.func,
  members: PropTypes.array,
  addSigner: PropTypes.func,
  upload: PropTypes.bool,
  newLine: PropTypes.bool,
  canInviteSigner: PropTypes.bool,
  onAccessChange: PropTypes.func,
  onDragEnd: PropTypes.func,
  ownerEmail: PropTypes.string,
  removeMember: PropTypes.func,
  onRemoveClick: PropTypes.func,
  isBusinessPerson: PropTypes.bool,
  addPurposeInMember: PropTypes.func,
  handleBlur: PropTypes.func,
  purpose: PropTypes.string,
  isTemporary: PropTypes.bool,
  uploadedSignedMembers: PropTypes.array,
  memberLoading: PropTypes.bool,
  isSaving: PropTypes.func,
  user: PropTypes.object,
  addSigners: PropTypes.func,
}

export default withTranslation()(Members)