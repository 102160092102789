let embedded = false

export function embedScript(onload) {
  if (embedded) {return}

  const s1= document.createElement('script')
  const s0= document.getElementsByTagName('script')[0]

  s1.async = true
  s1.src = 'https://embed.tawk.to/665988d9981b6c564776b544/1hv6rt5c9'
  s1.charset = 'UTF-8'
  s1.setAttribute('crossorigin','*')

  if (onload) {
    s1.onload = onload
  }

  s0.parentNode.insertBefore(s1,s0)
  embedded = true
}

function getApi() {
  return new Promise(res => {
    setInterval(() => {
      if (window.Tawk_API) {
        res(window.Tawk_API)
      }
    }, 100)
  })
}

export async function hideWidget() {
  const tawkApi = await getApi()

  tawkApi.hideWidget()
}

export async function showLiveChat() {
  const tawkApi = await getApi()

  tawkApi.maximize()
}

export async function hideLiveChat() {
  const tawkApi = await getApi()

  tawkApi.minimize()
}

export async function toggleLiveChat() {
  const tawkApi = await getApi()

  return tawkApi.isChatMinimized() ? showLiveChat() : hideLiveChat()
}