import React from 'react'
import { ControlButton } from '~/js/components/ControlButtons'
import Company from '~/img/icons/company.svg'
import PropTypes from 'prop-types'
import User from '~/img/icons/user.svg'

export default class Workspace extends React.Component {
  render() {
    const { t } = this.props

    return (
      <li>
        <div className="table__body__col">
          <div className="user">
            <div className="user__logo">
              <img
                src={this.props.isBusiness ? Company : User}
                alt={this.props.isBusiness ? 'Company' : 'User'}
              />
            </div>
            <p><strong>{this.props.name}</strong></p>
          </div>
        </div>
        <div className="table__body__col">
          {this.props.role && (<p>{t('user.settings.company_user.'+this.props.role)}</p>)}
        </div>
        <div className="table__body__col">
          {this.props.isAdmin && (
            <a className="btn btn--add btn--icon js-open-popup" onClick={this.props.onEnableFlexibleSubscriptionClick}>
              {t('user.settings.user.enable_flexible_subscription')}
            </a>
          )}
        </div>
        <div className="table__body__col table__body__col--overflow">
          <div className="col__right full__width">
            {this.props.canSwitchTo && (
              <a
                className="status status--switch js-open-popup"
                href="#"
                onClick={this.props.onSwitchClick}
              >
                {t('user.settings.workspace.switch_workspace')}
              </a>
            )}
            {this.props.canDelete && (
              <ControlButton
                type="delete"
                title={t('common.remove')}
                showLabel={false}
                showTooltip={true}
                onClick={this.props.onDeleteClick}
              />
            )}
            {this.props.canQuit && (
              <ControlButton
                type="quit"
                title={t('quit')}
                showLabel={false}
                showTooltip={true}
                onClick={this.props.onQuitClick}
              />
            )}
          </div>
        </div>
      </li>
    )
  }
}

Workspace.propTypes = {
  onQuitClick: PropTypes.func,
  t: PropTypes.func,
  canQuit: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  canDelete: PropTypes.bool,
  onSwitchClick: PropTypes.func,
  canSwitchTo: PropTypes.bool,
  onEnableFlexibleSubscriptionClick: PropTypes.func,
  isAdmin: PropTypes.bool,
  role: PropTypes.string,
  name: PropTypes.string,
  isBusiness: PropTypes.bool,
}
