import { ACTION_SET_SELECTED_ZEAL_DOCS } from '~/js/actions'
import { DOCUMENT_FORMAT_PDF } from '~/js/models/Document'
const currentTime = new Date().getTime()
let totalZealDeadlineExpired = 0
let totalZealOtherType = 0
let selectedDocuments = []

export default (
  state = {
    selectedZealDocIds: [],
    totalZealDeadlineExpired: 0,
    totalZealOtherType: 0,
    alreadySigned: 0,
  },
  action
) => {
  switch (action.type) {
  case ACTION_SET_SELECTED_ZEAL_DOCS:

    selectedDocuments = action.selectedZealDocuments.filter(d => {
      let select = true
      if (d.deadline && d.signForbid && new Date(d.deadline).getTime() < currentTime) {
        totalZealDeadlineExpired++
        select = false
      } else if (d.format !== DOCUMENT_FORMAT_PDF) {
        totalZealOtherType++
        select = false
      }

      return select
    })

    return Object.assign({}, state, {
      selectedZealDocIds: selectedDocuments.map(document => document.uuid),
      totalZealDeadlineExpired: totalZealDeadlineExpired,
      totalZealOtherType: totalZealOtherType,
      alreadySigned: action.alreadySigned,
    })
  default:
    return state
  }
}
