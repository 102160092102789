import React from 'react'
import { formatDateTimeNoSeconds } from '~/js/utils/date'
import PropTypes from 'prop-types'

export default class HistoryRecord extends React.Component {
  render() {
    const { t } = this.props
    return (
      <li>
        <div className="info-details__label">
          <label>{formatDateTimeNoSeconds(this.props.date)}</label>
        </div>
        <div className="info-details__dots" />
        <div className="info-details__info info-details__info--column">
          <p>{this.props.fullname}</p>
          <p className="info-details__document-history">
            <b>{t('user.document.'+this.props.action)}</b>
            {this.props.action !== 'purpose_updated' && (
              <span> {t('user.document.document_history').toLowerCase()}</span>
            )}
          </p>
        </div>
      </li>
    )
  }
}

HistoryRecord.propTypes = {
  t: PropTypes.func,
  date: PropTypes.string,
  fullname: PropTypes.string,
  action: PropTypes.string,
}