import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import successImg from '~/img/illustration/empty-document-new.svg'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import Language from '~/js/components/Language'
import Loader from '~/js/components/Loader'
import PropTypes from 'prop-types'

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props)

    const { params } = this.props.match

    this.state = {
      loading: true,
      person: null,
    }

    this.requestConfirmEmail(params.uuid)
  }

  requestConfirmEmail(uuid) {
    const { history, confirmEmailClick } = this.props

    apiClient
      .post(`v1/confirm-email/${uuid}`)
      .then(({ data: { data } }) => {
        data.person && confirmEmailClick(data.person)
        this.setState({ loading: false, person: data.person })
      })
      .catch(err => {
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
        history.push(generatePath(ROUTE_NAMES.USER_LOGIN))
      })
  }

  render() {
    const { loading, person } = this.state
    const { t } = this.props

    return (
      <div id="page-container" className="page-container--full-height">
        <section id="pricing-success">
          <div className="grid">
            <div className="pricing-success__content ta-center">
              <img className="logo" src={markIdLogo} alt="Mark ID" />
              {loading ? <Loader loadingText={t('user.validation.loading_data')} /> : (
                <>
                  <img src={successImg} alt={t('user.documents')} />
                  <h1 className="standard-title">{t('user.yay_your_email_is_confirmed')}</h1>
                  <p className="message">{t('user.press_continue_to_upload_document')}</p>
                  {person && (
                    <div className="pricing-success__user-info d-none">
                      <p className="pricing-success__user-info--name">{person.fullName}</p>
                      <p id="user-email-confirm-page" className="pricing-success__user-info--email">{person.email}</p>
                      <p id="user-phone-number-confirm-page" className="pricing-success__user-info--phone-number">{person.phone}</p>
                    </div>
                  )}
                  <Link className="btn btn--green" to={generatePath(ROUTE_NAMES.USER_LOGIN)}>{t('common.continue')}</Link>
                </>
              )}
            </div>
          </div>
          <Language />
        </section>
      </div>
    )
  }
}

export default withTranslation()(ConfirmEmail)

ConfirmEmail.propTypes = {
  t: PropTypes.func,
  params: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  showStatusMessage: PropTypes.func,
  confirmEmailClick: PropTypes.func,
}