import React from 'react'
import PropTypes from 'prop-types'
import Info from '../../../img/icons/info-slick-white.svg'
import InformationBar from '~/js/components/InformationBar'

let copiedTimeout

export default class FormGroupCopy extends React.Component {
  constructor(props) {
    super(props)

    this.inputField = React.createRef()
    this.state = {
      copied: false,
    }
  }

  componentWillUnmount() {
    clearTimeout(copiedTimeout)
  }

  copyValueToClipboard = () => {
    const elm = this.inputField.current

    elm.select()
    elm.setSelectionRange(0, 99999) /* For mobile devices */

    document.execCommand('copy')
    this.setStateCopied()
  }

  setStateCopied = () => {
    this.setState({ copied: true })

    copiedTimeout = setTimeout(() => {
      this.setState({ copied: false })
    }, 1500)
  }

  render() {
    const { t, expireAfter, signingLinkError } = this.props

    return (
      <div>
        {signingLinkError !== '' ? (
          <InformationBar
            icon={Info}
            iconAlt="Info"
            informationText={signingLinkError}
            informationClass='error'
          />
        ) :
          <>
            <div className="form-group form-group--share-link">
              <input
                ref={this.inputField}
                className="form-group__input"
                type="text"
                defaultValue={this.props.value}
              />
              <button
                className={'btn btn--primary' + (this.state.copied ? ' copied' : '')}
                type="button"
                id="copy-link"
                onClick={this.copyValueToClipboard}
              >
                <span className="primary-state">{t('user.document.copy_link')}</span>
                <span className="secondary-state">{t('common.copied')}</span>
              </button>
              <br />

            </div>
            <br />
            <p className="warning">* {t('user.document.document_signing_link_will_expire', { expireAfter: expireAfter })}</p>
          </>
        }

      </div>
    )
  }
}

FormGroupCopy.propTypes = {
  expireAfter: PropTypes.string,
  t: PropTypes.func,
  value: PropTypes.string,
  signingLinkError: PropTypes.string,
}