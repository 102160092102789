import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FormGroupCopy from '~/js/components/FormGroupCopy'
import FormSigner from '~/js/components/FormSigner'
import Modal from '~/js/components/Modal'
import { Tab, TabsList } from '~/js/components/TabsList'
import FormContactInvite from '../FormContactInvite'
import PropTypes from 'prop-types'
import * as documentService from '~/js/services/user/document'

function initializeTabs(signLinkHidden) {
  return [
    {
      id: 1,
      name: 'common.signing_link',
      active: false,
      hidden: signLinkHidden,
    },
    {
      id: 2,
      name: 'user.document.invite_contact',
      active: true,
      hidden: false,
    },
    {
      id: 3,
      name: 'user.document.invite_via_email',
      active: false,
      hidden: false,
    },
  ]
}

class ModalShare extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: initializeTabs(props.upload),
      signingLink: '',
      expireAfter: '',
      signingLinkError: ''
    }

    this.onCloseClick = this.onCloseClick.bind(this)
    this.getDocumentSigningLink = this.getDocumentSigningLink.bind(this)
    this.resetTabs = this.resetTabs.bind(this)
  }

  resetTabs() {
    this.setState({ tabs: initializeTabs(this.props.upload) })
  }

  onTabClick(tabIndex) {
    const tabs = this.state.tabs

    tabs.forEach(tab => tab.active = false)
    tabs[tabIndex].active = true

    if (tabIndex === 0) {
      this.getDocumentSigningLink()
    }

    this.setState({ tabs })
  }

  getDocumentSigningLink() {
    const { langCode } = this.props

    documentService
      /* eslint-disable camelcase */
      .getTemporarySigningLink({
        document_id: this.props.documentUuid,
        from_platform: true,
        expire_after: 20160,
        language: langCode,
      })
      /* eslint-enable camelcase */
      .then((data) => {
        this.setState({ signingLink: data.temporary_signing_link, expireAfter: data.valid_until })
      })
      .catch(error => {
        this.setState({ signingLinkError: error.message })
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.access !== this.props.access) {
      this.resetTabs()
    }
  }

  onCloseClick() {
    this.resetTabs()
    this.props.onCloseClick()
  }

  render() {
    if (!this.props.active) {return null}

    const { t } = this.props

    return (
      <Modal
        {...this.props}
        onCloseClick={this.onCloseClick}
      >
        <React.Fragment>
          <TabsList centered={false}>
            {this.state.tabs.map((tab, index) => (
              !tab.hidden ?
                <Tab
                  key={tab.id}
                  active={tab.active}
                  title={t(tab.name)}
                  onClick={this.onTabClick.bind(this, index)}
                /> : null
            ))}
          </TabsList>
          <div className="tabs-content">
            {!this.state.tabs[0].hidden &&
            <div className={'tabs-content__tab' + (this.state.tabs[0].active ? ' active' : '')}>
              <FormGroupCopy
                t={t}
                value={this.state.signingLink}
                expireAfter={this.state.expireAfter}
                signingLinkError={this.state.signingLinkError}
              />
            </div>
            }
            {!this.state.tabs[1].hidden &&
            <div className={'tabs-content__tab' + (this.state.tabs[1].active ? ' active' : '')}>
              <FormContactInvite
                t={t}
                addSigner={this.props.addSigner}
                addSigners={this.props.addSigners}
                showStatusMessage={this.props.showStatusMessage}
                isBusinessPerson={this.props.isBusinessPerson}
              />
            </div>
            }
            {!this.state.tabs[2].hidden &&
            <div className={'tabs-content__tab' + (this.state.tabs[2].active ? ' active' : '')}>
              <FormSigner
                t={t}
                upload={this.props.upload}
                addSigner={this.props.addSigner}
                showStatusMessage={this.props.showStatusMessage}
                isBusinessPerson={this.props.isBusinessPerson}
              />
            </div>
            }
          </div>
        </React.Fragment>
      </Modal>
    )
  }
}

const ModalShareWithTranslation = withTranslation()(ModalShare)
const mapStateToProps = state => ({
  langCode: state.i18n.langCode,
})
export default connect(
  mapStateToProps,
  null
)(ModalShareWithTranslation)

ModalShare.propTypes = {
  access: PropTypes.string,
  documentUuid: PropTypes.string,
  onCloseClick: PropTypes.func,
  active: PropTypes.bool,
  t: PropTypes.func,
  addSigner: PropTypes.func,
  addSigners: PropTypes.func,
  showStatusMessage: PropTypes.func,
  isBusinessPerson: PropTypes.bool,
  upload: PropTypes.bool,
  langCode: PropTypes.string,
}