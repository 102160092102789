import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InformationBar from '~/js/components/InformationBar'
import InfoIcon from '../../../../img/icons/info-slick-white.svg'
import ErrorIcon from '~/img/icons/error-logo.svg'
import { Link } from 'react-router-dom'
import { generatePath } from '~/js/routes/router'

export default function UsbSigning({ onStartSigning, loading, isDocumentsSigned, usbErrorMessage, selectedAllCanBeSigned }) {
  const { t } = useTranslation()

  const [isSigningEnabled, setIsSigningEnabled] = useState(0)
  const [hadAgreedOnConditions, setHadAgreedOnConditions] = useState(0)

  useEffect(() => {
    resolveSignatureEnablement()
  }, [isDocumentsSigned, hadAgreedOnConditions])

  const resolveSignatureEnablement = () => {
    setIsSigningEnabled(!isDocumentsSigned ? hadAgreedOnConditions : false)
  }

  const resolveConditionsAgreement = () => {
    setHadAgreedOnConditions(!hadAgreedOnConditions)
    resolveSignatureEnablement()
  }

  return (
    <div className="usb-signing">
      <InformationBar
        icon={InfoIcon}
        iconAlt="Info"
        informationClass="info"
        informationText={t('user.document.signing_with_usb_info')}
      />
      {!selectedAllCanBeSigned && (
        <InformationBar
          icon={ErrorIcon}
          iconAlt="Error icon"
          informationClass="error"
          informationText={t('user.document.some_documents_cant_signed')}
        />
      )}
      {usbErrorMessage &&
        <InformationBar
          icon={ErrorIcon}
          iconAlt="Error icon"
          informationClass="error"
          informationText={
            <>
              {t(usbErrorMessage.errorText)}
              &nbsp;
              {usbErrorMessage.errorLink &&
                <>
                  <Link
                    to={{ pathname: generatePath(usbErrorMessage.errorLink) }}
                    target="_blank"
                    className="anchor text-lowercase"
                  >
                    {t('user.login.more_information')}
                  </Link>
                  .
                </>
              }
            </>
          }
        />
      }

      <div className="wrapped-section mt-3">
        <div className="form-group form-group__checkbox" onClick={resolveConditionsAgreement}>
          <input
            id="signature-validation-checkbox"
            name="signatureValidation"
            value={hadAgreedOnConditions}
            type="checkbox"
            onChange={resolveConditionsAgreement}
            disabled={loading || isDocumentsSigned}
            checked={hadAgreedOnConditions}
          />
          <label htmlFor="signature-validation" id="signature-validation-checkbox-label">
            {t('user.document.validate_your_signature')}
          </label>
        </div>
      </div>

      <div className="usb-signing__button">
        <button
          className={`btn btn--primary ${loading ? 'loading' : ''}`}
          disabled={loading || !isSigningEnabled}
          onClick={onStartSigning}
        >
          {t('start_signing')}
        </button>
      </div>
    </div>
  )
}

UsbSigning.propTypes = {
  onStartSigning: PropTypes.func,
  loading: PropTypes.bool,
  isDocumentsSigned: PropTypes.bool,
  usbErrorMessage: PropTypes.object,
  selectedAllCanBeSigned: PropTypes.bool,
}
