// @flow

import type { Node } from 'react'
import Modal from '../Modal'
import { useEffect } from 'react'
import { getMarkSignAppHost } from '../../utils/host'

type Props = {
  active?: boolean,
  onCloseClick: () => void,
  src: string,
  onModalActivityCompletion: () => void
}

export default function Modal3DSConfirmation({
  active, onCloseClick, src, onModalActivityCompletion
}: Props): Node {
  useEffect(() => {
    const listener = (event: any) => {
      event.origin === `https://${getMarkSignAppHost()}`
      && event.data.activityStatus === 'completed' && onModalActivityCompletion()
    }
    window.addEventListener('message', listener, false)
    return () => window.removeEventListener('message', listener)
  }, [])

  return (
    <Modal
      active={active}
      className="popup--pdf-view"
      size="small"
      onCloseClick={onCloseClick}
    >
      <iframe src={src} style={{ border: '0px', height: '400px' }}></iframe>
    </Modal>
  )
}
