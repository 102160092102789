import _throttle from 'lodash/throttle'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { saveState, loadState } from './localStorage'
import { isDebug } from '../config'
import reducers from '../reducers'

function configureStore(preloadedState) {
  const enhancers = [
    applyMiddleware(thunkMiddleware),
  ]

  if (isDebug && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }

  const composedEnhancers = compose(...enhancers)
  const store = createStore(reducers, preloadedState, composedEnhancers)

  store.subscribe(_throttle(() => {
    saveState({
      cart: store.getState().cart,
    })
  }, 1000))

  return store
}

export default configureStore(loadState())