import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ControlButtonsLicense from '~/js/components/ControlButtonsLicense'

export default function License(props) {
  const { t } = useTranslation()
  return (
    <li className="table__row" key={props.id}>
      <div className="table__body__col">
        <p><strong>{props.subscription}</strong></p>
      </div>
      <div className="table__body__col">{props.expirationDate}</div>
      <div className="table__body__col">
        <p>{props.businessPerson ? t('user.settings.company_user.assigned') : t('user.settings.company_user.unassigned')}</p>
      </div>

      <div className="table__body__col table__body__col--status">
        <p>{props.businessPersonName}</p>
      </div>

      <ControlButtonsLicense
        t={t}
        noText={true}
        className="table__body__col table__body__col--overflow"
        canAssignSubscription={props.businessPersonName === null}
        canUnAssignSubscription={props.businessPersonName !== null && props.businessPersonEmail !== props.businessOwnerEmail}
        onAssignSubscriptionClick={props.onAssignSubscriptionClick}
        onUnAssignSubscriptionClick={props.onUnAssignSubscriptionClick}
      />
    </li>
  )
}

License.propTypes = {
  subscription: PropTypes.string,
  expirationDate: PropTypes.string,
  businessPersonName: PropTypes.string,
  onAssignSubscriptionClick: PropTypes.func,
  onUnAssignSubscriptionClick: PropTypes.func,
  id: PropTypes.number,
  businessPerson: PropTypes.number,
  businessPersonEmail: PropTypes.string,
  businessOwnerEmail: PropTypes.string,
}