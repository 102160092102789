// @flow

import * as React from 'react'

export const SM_TYPE_SUCCESS = 'success'
export const SM_TYPE_ERROR = 'warning'

export enum Type {
  Success = 'success',
  Error = 'warning',
}

type DefaultProps = {
  type: Type,
  onCloseClick: () => void,
}

type Props = {
  ...DefaultProps,
  active?: boolean,
  fading?: boolean,
  fullWidth?: boolean,
  className?: string,
  message: string,
}

export default class StatusMessage extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    type: Type.Success,
    onCloseClick: () => {},
  }

  render(): React.Node {
    const { active, type, fullWidth, fading, message, onCloseClick } = this.props
    const typeValue: string = (type: string)

    if (!active) {
      return null
    }

    let className = `statusbar statusbar--${typeValue} js-active`

    if (fullWidth) {
      className += ' statusbar--full-width'
    }

    if (fading) {
      className += ' statusbar--fading'
    }

    if (this.props.className) {
      className += ` ${this.props.className}`
    }

    return (
      <div className={className}>
        <p>{message}</p><span className="close" onClick={onCloseClick} />
      </div>
    )
  }
}