import React from 'react'
import PropTypes from 'prop-types'
import * as statisticsService from '~/js/services/user/statistics'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import Datetime from '~/js/components/DateTime'
import { formatDate } from '~/js/utils/date'

export default class Statistics extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      endDate: '',
      startDate: '',
      'businessStatistics': null
    }

    this.findCompanyStatistics = this.findCompanyStatistics.bind(this)
    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onEndDateChange = this.onEndDateChange.bind(this)
  }

  onStartDateChange(newEndDate)
  {
    this.setState({ startDate: formatDate(newEndDate) })
  }

  onEndDateChange(newEndDate)
  {
    this.setState({ endDate: formatDate(newEndDate) })
  }

  findCompanyStatistics(startDate, endDate)
  {
    const { showStatusMessage } = this.props

    statisticsService
      .getBusinessStatistics(startDate, endDate)
      .then((result) => {
        this.setState({
          businessStatistics: result.data.businessStatistics
        })
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this

    if (state.startDate !== prevState.startDate || state.endDate !== prevState.endDate) {
      this.findCompanyStatistics(state.startDate, state.endDate)
    }
  }

  render() {
    const { t } = this.props
    const { startDate, endDate, businessStatistics } = this.state

    return (
      <div className="wrapped-section statistics">
        <div className="statistics__datepicker-row">
          <div className="form-group form-group--select">
            <label>{t('common.from')}</label>
            <Datetime
              value={startDate}
              onChange={this.onStartDateChange}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ className: 'form-group__input', readOnly: true }}
              showPastDate={true}
            />
          </div>
          <div className="form-group form-group--select">
            <label>{t('common.to')}</label>
            <Datetime
              value={endDate}
              onChange={this.onEndDateChange}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ className: 'form-group__input', readOnly: true }}
              showPastDate={true}
            />
          </div>
        </div>
        <div className="statistics__pin-one">
          <div className="wrapped-section__headline">
            <h2 className="headline headline--standard">{t('user.settings.statistics_tab.pin_one_header')}</h2>
          </div>
          <div className="info-details info-details--standard">
            <ul>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_one_smart_id')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.skSmartId.pin1 ? businessStatistics.skSmartId.pin1 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_smart_id')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.skSmartId.pin2 ? businessStatistics.skSmartId.pin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_one_zeal_id')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.zealId.pin1 ? businessStatistics.zealId.pin1 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_one_smart_card_usb')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.smartCardUsb.pin1 ? businessStatistics.smartCardUsb.pin1 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_smart_card_usb')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.smartCardUsb.pin2 ? businessStatistics.smartCardUsb.pin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_smart_id_platform')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.platform.smartIdPin2 ? businessStatistics.platform.smartIdPin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_mobile_id_platform')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.platform.mobileIdPin2 ? businessStatistics.platform.mobileIdPin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_zeal_id_platform')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.platform.zealIdPin2 ? businessStatistics.platform.zealIdPin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.pin_two_smart_card_usb_platform')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.platform.smartCardUsbPin2 ? businessStatistics.platform.smartCardUsbPin2 : '-'}</span>
                </div>
              </li>
              <li>
                <div className="info-details__label info-details__label--shorter">
                  <label>{t('user.settings.statistics_tab.document_validation_token')}</label>
                </div>
                <div className="info-details__info">
                  <span>{businessStatistics && businessStatistics.totalValidationByToken ? businessStatistics.totalValidationByToken : '-'}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

Statistics.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
}