import React from 'react'
import Modal from '~/js/components/Modal'
import PropTypes from 'prop-types'
import UsbCard from '../../../img/icons/usb-card.png'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import Loader from '~/js/components/Loader'

export default class ModalSignWithUsb extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputType: 'password',
      showPassword: 'invisible',
    }

    this.toggleInputType = this.toggleInputType.bind(this)
  }

  toggleInputType() {
    const { inputType } = this.state

    this.setState({
      showPassword: this.state.showPassword === 'invisible' ? 'visible': 'invisible',
      inputType: inputType === 'password' ? 'text': 'password'
    })
  }

  render() {
    const { t, onSignDocuments, loading, totalSigned, total } = this.props
    const { inputType } = this.state

    const initialValues = {
      newPassword: ''
    }
    const validationSchema = Yup.object().shape({
      newPassword: Yup.string()
        .required(t('validation.field_is_required')),
    })

    return (
      <Modal
        className="popup__user"
        size="small"
        {...this.props}
        closable={!loading}
      >
        <div className="modal-sign-usb">
          <div className="modal-sign-usb__header">
            <h3 className="standard-title ta-center">{t('user.document.sign_with_usb_key_chip_card')}</h3>
            <img src={UsbCard} alt="Usb Card" />
          </div>
          {loading && <Loader loadingText={t('user.document.documents_signed_among_documents', { totalSigned: totalSigned, total: total })} />}
          {!loading && (
            <>
              <div className="modal-sign-usb__form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSignDocuments}
                >
                  {formikProps => (
                    <Form>
                      <div className={'form-group' + (formikProps.errors.newPassword && formikProps.touched.newPassword ? ' form-group--error' : '')}>
                        <label>{t('device_password')}</label>
                        <Field className="form-group__input" type={inputType} name="newPassword" />
                        <div className="fl-right">
                          <span
                            className={`form-btn form-btn--${this.state.showPassword}`}
                            onClick={this.toggleInputType}
                          />
                        </div>
                        <ErrorMessage className="form-group__error" name="newPassword" component="span" />
                      </div>
                      <button type="submit" className="btn btn--primary">
                        {t('sign')}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="modal-sign-usb__info">
                <p>{t('user.document.usb_before_signing_info')}</p>
              </div>
            </>
          )}
        </div>
      </Modal>
    )
  }
}

ModalSignWithUsb.propTypes = {
  t: PropTypes.func,
  onSignDocuments: PropTypes.func,
  loading: PropTypes.bool,
  totalSigned: PropTypes.number,
  total: PropTypes.number,
}
