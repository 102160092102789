import React from 'react'
import Modal from '~/js/components/Modal'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import * as workspaceService from '~/js/services/user/workspace'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import PropTypes from 'prop-types'

export default class AcceptInvitation extends React.Component {
  constructor(props) {
    super(props)
    const { workspaceId } = props.match.params

    this.state = {
      workspace: null,
    }

    this.fetchWorkspace(workspaceId)
  }

  fetchWorkspace(workspaceId) {
    workspaceId = workspaceId || this.state.workspace.id

    workspaceService.getWorkspace(workspaceId)
      .then(({ data: workspace }) => this.setState({ workspace }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onDeclineClick = () => {
    this.props.history.push(generatePath(ROUTE_NAMES.USER_DASHBOARD))
  }

  onAcceptClick = () => {
    const { workspace } = this.state
    const { t, history, showStatusMessage, fetchUser } = this.props
    const { businessPersonId } = this.props.match.params

    workspaceService.joinWorkspace(workspace.id, businessPersonId)
      .then(() => fetchUser())
      .then(() => {
        showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.workspace.you_have_successfully_joined_workspace'))
        history.push(generatePath(ROUTE_NAMES.USER_DASHBOARD))
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  render() {
    const { t } = this.props
    const { workspace } = this.state

    return (
      <main>
        <StatusMessages />
        <Modal
          active={true}
          closable={true}
          title={t('user.settings.workspace.confirm_invitation_to_join_workspace')}
          onCloseClick={this.onDeclineClick}
        >
          { workspace && (
            <table className="table" style={{ marginTop: '20px' }}>
              <tbody>
                <tr>
                  <th scope="row" className="ta-left">{t('user.settings.workspace.company_name')}</th>
                  <td>{workspace.businessName}</td>
                </tr>
                <tr>
                  <th scope="row" className="ta-left">{t('user.settings.workspace.registration_code')}</th>
                  <td>{workspace.registrationCode}</td>
                </tr>
                <tr>
                  <th scope="row" className="ta-left">{t('user.settings.workspace.address')}</th>
                  <td>{workspace.address}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div style={{ marginTop: '20px' }}>
            {t('user.settings.workspace.do_you_really_want_to_join_this_workspace')}
          </div>
          <div className="center-element center-element--space-between" style={{ marginTop: '15px' }}>
            <button className="btn btn--grey" onClick={this.onDeclineClick}>{t('common.decline')}</button>
            <button className="btn btn--primary" onClick={this.onAcceptClick}>{t('common.accept')}</button>
          </div>
        </Modal>
      </main>
    )
  }
}

AcceptInvitation.propTypes = {
  t: PropTypes.func,
  fetchUser: PropTypes.func,
  showStatusMessage: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
}