import React from 'react'
import markIdLogo from '~/img/marksign-logo-blue.svg'
import pillShape from '~/img/shape/pill.svg'
import circle from '~/img/shape/circle.svg'
import circleOne from '~/img/shape/circle_one.png'
import circleTwo from '~/img/shape/circle_two.png'
import Language from '../../../components/Language'
import { withTranslation } from 'react-i18next'
import config from '~/js/config'
import PropTypes from 'prop-types'
import StatusMessages from '~/js/containers/StatusMessages'
import { generatePath } from '../../router'
import { ROUTE_NAMES } from '../../config'
import { getSimplySignAccessToken } from '../../../services/user/user'
import { SM_TYPE_ERROR } from '../../../components/StatusMessages'
import { Redirect } from 'react-router-dom'
import Loader from '../../../components/Loader'
class SimplySignCallback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      redirectTo: undefined,
    }

    this.findAccessToken = this.findAccessToken.bind(this)
    this.findAccessToken()
  }

  findAccessToken() {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')

    if (code === '') {
      this.props.showStatusMessage(SM_TYPE_ERROR, 'No code found')
      return false
    }

    getSimplySignAccessToken(code)
      .then(data => {

        this.props.setSessionId(data.sessionId)
        this.props.setSerialNumber(data.serialNumber)

        setTimeout(() => {
          this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_WORKSPACES) })
        }, 1000)
      })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
        setTimeout(() => {
          this.setState({
            isLoading: false,
            redirectTo: generatePath(ROUTE_NAMES.USER_LOGIN),
          })
        }, 1000)
      })
  }

  render() {
    const { t } = this.props
    const { isLoading } = this.state

    return (
      <>
        {isLoading && <Loader loadingText={t('common.loading')} />}

        {!isLoading &&
          <div className="usb-page-container--full-height" id="usb-page-container">
            <section id="login" className="usb-error login-form-container software_accordion">
              <div className="grid">
                <a href={`https://${config.get('MARKSIGN_HOST')}`} target="_self">
                  <img src={markIdLogo} alt="Logo" className="login__logo" />
                </a>
                <div className="usb__container">
                  <div>
                    <h4 className="ta-center heading-text">SimplySign Login</h4>
                    <h5 className="ta-center">Login using Simply Sign</h5>
                  </div>
                  <div className="middle-bar">
                    <div className="middle-logo-round middle-logo-left">
                      <img src={circleOne} alt="" />
                      <h6 dangerouslySetInnerHTML={{ __html: t('usb.mark_sign_software_top') }}></h6>
                    </div>
                    <div className="center-round-icon">
                      <img src={pillShape} alt="" />
                      <img src={circle} alt="" />
                      <img src={circle} alt="" />
                    </div>
                    <div className="middle-logo-round middle-logo-right">
                      <img src={circleTwo} alt="" />
                      <h6>Authentication from Simply Sign App</h6>
                    </div>
                  </div>

                  <section className="middle-section"></section>
                </div>
              </div>
              <div className="usb-lang">
                <Language />
              </div>
            </section>
            <StatusMessages />
            {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
          </div>
        }
      </>
    )
  }
}

export default withTranslation()(SimplySignCallback)

SimplySignCallback.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  setSessionId: PropTypes.func,
  setSerialNumber: PropTypes.func,
}
