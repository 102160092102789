import React from 'react'
import SignatureFrontTopPage from '~/img/illustration/signature_front_top.png'
import SignatureBackTopPage from '~/img/illustration/signature_back_top.png'
import SignatureFrontBottomPage from '~/img/illustration/signature_front_bottom.png'
import SignatureBackBottomPage from '~/img/illustration/signature_back_bottom.png'
import NoSignature from '~/img/illustration/no_signature.png'

class RadioButtonDocSign extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      signaturesPlacingPosition: '2',
    };
  }

  render() {
    const { signaturesPlacingPosition, onRadioButtonDocSignCheck, t } = this.props;
    return (
      <div className="form-group_signatures">
        <label>
          <input type="radio" name="signaturePosition" value="2"
                 checked={signaturesPlacingPosition === "2"}
                 onChange={(e) => onRadioButtonDocSignCheck(e.target.value)}
          />
          <img src={SignatureFrontTopPage} alt="Signature"/>
          <p>{t('user.document.signature_position_first_top')}</p>
        </label>
        <label>
          <input type="radio" name="signaturePosition" value="3"
                 checked={signaturesPlacingPosition === "3"}
                 onChange={(e) => onRadioButtonDocSignCheck(e.target.value)}
          />
          <img src={SignatureFrontBottomPage} alt="Signature"/>
          <p>{t('user.document.signature_position_first_bottom')}</p>
        </label>
        <label>
          <input type="radio" name="signaturePosition" value="4"
                 checked={signaturesPlacingPosition === "4"}
                 onChange={(e) => onRadioButtonDocSignCheck(e.target.value)}
          />
          <img src={SignatureBackTopPage} alt="Signature"/>
          <p>{t('user.document.signature_position_last_top')}</p>
        </label>
        <label>
          <input type="radio" name="signaturePosition" value="5"
                 checked={signaturesPlacingPosition === "5"}
                 onChange={(e) => onRadioButtonDocSignCheck(e.target.value)}
          />
          <img src={SignatureBackBottomPage} alt="Signature"/>
          <p>{t('user.document.signature_position_last_bottom')}</p>
        </label>
        <label>
          <input type="radio" name="signaturePosition" value="1"
                 checked={signaturesPlacingPosition === '1'}
                 onChange={(e) => onRadioButtonDocSignCheck(e.target.value)}
          />
          <img src={NoSignature} alt="Signature"/>
          <p>{t('user.document.signature_position_invisible')}</p>
        </label>
      </div>
    )
  }
}

export default RadioButtonDocSign