import React from 'react'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import apiClient from '~/js/utils/apiClientDocs'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import PropTypes from 'prop-types'

class NeopayUserCallback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: null,
      transactionDetails: {},
      product: {}
    }

    this.fetchInfo()
  }

  fetchInfo() {
    const { location } = this.props

    Promise.all([
      this.fetchTransactionInfo(location.search),
      this.props.getSessionStatus(),
    ])
      .then(([ transactionData, sessionStatus ]) => {
        const workspace = sessionStatus.workspace.status === 'active' ? 'workspace' : 'user'

        if (location.search.indexOf('canceled=1') !== -1) {
          this.setState({ redirect: generatePath(ROUTE_NAMES.USER_PRICING, { workspace }) })
        } else {
          const product = transactionData.products[0]
          const transaction = transactionData.transaction
          const productQuantity = transactionData.products.length
          let priceIncludingVat = product.priceIncludingVat.replace(',', '.')
          priceIncludingVat = priceIncludingVat.indexOf('€') === 0 ? priceIncludingVat.substr(1) : priceIncludingVat.substr(0, priceIncludingVat.indexOf('€'))
          priceIncludingVat = Math.round((parseFloat(priceIncludingVat) * productQuantity) * 100) / 100

          let price = product.price.replace(',', '.')
          price = price.indexOf('€') === 0 ? price.substr(1) : price.substr(0, price.indexOf('€'))
          transaction.price = Math.round((parseFloat(price) * productQuantity) * 100) / 100

          transaction.tax = Math.round((((priceIncludingVat - transaction.price) / priceIncludingVat) * transaction.amount) * 100) / 100
          transaction.finalPrice = transaction.amount - transaction.tax
          transaction.discount = Math.round((transaction.price - transaction.finalPrice) * 100) / 100
          transaction.quantity = productQuantity

          this.setState({
            transactionDetails: transaction,
            product: product
          })

          this.pushGTagPurchase(transaction, product)
          this.setState({ redirect: generatePath(ROUTE_NAMES.USER_PRICING_SUCCESS, { workspace }) })
        }
      })
      .catch(err => {
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)

        this.setState({ redirect: generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT, { workspace: 'user' }) })
      })

  }

  fetchTransactionInfo(search) {
    return apiClient.get(`v1/neopay/transaction-info${search}`)
      .then(({ data: { data } }) => data)
  }

  pushGTagPurchase = (transaction, product) => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: transaction.id,
            revenue: transaction.amount,
          },
          products: [{
            name: product.name,
            id: product.id,
            price: product.price,
            quantity: 1,
          }]
        }
      }
    })
  }

  render() {
    const { redirect, transactionDetails, product } = this.state

    return (
      <span>
        {redirect && (
          <Redirect
            to={{
              pathname: redirect,
              state: { transactionDetails: transactionDetails, product: product }
            }}
          />
        )}
      </span>
    )
  }
}

export default withTranslation()(NeopayUserCallback)

NeopayUserCallback.propTypes = {
  getSessionStatus: PropTypes.func,
  showStatusMessage: PropTypes.func,
  location: PropTypes.object,
}