import React from 'react'
import loadingImg from '~/img/illustration/loading.svg'
import Modal from '~/js/components/Modal'
import Timer from '~/js/components/Timer'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'

export default class ModalSessionEnd extends React.Component {
  render() {
    const { t, time } = this.props

    return (
      <Modal
        className="popup--session"
        active={true}
        closable={false}>
        <div className="ta-center">
          <img src={loadingImg} alt="Clock" />
          {time > 0 ? (
            <>
              <h3 className="standard-title">{t('user.login.your_session_is_about_to_end')}</h3>
              <Timer
                className="popup__timer"
                time={time} />
              <div className="center-element center-element--space-between">
                <a href={generatePath(ROUTE_NAMES.USER_LOGOUT)} className="btn btn--primary">{t('logout')}</a>
                <button className="btn btn--secondary" onClick={this.props.onExtendSessionClick}>{t('user.login.extend_session')}</button>
              </div>
            </>
          ) : (
            <>
              <h3 className="standard-title">{t('user.login.your_session_has_ended')}</h3>
              <Timer
                className="popup__timer"
                time={time} />
              <div className="center-element">
                <a href={generatePath(ROUTE_NAMES.USER_LOGIN)} className="btn btn--secondary">{t('user.login.login')}</a>
              </div>
            </>
          )}
        </div>
      </Modal>
    )
  }
}

ModalSessionEnd.defaultProps = {
  onExtendSessionClick: () => {},
}