// @flow

import type { ActionAddStatusMessage, ActionRemoveStatusMessage } from '../actions'
import type { Type } from '../components/StatusMessages/StatusMessage/StatusMessage'

type StatusMessage = {
  +id: number,
  +type: Type,
  +message: string,
  +createdAt: Date,
}

type SliceStatusMessages = StatusMessage[]

export default (
  state: SliceStatusMessages = [],
  action: ActionAddStatusMessage | ActionRemoveStatusMessage
): SliceStatusMessages => {
  switch (action.type) {
  case 'ADD_STATUS_MESSAGE':
    return [
      ...state,
      {
        id: action.id,
        type: action.statusMessageType,
        message: action.message,
        createdAt: new Date(),
      },
    ].sort((a, b) => a.id - b.id)
  case 'REMOVE_STATUS_MESSAGE':
    return state.filter(message => message.id !== action.id)
  default:
    return state
  }
}