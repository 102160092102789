//
// In this file, session means the period in which user is logged into a specific account
// So, there can be sessions for person and business person
//

const hourDiffForDecision = 0

const storageKey = 'uniqueEmailDecisionInfos'

const storeDecisionInfos = (uniqueEmailDecisionInfos) => localStorage.setItem(storageKey, JSON.stringify(uniqueEmailDecisionInfos))

const retrieveDecisionInfos = () => JSON.parse(localStorage.getItem(storageKey)) || {}

const getSessionKey = (user) => `${user.id}${user.workspaceId ? '_' + user.workspaceId : ''}`

const removeSessionDecisionInfos = (user) => {
  const uniqueEmailDecisionInfos = retrieveDecisionInfos()
  delete uniqueEmailDecisionInfos[getSessionKey(user)]
  storeDecisionInfos(uniqueEmailDecisionInfos)
}

const getSessionDecisionInfos = (user) => {
  const uniqueEmailDecisionInfos = retrieveDecisionInfos()
  return uniqueEmailDecisionInfos[getSessionKey(user)] || {
    identity: null,
    lastInteractionAt: 0
  }
}

export const updateSessionDecisionInfos = (user) => {
  const uniqueEmailDecisionInfos = retrieveDecisionInfos()
  uniqueEmailDecisionInfos[getSessionKey(user)] = {
    identity: user.identityId,
    lastInteractionAt: (new Date()).getTime()
  }
  storeDecisionInfos(uniqueEmailDecisionInfos)
}

export const canAskForUniqueEmailDecision = (user) => {
  //
  // This function will return true only if
  // 1. has not yet provided his decision and nonUniqueEmail is true
  // 2. has newly logged in and
  // 3. specific amount of time has passed since his last activity
  //
  const currentUserNld = getSessionDecisionInfos(user)
  !user.nonUniqueEmail && removeSessionDecisionInfos(user)
  return user.nonUniqueEmail && currentUserNld.identity !== user.identityId &&
    (!currentUserNld.lastInteractionAt || (new Date()).getTime() >= currentUserNld.lastInteractionAt + (hourDiffForDecision * 3600) * 1000)
}
