import React from 'react'
import PropTypes from 'prop-types'
import FilesViewer from './FilesViewer'
import { SM_TYPE_ERROR } from '../StatusMessages'
import { getUserWorkspace } from '../../routes/router'
import * as documentService from '../../services/user/document'
import { DOCUMENT_FORMAT_PDF, DOCUMENT_FORMAT_ADOC, DOCUMENT_CONTAINER_FORMATS } from '../../models/Document'
import { getMarkSignAppHost } from '../../utils/host'
import ReactPdf from './ReactPdf'
import PdfViewer from './PdfViewer'

export default class DocumentViewer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      documentFiles: [],
    }

    this.fetchData = this.fetchData.bind(this)
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.fetchData()
  }

  fetchData() {
    const { document, isTemporary, temporarySignLinkUuId, httpHeaders } = this.props
    if (document.format === DOCUMENT_FORMAT_PDF) {
      return
    }

    if (isTemporary) {
      documentService
        .getFilesForTemporaryPreview(document.uuid, temporarySignLinkUuId, httpHeaders)
        .then(({ data }) => this.setState({ documentFiles: data }))
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
    } else {
      documentService
        .getFiles(document.uuid)
        .then(({ data }) => this.setState({ documentFiles: data }))
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
    }

  }

  onDownloadClick(file) {
    const { document, isTemporary, temporarySignLinkUuId } = this.props
    const url = `https://${getMarkSignAppHost()}/api/v1/${getUserWorkspace()}/document/${document.uuid}/files_by_path` + (isTemporary ? `/${temporarySignLinkUuId}` : '') + `?filePathInPackage=${file.filePathInPackage}`

    window.open(url, '_blank')
  }

  getPdfUrl() {
    const { document, isTemporary, temporarySignLinkUuId } = this.props
    return `https://${getMarkSignAppHost()}/api/v1/${getUserWorkspace()}/document/${document.uuid}/name/${document.documentName}` + (isTemporary ? `/${temporarySignLinkUuId}` : '')
  }

  render() {
    const { document, t, hideScrollBar, signed, isTemporary, className, httpHeaders, version } = this.props
    const { documentFiles } = this.state

    return (
      <div className={'preview__document-content' + (className ? ` ${className}` : '')} style={(hideScrollBar) ? hideScrollBar : {}}>
        {document.format === DOCUMENT_FORMAT_PDF && !isTemporary && (
          <PdfViewer
            url={this.getPdfUrl()}
            signed={signed}
            version={version}
          />
        )}
        {document.format === DOCUMENT_FORMAT_PDF && isTemporary && (
          <ReactPdf
            url={this.getPdfUrl()}
            httpHeaders={httpHeaders}
            version={version}
          />
        )}
        {DOCUMENT_CONTAINER_FORMATS.indexOf(document.format) !== -1 && documentFiles.length > 0 && (
          <FilesViewer
            t={t}
            showMainFile={document.format === DOCUMENT_FORMAT_ADOC}
            files={documentFiles}
            onDownloadClick={this.onDownloadClick}
            version={version}
          />
        )}
      </div>
    )
  }
}

DocumentViewer.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  document: PropTypes.object,
  temporarySignLinkUuId: PropTypes.string,
  showStatusMessage: PropTypes.func,
  isTemporary: PropTypes.bool,
  hideScrollBar: PropTypes.object,
  signed: PropTypes.bool,
  httpHeaders: PropTypes.object,
  version: PropTypes.string,
}
