//
// In this file, session means the period in which user is logged into a specific account
// So, there can be sessons for person and business person
//

const hourDiffForDecision = 0

const storageKey = 'newsletterDecisionInfos'

const storeDecisionInfos = (newsletterDecisionInfos) => localStorage.setItem(storageKey, JSON.stringify(newsletterDecisionInfos))

const retrieveDecisionInfos = () => JSON.parse(localStorage.getItem(storageKey)) || {}

const getSessionKey = (user) => `${user.id}${user.workspaceId ? '_' + user.workspaceId : ''}`

const removeSessionDecisionInfos = (user) => {
  const newsletterDecisionInfos = retrieveDecisionInfos()
  delete newsletterDecisionInfos[getSessionKey(user)]
  storeDecisionInfos(newsletterDecisionInfos)
}

const getSessionDecisionInfos = (user) => {
  const newsletterDecisionInfos = retrieveDecisionInfos()
  return newsletterDecisionInfos[getSessionKey(user)] || {
    identity: null,
    lastInteractionAt: 0
  }
}

export const updateSessionDecisionInfos = (user) => {
  const newsletterDecisionInfos = retrieveDecisionInfos()
  newsletterDecisionInfos[getSessionKey(user)] = {
    identity: user.identityId,
    lastInteractionAt: (new Date()).getTime()
  }
  storeDecisionInfos(newsletterDecisionInfos)
}

export const canAskForDecision = (user) => {
  //
  // This function will return true only if
  // 1. has not yet provided his decision and
  // 2. he has newly logged in and
  // 3. specific amount of time has passed since his last activity
  //
  const currentUserNld = getSessionDecisionInfos(user)
  user.newsletterDecisionAt && removeSessionDecisionInfos(user)
  return !user.newsletterDecisionAt && currentUserNld.identity !== user.identityId &&
    (!currentUserNld.lastInteractionAt || (new Date()).getTime() >= currentUserNld.lastInteractionAt + (hourDiffForDecision * 3600) * 1000)
}
