import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import store from './bootstrap/store'
import { isDebug } from './config'
import translationLT from '../public/locales/lt/translation.json'
import translationEN from '../public/locales/en/translation.json'
import translationPL from '../public/locales/pl/translation.json'

// translations resources
const resources = {
  lt: {
    translation: translationLT
  },
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
}

const { i18n: { langCode } } = store.getState()

i18n
  .use(initReactI18next)
  .init({
    resources,
    react: {
      useSuspense: false
    },
    lng: langCode,
    debug: isDebug,
    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    }
  })

export default i18n