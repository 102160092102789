import React from 'react';

export default class CompanyUsersList extends React.Component {
  render() {
    return (
      <ul className="table__body">
        {this.props.children}
      </ul>
    );
  }
}