import React from 'react';

function padStart(num, size) {
  num = num.toString()
  while (num.length < size) num = '0' + num

  return num
}

function renderTime(time, showMinutes) {
  let render

  if (showMinutes) {
    render = `${padStart(~~(time / 60), 2)}:${padStart(time % 60, 2)}`
  }

  return render
}

function calculateTime(startTime, duration) {
  const elapsedTime = Date.now() - startTime

  return Math.max(0, duration - Math.round(elapsedTime / 1000))
}

export default function Timer({
  className,
  time,
  onTimeEnd,
  onTimeChange,
  showMinutes,
}) {
  let timerClassName = 'timer'

  const [ startTime, setStartTime ] = React.useState(Date.now())
  const [ duration, setDuration ] = React.useState(time)
  const [ endTime, setEndTime ] = React.useState(null)

  const updateTime = () => {
    const currentTime = calculateTime(startTime, duration)

    onTimeChange(currentTime)

    if (currentTime === 0 && endTime === null) {
      setEndTime(Date.now())
      onTimeEnd()
    }
  }

  React.useEffect(() => {
    const timer = time > 0 ? setTimeout(updateTime, 1000) : null

    return () => clearTimeout(timer)
  })

  if (className) {
    timerClassName += ` ${className}`
  }

  return (
    <span className={timerClassName}>{renderTime(time, showMinutes)}</span>
  )
}

Timer.defaultProps = {
  time: 90,
  onTimeEnd: () => {},
  onTimeChange: () => {},
  showMinutes: true,
};