import React from 'react'
import InputSearch from '~/js/components/InputSearch'
import PropTypes from 'prop-types'

export default class AppHeaderSearch extends React.Component {
  render() {
    const { t } = this.props

    return (
      <form
        className={'header__search' + (this.props.className ? ` ${this.props.className}` : '')}
        onSubmit={e => e.preventDefault()}
      >
        <div className="form-wrap form-wrap--search">
          <InputSearch
            value={this.props.value}
            wait={this.props.wait}
            onChange={this.props.onChange}
            placeholder={t('common.search')}
          />
        </div>
      </form>
    )
  }
}

AppHeaderSearch.defaultProps = {
  wait: 500,
}

AppHeaderSearch.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  wait: PropTypes.number,
}