// @flow

import Accordion from '../Accordion'
import ChevronDown from '../../../../assets/img/icons/chevron-down.svg'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  members: Object
}

export default function MembersValidate(props: Props): Node {
  const { members } = props
  const { t, i18n } = useTranslation()

  return (
    <div className="wrapped-section">
      <div className="wrapped-section__headline wrapped-section__headline--two">
        <h2 className="headline headline--members">{t('members')}</h2>
      </div>
      <div className="info-details info-details--wider">
        {members.slice(0).map((member) => (
          <Accordion key={`member-${member.id}`}>
            <div className="document-validation">
              <div key={`time-stamp-${member.id}`} className="document-validation__header">
                <div className="document-validation__to-display">
                  <h3>{member.signatureCertificates[0].GivenName} {member.signatureCertificates[0].Surname}</h3>
                  <div className="document-validation__validation-information">
                    {i18n.language === 'lt'
                      ? <p className="document-validation__qualified-signature">{t('common.qualified_signature')}</p>
                      : <p className="document-validation__qualified-signature">{member.signatureLevelDesc}</p>
                    }
                    <div className={`status status--${member.indication === 'signed' ? 'signed' : 'not-signed'}`}>
                      {member.indication === 'signed' ? t('user.document.signature_valid') : t('user.document.signature_invalid')}
                      <span>
                        <img className="arrow-down" src={ChevronDown} alt="Chevron Down" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="document-validation__to-print">
                  <span className="document-validation__username">{member.signatureCertificates[0].GivenName} {member.signatureCertificates[0].Surname}</span>
                  <span className="status status--signed">
                    {t('user.document.signature_valid')}
                    <span>
                      <img className="arrow-down" src={ChevronDown} alt="Chevron Down" />
                    </span>
                  </span>
                  {i18n.language === 'lt'
                    ? <span className="document-validation__qualified-signature">{t('common.qualified_signature')}</span>
                    : <span className="document-validation__qualified-signature">{member.signatureLevelDesc}</span>
                  }
                </div>
              </div>
              <div className="document-validation__certificates">
                <ul>
                  <li>
                    <div className="info-details__label"><label>{t('reason')}</label></div>
                    <div className="info-details__info"><p> {member.reason}</p></div>
                  </li>
                  <li>
                    <div className="info-details__label"><label>{t('signing_time')}</label></div>
                    <div className="info-details__info"><p>{member.signingTime}</p></div>
                  </li>
                  <li>
                    <div className="info-details__label"><label>{t('extension_period_max')}</label></div>
                    <div className="info-details__info"><p>{member.extensionPeriodMax}</p></div>
                  </li>
                  <li>
                    <div className="info-details__label"><label>{t('signature_format')}</label></div>
                    <div className="info-details__info"><p>{member.signatureFormat}</p></div>
                  </li>
                  {member.signatureLevel && (
                    <li>
                      <div className="info-details__label"><label>{t('signature_level')}</label></div>
                      <div className="info-details__info"><p>{member.signatureLevel}</p></div>
                    </li>
                  )}
                </ul>
                <h3>{t('user.document.signature_certificate')}</h3>
                {member.signatureCertificates.slice(0, 1).map((signatureCertificate) => (
                  <ul key={`sertificate-${member.id}`}>
                    <li>
                      <div className="info-details__label"><label>{t('user.document.certification_owner')}</label></div>
                      <div className="info-details__info">
                        <p>
                          {signatureCertificate.GivenName} {signatureCertificate.Surname}
                        </p>
                      </div>
                    </li>
                    {typeof signatureCertificate !== 'undefined' && (
                      <li>
                        <div className="info-details__label"><label>{t('personal_code')}</label></div>
                        <div className="info-details__info">
                          <p>
                            {signatureCertificate.PersonalCode}
                          </p>
                        </div>
                      </li>
                    )}
                    {typeof signatureCertificate.certificate[0] !== 'undefined' && (
                      <li>
                        <div className="info-details__label"><label>{t('user.document.certificate_issued_by')}</label></div>
                        <div className="info-details__info">
                          <p>
                            {signatureCertificate.certificate[0].CommonName}, {signatureCertificate.certificate[0].OrganizationName}, {signatureCertificate.certificate[0].CountryName}
                          </p>
                        </div>
                      </li>
                    )}
                    <li>
                      <div className="info-details__label"><label>{t('common.valid_from')}</label></div>
                      <div className="info-details__info"><p>{member.signatureCertificates[0].NotBefore}</p></div>
                    </li>
                    <li>
                      <div className="info-details__label"><label>{t('common.valid_until')}</label></div>
                      <div className="info-details__info"><p>{member.signatureCertificates[0].NotAfter}</p></div>
                    </li>
                  </ul>
                ))}
                {member.allTimestamps.length > 0 && (
                  <>
                    <h3>{t('user.document.timestamp_data')}</h3>
                    {member.allTimestamps.slice(0, 1).map((timestamp) => (
                      <ul key={`timestamp-${member.id}`}>
                        <li>
                          <div className="info-details__label"><label>{t('common.certificate')}</label></div>
                          <div className="info-details__info">
                            <p>
                              {timestamp.CommonName},&nbsp;
                              {timestamp.OrganizationName},&nbsp;
                              {timestamp.CountryName}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('user.document.certificate_issued_by')}</label></div>
                          <div className="info-details__info">
                            <p>
                              {member.allTimestamps[0].certificate[0].CommonName},&nbsp;
                              {member.allTimestamps[0].OrganizationName},&nbsp;
                              {member.allTimestamps[0].CountryName}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('common.valid_from')}</label></div>
                          <div className="info-details__info"><p>{member.allTimestamps[0].NotBefore}</p></div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('common.valid_until')}</label></div>
                          <div className="info-details__info"><p>{member.allTimestamps[0].NotAfter}</p></div>
                        </li>
                      </ul>
                    ))}
                  </>
                )}
                {member.allRevocations.length > 0 && (
                  <>
                    <h3>{t('user.document.ocsp_tag_data')}</h3>
                    {member.allRevocations.slice(0, 1).map((revocation) => (
                      <ul key={`rev-${member.id}`}>
                        <li>
                          <div className="info-details__label"><label>{t('common.certificate')}</label></div>
                          <div className="info-details__info">
                            <p>
                              {revocation.CommonName},&nbsp;
                              {revocation.OrganizationName},&nbsp;
                              {revocation.CountryName}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('user.document.certificate_issued_by')}</label></div>
                          <div className="info-details__info">
                            <p>
                              {revocation.certificate[0].CommonName},&nbsp;
                              {revocation.OrganizationName},&nbsp;
                              {revocation.CountryName}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('common.valid_from')}</label></div>
                          <div className="info-details__info"><p>{revocation.NotBefore}</p></div>
                        </li>
                        <li>
                          <div className="info-details__label"><label>{t('common.valid_until')}</label></div>
                          <div className="info-details__info"><p>{revocation.NotAfter}</p></div>
                        </li>
                      </ul>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Accordion>
        ))}
      </div>
    </div>
  )
}