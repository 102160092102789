import React from 'react'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import Tooltip from '../Tooltip'
import ModalHubspot from '../../components/ModalHubspotForm/ModalHubspotForm'
import { Swiper, SwiperSlide } from 'swiper/react'
import Money from '../Money'
import i18next from 'i18next'
import ModalWorkspace from '../ModalWorkspace'
import * as workspaceService from '~/js/services/user/workspace'
import { SETTINGS_COMPANY_USERS } from '~/js/models/Settings'
import PropTypes from 'prop-types'
import Snowflakes from '~/js/components/Snowflakes'
import ModalAdditionalAndGlobalSignatures from '../ModalAdditionalAndGlobalSignatures'
import { findSignatureProducts, getGlobalProductsList } from '../../utils/common'
import { AUTH_TYPE_ZEALID } from '../../services/user/user'

function ProductSignatureLimit({ t, signaturesLimit }) {
  return (
    <div>
      <i className="icon-single icon-single--checked" />{signaturesLimit} {t('user.pricing.signatures_per_month_for_free')}
    </div>
  )
}

export default class PricingTable extends React.Component {
  constructor(props) {
    super(props)

    this.paragraph = React.createRef()

    this.state = {
      annualPriceChecked: true,
      showChristmasLogin: false,
      modalHubspot: {
        active: false
      },
      modalAdditionalSignatures: {
        active: false
      },
      modalCreateNewWorkspace: {
        active: false
      },
      showPricingTableInfo: {
        active: false
      },
    }

    this.swiper = undefined

    this.onBuyClick = this.onBuyClick.bind(this)
    this.toggleClass = this.toggleClass.bind(this)
    this.addWorkspace = this.addWorkspace.bind(this)
    this.onSwitchChange = this.onSwitchChange.bind(this)
    this.onContactUsClick = this.onContactUsClick.bind(this)
    this.onActivatePlanClick = this.onActivatePlanClick.bind(this)
    this.onReactSwitchChange = this.onReactSwitchChange.bind(this)
    this.toggleChristmasAdd = this.toggleChristmasAdd.bind(this)
    this.onBuyAdditionalSignaturesClick = this.onBuyAdditionalSignaturesClick.bind(this)
    this.closeAdditionalSignaturesModal = this.closeAdditionalSignaturesModal.bind(this)
    this.isGlobalUser = this.isGlobalUser.bind(this)
  }

  closeAdditionalSignaturesModal() {
    this.setState({ modalAdditionalSignatures: { active: false } })
  }

  onReactSwitchChange() {
    this.setState(prevState => ({ annualPriceChecked: !prevState.annualPriceChecked }))
  }

  onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const product = this.props.products.find(itm => itm.id === selectedProductId)

    this.props.clearCart()
    this.props.addCartItem(product)
    this.props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  onBuyAdditionalSignaturesClick() {
    this.setState({ modalAdditionalSignatures: { active: true } })
  }

  onContactUsClick() {
    this.setState({
      modalHubspot: {
        active: true
      }
    })
  }

  onSwitchChange() {
    this.setState({ annualPriceChecked: !this.state.annualPriceChecked })
    this.state.annualPriceChecked ?
      this.paragraph.current.style.visibility = 'hidden' :
      this.paragraph.current.style.visibility = 'visible'
  }

  onActivatePlanClick() {
    this.setState({
      modalCreateNewWorkspace: {
        active: true
      }
    })
  }

  addWorkspace([name, registrationCode, address, email]) {
    return workspaceService
      .addWorkspace({
        name: name,
        registrationCode: registrationCode,
        address: address,
        email: email,
        activateFlexibleSubscription: true
      })
      .then(data => {
        this.props.switchWorkspace(data.workspaceId)
        this.props.history.push(generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_COMPANY_USERS }))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.setState({ modalCreateNewWorkspace: { active: false } })
      })
  }

  toggleClass() {
    const currentState = this.state.showPricingTableInfo.active
    this.setState({ showPricingTableInfo: { active: !currentState } })
  }

  toggleChristmasAdd() {
    const christmasStartDate = Date.parse('2022-12-15T00:00:00+02:00')
    const currentDay = Date.now()
    const christmasEndDate = Date.parse('2023-01-01T23:59:59+02:00')

    this.setState( { showChristmasLogin: currentDay >= christmasStartDate && currentDay <= christmasEndDate })
  }

  isGlobalUser() {
    const { user } = this.props
    const countries = ['LT', 'LV', 'EE']

    return !countries.includes(user.country) && user.authMethod === AUTH_TYPE_ZEALID
  }

  componentDidMount() {
    this.toggleChristmasAdd()
  }

  componentDidUpdate(prevProps) {
    if (this.swiper && prevProps.isSidebarShrinked !== this.props.isSidebarShrinked) {
      // We are updating the swiper width to match the shrinked width change amount of the sidebar
      // Full Sidebar Width - Shrinked Sidebar Width = 235px
      this.swiper.params.width = this.props.isSidebarShrinked
        ? this.swiper.$el[0].clientWidth + 235
        : this.swiper.$el[0].clientWidth - 235
      this.swiper.emit('resize')
    }
  }

  render() {
    const { t, user, products, signatureProducts, isSidebarShrinked } = this.props

    return (
      <main className={isSidebarShrinked ? 'shrink' : ''}>
        <section id="pricing">
          <div className="grid">
            <div className="header_undertext ta-left">{t('user.pricing.prices_do_not_include_vat')}</div>
            <div className="center ta-center">
              <div className="switch-button">
                <input className="switch-button-checkbox" type="checkbox" onChange={this.onSwitchChange} />
                <label className="switch-button-label">
                  <span className="switch-button-monthly">{t('user.pricing.discount_switch_monthly')}</span>
                  <span className="switch-button-label-span">{t('user.pricing.discount_switch_yearly')}</span>
                </label>
              </div>
              <p ref={this.paragraph} id="discount_text">{t('user.pricing.save_up_to')}</p>
            </div>
            {this.state.showChristmasLogin &&
              <div className="christmas-add">
                <p dangerouslySetInnerHTML={{ __html: t('user.pricing.christmas_offer_text') }} />
                <Snowflakes />
              </div>
            }
            <div className="overflow-mobile swiper-container">
              <Swiper
                updateOnWindowResize={true}
                slidesPerView={1}
                initialSlide={2}
                breakpoints={{
                  1000: {
                    allowTouchMove: false,
                    slidesPerView: 4,
                  }
                }}
                onSwiper={(swiper) => {
                  this.swiper = swiper
                }}
                onBeforeResize={swiper => {
                  /**
                   * Setting width to null to clear any manual width value when window is resized
                   */
                  swiper.params.width = null
                  return swiper
                }}
              >
                {products.filter(product =>
                  product.category === 'free_subscription_plan' ||
                      (this.state.annualPriceChecked ?
                        product.category === 'annual_subscription_plan' :
                        product.category === 'monthly_subscription_plan') ||
                      product.category === 'special_subscription_plan'
                ).map((product) => (
                  <SwiperSlide key={product.id} className="pricing-table__col">
                    {this.props.user.subscription === product.name && (
                      <div className={i18next.language === 'en' ? 'pricing__best-value' : 'pricing__best-value pricing__best-value_lt'}>
                        <div className="best-value__lines" />
                        <span>{t('user.pricing.current_plan')}</span>
                      </div>
                    )}
                    <div className="pricing-table__row pricing-table__row--head">
                      {this.state.annualPriceChecked && product.category === 'annual_subscription_plan' && (
                        <span className="discount">{product.name === 'professional' ? '- 14%' : '- 13%'}</span>
                      )}
                      <p className="plan_name">{t(`user.pricing.plan_${product.name}`)}</p>
                      <span className="horizontal_line_blue" />
                      <label>{t(`user.pricing.plan_${product.name}_desc`)}</label>
                      <span className="horizontal_line_gray" />
                      <div className="pricing_page_container">
                        <div className="price_column">
                          <Money
                            price={product.annualPlanPriceMonthly || product.monthlyPrice}
                            annualPriceChecked={this.state.annualPriceChecked}
                            special={product.name === 'flexible'}
                            t={t}
                          /> /{t('user.pricing.price_monthly')}
                          {
                            product.name === 'teams'
                            &&
                            <Tooltip
                              text={t('user.pricing.single_user_pricing')}
                              inline={true}
                              alignMiddle={true}
                            >
                              &nbsp;<span className="info-icon-grey" />
                            </Tooltip>
                          }
                        </div>
                        {this.state.annualPriceChecked && product.name !== 'start' && product.name !== 'flexible' && (
                          <div className='price_column discount_column'>
                            <span className="price_crossed">{product.price}</span>
                            <span className="price_with_discount">{product.annualPlanPrice}</span>
                            <span className="discount_subscription_type">/{t('user.pricing.price_yearly')}</span>
                          </div>
                        )}
                      </div>
                      <div className='pricing_action'>
                        {
                          product.name !== 'start'
                            ? (
                              <button
                                id={product.name === 'professional' ? 'isbandyti_dabar_profesionalui_platform' : (product.name === 'teams' ? 'isbandyti_dabar_komandai_platform' : 'gauti_pasiulyma_platform')}
                                disabled={this.props.user.subscription === product.name && this.props.user.subscription === 'flexible'}
                                className={'btn btn--' + (this.props.user.subscription === product.name ? 'green' : 'secondary')}
                                onClick={() => product.name !== 'flexible' ?
                                  (this.props.user.subscription === product.name ?
                                    this.onBuyAdditionalSignaturesClick() :
                                    this.onBuyClick(product.name, product.price, product.id, product.category)) :
                                  (this.props.user.canActivateFlexibleAccount ? this.onActivatePlanClick() : this.onContactUsClick())}
                              >{product.name !== 'flexible' ?
                                  (this.props.user.subscription === product.name ? t('user.pricing.add_more_signatures') : t('order_now')) :
                                  (this.props.user.canActivateFlexibleAccount ? t('user.pricing.activate_plan') : t('user.pricing.contact_sales'))}
                              </button>
                            )
                            : (
                              <button
                                id="pradeti_dabar_startui_platform"
                                className={(user.subscription === 'start' || user.subscription === 'no_plan') ? 'btn btn--secondary' : 'btn btn--primary'}
                                onClick={(user.subscription === 'start' || user.subscription === 'no_plan') ? this.onBuyAdditionalSignaturesClick : ''}
                                disabled={(user.subscription !== 'start' && user.subscription !== 'no_plan')}
                              >
                                {(user.subscription === 'start' || user.subscription === 'no_plan') ? t('user.pricing.buy_more_signatures') : t('try_now')}
                              </button>
                            )
                        }
                      </div>
                      {product.name === 'start' && (
                        <span className="pricing-table__green-text-start">{user.authMethod === AUTH_TYPE_ZEALID ? '' : t('always_free')}</span>
                      )}
                      {(product.name === 'professional' || product.name === 'teams') && (
                        <span className="pricing-table__green-text-start"><strong>{t('no_commitments')}</strong></span>
                      )}
                      {product.name === 'flexible' && (
                        <span className="pricing-table__green-text-start" />
                      )}
                      <span className="horizontal_line_gray" />
                      <div className="pricing_description">
                        {product.name === 'start' && (
                          <React.Fragment>
                            <ProductSignatureLimit
                              t={t}
                              signaturesLimit={product.signaturesLimit}
                            />
                            <div><i
                              className="icon-single icon-single--checked"
                            />{t('user.pricing.validations_per_month_for_free', { amount: 6 })}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.basic_functions')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_two')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_three')}
                            </div>
                          </React.Fragment>
                        )}
                        {product.name === 'professional' && (
                          <React.Fragment>
                            <ProductSignatureLimit
                              t={t}
                              signaturesLimit={product.signaturesLimit}
                            />
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.validations_per_month_for_free', { amount: 30 })}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" /><strong>{t('user.pricing.plan_prof_bottom_desc_one')}</strong>
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_two')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_three')}
                            </div>
                          </React.Fragment>
                        )}
                        {product.name === 'teams' && (
                          <React.Fragment>
                            <ProductSignatureLimit
                              t={t}
                              signaturesLimit={product.signaturesLimit}
                            />
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.validations_per_month_for_free', { amount: 50 })}
                            </div>
                            <div id="top-nav-start">
                              <i className="icon-single icon-single--checked" /><strong>{t('user.pricing.plan_teams_bottom_desc_one')}</strong>
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_teams_bottom_desc_two')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_teams_bottom_desc_three')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_three')}
                            </div>
                          </React.Fragment>
                        )}
                        {product.name === 'flexible' && (
                          <React.Fragment>
                            <div>
                              <i className="icon-single icon-single--checked" /><strong>{t('user.pricing.enterprise_desc_bottom_one')}</strong>
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.unlimited_validations_per_month_for_free')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.enterprise_desc_bottom_two')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.enterprise_desc_bottom_three')}
                            </div>
                            <div>
                              <i className="icon-single icon-single--checked" />{t('user.pricing.plan_prof_bottom_desc_three')}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="swiper-pagination" />

            <div className="ta-center pricing-table_header-between">
              <div onClick={this.toggleClass.bind(this)}>
                <h2>{t('user.pricing.compare_products')}</h2>
                <i className="icon-single icon-arrow-down" />
              </div>
            </div>

            <div className={`pricing-table ${this.state.showPricingTableInfo.active ? 'pricing-table-show' : 'pricing-table-hidden'}`}>
              <div className="pricing-table__col pricing-table__col--label">
                <div className="pricing-table__row">
                  <div className="pricing-table__row pricing-table__row--colspan">
                    <label><strong>{t('plans')}</strong></label>
                  </div>
                  <label>
                    {t('free_signatures')}
                    <Tooltip text={t('free_signatures_description')} inline={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label>
                    {t('additional_signature_price')}
                    <Tooltip text={t('signature_price_description')} inline={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label>
                    {t('document_validations')}
                  </label>
                  <label>
                    {t('user.pricing.plan_prof_bottom_desc_three')} <span className="tooltip-new">{t('new')}</span>
                    <Tooltip
                      text={t('global_price_signature_description')} inline={true} newLine={true}
                      newLineBig={this.props.i18n.language === 'pl'}
                    ><span className="info-icon" />
                    </Tooltip>
                  </label>
                  <label>
                    {t('max_user_number')}
                  </label>
                  <label>
                    {t('create_business_account')}
                    <Tooltip text={t('create_business_account_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label className="border_bottom_padding">
                    {t('document_storage')}
                  </label>
                  <div className="pricing-table__row pricing-table__row--colspan">
                    <label><strong>{t('main_advantages')}</strong></label>
                  </div>
                  <label>
                    {t('security_requirements')}
                    <Tooltip
                      text={t('security_requirements_description')} newLine={this.props.i18n.language === 'lt'}
                      newLineBig={this.props.i18n.language === 'lt'}
                    ><span className="info-icon" />
                    </Tooltip>
                  </label>
                  <label>
                    {t('several_document_types_support')}
                    <Tooltip text={t('several_document_types_support_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label>
                    {t('simple_document_signing')}
                    <Tooltip text={t('simple_document_signing_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label>
                    {t('actions_history')}
                    <Tooltip
                      text={t('actions_history_description')} inline={true} newLine={true}
                      newLineBig={this.props.i18n.language === 'pl'}
                    ><span className="info-icon" />
                    </Tooltip>
                  </label>
                  <label>
                    {t('group_actions')}
                    <Tooltip text={t('group_actions_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label className="border_bottom_padding">
                    {t('address_book')}
                    <Tooltip text={t('address_book_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <div className="pricing-table__row pricing-table__row--colspan">
                    <label><strong>{t('additional_features')}</strong></label>
                  </div>
                  <label>
                    {t('purchase_additional_signatures')}
                    <Tooltip
                      text={t('purchase_additional_signatures_description')} inline={true} newLine={true}
                      newLineBig={this.props.i18n.language === 'pl'}
                    ><span className="info-icon" />
                    </Tooltip>
                  </label>
                  <label>
                    {t('order_signing_documents')}
                    <Tooltip
                      text={t('order_signing_documents_description')} inline={true}
                      newLine={this.props.i18n.language === 'pl'}
                    ><span className="info-icon" />
                    </Tooltip>
                  </label>
                  <label>
                    {t('general_document_categories')}
                    <Tooltip text={t('general_document_categories_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label>
                    {t('signing_reminders')}
                    <Tooltip text={t('signing_reminders_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                  <label className="border_bottom_padding">
                    {t('documents_comments')}
                    <Tooltip text={t('documents_comments_description')} inline={true} newLine={true}><span
                      className="info-icon"
                    />
                    </Tooltip>
                  </label>
                </div>
                <div className="pricing-table__row pricing-table__row--colspan">
                  <label><strong>{t('document_management_in_team')}</strong></label>
                </div>
                <div className="pricing-table__row">
                  <label>
                    {t('user_access_rights_management')}
                    <Tooltip text={t('user_access_rights_management_description')} inline={true} newLine={this.props.i18n.language === 'pl'}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('assign_organizations_account')}
                    <Tooltip text={t('assign_organizations_account_description')} inline={true}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('general_categories_of_documents')}
                    <Tooltip text={t('general_categories_of_documents_description')} inline={true} newLine={true}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('general_contacts')}
                    <Tooltip text={t('general_contacts_description')} inline={true}><span className="info-icon" /></Tooltip>
                  </label>
                </div>
                <div className="pricing-table__row">
                  <label>
                    {t('centralized_document_management')}
                    <Tooltip text={t('centralized_document_management_description')} inline={true} newLine={true}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('general_organization_accounting')}
                    <Tooltip text={t('general_organization_accounting_description')} inline={true} newLine={true} newLineBig={this.props.i18n.language === 'pl'}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('adapting_organizational_style')}
                    <Tooltip text={t('adapting_organizational_style_description')} inline={true} newLine={true}><span className="info-icon" /></Tooltip>
                  </label>
                  <label>
                    {t('api_connection')}
                    <Tooltip text={t('api_connection_description')} inline={true} newLine={true}><span className="info-icon" /></Tooltip>
                  </label>
                </div>
              </div>
              <div className="pricing-table__col">
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single"><strong>3</strong></i>
                  <i className="icon-single"><strong>0,99 €</strong></i>
                  <i className="icon-single"><strong>6</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single"><strong>1</strong></i>
                  <i className="icon-single"><strong>-</strong></i>
                  <i className="icon-single border_bottom_padding"><strong>150 MB</strong></i>
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                </div>
                <div className="pricing-table__row">
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                </div>
              </div>
              <div className="pricing-table__col">
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single"><strong>20</strong></i>
                  <i className="icon-single"><strong>0,40 €</strong></i>
                  <i className="icon-single"><strong>30</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single"><strong>1</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single border_bottom_padding"><strong>{t('unlimited')}</strong></i>
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                </div>
                <div className="pricing-table__row">
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                </div>
              </div>
              <div className="pricing-table__col">
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single"><strong>40</strong></i>
                  <i className="icon-single"><strong>0,30 €</strong></i>
                  <i className="icon-single"><strong>50</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single"><strong>2+</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single border_bottom-margin_auto"><strong>{t('unlimited')}</strong></i>
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom-margin_auto" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom-margin_auto" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i id="bottom-nav-stop" className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                </div>
                <div className="pricing-table__row">
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                  <i className="icon-single" />
                </div>
              </div>
              <div className="pricing-table__col">
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single"><strong>-</strong></i>
                  <i className="icon-single"><strong>-</strong></i>
                  <i className="icon-single"><strong>{t('unlimited')}</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single"><strong>2+</strong></i>
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single border_bottom_padding"><strong>{t('unlimited')}</strong></i>
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single icon-checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div>
                  <i className="icon-single" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked border_bottom_padding" />
                </div>
                <div className="pricing-table__row pricing-table__row--colspan" />
                <div className="pricing-table__row">
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                </div>
                <div className="pricing-table__row">
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                  <i className="icon-single icon-single--checked" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalAdditionalAndGlobalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalSignatures.active}
          onCloseClick={this.closeAdditionalSignaturesModal}
          signaturesProducts={findSignatureProducts(signatureProducts, user.subscription)}
          globalSignaturesProducts={getGlobalProductsList(user.subscription, signatureProducts)}
          onBuyClick={this.onBuyClick}
        />
        <ModalHubspot
          type='checkout'
          active={this.state.modalHubspot.active}
          onCloseClick={() => this.setState({ modalHubspot: { active: false } })}
        />
        <ModalWorkspace
          active={this.state.modalCreateNewWorkspace.active}
          title={t('user.settings.workspace.create_new_workspace')}
          addWorkspace={this.addWorkspace}
          onCloseClick={() => this.setState({ modalCreateNewWorkspace: { active: false } })}
        />
      </main >
    )
  }
}

PricingTable.propTypes = {
  showStatusMessage: PropTypes.func,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
  t: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  switchWorkspace: PropTypes.func,
  i18n: PropTypes.object,
  products: PropTypes.array,
  signatureProducts: PropTypes.array,
  isSidebarShrinked: PropTypes.bool,
}

ProductSignatureLimit.propTypes = {
  t: PropTypes.func,
  signaturesLimit: PropTypes.number,
}
