import React from 'react';

export default class TabsList extends React.Component {
  render() {
    let tabsListClassName = 'tabs-list';

    if (this.props.withNumbers) {
      tabsListClassName += ' tabs-list--with-numbers';
    }
    if (this.props.centered) {
      tabsListClassName += ' tabs-list--center';
    }

    return (
      <div className={this.props.wrapperClassName}>
        <ul className={tabsListClassName}>
          {this.props.children}
        </ul>
        {this.props.showOverlay ? <div className="tabs-mobile-overlay" /> : null}
      </div>
    );
  }
}