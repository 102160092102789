// @flow

import React, { useEffect } from 'react'
import type { Node } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Modal from '../Modal'
import Iframe from '../Iframe'
import Loader from '../Loader'
export const TYPE_HASH = 'hash'
export const TYPE_AUTH = 'auth'

type Props = {
  active?: boolean,
  qrHtml: string,
  onCodeReceive?: (string) => void,
  onCloseClick: () => void,
  isLoading?: boolean
}

ModalZealIdQrCode.defaultProps = {
  type: TYPE_AUTH,
}

export default function ModalZealIdQrCode({ active, qrHtml, onCodeReceive, onCloseClick, isLoading }: Props): Node {
  const { t } = useTranslation()
  useEffect(() => {
    if (!onCodeReceive) {
      return
    }

    const onMessage = (e: MessageEvent) => {
      const code = parseMessageCode(e)

      if (code) {
        onCodeReceive(code)
      }
    }

    window.addEventListener('message', onMessage)

    return () => window.removeEventListener('message', onMessage)
  }, [])

  return (
    <Modal
      active={active}
      className="popup--pdf-view zealid-sign"
      size="small"
      onCloseClick={onCloseClick}
    >
      {isLoading ? (
        <Loader loadingText={t('common.loading')} />
      ) :
        (
          <>
            <Iframe
              html={qrHtml}
              style={{ height:'80vh', border: '0px' }}
            />
            <div className="zeal-faq-links">
              <Trans i18nKey="user.login.help_how_to_create_zeal_id">
                <a href={t('helpdesk.landing')} target='_blank' rel='noreferrer'>DUK</a>
                <a href={t('helpdesk.how_to_create_zeal_id')} target='_blank' rel='noreferrer'>How to create ZealiD</a>
              </Trans>
            </div>
          </>
        )}
    </Modal>
  )
}

function parseMessageCode({ data: fullUrl }: MessageEvent): ?string {
  let url

  if (typeof fullUrl !== 'string') {
    return null
  }

  try {
    url = new URL(fullUrl)
  } catch (err) {
    return null
  }

  return url.searchParams.get('code')
}
