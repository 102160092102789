// @flow

import apiClient from '../../utils/apiClientDocs'
import type { ApiResponse } from './serviceHelpers'
import Product, { CATEGORY_SIGNATURE } from '../../models/Product'

export function getProducts(): Promise<ApiResponse<Product[]>> {
  return apiClient
    .get('v1/products')
    .then(({ data }) => data)
}

export function getPlanProducts(): Promise<ApiResponse<Product[]>> {
  return getProducts()
}

export function getSignatureProducts(): Promise<Product[]> {
  return getProducts()
    .then(({ data: products }) => products.filter(p => p.categories.includes(CATEGORY_SIGNATURE)))
}