import { connect } from 'react-redux'
import { fetchValidationInfo } from '../actions/validateDocument'
import { ValidateDocument } from '../routes/app/User/ValidateDocument'

const mapDispatchToProps = dispatch => ({
  fetchValidation: (user) => dispatch(fetchValidationInfo(user)),
})

export default connect(
  null,
  mapDispatchToProps
)(ValidateDocument)