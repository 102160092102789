import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

const withEmailValidation = (EmailValidationComponent) => {
  class NewEmailValidationComponent extends Component {
    isValidEmail = (email) => {
      return email && !(/[ąčęėįšųūž]/).test(email)
    }

    render() {
      return <EmailValidationComponent
        {...this.props}
        isValidEmail={this.isValidEmail}
      />
    }
  }

  return withTranslation()(NewEmailValidationComponent)
}

export default withEmailValidation