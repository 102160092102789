import React from 'react'
import Tooltip from '~/js/components/Tooltip'

export default class ControlButton extends React.Component {
  render() {
    const { title, showLabel, showTooltip } = this.props

    return (
      <button
        className={`controls__btn controls__btn--${this.props.type}`}
        type="button"
        id={`btn--${this.props.type}`}
        onClick={this.props.onClick}
        disabled={this.props.isDisabled}>
        {showTooltip
          ? (
            <Tooltip text={title}>
              <i className="icon" />
            </Tooltip>
          )
          : <i className="icon" />
        }
        {showLabel && (
            <div>
              <p className="mobile">{this.props.mobileTitle ? this.props.mobileTitle : title}</p>
              <p className="desktop">{title}</p>
            </div>
        )}
        {this.props.children}
      </button>
    )
  }
}

ControlButton.defaultProps = {
  showLabel: true,
  showTooltip: false,
}