import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ConfirmEmail from '~/js/routes/app/User/ConfirmEmail'
import Sidebar from '~/js/containers/Sidebar'
import Documents from '~/js/containers/Documents'
import Pricing from './Pricing'
import Register from '~/js/containers//RegisterRoute'
import UploadDocument from './UploadDocument'
import UploadDocumentValidate from '~/js/containers/UploadDocumentValidateRoute'
import DocumentPreview from '~/js/containers/DocumentPreviewRoute'
import DocumentKeyInvitation from './DocumentKeyInvitation'
import DocumentSignerInvitation from './DocumentSignerInvitation'
import DiscountFirst from './DiscountFirst'
import DiscountSecond from './DiscountSecond'
import UploadDocumentPreview from './UploadDocumentPreview'
import ValidateDocumentRoute from '../../../containers/ValidateDocumentRoute'
import Settings from '~/js/containers/UserSettingsRoute'
import ModalSessionEnd from '~/js/components/ModalSessionEnd'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import { ROUTE_NAMES, ROUTE_PATHS } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import AcceptInvitation from './AcceptInvitation'
import PricingSuccessRoute from '~/js/containers/PricingSuccessRoute'
import PurchasePlan from './PurchasePlan'
import { formatWorkspaces } from '~/js/utils/user'
import PricingCheckout from '~/js/containers/PricingCheckoutRoute'
import * as UserService from '~/js/services/user/user'
import WorkspaceAssign from './WorkspaceAssign'
import UsersQuantityChange from '~/js/containers/UsersQuantityChangeRoute'
import SubscriptionWorkspace from '~/js/containers/SubscriptionWorkspaceRoute'
import GlobalSignature from '~/js/containers/GlobalSignaturePricingTable'
import PropTypes from 'prop-types'
import DocumentViewInvitation from './DocumentViewInvitation'
import UsbDocuments from '~/js/containers/UsbDocumentsRoute'
import ModalNewsletterDecision from '~/js/components/ModalNewsletterDecision'
import * as lsNewsletterDecisionUtils from '~/js/bootstrap/localStorageNewsletterDecision'
import ModalUniqueEmail from '../../../components/ModalUniqueEmail'
import * as lsUniqueEmailDecisionUtils from '~/js/bootstrap/localStorageUniqueEmailDecision'

const generateRouteRedirect = routeName => function RouteRedirect() {
  return <Redirect to={generatePath(routeName)} />
}
const userRoutes = [
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_CONFIRM_PROFILE),
    component: Register,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_CONFIRM_EMAIL),
    component: ConfirmEmail,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DASHBOARD),
    component: generateRouteRedirect(ROUTE_NAMES.USER_DOCUMENTS),
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW),
    component: UploadDocumentPreview,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW),
    component: ValidateDocumentRoute,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD),
    component: UploadDocument,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE),
    component: UploadDocumentValidate,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS),
    component: Documents,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_KEY_INVITATION),
    component: DocumentKeyInvitation,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.WORKSPACE_ASSIGN),
    component: WorkspaceAssign,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_SIGNER_INVITATION),
    component: DocumentSignerInvitation,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DISCOUNT_FIRST),
    component: DiscountFirst,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DISCOUNT_SECOND),
    component: DiscountSecond,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_VIEW_INVITATION),
    component: DocumentViewInvitation,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_PREVIEW),
    component: DocumentPreview,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_SETTINGS),
    component: Settings,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN),
    component: SubscriptionWorkspace,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_PRICING_CHECKOUT),
    component: PricingCheckout,
    showSidebar: false,
    containerClass: 'page-container--full-width',
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_USERS_QUANTITY),
    component: UsersQuantityChange,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_PRICING),
    component: Pricing,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_ACCEPT_INVITATION),
    component: AcceptInvitation,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_PRICING_SUCCESS),
    component: PricingSuccessRoute,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_PURCHASE_PLAN),
    component: PurchasePlan,
    showSidebar: false,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_GLOBAL_SIGNATURES),
    component: GlobalSignature,
    showSidebar: true,
  },
  {
    path: ROUTE_PATHS.get(ROUTE_NAMES.USER_USB_DOCUMENTS),
    component: UsbDocuments,
    showSidebar: true,
  },
]
const sessionLifetime = 3600 // = 60min = 1hr
let sessionCheckInterval
const canAskForNlDecision = lsNewsletterDecisionUtils.canAskForDecision
const canAskForUniqueEmailDecision = lsUniqueEmailDecisionUtils.canAskForUniqueEmailDecision
const updateNlSessionDecisionInfos = lsNewsletterDecisionUtils.updateSessionDecisionInfos
const updateUniqueEmailSessionDecisionInfos = lsUniqueEmailDecisionUtils.updateSessionDecisionInfos

class User extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetchingData: true,
      isSwitchingWorkspace: false,
      sidebarWorkspaces: [],
      sessionModal: {
        active: false,
        timer: null,
      },
      newsletterDecisionModal: {
        active: false,
        shown: false
      },
      uniqueEmailModal: {
        active: false,
        shown: false
      }
    }

    this.isFetchingData = this.isFetchingData.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.startSessionChecking = this.startSessionChecking.bind(this)
    this.stopSessionChecking = this.stopSessionChecking.bind(this)
    this.getLastRequestTimeDiff = this.getLastRequestTimeDiff.bind(this)
    this.onExtendSession = this.onExtendSession.bind(this)
    this.updateWorkspaces = this.updateWorkspaces.bind(this)
    this.onSidebarWorkspaceSwitch = this.onSidebarWorkspaceSwitch.bind(this)
    this.switchWorkspace = this.switchWorkspace.bind(this)
    this.canShowContent = this.canShowContent.bind(this)
    this.decideNewsletterDecisionModalView = this.decideNewsletterDecisionModalView.bind(this)
    this.decideUniqueEmailModalView = this.decideUniqueEmailModalView.bind(this)
    this.onNewsletterDecisionSubmit = this.onNewsletterDecisionSubmit.bind(this)
    this.closeNewsletterDecisionModal = this.closeNewsletterDecisionModal.bind(this)
    this.closeUniqueEmailModal = this.closeUniqueEmailModal.bind(this)
    this.onUniqueEmailUpdateClick = this.onUniqueEmailUpdateClick.bind(this)

    this.fetchData()
      .then(this.startSessionChecking)
  }

  decideNewsletterDecisionModalView() {
    const pathRegexs = [/^\/user\/confirm-/, /\/accept-invitation$/, /\/checkout$/]
    const isLocationSkippable = () => pathRegexs.some(regex => !!this.props.location.pathname.match(regex))
    !isLocationSkippable() && canAskForNlDecision(this.props.user) && this.setState({
      newsletterDecisionModal: {
        active: true,
        shown: true
      }
    })
  }

  decideUniqueEmailModalView() {
    const pathRegexs = [/^\/user\/confirm-/, /\/accept-invitation$/, /\/checkout$/]
    const isLocationSkippable = () => pathRegexs.some(regex => !!this.props.location.pathname.match(regex))
    !isLocationSkippable() && canAskForUniqueEmailDecision(this.props.user) && this.setState({
      uniqueEmailModal: {
        active: true,
        shown: true
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workspaces !== this.props.workspaces) {
      this.updateWorkspaces()
    }

    this.props.user && prevProps.user !== this.props.user && this.decideNewsletterDecisionModalView()
    this.props.user && prevProps.user !== this.props.user && this.decideUniqueEmailModalView()
  }

  componentWillUnmount() {
    this.stopSessionChecking()
  }

  isFetchingData() {
    return this.state.isFetchingData || this.props.userIsFetching || this.props.userIsSwitching || this.state.isSwitchingWorkspace
  }

  fetchData() {
    if (!this.state.isFetchingData) {
      this.setState({ isFetchingData: true })
    }

    return Promise.all([
      this.props.fetchUser(),
      this.props.fetchProducts(),
    ])
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.props.triggerUserLoggedInEvent(this.props.user, location.pathname)
        this.setState({ isFetchingData: false })
      })
  }

  startSessionChecking() {
    sessionCheckInterval = setInterval(() => {
      const requestTimeDiff = this.getLastRequestTimeDiff()

      if (requestTimeDiff === null) {
        return
      }

      const sessionTimeLeft = Math.max(0, sessionLifetime - requestTimeDiff)

      if (sessionTimeLeft < 300) {
        this.setState({
          sessionModal: {
            active: true,
            timer: sessionTimeLeft,
          }
        })
      }
    }, 1000)
  }

  stopSessionChecking() {
    clearInterval(sessionCheckInterval)
    sessionCheckInterval = undefined
  }

  getLastRequestTimeDiff() {
    const { userLastRequestTime } = this.props

    return userLastRequestTime
      ? Math.abs(Math.round((userLastRequestTime - Date.now()) / 1000))
      : null
  }

  onExtendSession() {
    UserService.extendSession()
      .then(() => {
        this.setState({
          sessionModal: {
            active: false,
            timer: null,
          }
        })
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  updateWorkspaces() {
    const { user, workspaces } = this.props
    const sidebarWorkspaces = formatWorkspaces(user, workspaces)

    this.setState({ sidebarWorkspaces })
  }

  onSidebarWorkspaceSwitch(workspaceId) {
    const { history } = this.props

    new Promise(res => this.setState({ isSwitchingWorkspace: true }, res))
      .then(() => this.props.switchWorkspace(workspaceId))
      .then(() => {
        const path = generatePath(ROUTE_NAMES.USER_DASHBOARD, { workspace: workspaceId ? 'workspace' : 'user' })
        history.push(path)
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ isSwitchingWorkspace: false }))
  }

  switchWorkspace(workspaceId) {
    return new Promise(res => this.setState({ isSwitchingWorkspace: true }, res))
      .then(() => this.props.switchWorkspace(workspaceId))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ isSwitchingWorkspace: false }))
  }

  componentDidMount() {
    if (new URL(location.href).searchParams.get('widget')) {
      userRoutes.find(route => route.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_PREVIEW)).showSidebar = false
    }
  }

  canShowContent() {
    const { sessionModal } = this.state

    return sessionModal.timer === null || sessionModal.timer > 0
  }

  onNewsletterDecisionSubmit(values) {
    UserService
      .confirmNewsletterDecision(this.props.user, values.isNewsletterAccepted)
      .then(() => this.props.fetchUser())
      .then(() => this.closeNewsletterDecisionModal())
  }

  closeNewsletterDecisionModal() {
    this.setState({
      newsletterDecisionModal: {
        active: false,
        shown: true
      }
    }, () => updateNlSessionDecisionInfos(this.props.user))
  }

  closeUniqueEmailModal() {
    this.setState({
      uniqueEmailModal: {
        active: false,
        shown: true
      }
    }, () => updateUniqueEmailSessionDecisionInfos(this.props.user))
  }

  onUniqueEmailUpdateClick() {
    this.setState({
      uniqueEmailModal: {
        active: false,
        shown: true
      },
    }, () => updateUniqueEmailSessionDecisionInfos(this.props.user))

    this.props.history.push(generatePath(ROUTE_NAMES.USER_SETTINGS))
  }

  render() {
    if (this.isFetchingData() || !this.props.user) {
      return null
    }

    const { sessionModal, newsletterDecisionModal, uniqueEmailModal } = this.state
    const { match, scrollEnabled } = this.props
    const childrenProps = {
      showStatusMessage: this.props.showStatusMessage,
      t: this.props.t,
      i18n: this.props.i18n,
      user: this.props.user,
      fetchUser: this.props.fetchUser,
      resetUser: this.props.resetUser
    }

    const renderUserRoute = route => {
      const langPrefix = match.params[0] ? match.params[0] : ''
      const path = langPrefix + route.path.replace(/\/:workspace\//, match.params[1] + '/')
      let containerClassName = route.showSidebar || new URL(location.href).searchParams.get('widget') ?
        '' : 'page-container--full-height'

      if (route.containerClass) {
        containerClassName += ` ${route.containerClass}`
      }

      if (!scrollEnabled) {
        containerClassName = containerClassName ? containerClassName + ' ovh' : 'ovh'
      }

      return (
        <Route key={path} path={path}>
          {props => (
            <div id='page-container' className={containerClassName}>
              {route.showSidebar && this.canShowContent() && (
                <Sidebar
                  {...childrenProps}
                  workspaces={this.state.sidebarWorkspaces}
                  onWorkspaceSwitch={this.onSidebarWorkspaceSwitch}
                  path={route.path}
                  history={this.props.history}
                />
              )}
              {this.canShowContent() && (
                <route.component
                  {...childrenProps}
                  {...props}
                  allWorkspaces={this.state.sidebarWorkspaces}
                  switchWorkspace={this.switchWorkspace}
                />
              )}
              {sessionModal.active && (
                <ModalSessionEnd
                  t={this.props.t}
                  time={sessionModal.timer}
                  onExtendSessionClick={this.onExtendSession}
                />
              )}
              {!sessionModal.active && this.canShowContent() && newsletterDecisionModal.active && (
                <ModalNewsletterDecision
                  t={this.props.t}
                  active={newsletterDecisionModal.active}
                  onNewsletterDecisionSubmit={this.onNewsletterDecisionSubmit}
                  onNewsletterDecisionModalClose={this.closeNewsletterDecisionModal}
                />
              )}
              {!sessionModal.active && this.canShowContent() && uniqueEmailModal.active && (
                <ModalUniqueEmail
                  user={this.props.user}
                  t={this.props.t}
                  active={uniqueEmailModal.active}
                  onUniqueEmailModalClose={this.closeUniqueEmailModal}
                  onUniqueEmailUpdateClick={this.onUniqueEmailUpdateClick}
                />
              )}
            </div>
          )}
        </Route>
      )
    }

    return (
      <Switch>
        {userRoutes.map(renderUserRoute)}
      </Switch>
    )
  }
}

User.propTypes = {
  user: PropTypes.object,
  showStatusMessage: PropTypes.func,
  switchWorkspace: PropTypes.func,
  workspaces: PropTypes.array,
  product: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object,
  userIsFetching: PropTypes.bool,
  fetchUser: PropTypes.func,
  fetchProducts: PropTypes.func,
  userLastRequestTime: PropTypes.any,
  match: PropTypes.object,
  i18n: PropTypes.object,
  resetUser: PropTypes.func,
  userIsSwitching: PropTypes.bool,
  scrollEnabled: PropTypes.bool,
  triggerUserLoggedInEvent: PropTypes.func,
  location: PropTypes.object,
}

export default withTranslation()(User)