// @flow

import * as React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import store from '../../bootstrap/store'
import history from '../../bootstrap/history'
import Login from '../../containers/LoginRoute'
import Register from '../../routes/app/Register'
import Workspaces from '../../containers/WorkspacesRoute'
import User from '../../containers/UserRoute'
import ConfirmEmail from '../../containers/ConfirmEmailRoute'
import { ROUTE_PATHS, ROUTE_NAMES } from '../../routes/config'
import NeopayUserCallback from '../../containers/NeopayUserCallbackRoute'
import SmartIdLogin from '../../routes/app/SmartIdLogin'
import MobileIdLogin from '../../routes/app/MobileIdLogin'
import SmartIdRegister from '../../routes/app/SmartIdRegister'
import MobileIdRegister from '../../routes/app/MobileIdRegister'
import SoftwareDiagnostic from '../../containers/SoftwareDiagnosticRoute'
import SmartCardLogin from '../../containers/SmartCardLogin'
import SimplySignCallbackRoute from '../../containers/SimplySignCallbackRoute'
import LtIdLogin from '../../routes/app/LtIdLogin'
import LtIdRegister from '../../routes/app/LtIdRegister'

export default function App(): React.Node {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}

const langPrefix = '(/en|/lt|/pl)?'

function AppRouter(): React.Node {
  const { t } = useTranslation()
  const langCode = useSelector(state => state.i18n.langCode)

  React.useEffect(() => {
    document.title = t('common.tab_title')
  }, [ langCode ])

  return (
    <Router history={history}>
      <Switch>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.SOFTWARE_DIAGNOSTIC)}>
          {props => (
            <SoftwareDiagnostic {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.SIMPLY_SIGN_CALLBACK)}>
          {props => (
            <SimplySignCallbackRoute {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_LOGIN)}>
          {props => (
            <Login {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_LOGIN_SMART_ID)}>
          {props => (
            <SmartIdLogin {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_LOGIN_MOBILE_ID)}>
          {props => (
            <MobileIdLogin {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_LOGIN_LT_ID)}>
          {props => (
            <LtIdLogin {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_LOGIN_SMART_CARD)}>
          {props => (
            <SmartCardLogin {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_REGISTER)}>
          {props => (
            <Register {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_REGISTER_SMART_ID)}>
          {props => (
            <SmartIdRegister {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_REGISTER_MOBILE_ID)}>
          {props => (
            <MobileIdRegister {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_REGISTER_LT_ID)}>
          {props => (
            <LtIdRegister {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_REGISTER_SMART_CARD)}>
          {props => (
            <SmartCardLogin {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.USER_WORKSPACES)}>
          {props => (
            <Workspaces {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.CONFIRM_EMAIL)}>
          {props => (
            <ConfirmEmail {...props} />
          )}
        </Route>
        <Route path={langPrefix + ROUTE_PATHS.get(ROUTE_NAMES.NEOPAY_USER_CALLBACK)}>
          {props => (
            <NeopayUserCallback {...props} />
          )}
        </Route>
        <Route path={langPrefix + '(/user)'}>
          {props => (
            <User {...props} />
          )}
        </Route>
        <Route path={langPrefix + '(/workspace)'}>
          {props => (
            <User {...props} />
          )}
        </Route>
      </Switch>
    </Router>
  )
}
