import React from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import confirmEmail from '~/img/illustration/empty-documents.svg'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'

export default class ModalEmailConfirmation extends React.Component {
  render() {
    const { t } = this.props
    return (
      <Modal
        {...this.props}>
        <section id="pricing-success">
          <div className="grid">
            <div className="pricing-success__content email-confirmation-content">
              <img src={confirmEmail} alt={t('user.documents')} />
              <h3 className="standard-title">{t('user.login.please_verify_your_email')}</h3>
              <p className="message">{t('user.login.you_will_receive_email_shortly')}</p>
              <Link className="btn btn--medium-grey" to={generatePath(ROUTE_NAMES.USER_SETTINGS)}>{t('common.change_email')}</Link>
            </div>
          </div>
        </section>
      </Modal>
    )
  }
}

ModalEmailConfirmation.propTypes = {
  t: PropTypes.func
}