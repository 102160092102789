import Tooltip from '../Tooltip'
import ProIconImage from '~/img/icons/pro-icon.svg'
import FlexIconImage from '~/img/icons/flex-icon.svg'
import PropTypes from 'prop-types'

export default function IconTooltip({ tooltipText, inline, newLine, flex, extraClass }) {
  return (
    <Tooltip text={tooltipText} inline={inline} newLine={newLine} img={true}>
      <img className={(extraClass ? extraClass : '') + ' pro-icon'} src={flex ? FlexIconImage : ProIconImage} alt={flex ? 'Flex Icon' : 'Pro Icon'} />
    </Tooltip>
  )
}

IconTooltip.propTypes = {
  tooltipText: PropTypes.string,
  inline: PropTypes.bool,
  newLine: PropTypes.bool,
  flex: PropTypes.bool,
  extraClass: PropTypes.string,
}
