import React from 'react'
import PropTypes from 'prop-types'

export default class ContactAssign extends React.Component {
  render() {
    return (
      <li>
        <div className="form-group__checkbox form-group__checkbox--single">
          <input
            className="checkbox__contact" type="checkbox" id={this.props.id}
            checked={this.props.selected} onChange={this.props.onSelect}
          />
          <label htmlFor={this.props.id} />
        </div>
        <span className="list__name cursor-pointer" onClick={this.props.onSelect} >
          <strong>{this.props.name} {this.props.surname}</strong>
        </span>
        <span className="cursor-pointer" onClick={this.props.onSelect}>{this.props.email}</span>
      </li>
    )
  }
}

ContactAssign.propTypes = {
  onSelect: PropTypes.func,
  id: PropTypes.number,
  surname: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  selected: PropTypes.bool,
}
