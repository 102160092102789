import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import markSignLogo from '~/img/marksign-logo-blue.svg'

class ModalUniqueEmail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t, user } = this.props
    const onCloseClick = this.props.onUniqueEmailModalClose

    if (!this.props.active) {
      return null
    }

    return (
      <div className="popup popup__user modal-newsletter-decision js-active">
        <div className="popup__overlay" />
        <OutsideClickHandler
          onOutsideClick={onCloseClick}
        >
          <div className="popup__content">
            <img className="logo" src={markSignLogo} alt="logo"></img>
            <a
              className="popup__close js-popup__close" onClick={e => {
                e.preventDefault()
                onCloseClick()
              }}
            />
            <h4 className="ta-center title">{t('unique_email_modal.title')}</h4>
            <p className="ta-center description">{t('unique_email_modal.description')}</p>
            <p className="ta-center description">
              {!user.workspaceId && <span className="profile-description" dangerouslySetInnerHTML={{ __html: t('unique_email_modal.update_profile') }} />}
              {user.workspaceId && <span className="profile-description" dangerouslySetInnerHTML={{ __html: t('unique_email_modal.update_personal_profile') }} />}
              <a className="profile-update-link" onClick={e => {e.preventDefault(); this.props.onUniqueEmailUpdateClick()}}> {t('unique_email_modal.update_profile_link')}</a>
            </p>
            <a
              onClick={e => {e.preventDefault(); this.props.onUniqueEmailUpdateClick()}}
              className="btn btn--primary btn-submit ta-center"
            >
              {t('unique_email_modal.button')}
            </a>

            <p className="ta-center t-and-c">
              <Trans i18nKey="unique_email_modal.how_do_you_change">
                <a href={t('helpdesk.how_do_you_change')} target="_blank" rel="noreferrer">How do you change it</a>
              </Trans>
            </p>
          </div>

        </OutsideClickHandler>
      </div>
    )
  }
}

export default withTranslation()(ModalUniqueEmail)

ModalUniqueEmail.propTypes = {
  active: PropTypes.bool,
  t: PropTypes.func,
  onUniqueEmailModalClose: PropTypes.func,
  onUniqueEmailUpdateClick: PropTypes.func,
  user: PropTypes.object,
}